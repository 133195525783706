import { Component, Injector } from '@angular/core';
import { LoggedComponent } from '../../../shared/components/logged/logged.component';
import { AdministrationService } from '../../../core/services/administration.service';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import { TranslationService } from '../../../core/services/translation.service';
import { NotificationService } from '../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'translation-management',
  templateUrl: './translation-management.component.html',
  styleUrls: ['./translation-management.component.scss']
})
export class TranslationManagementComponent extends LoggedComponent {
  fileUploaded = false;
  fileName = '';
  base64data: string | ArrayBuffer;
  translate: TranslateService = this.injector.get(TranslateService);

  constructor(
    userService: UserService,
    private layoutService: LayoutService,
    private injector: Injector,
    public i18n: TranslateService,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    public administrationService: AdministrationService,
    private loaderService: LoaderService
  ) {
    super(userService);
  }

  onFileSelected(event) {
    this.loaderService.showLoader();

    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        this.base64data = reader.result;
        this.fileName = selectedFile.name;
        this.loaderService.hideLoader();
      };
    } else {
      this.loaderService.hideLoader();
    }
    this.fileUploaded = true;
  }

  async uploadTrad() {
    try {
      this.loaderService.showLoader();

      await this.translationService.uploadTranslation(this.base64data);

      const summary = await this.translate
        .get('TRANSLATION_UPDATE')
        .toPromise();
      const message = await this.translate
        .get('TRANSLATION_UPDATED')
        .toPromise();
      this.i18n.setDefaultLang(this.userService.user.language.toLowerCase());
      this.i18n.use(this.userService.user.language.toLowerCase());
      this.notificationService.addSingleInfo(summary, message);
    } catch (e) {
      this.notificationService.addSingleError(e);
    }
    finally {
      this.loaderService.hideLoader();
    }
    
    this.fileUploaded = false;
    this.fileName = '';
  }

  async exportTranslation() {
    try {
      this.loaderService.showLoader();

      await this.translationService.exportTranslation();
    } catch (error) {
    } finally {
      this.loaderService.hideLoader();
    }
  }
}
