import { Component, Injector, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { LoggedComponent } from '../../../shared/components/logged/logged.component';
import { User } from '../../../models/user.model';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { CreateUserComponent } from '../create-user/create-user.component';
import { LoaderService } from 'app/core/services/loader.service';
import { MunicipalityService } from '../../../core/services/municipality.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent extends LoggedComponent implements OnInit, AfterViewInit {
  rolesOptions: SelectItem[];
  municipalityOptions: SelectItem[];
  users: User[] = [];

  @ViewChild('table', { static: true })
  table: Table;

  private translate = this.injector.get(TranslateService);

  constructor(
    public userService: UserService,
    public municipalityService: MunicipalityService,
    private injector: Injector,
    private layoutService: LayoutService,
    private loaderService: LoaderService
  ) {
    super(userService);
  }

  async ngOnInit() {
  }

  async ngAfterViewInit() {
    await this.initLists();
  }

  async initLists() {
    this.loaderService.showLoader();

    try {
      await this.userService.getUsersAll().then(users => this.users = this.initUsers(users));
      await this.userService.findAllRoles();
      this.rolesOptions = [];
      this.municipalityOptions = [];
      // this.rolesOptions.push({label: 'Tous', value: null});
      await this.userService.roles.forEach(async role => {
        const trad = await this.translate.get(role.code).toPromise();
        this.rolesOptions.push({ label: trad, value: role.id });
      });
      const municipalites = await this.municipalityService.findAll();
      municipalites.forEach(async mun => {
        this.municipalityOptions.push({ label: mun.name, value: mun.id });
      });
      this.municipalityOptions.sort(function (a, b) {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }
        return 0;
      });
    } catch (error) {
      console.log('ERROR' + error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async editUser(user: User) {
    await this.initLists();
    this.userService.manageUserMode = true;
    this.userService.selectedUser = Object.assign({}, user);
    this.userService.showList = false;
  }

  async addUser() {
    await this.initLists();
    const user: User = { id: null };
    this.userService.selectedUser = user;
    this.layoutService.centerPanelContent = CreateUserComponent;
  }

  public filter(value: string, field: string, op: any) {
    this.table.filter(value, field, op);
  }

  public initUsers(users: User[]) {
    if (users) {
      return users.map(u => {
        u['rolesId'] = u.roles.map(r => r.id);
        u['roleId'] = u['rolesId'][0];
        u['municipalityId'] = u.municipalities && u.municipalities.length > 0 ? u.municipalities[0].id : undefined;
        return u;
      });
    } else {
      return undefined;
    }
  }
}
