import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import { AdministrationComponent } from '../../administration/administration/administration.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'administration-icon',
  templateUrl: './layout.administration-icon.component.html',
  styleUrls: ['./layout.administration-icon.component.scss']
})
export class AdministrationIconComponent implements OnInit {
  constructor(
    public userService: UserService,
    private i18n: TranslateService,
    private layoutService: LayoutService
  ) {}

  showAdmin() {
    if (
      this.layoutService.centerPanelComponent !==
      this.layoutService.CENTRAL_PANEL_ADMINISTRATION
    ) {
      this.layoutService.centerPanelComponent = this.layoutService.CENTRAL_PANEL_ADMINISTRATION;
      this.layoutService.closeRightPanel();
      this.layoutService.closeLeftPanel();
      this.layoutService.centerPanelContent = AdministrationComponent;
      this.layoutService.centerPanelVisible = true;
    }
  }
  ngOnInit() {}
}
