import { Injectable } from '@angular/core';
import { User } from '../../models/user.model';

import { environment } from '../../../environments/environment';
import { BaseAbstractService } from './base-abstract.service';
import { roleLink } from '../../../assets/config/roleLink';
import { Role, ROLES } from '../../models/role.model';

import * as ol from 'ol';
import * as olGeom from 'ol/geom';
import * as olFormat from 'ol/format';
import { CryptoService } from './crypto.service';
import { Municipality } from '../../models/municipality.models';
import { MunicipalityService } from './municipality.service';

@Injectable()
export class UserService extends BaseAbstractService {
  ROLE_CREATE = false;
  ROLE_UNACTIVATE = false;
  ROLE_EDIT = false;
  ROLE_READ = false;
  ROLE_WORKFLOW = false;
  ROLE_CREATE_RC = false;
  ROLE_RECENSE = false;

  secretaryCode = 'SECRETARY_CC';
  agentCode = 'MUNICIPALITY_AGENT';
  sprbCode = 'SPRB_AGENT';
  contractorCode = 'CONTRACTOR';

  private _roles: Role[];
  private _editMode = false;

  private _showList = true;

  private _languages: string[] = ['FR', 'NL'];

  private _manageUserMode: boolean;

  private _selectedUser: User;

  private _users: User[];

  async findAllRoles(): Promise<Role[]> {
    this.roles = this.roles ? this.roles : await this.get(`${this.url}/userRoles`);
    return this.roles;
  }

  get roles(): Role[] {
    return this._roles;
  }

  set roles(value: Role[]) {
    this._roles = value;
  }

  get editMode(): boolean {
    return this._editMode;
  }

  set editMode(value: boolean) {
    this._editMode = value;
  }

  get showList(): boolean {
    return this._showList;
  }

  set showList(value: boolean) {
    if (!this._showList && value) {
      this.getUsersAll();
    }
    this._showList = value;
  }

  get languages() {
    return this._languages;
  }

  set languages(value) {
    this._languages = value;
  }

  get manageUserMode(): boolean {
    return this._manageUserMode;
  }

  set manageUserMode(value: boolean) {
    this._manageUserMode = value;
  }

  get selectedUser(): User {
    this.updateUserMunicipality(this._selectedUser);
    return this._selectedUser;
  }

  set selectedUser(value: User) {
    this._selectedUser = value;
  }

  get users(): User[] {
    return this._users;
  }

  set users(value: User[]) {
    this._users = value;
  }

  get role(): Role {
    return this.user.roles[0];
  }

  hasToken(): boolean {
    return this._session.oAuthtoken != null && typeof this._session.oAuthtoken !== 'undefined';
  }

  checkRole() {
    this.ROLE_CREATE = false;
    this.ROLE_UNACTIVATE = false;
    this.ROLE_EDIT = false;
    this.ROLE_READ = false;
    this.ROLE_WORKFLOW = false;
    this.ROLE_CREATE_RC = false;
    this.ROLE_RECENSE = false;
    if (this.user && this.user.roles.length > 0) {
      for (const role of this.user.roles) {
        if (roleLink[role.code].CREATE) {
          this.ROLE_CREATE = true;
        }
        if (roleLink[role.code].UNACTIVATE) {
          this.ROLE_UNACTIVATE = true;
        }
        if (roleLink[role.code].EDIT) {
          this.ROLE_EDIT = true;
        }
        if (roleLink[role.code].READ) {
          this.ROLE_READ = true;
        }
        if (roleLink[role.code].WORKFLOW) {
          this.ROLE_WORKFLOW = true;
        }
        if (roleLink[role.code].CREATE_RC) {
          this.ROLE_CREATE_RC = true;
        }
        if (roleLink[role.code].RECENSE) {
          this.ROLE_RECENSE = true;
        }
      }
    }
  }

  get url(): string {
    return environment.backendURL + environment.identityPath + this.configService.appConfig.apiUrl + 'User/';
  }

  async getUser(): Promise<User> {
    const user = await this.get<any>(this.url);
    this.user = user;

    this.checkRole();
    return this.user;
  }

  async getUsersAll(): Promise<User[]> {
    this.users = this.users ? this.users : await this.get<any[]>(`${this.url}users/all`);
    return this.users.sort((u1, u2) => {
      return u1.login.localeCompare(u2.login);
    });
  }

  async createUser(user: User): Promise<User> {
    const _user = user;
    const role = user['role'] ? (user['role'].value ? user['role'].value : user['role']) : _user.roles[0];
    if (role.code !== ROLES.MUNICIPALITY_AGENT) {
      _user.municipality = null;
    }
    if (user.municipality) {
      const mun = _user.municipality;
      delete _user.municipality;
      _user.municipalities = [mun];
    } else {
      delete _user.municipality;
      delete _user.municipalities;
      _user.municipalities = [];
    }

    _user.language = _user.language['value'] ? _user.language['value'] : _user.language;
    _user.isActive = _user.isActive ? true : false;
    _user.mobile = _user.mobile ? true : false;
    _user.desktop = _user.desktop ? true : false;
    _user.isAdmin = _user.isAdmin ? true : false;
    if (user['role']) {
      _user.roles = [role];

      delete _user['role'];
    }
    const updated = await this.post<User>(this.url, _user);
    return updated;
  }

  async updateUser(user: User): Promise<User> {
    const _user = user;
    const role = user['role'] ? (user['role'].value ? user['role'].value : user['role']) : _user.roles[0];
    if (role.code !== ROLES.MUNICIPALITY_AGENT) {
      _user.municipality = null;
    }
    if (user.municipality) {
      const mun = _user.municipality;
      delete _user.municipality;
      _user.municipalities = [mun];
    } else {
      delete _user.municipality;
      delete _user.municipalities;
      _user.municipalities = [];
    }

    _user.language = _user.language['value'] ? _user.language['value'] : _user.language;
    _user.isActive = _user.isActive ? true : false;
    _user.mobile = _user.mobile ? true : false;
    _user.desktop = _user.desktop ? true : false;
    _user.isAdmin = _user.isAdmin ? true : false;
    if (user['role']) {
      _user.roles = [role];

      delete _user['role'];
    }
    const updated = await this.put<User>(this.url, _user);
    if (this.user.login.toLocaleLowerCase() === updated.login.toLocaleLowerCase()) {
      this.user = updated;
    }
    const found = this.users.find(u => u.login.toLocaleLowerCase() === updated.login.toLocaleLowerCase());
    Object.assign(found, updated);
    return updated;
  }

  async queryUsers(req?: any): Promise<User[]> {
    return await this.get<any[]>(this.url);
  }

  deleteUser(login: string): Promise<boolean> {
    return this.delete<boolean>(`${this.url}${login.toLocaleLowerCase()}`);
  }

  changePasswordUser(password: string): Promise<boolean> {
    return this.post<boolean>(`${this.url}change_password`, password);
  }

  resetPassword(login: string, mail: string): Promise<boolean> {
    const data = {
      username: login.toLocaleLowerCase(),
      email: mail,
    };
    return this.postNoAuth<boolean>(`${this.url}reset`, data);
  }

  async checkLogin(): Promise<any> {
    await this.checkCredentials();
    if (this.hasToken()) {
      await this.getUser();
    }
  }

  changeUserLanguage(language: string): Promise<User> {
    if (this.user) {
      this.user.language = language;
    }
    return super.put<User>(`${this.url}${language}`, language);
  }

  hasRole(code: string) {
    return this.user &&
      this.user.roles.find(role => {
        return role.code === code;
      })
      ? true
      : false;
  }

  isInMunicipalities(geom: string): boolean {
    if (this.ROLE_EDIT) {
      if (this.hasRole(this.sprbCode) || this.hasRole(this.contractorCode)) {
        return true;
      } else if (this.hasRole(this.agentCode) && this.municipality) {
        const feature = new olFormat.GeoJSON().readFeature(geom);
        const coordinate = (<olGeom.Point>feature.getGeometry()).getCoordinates();
        if (this.geom && this.geom.intersectsCoordinate(coordinate)) {
          return true;
        }
      }
    }
    return false;
  }

  async checkPassword(password: string): Promise<boolean> {
    const crypted = btoa(CryptoService.cryptAsSHA256(password));
    const data = { login: this.user.login.toLocaleLowerCase(), password: crypted };
    return await this.post<boolean>(this.url + 'checkPassword', data);
  }

  async updatePassword(password: string): Promise<boolean> {
    const crypted = btoa(CryptoService.cryptAsSHA256(password));
    const data = { login: this.user.login.toLocaleLowerCase(), password: crypted };
    return await this.post<boolean>(this.url + 'updatePassword', data);
  }
  get feature(): ol.Feature {
    return MunicipalityService.parseFeature(this.municipality);
  }
  get geom(): olGeom.MultiPolygon {
    return MunicipalityService.parseGeom(this.municipality);
  }
  get municipality(): Municipality {
    this.updateUserMunicipality(this.user);
    return this.user.municipality;
  }

  updateUserMunicipality(user: User) {
    user.municipality = user.municipalities && user.municipalities[0] ? user.municipalities[0] : null;
  }
}
