import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login.component';


export const LoginRoutes: Routes = [
    { path: '', component: LoginComponent }
];


@NgModule({
    providers: [],
    imports: [ RouterModule.forChild(LoginRoutes) ]
})
export class LoginRoutingModule {}
