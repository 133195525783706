import { Message } from 'primeng/components/common/api';
import * as geom from 'ol/geom';
import { saveAs } from 'file-saver';

export default class Utils {
  static makeUniqueArray(sources: any[]): any[] {
    const results: any[] = [];

    sources.forEach(source => {
      if (
        results.findIndex(result => {
          if (typeof result.isEqual === 'function') {
            return result.isEqual(source);
          } else {
            return result === source;
          }
        }) === -1
      ) {
        results.push(source);
      }
    });

    return results;
  }

  static daysBetween(dateStart: Date, dateEnd: Date): number {
    // Get 1 day in milliseconds
    const one_day = 1000 * 60 * 60 * 24;
    // Convert both dates to milliseconds
    const date1_ms = dateStart.getTime();
    const date2_ms = dateEnd.getTime();
    // Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms;
    // Convert back to days and return
    return Math.round(difference_ms / one_day);
  }

  static showError(errorMessages: Message[], error) {
    if (typeof error.error === typeof 'string') {
      errorMessages.push({
        severity: 'error',
        summary: 'Error',
        detail: error.error
      });
    } else {
      errorMessages.push({
        severity: 'error',
        summary: 'Error',
        detail: error.message
      });
    }
  }

  static getGeomMultiPolyFromString(geomString: string) {
    const geomJSON = JSON.parse(geomString);
    const geomOL = new geom.MultiPolygon(geomJSON.coordinates);
    return geomOL;
  }

  static getGeomPointFromString(geomString: string) {
    const geomJSON = JSON.parse(geomString);
    const geomOL = new geom.Point(geomJSON.coordinates);
    return geomOL;
  }

  static downloadBlob(blob: any, title: string) {
    saveAs(blob, title);
    /*const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = downloadUrl;
    a.download = title;
    document.body.appendChild(a);
    a.click();*/
  }

  static getAddressLabel(address): string {
    if (address) {
      try {
        const addressParsed = JSON.parse(address);
        return `${addressParsed.name}, ${addressParsed.postCode} ${addressParsed.municipality}`;
      } catch (e) {}
    }
    return null;
  }
}

export const CALENDAR_LOCALE_EN = {
  closeText: 'Done',
  prevText: 'Prev',
  nextText: 'Next',
  currentText: 'Today',
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  monthNamesShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],
  dayNames: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  weekHeader: 'Wk',
  dateFormat: 'dd/mm/yy',
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ''
};
