import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MunicipalityService } from '../../core/services/municipality.service';

@Pipe({ name: 'municipalityName' })
export class MunicipalityNamePipe implements PipeTransform {
  constructor(private municipalityService: MunicipalityService, private translateService: TranslateService) {
  }

  async transform(id: number): Promise<string> {
    const mun = await this.municipalityService.getMunicipalityForId(id);
    if (this.translateService.currentLang &&
      this.translateService.currentLang.toLocaleLowerCase() === 'fr') {
      return mun.nameFr;
    } else {
      return mun.nameNl;
    }
  }
}
