<div class="ui-g" *ngIf="loaded">
  <div class="ui-g-12">
    <div class="ui-g-4">

    </div>
    <div class="ui-g-4">
      <p-panel>
        <p-header>
          <span>{{'NEW-USER'|translate}}</span>
        </p-header>

        <form role="form" novalidate [formGroup]="userForm" (ngSubmit)="save(userForm.value)">
          <div class="ui-g">

            <!--LOGIN-->
            <div class="ui-g-5">
              <label class="custom-label">{{'LOGIN'|translate}}</label>
            </div>
            <div class="ui-g-7">
              <input type="text" class="editSupportInput" pInputText formControlName="login" required>
            </div>

            <!--FIRSTNAME-->
            <div class="ui-g-5">
              <label class="custom-label">{{'SURNAME'|translate}}</label>
            </div>
            <div class="ui-g-7">
              <input type="text" class="editSupportInput" pInputText formControlName="surname" required>
            </div>

            <!--NAME-->
            <div class="ui-g-5">
              <label class="custom-label">{{'NAME'|translate}}</label>
            </div>
            <div class="ui-g-7">
              <input type="text" class="editSupportInput" pInputText formControlName="name"  required>
            </div>

            <!--MAIL-->
            <div class="ui-g-5">
              <label class="custom-label">{{'MAIL_ADDRESS'|translate}}</label>
            </div>
            <div class="ui-g-7">
              <input type="email" class="editSupportInput" pInputText formControlName="email"  required>
            </div>
            <!--LANGUE-->
            <div class="ui-g-5">
              <label class="custom-label">{{'LANGUAGE'|translate}}</label>
            </div>
            <div class="ui-g-7">
              <p-dropdown formControlName="language" [style]="{'width':'100%'}" [options]="languagesOptions" ></p-dropdown>
            </div>

            <!--ROLE-->
            <div class="ui-g-5">
              <label class="custom-label">{{'ROLE'|translate}}</label>
            </div>
            <div class="ui-g-7">
              <p-dropdown formControlName="role" (onChange)="roleChanged($event)" [style]="{'width':'100%'}" [options]="rolesOptions" ></p-dropdown>
            </div>

            <!--MUNICIPALITY-->
            <div *ngIf="showMunicipalitiesDropdown()" class="ui-g-5">
              <label class="custom-label">{{'TOWN'|translate}}</label>
            </div>
            <div *ngIf="showMunicipalitiesDropdown()" class="ui-g-7">
              <p-dropdown formControlName="municipality" [style]="{'width':'100%'}" [options]="municipalityOptions" ></p-dropdown>
            </div>

            <!--ADMIN-->
            <div class="ui-g-5">
              <label class="custom-label">{{"ADMINISTRATOR" | translate}}</label>
            </div>
            <div class="ui-g-7">
              <p-checkbox formControlName="isAdmin"  binary="true"></p-checkbox>
            </div>

            <!--MOBILE-->
            <div class="ui-g-5">
              <label class="custom-label">{{"SHOW_USER:LABEL:MOBILE" | translate}}</label>
            </div>
            <div class="ui-g-7">
              <p-checkbox formControlName="mobile"  binary="true"></p-checkbox>
            </div>

            <!--DESKTOP-->
            <div class="ui-g-5">
              <label class="custom-label">{{"SHOW_USER:LABEL:DESKTOP" | translate}}</label>
            </div>
            <div class="ui-g-7">
              <p-checkbox formControlName="desktop"  binary="true"></p-checkbox>
            </div>

            <!--ACTIVITY-->
            <div class="ui-g-5">
              <label class="custom-label">{{'ACTIVE'|translate}}</label>
            </div>
            <div class="ui-g-7">
              <p-checkbox formControlName="isActive"  binary="true"></p-checkbox>
            </div>
          </div>

          <div class="ui-g-">
            <div class="ui-g-12" style="text-align: right; margin-top: 10px;">
              <p-button (click)="cancel()" icon="fas fa-times" [ngStyle]="{'margin-left':'5px', 'margin-right': '10px'}" class="secondary" label="{{'CANCEL'|translate}}"></p-button>
              <p-button [disabled]="userForm.invalid" type="submit" icon="fas fa-save" [ngStyle]="{'margin-left':'5px'}" label="{{'SAVE'|translate}}"></p-button>
            </div>
          </div>
        </form>
      </p-panel>
    </div>
    <div class="ui-g-4">

    </div>
  </div>
</div>
