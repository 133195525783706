<div class="sign" style="background-color: white;">

  <p-panel class="customPanel">
    <p-header id="panel-sign-view-header">
      <div class="ui-g" [ngClass]="{'view-sign': !signService.editMode, 'edit-sign': signService.editMode}">
        <div class="ui-g-9">
          <img *ngIf="editSign.type" src="{{editSign.type| signTypeImage}}" alt="pic" style="max-height: 25px; margin-right: 5px;" />
          <span style="vertical-align: top;">{{ 'SIGN' | translate }}</span>
        </div>
        <div class="ui-g-3">
          <i style="float: right; cursor:pointer; margin: 0 0 0 5px;;" class="fa fa-times iconHover" (click)="close()"></i>
          <i style="float: right; cursor:pointer; margin: 0 0 0 5px;;" class=" fa fa-expand-arrows-alt iconHover"
            (click)="expand()"></i>
        </div>
      </div>
    </p-header>

    <p-tabView class="tabView tabView-no-padding">

      <p-tabPanel header="{{'DETAILS' | translate}}">
        <form [formGroup]="signForm" [ngClass]="{'view-sign': !edit, 'edit-sign': signService.editMode}">

          <div class="ui-g">
            <h3 style="margin: 25px 0 0 0;">{{ "ADMINISTRATIVE_DETAILS" | translate }}</h3>
            <div class="ui-g-12 signItem">
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ "MANAGER" | translate }}</label><span class="edit" *ngIf="edit"> *</span>
                </div>
                <div class="ui-g-7 view">
                  <span *ngIf="editSign.gestion">{{editSign.gestion.code | translate }}</span>
                </div>
                <div class="ui-g-7" *ngIf="edit">
                  <p-dropdown class="editSignInput" [options]="signGestionOptions" [(ngModel)]="editSign.gestion"
                    [style]="{'width':'100%'}" [filter]="true" formControlName="gestion" required></p-dropdown>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ "PLACEMENT" | translate }}</label><span class="edit" *ngIf="edit"> *</span>
                </div>
                <div class="ui-g-7 view">
                  <span *ngIf="editSign.placement">{{editSign.placement.code | translate }}</span>
                </div>
                <div class="ui-g-7 edit" *ngIf="edit">
                  <p-dropdown class="editSignInput" [options]="signPlacementOptions" [(ngModel)]="editSign.placement"
                    [style]="{'width':'100%'}" [filter]="true" formControlName="placement" required></p-dropdown>
                </div>
              </div>

              <div class="ui-g view">
                <div class="ui-g-5">
                  <label>{{ "TYPE_OF_REQUEST" | translate }}</label>
                </div>
                <div class="ui-g-7">
                  <span>{{editSign.requestType}}</span>
                </div>
              </div>
              <div class="ui-g edit" *ngIf="edit">
                <div class="ui-g-12">
                  <label>{{ "TYPE_OF_REQUEST" | translate }}</label>
                </div>
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <textarea class="editSignInput" formControlName="requestType" pInputTextarea [rows]="5" [(ngModel)]="editSign.requestType"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ui-g">
            <h3 style="margin: 25px 0 0 0;">{{ "TECHNICAL_DETAILS" | translate }}</h3>
            <div class="ui-g-12 signItem">
              <!-- CATEGORY -->
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ "CATEGORY" | translate }}
                    <span class="edit" *ngIf="edit"> *</span>
                  </label>
                </div>
                <div class="ui-g-7 view">
                  <!--Simplest  way for tooltiping because z-index has been override everywhere so primeng pTooltip doesnt work (it is bagdad over here) -->
                  <span *ngIf="signService.sign.type;let type" title="{{(type.typeCategorie.code) | translate}}">{{(type.typeCategorie.code) | translate}}</span>
                </div>
                <div class="ui-g-7 edit" *ngIf="editSign">
                  <p-dropdown [style]="{'width':'100%'}" [options]="signCategoryOptions" dataKey="id" [formControlName]="'category'" (onChange)="filterSignTypeOptions($event)">
                  </p-dropdown>
                </div>
              </div>
              <!-- TYPE -->
              <div *ngIf="signForm.controls['category'].value" class="ui-g">
                <div class="ui-g-5">
                  <label>{{ "TYPE" | translate }}
                    <span class="edit" *ngIf="edit"> *</span>
                  </label>
                </div>
                <div class="ui-g-7 view">
                  <span *ngIf="signService.sign.type;let type" title="{{type.code}} - {{ type | signTypeLabel }}">{{type.code}} - {{type | signTypeLabel}}</span>
                </div>
                <div class="ui-g-7 edit" *ngIf="edit">
                  <p-dropdown class="editSignInput" [options]="filteredSignTypeOptions" [(ngModel)]="editSign.type" dataKey="id"
                    [style]="{'width':'100%'}" filter="true" [filter]="true" formControlName="type" required>
                    <ng-template let-type pTemplate="selectedItem">
                      <img *ngIf="type.value.code!='EMPTY'" src="{{type.value| signTypeImage}}" style="width:24px;position:absolute;top:1px;left:5px" />
                      <div style="font-size:14px; margin-top:4px; margin-left: 35px;">{{type.label}}</div>
                    </ng-template>
                    <ng-template let-type pTemplate="item">
                      <div class="ui-helper-clearfix" style="position: relative;height: 100%;">
                        <img *ngIf="type.value.code!='EMPTY'" src="{{type.value| signTypeImage}}" style="width:24px;position:absolute;top:1px;left:5px" />
                        <div style="font-size:14px; margin-top:4px; margin-left: 35px; width: 200px;">
                          {{type.label}}
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ "FORMAT" | translate }}
                    <span class="edit" *ngIf="edit"> *</span>
                  </label>
                </div>
                <div class="ui-g-7 view">
                  <span *ngIf="editSign.format">{{editSign.format.code | translate }}</span>
                </div>
                <div class="ui-g-7 edit" *ngIf="edit">
                  <p-dropdown class="editSignInput" [options]="signFormatOptions" [(ngModel)]="editSign.format" [style]="{'width':'100%'}"
                    [filter]="true" formControlName="format" required></p-dropdown>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ "SPECIFICITY" | translate }}
                    <span class="edit"> *</span>
                  </label>
                </div>
                <div class="ui-g-7 view">
                  <span *ngIf="editSign.specificity">{{editSign.specificity.code | translate }}</span>
                </div>
                <div class="ui-g-7 edit" *ngIf="edit">
                  <p-dropdown class="editSignInput" [options]="signSpecificityOptions" [(ngModel)]="editSign.specificity"
                    [style]="{'width':'100%'}" [filter]="true" formControlName="specificity" required></p-dropdown>
                </div>
              </div>
              <div class="ui-g" *ngIf="editSign.type?.generic">

                <div class="ui-g-12 view">
                  <div class="ui-g-5">
                    <label>{{ "SIGN:GENERIC:BOOLEAN:TEXT" | translate }} FR</label>
                  </div>
                  <div class="ui-g-7">
                    <span>{{signService.sign.genericTextFr}}</span>
                  </div>
                </div>

                <div class="ui-g-12 edit" *ngIf="edit">
                  <div class="ui-g-12">
                    <label>{{ "SIGN:GENERIC:BOOLEAN:TEXT" | translate }} FR</label>
                  </div>
                  <div class="ui-g-12">
                    <div class="ui-inputgroup">
                      <textarea class="editSignInput" formControlName="genericCom" pInputTextarea [rows]="5"
                        [(ngModel)]="editSign.genericTextFr"></textarea>
                    </div>
                  </div>
                </div>

                <div class="ui-g-12 view">
                  <div class="ui-g-5">
                    <label>{{ "SIGN:GENERIC:BOOLEAN:TEXT" | translate }} NL</label>
                  </div>
                  <div class="ui-g-7">
                    <span>{{signService.sign.genericTextNl}}</span>
                  </div>
                </div>

                <div class="ui-g-12 edit" *ngIf="edit">
                  <div class="ui-g-12">
                    <label>{{ "SIGN:GENERIC:BOOLEAN:TEXT" | translate }} NL</label>
                  </div>
                  <div class="ui-g-12">
                    <div class="ui-inputgroup">
                      <textarea class="editSignInput" formControlName="genericCom" pInputTextarea [rows]="5"
                        [(ngModel)]="editSign.genericTextNl"></textarea>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="ui-g view">
            <h3 style="margin: 25px 0 0 0;">{{ "PANEL_PHOTOGRAPHS" | translate }}</h3>
            <div class="ui-g-12 signItem">
              <picture-view [imgs]="signService.sign.imgs" [pictureService]="pictureServiceSign" [sourceId]="signService.sign.id"
                *ngIf="signService.sign.imgs && signService.sign.imgs.length>0"></picture-view>
            </div>
          </div>
          <div class="ui-g edit" *ngIf="edit">
            <h3 style="margin: 25px 0 0 0;">{{ "PANEL_PHOTOGRAPHS" | translate }}</h3>
            <div class="ui-g-12 signItem">
              <picture-view [imgs]="editSign.imgs" [pictureService]="pictureServiceSign" [sourceId]="signService.sign.id"
                [canEdit]="true" *ngIf="pictureServiceSign.imgs"></picture-view>
            </div>
          </div>

          <div class="ui-g">
            <h3 style="margin: 25px 0 0 0;">{{ "OTHER_INFORMATIONS" | translate }}</h3>
            <div class="ui-g-12 signItem">

              <div class="ui-g view">
                <div class="ui-g-5">
                  <label>{{ "REMARKS" | translate }}</label>
                </div>
                <div class="ui-g-7">
                  <span>{{editSign.note}}</span>
                </div>
              </div>

              <div class="ui-g edit" *ngIf="edit">
                <div class="ui-g-12">
                  <label>{{ "REMARKS" | translate }}</label>
                </div>
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <textarea class="editSignInput" formControlName="note" pInputTextarea [rows]="5" [(ngModel)]="editSign.note"></textarea>
                  </div>
                </div>
              </div>

              <div class="ui-g view">
                <div class="ui-g-5">
                  <label>{{ "OTHERS" | translate }}</label>
                </div>
                <div class="ui-g-7">
                  <span>{{editSign.other}}</span>
                </div>
              </div>

              <div class="ui-g edit" *ngIf="edit">
                <div class="ui-g-12">
                  <label>{{ "OTHERS" | translate }}</label>
                </div>
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <textarea class="editSignInput" formControlName="other" pInputTextarea [rows]="5" [(ngModel)]="editSign.other"></textarea>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="ui-g view sticky-bottom-buttons">
            <div class="ui-g-12">
              <p-button icon="fa fa-edit" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'EDIT' | translate}}"
                (click)="enableEdit()" *ngIf="canUpdate"></p-button>
              <p-button icon="fal fa-clone" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'DUPLICATE' | translate}}"
                (click)="enableDuplicate()" *ngIf="canUpdate && this.showDuplicateButton"></p-button>
              <p-button icon="fa fa-arrow-left" [ngStyle]="{'margin-left':'5px'}" class="secondary right" label="{{'BACK' | translate}}"
                (click)="backSupport()"></p-button>
            </div>
          </div>

          <div class="ui-g" *ngIf="edit">
            <div class="ui-g-12">
              <p-messages [(value)]="errorMessages"></p-messages>
            </div>
          </div>
          <div class="ui-g sticky-bottom-buttons" *ngIf="edit && canUpdate">
            <div class="ui-g-12">
              <p-button icon="fa fa-check" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'VALIDATE' | translate}}"
                (click)="submit()" [disabled]="signForm.invalid"></p-button>
              <p-button icon="fa fa-fw fa-times" [ngStyle]="{'margin-left':'5px'}" class="secondary right" label="{{'CANCEL' | translate}}"
                (click)="cancel()"></p-button>
            </div>
          </div>
        </form>

      </p-tabPanel>


      <p-tabPanel header="{{'COMPLEMENTARY_REGULATION' | translate}}" *ngIf="signService.sign.id">

        <div class="ui-g">
          <div class="ui-g-12" *ngFor="let rcPending of rcsPending">

            <p-panel class="panel-task-list-panel-orange" (click)="showTaskRc(rcPending)">
              <p-header>
                <div>
                  <b>{{"RC:PENDING" | translate}}</b> ({{(rcPending|rcLastTask).startDate | date:'dd/MM/yyyy'}})</div>
              </p-header>
              <div class="ui-g">
                <div class="ui-g-12">
                  <span style="float: left;">
                    {{rcPending.title}}
                  </span>
                  <span style="float: right; z-index: 6001">
                    <span>&nbsp;</span>
                    <p-button class="cerclebutton" icon="icon fas fa-fw fa-download" (onClick)="getRcDocument(rcPending)"></p-button>
                  </span>
                  <span>&nbsp;</span>
                  <span style="float: right; z-index: 6001">
                    <span>&nbsp;</span>
                    <p-button class="cerclebutton" icon="far fa-eye" (onClick)="showSupportForRc(rcPending, $event)"></p-button>
                  </span>
                </div>
              </div>
            </p-panel>

          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-12" *ngFor="let rcOk of rcsOk">


            <p-panel class="panel-task-list-panel-green" (click)="showTaskRc(rcOk)">
              <p-header>
                <div>
                  <b>{{"RC:ACTIVE" | translate}}</b> ({{(rcOk|rcLastTask).startDate | date:'dd/MM/yyyy'}})</div>
              </p-header>
              <div class="ui-g">
                <div class="ui-g-12">
                  <span style="float: left;">
                    {{rcOk.title}}
                  </span>
                  <span style="float: right; z-index: 6001">
                    <span>&nbsp;</span>
                    <p-button class="cerclebutton" icon="icon fas fa-fw fa-download" (onClick)="getRcDocument(rcOk)"></p-button>
                  </span>
                  <span>&nbsp;</span>
                  <span style="float: right; z-index: 6001">
                    <span>&nbsp;</span>
                    <p-button class="cerclebutton" icon="far fa-eye" (onClick)="showSupportForRc(rcOk, $event)"></p-button>
                  </span>
                </div>
              </div>
            </p-panel>

          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-12" *ngFor="let rcRemoved of rcsRemoved">

            <p-panel class="panel-task-list-panel-red" (click)="showTaskRc(rcRemoved)">
              <p-header>
                <div>
                  <b>{{"RC_OLD" | translate}}</b> ({{(rcRemoved|rcLastTask).startDate | date:'dd/MM/yyyy'}})</div>
              </p-header>
              <div class="ui-g">
                <div class="ui-g-12">
                  <span style="float: left;">
                    {{rcRemoved.title}}
                  </span>
                  <span style="float: right; z-index: 6001">
                    <span>&nbsp;</span>
                    <p-button class="cerclebutton" icon="icon fas fa-fw fa-download" (onClick)="getRcDocument(rcRemoved)"></p-button>
                  </span>
                  <span>&nbsp;</span>
                  <span style="float: right; z-index: 6001">
                    <span>&nbsp;</span>
                    <p-button class="cerclebutton" icon="far fa-eye" (onClick)="showSupportForRc(rcRemoved, $event)"></p-button>
                  </span>
                </div>
              </div>
            </p-panel>

          </div>
        </div>

      </p-tabPanel>


      <p-tabPanel header="{{'HISTORICAL' | translate}}">
        <form [formGroup]="signForm" [ngClass]="{'view-sign': !signService.editMode, 'edit-sign': signService.editMode}">
          <div class="ui-g">
            <h3 style="margin: 25px 0 0 0;">{{ 'PANEL_PLACEMENT' | translate }}</h3>
            <div class="ui-g-12 signItem">
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'PLACEMENT_DATE' | translate }}</label>
                </div>
                <div class="ui-g-7 view">
                  <span *ngIf="signService.sign && signService.sign.placementDate">{{signService.sign.placementDate |
                    date:'dd/MM/yyyy'}}</span>
                </div>
                <div *ngIf="editSign" class="ui-g-7 edit">
                  <p-calendar class="width100 customCalendar" [showIcon]="true"
                    [utc]="true" dateFormat="dd/mm/yy" [style]="{'width':'100%'}" [locale]="myCalendarLocal"
                    formControlName="placementDate"></p-calendar>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'CONTRACTOR' | translate }}</label>
                </div>
                <div class="ui-g-7 view">
                  <span>{{editSign.placementUser}}</span>
                </div>
                <div class="ui-g-7 edit">
                  <div class="ui-inputgroup">
                    <input class="editSignInput" formControlName="placementUser" pInputText [(ngModel)]="editSign.placementUser">
                  </div>
                </div>
              </div>

              <div class="ui-g view">
                <div class="ui-g-5">
                  <label>{{ 'NOTE' | translate }}</label>
                </div>
                <div class="ui-g-7">
                  <span>{{editSign.placementNote}}</span>
                </div>
              </div>

              <div class="ui-g edit">
                <div class="ui-g-12">
                  <label>{{ 'NOTE' | translate }}</label>
                </div>
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <textarea class="width100 customCalendar" formControlName="placementNote" pInputTextarea [rows]="5"
                      [(ngModel)]="editSign.placementNote"></textarea>
                  </div>
                </div>
              </div>

              <picture-view class="view" [imgs]="signService.sign.placementImgs" [pictureService]="pictureServiceSignPlacement"
                [sourceId]="signService.sign.id" *ngIf="signService.sign.placementImgs && signService.sign.placementImgs.length>0"></picture-view>
              <picture-view class="edit" [imgs]="editSign.placementImgs" [pictureService]="pictureServiceSignPlacement"
                [sourceId]="editSign.id" [canEdit]="true" *ngIf="pictureServiceSignPlacement.imgs"></picture-view>
            </div>
          </div>

          <div class="ui-g">
            <h3 style="margin: 25px 0 0 0;">{{ 'PANEL_REMOVAL' | translate }}</h3>
            <div class="ui-g-12 signItem">
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'REMOVAL_DATE' | translate }}</label>
                </div>
                <div class="ui-g-7 view">
                  <span *ngIf="signService.sign && signService.sign.removalDate">{{signService.sign.removalDate |
                    date:'dd/MM/yyyy'}}</span>
                </div>
                <div class="ui-g-7 edit">
                  <p-calendar *ngIf="editSign" class="editSignInput" [showIcon]="true"
                    [utc]="true" dateFormat="dd/mm/yy" [style]="{'width':'100%'}" [locale]="myCalendarLocal"
                    formControlName="removalDate"></p-calendar>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'CONTRACTOR' | translate }}</label>
                </div>
                <div class="ui-g-7 view">
                  <span>{{editSign.removalUser}}</span>
                </div>
                <div class="ui-g-7 edit">
                  <div class="ui-inputgroup">
                    <input class="editSignInput" formControlName="removalUser" pInputText [(ngModel)]="editSign.removalUser">
                  </div>
                </div>
              </div>

              <div class="ui-g view">
                <div class="ui-g-5">
                  <label>{{ 'NOTE' | translate }}</label>
                </div>
                <div class="ui-g-7">
                  <span>{{editSign.removalNote}}</span>
                </div>
              </div>

              <div class="ui-g edit">
                <div class="ui-g-12">
                  <label>{{ 'NOTE' | translate }}</label>
                </div>
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <textarea class="editSignInput" formControlName="removalNote" pInputTextarea [rows]="5" [(ngModel)]="editSign.removalNote"></textarea>
                  </div>
                </div>
              </div>

              <picture-view class="view" [imgs]="signService.sign.removalImgs" [pictureService]="pictureServiceSignRemoval"
                [sourceId]="signService.sign.id" *ngIf="signService.sign.removalImgs && signService.sign.removalImgs.length>0"></picture-view>
              <picture-view class="edit" [imgs]="editSign.removalImgs" [pictureService]="pictureServiceSignRemoval"
                [sourceId]="editSign.id" [canEdit]="true" *ngIf="pictureServiceSignRemoval.imgs"></picture-view>
            </div>
          </div>

          <div class="ui-g view sticky-bottom-buttons">
            <div class="ui-g-12">
              <p-button icon="fa fa-edit" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'EDIT' | translate}}"
                (click)="enableEdit()" *ngIf="canUpdate"></p-button>
              <p-button icon="fa fa-arrow-left" [ngStyle]="{'margin-left':'5px'}" class="secondary right" label="{{'BACK' | translate}}"
                (click)="backSupport()"></p-button>
            </div>
          </div>

          <div class="ui-g edit">
            <div class="ui-g-12">
              <p-messages [(value)]="errorMessages"></p-messages>
            </div>
          </div>
          <div class="ui-g edit sticky-bottom-buttons" *ngIf="canUpdate">
            <div class="ui-g-12">
              <p-button icon="fa fa-check" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'VALIDATE' | translate}}"
                (click)="submit()" [disabled]="signForm.invalid"></p-button>
              <p-button icon="fa fa-fw fa-times" [ngStyle]="{'margin-left':'5px'}" class="secondary right" label="{{'CANCEL' | translate}}"
                (click)="cancel()"></p-button>
            </div>
          </div>
        </form>
      </p-tabPanel>

    </p-tabView>
  </p-panel>
</div>
