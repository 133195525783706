<div class="panel-show-multi-rc">
  <p-panel>
    <p-header id="panel-search-rc-region-header">
      <title-header [title]="'PANEL_SHOW_MULTIPLE_RC:TITLE_RU' | translate" [direction]="'RIGHT'" *ngIf="rcService.ruMode"></title-header>
      <title-header [title]="'PANEL_SHOW_MULTIPLE_RC:TITLE_DAY_ORDER' | translate" [direction]="'RIGHT'" *ngIf="rcService.dayOrderMode"></title-header>
    </p-header>
    <div style="margin-left: 10px; margin-right: 10px;">
      <form [formGroup]="uniqueRegulationForm">

        <div class="ui-g">

          <div class="ui-g-12">
            <p-editor class="rc-editor"
                      [(ngModel)]="uniqueRegulation"
                      formControlName="body" >
            </p-editor>
          </div>
        </div>

        <div class="ui-g">
          <div class="ui-g-12">
            <p-button icon="fa fa-file-download" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'PANEL_SHOW_MULTIPLE_RC:BUTTON:DOWNLOAD' | translate}}" (click)="download()"></p-button>
            <p-button icon="fa fa-fw fa-times" [ngStyle]="{'margin-left':'5px'}" class="secondary right" label="{{'PANEL_SHOW_MULTIPLE_RC:BUTTON:RESET' | translate}}" (click)="close()"></p-button>
          </div>
        </div>

      </form>
    </div>
  </p-panel>
</div>
