import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-rc-intro-conclu',
  templateUrl: './rc-intro-conclu.component.html',
  styleUrls: ['./rc-intro-conclu.component.scss']
})
export class RcIntroConcluComponent {
  @Input() label: string;
  @Input() disabled = false;

  @Input() modelFr: string;
  @Output() modelFrChange: EventEmitter<string> = new EventEmitter();

  @Input() modelNl: string;
  @Output() modelNlChange: EventEmitter<string> = new EventEmitter();

}
