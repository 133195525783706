<div class="ui-g">
  <div class="ui-g-12">
    <p-panel *ngIf="signTypeService.signType" header="{{'SIGN_DETAILS'|translate}}">
      <div class="ui-g">
        <div class="ui-g-4" style="text-align: center">
          <div class="ui-g-12">
            <img *ngIf="!imageUploaded" style="max-height: 200px;" [src]="signTypeService.signType| signTypeImage" />
            <img *ngIf="imageUploaded" style="max-height: 200px;" [src]="newPicture.file" />
          </div>
          <div class="ui-g-12" style="text-align: center;">
            <div class="ui-g-6">
              <p-button *ngIf="imageUploaded" class="secondary" (click)="cancelUploadPicture()" icon="fas fa-times"
                        label="{{'CANCEL'|translate}}"></p-button>
            </div>
            <div [class]="imageUploaded ? 'ui-g-6':'ui-g-12' ">
              <input #input hidden="true" type="file" accept="image/*" (change)="onFileSelected($event)">
              <p-button (click)="input.click()" icon="fas fa-edit" label="{{'EDIT'|translate}}"></p-button>
            </div>
          </div>
        </div>

        <div class="ui-g-8">
          <div class="ui-g">
            <div class="ui-g-6">
              <div class="ui-g-5">
                <label class="custom-label">{{'CATEGORY'|translate}}</label>
              </div>
              <div class="ui-g-7">
                <p-dropdown *ngIf="loaded" class="width100" [style]="{'width':'100%'}" [options]="signTypeCategoryOptions"
                            [(ngModel)]="signUpdate.typeCategorie"></p-dropdown>
              </div>
            </div>
            <div class="ui-g-6">
              <div class="ui-g-5">
                <label class="custom-label">{{'CODE'|translate}} * </label>
              </div>
              <div class="ui-g-7">
                <input class="editSupportInput" required type="text" pInputText [(ngModel)]="signUpdate.code" />
              </div>
            </div>
          </div>

          <div class="ui-g">
            <div class="ui-g-6">
              <div class="ui-g-5">
                <label class="custom-label">{{'LABEL'|translate}} FR</label>
              </div>
              <div class="ui-g-7">
                <input class="editSupportInput" type="text" pInputText [(ngModel)]="signUpdate.labelFr" />
              </div>
            </div>
            <div class="ui-g-6">
              <div class="ui-g-5">
                <label class="custom-label">{{'LABEL'|translate}} NL</label>
              </div>
              <div class="ui-g-7">
                <input class="editSupportInput" type="text" pInputText [(ngModel)]="signUpdate.labelNl" />
              </div>
            </div>
          </div>
          <div class="ui-g">
            <div class="ui-g-6">
              <div class="ui-g-5">
                <label class="custom-label">{{'GENERICITY'|translate}}</label>
              </div>
              <div class="ui-g-7">
                <p-checkbox [binary]="true" [(ngModel)]="signUpdate.generic"></p-checkbox>
              </div>
            </div>
            <div class="ui-g-6"></div>
          </div>
        </div>
      </div>
    </p-panel>
    <br>
    <p-panel header="{{'MEASURE_LIST'|translate}}">
      <app-measure-list [measures]="measures$ | async"></app-measure-list>
    </p-panel>
    <div style="margin-top: 10px; text-align: right">
      <p-button [ngStyle]="{'margin-right': '10px'}" (click)="goBack()" class="secondary" icon="fas fa-long-arrow-alt-left"
                label="{{'BACK'|translate}}"></p-button>
      <p-button [disabled]="signUpdate.code == null || signUpdate.code.length == 0" (click)="save(signUpdate.code == null || signUpdate.code.length == 0)" icon="fas fa-save" label="{{'SAVE'|translate}}"></p-button>
    </div>
  </div>
</div>
