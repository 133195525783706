import { Injectable } from '@angular/core';
import { Picture } from '../../models/picture.model';
import { BaseAbstractService } from './base-abstract.service';
import { environment } from '../../../environments/environment';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class PictureService extends BaseAbstractService {
  _imgs: Picture[] = [];
  set imgs(imgs: Picture[]) {
    this._imgs = imgs;
    this.selectedPictureSource.next(imgs);
  }

  get imgs(): Picture[] {
    return this._imgs;
  }
  public selectedPictureSource = new Subject<Picture[]>();
  selectedPictureHandler: Observable<Picture[]> = <Observable<Picture[]>>this.selectedPictureSource.asObservable();

  get url(): string {
    return environment.backendURL + environment.picturePath + this.configService.appConfig.apiUrl + 'Sign/pictureFile/';
  }

  getPicture(objectId: number): Promise<Picture[]> {
    return this.get<Picture[]>(`${this.url}${objectId}`);
  }

  async getPictureFull(objectId: number): Promise<Picture[]> {
    let imgs = await this.get<Picture[]>(`${this.url}full/${objectId}`);
    imgs = imgs ? imgs : [];
    return imgs;
  }

  createPicture(img: Picture[]): Promise<Picture> {
    return this.post<Picture>(`${this.url}`, img);
  }

  deletePicture(picture: Picture): Promise<Picture> {
    return this.delete<Picture>(`${this.url}${picture.gedId}`);
  }
}

Injectable();
@Injectable()
export class PictureServiceSign extends PictureService {
  get url(): string {
    return environment.backendURL + environment.signPath + this.configService.appConfig.apiUrl + 'Sign/Pictures/';
  }
}

Injectable();
@Injectable()
export class PictureServiceSignPlacement extends PictureService {
  get url(): string {
    return (
      environment.backendURL + environment.signPath + this.configService.appConfig.apiUrl + 'Sign/Pictures/placement/'
    );
  }
}

Injectable();
@Injectable()
export class PictureServiceSignRemoval extends PictureService {
  get url(): string {
    return (
      environment.backendURL + environment.signPath + this.configService.appConfig.apiUrl + 'Sign/Pictures/removed/'
    );
  }
}
