import { Component, OnInit } from '@angular/core';
import { RcService } from '../../../core/services/rc.service';
import { LayoutService } from '../../../core/services/layout.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PanelSearchRcResultComponent } from '../panel-search-rc-result/panel-search-rc-result.component';
import { Rc } from '../../../models/rc.model';
import { UserService } from '../../../core/services/user.service';
import { NotificationService } from '../../../core/services/notification.service';
import { LoaderService } from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'panel-show-multi-rc',
  templateUrl: './panel-show-multi-rc.component.html',
  styleUrls: ['./panel-show-multi-rc.component.scss']
})
export class PanelShowMultiRcComponent implements OnInit {
  uniqueRegulationForm: FormGroup;

  private _uniqueRegulation: string = null;
  get uniqueRegulation(): string {
    return this._uniqueRegulation;
  }
  set uniqueRegulation(value: string) {
    this._uniqueRegulation = value;
  }

  rcs: Rc[];

  constructor(
    private layoutService: LayoutService,
    public rcService: RcService,
    private errorService: NotificationService,
    private userService: UserService,
    private fb: FormBuilder,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.uniqueRegulationForm = this.fb.group({
      body: this.fb.control('')
    });
    this.init();
  }

  async init() {
    this.uniqueRegulation = '';

    try {
      this.loaderService.showLoader();

      this.generateUniqueRegulation();
    } catch (error) {

    } finally {
      this.loaderService.hideLoader();
    }
  }

  generateUniqueRegulation() {
    this.rcService.rcs.forEach(rc => {
      const header = rc.header ? rc.header : '';
      const footer = rc.footer ? rc.footer : '';
      this.uniqueRegulation += header + rc.body + footer + `<p><br></p>`;
    });
  }

  download() {
    try {
      this.loaderService.showLoader();

      this.rcService.generateWord(this.uniqueRegulation).toPromise();
    } catch (error) {
      this.errorService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  close() {
    this.layoutService.closeRightPanel();
  }
}
