import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface LoaderState {
  show: boolean;
}

@Injectable()
export class LoaderService {
  private _loadingCount = 0;
  private loaderSubject = new Subject<LoaderState>();

  loaderState = this.loaderSubject.asObservable();

  constructor() { }

  public showLoader() {
    this._loadingCount++;

    this.loaderSubject.next(<LoaderState>{show: true});
  }

  public hideLoader() {
    if (this._loadingCount > 0) {
      this._loadingCount--;
    }

    if (this._loadingCount === 0) {
      this.loaderSubject.next(<LoaderState>{show: false});
    }
  }
}
