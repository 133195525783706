import { Component, OnInit, OnDestroy } from '@angular/core';
import { Support } from '../../../models/support.model';
import { SupportService } from '../../../core/services/support.service';
import { MapService } from '../../../core/services/map.service';
import { SupportComponent } from '../../../features/supports/support/support.component';
import { LayoutService } from '../../../core/services/layout.service';
import { Sign, SignHelper } from '../../../models/sign.model';
import { SignService } from '../../../core/services/sign.service';
import { SignComponent } from '../../signs/sign/sign.component';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../core/services/notification.service';
import Utils from '../../../shared/services/utils';
import { LoaderService } from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'panel-support-list',
  templateUrl: './panel-support-list.component.html',
  styleUrls: ['./panel-support-list.component.scss']
})
export class PanelSupportListComponent implements OnInit, OnDestroy {
  multiSelect = false;

  supports: Support[] = new Array();

  supportsSelected: Support[] = new Array();
  supportSelectedBool = false;
  supportChanges: Subscription;

  constructor(
    private supportService: SupportService,
    private signService: SignService,
    private errorService: NotificationService,
    protected layoutService: LayoutService,
    private mapService: MapService,
    private loaderService: LoaderService
  ) {}
  ngOnInit() {
    this.addSupportsId(this.supportService.selectedSupportsIds);
    this.supportChanges = this.supportService.selectedSupportsIdsHandler.subscribe(
      (ids: number[]) => {
        this.addSupportsId(ids);
      }
    );
  }
  ngOnDestroy() {
    this.supportChanges.unsubscribe();
  }

  getAddressLabel(address) {
    return Utils.getAddressLabel(address);
  }

  async addSupportId(id: number) {
    if (!this.supports.find(s => s.id === id)) {
      try {
        const support = await this.supportService.getSupport(id);
        this.supports.push(support);
      } catch (error) {
        this.errorService.addSingleError(error);
      }
    }
  }

  addSupportsId(ids: number[]) {
    if (ids) {
      ids.forEach(id => {
        this.addSupportId(id);
      });
    }
  }

  addSupport(support: Support) {
    if (!this.supports.find(s => s.id === support.id)) {
      this.supports.push(support);
    }
  }

  addSupports(supports: Support[]) {
    for (const support of supports) {
      this.addSupport(support);
    }
  }

  removeSupportId(id: number) {
    const index = this.supports.indexOf(this.supports.find(s => s.id === id));
    if (index !== -1) {
      this.supports.splice(index, 1);
    }
  }

  removeSupport(support: Support) {
    const index = this.supports.indexOf(
      this.supports.find(s => s.id === support.id)
    );
    if (index !== -1) {
      this.unselectSupport(support);
      this.supports.splice(index, 1);
    }
  }

  isSelected(support: Support): boolean {
    const index = this.supportsSelected.indexOf(
      this.supportsSelected.find(s => s.id === support.id)
    );
    if (index !== -1) {
      return true;
    }
    return false;
  }

  onclick(support: Support) {
    if (this.isSelected(support)) {
      this.unselectSupport(support);
    } else {
      this.selectSupport(support);
    }
  }

  selectSupport(support: Support) {
    if (this.multiSelect) {
      if (!this.supportsSelected.find(s => s.id === support.id)) {
        this.supportsSelected.push(support);
      }
    } else {
      this.supportsSelected = new Array(support);
      this.showSupportComponent();
    }
    this.supportSelectedBool = true;
  }

  unselectSupport(support: Support) {
    if (this.multiSelect) {
      const index = this.supportsSelected.indexOf(
        this.supportsSelected.find(s => s.id === support.id)
      );
      if (index !== -1) {
        this.supportsSelected.splice(index, 1);
      }
      if (this.supportsSelected.length > 0) {
        this.supportSelectedBool = false;
      }
    } else {
      this.supportsSelected = new Array();
      this.supportSelectedBool = false;
      this.hideSupportComponent();
    }
  }

  get numberOfElements(): number {
    return this.supports.length;
  }

  async addSignToSupport() {
    if (!this.multiSelect && this.supportsSelected.length > 0) {
      if(this.mapService.duplicateModeActivated) {
        try {
          this.loaderService.showLoader();

          const newSign = SignHelper.duplicateSignFrom(this.signService.memorizedSign);
          newSign.supportId = this.supportsSelected[0].id;
          await this.signService.createOrUpdateSign(newSign);
          const supportUpdated = await this.supportService.getSupport(newSign.supportId);
          this.supportService.support = supportUpdated;

          this.mapService.refresh();
        } finally {
          this.loaderService.hideLoader();
        }
      }
      else {
        this.signService.sign = <Sign>{
          supportId: this.supportsSelected[0].id
        };
        this.signService.editMode = true;
        this.layoutService.rightPanelVisible = true;
        this.layoutService.rightPanelContent = SignComponent;
      }
    }
    this.cancelSelect();
  }

  showSupport() {
    if (!this.multiSelect && this.supportsSelected.length > 0) {
      this.supportService.support = this.supportsSelected[0];
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = SupportComponent;
    }
    this.cancelSelect();
  }

  cancelSelect() {
    this.mapService.addSignModeActivated = false;
    this.resetSelect();
    this.supportService._selectedSupportsIds = new Array();
    this.supports = new Array();
    this.layoutService.leftPanelVisible = false;
  }

  resetSelect() {
    this.supportsSelected = new Array();
    this.supportSelectedBool = false;
  }

  mouseEnter() {
    // console.info('in', support.geom);
  }
  mouseLeave() {
    // console.info('out', support.geom);
  }

  showSupportComponent() {
    this.layoutService.rightPanelVisible = true;
    this.supportService.support = this.supportsSelected[0];
    this.layoutService.rightPanelContent = SupportComponent;
  }
  hideSupportComponent() {
    this.layoutService.rightPanelVisible = false;
  }

  close() {
    this.layoutService.leftPanelVisible = false;
  }
}
