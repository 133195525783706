import { Component, OnInit } from '@angular/core';
import { PanelTaskListComponent } from '../../tasks/panel-task-list/panel-task-list.component';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import { TaskService } from '../../../core/services/task.service';
import { RcService } from '../../../core/services/rc.service';
import { Rc, RcHelper } from '../../../models/rc.model';
import { NotificationService } from '../../../core/services/notification.service';
import { SignService } from '../../../core/services/sign.service';
import { LoaderService } from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tasks-button',
  templateUrl: './layout.tasks-button.component.html',
  styleUrls: ['./layout.tasks-button.component.scss']
})
export class TasksButtonComponent implements OnInit {
  displayGrid = false;
  pendingLoad = true;

  constructor(
    private taskService: TaskService,
    private signService: SignService,
    private rcService: RcService,
    private errorService: NotificationService,
    private layoutService: LayoutService,
    private userService: UserService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {}

  async showTasks() {
    try {
      this.loaderService.showLoader();

      let rcTasks: Rc[] = await this.rcService.getTasks().toPromise();

      rcTasks = rcTasks.sort((a: Rc, b: Rc) => {
        const dda = RcHelper.getDueDate(a);
        const ddb = RcHelper.getDueDate(b);
        if (dda > ddb) {
          return 1;
        }
        if (dda < ddb) {
          return -1;
        }
        return 0;
      });
      this.taskService.rcTasks = rcTasks;

      if (this.userService.ROLE_RECENSE) {
        try {
          this.signService.signsRecense = await this.signService.getRecense();
        } catch (error) {
          this.signService.signsRecense = [];
        }
      }
      this.layoutService.leftPanelContent = PanelTaskListComponent;
      this.layoutService.leftPanelVisible = true;
    } catch (error) {
      this.errorService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }
}
