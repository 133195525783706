// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/components/button/button';
import { DialogModule } from 'primeng/dialog';
import { DataGridModule } from 'primeng/datagrid';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { GMapModule } from 'primeng/gmap';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';
import { DragDropModule } from 'primeng/dragdrop';
import { CalendarModule } from 'primeng/calendar';
import { LightboxModule } from 'primeng/lightbox';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { EditorModule } from 'primeng/editor';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DataViewModule } from 'primeng/dataview';
// Pipes
import { PropercasePipe } from './pipes/propercase.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
// Components
import { PageHeaderComponent } from './components/page-header.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PictureViewComponent } from './components/picture-view/picture-view.component';
import { TitleHeaderComponent } from './components/title-header/title-header.component';
import { GrowlModule } from 'primeng/growl';
import { SafeHtmlPipe } from './pipes/bypass-trust-html.pipe';
import { SubstringPipe } from './pipes/subtring.pipe';
import {
  RcCurrentTaskPipe,
  RcDueDatePipe,
  RcLastCompletedTaskPipe,
  RcLastTaskPipe,
  RcTaskDatePipe,
  RcTaskStatusPipe,
} from './pipes/rc.pipe';
import { RichTextAreaComponent } from './components/rich-text-area/rich-text-area.component';
import { BasemapListComponent } from './viewer/modules/basemap-list/basemap-list.component';
import { BasemapComponent } from './viewer/modules/basemap/basemap.component';
import { SignImagePipe, SignTypeImagePipe, SignTypeLabelPipe } from './pipes/sign-type.pipe';
import { TabViewModule } from 'primeng/components/tabview/tabview';
import { ToggleButtonModule } from 'primeng/components/togglebutton/togglebutton';
import { ConfirmDialogModule } from 'primeng/components/confirmdialog/confirmdialog';
import { DataListModule } from 'primeng/components/datalist/datalist';
import { CarouselModule } from 'primeng/components/carousel/carousel';
import { FileUploadModule } from 'primeng/components/fileupload/fileupload';
import { RadioButtonModule } from 'primeng/components/radiobutton/radiobutton';
import { MultiSelectModule } from 'primeng/components/multiselect/multiselect';
import { AutoCompleteModule } from 'primeng/components/autocomplete/autocomplete';
import { SelectButtonModule } from 'primeng/components/selectbutton/selectbutton';
import { TooltipModule } from 'primeng/components/tooltip/tooltip';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule, TabMenuModule, TreeTableModule, TriStateCheckboxModule } from 'primeng/primeng';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { DynamicDialogModule } from 'primeng/components/dynamicdialog/dynamicdialog';
import { HasNotMultipleOptionsPipe } from './pipes/has-not-multiple-options.pipe';
import { SignAddressPipe } from './pipes/sign-address.pipe';
import { GisViewerUIModule } from '@nsi/gis-viewer-ui';
import { HelpdeskComponent } from './components/helpdesk/helpdesk.component';
import { MunicipalityNamePipe } from './pipes/municipality-name.pipe';

const MODULES: any[] = [
  CommonModule,
  FormsModule,
  HttpClientModule,
  RouterModule,
  TabViewModule,
  MenubarModule,
  ButtonModule,
  ToggleButtonModule,
  DialogModule,
  DataGridModule,
  PanelModule,
  InputTextModule,
  SidebarModule,
  GMapModule,
  MessagesModule,
  MessageModule,
  OverlayPanelModule,
  DropdownModule,
  DragDropModule,
  CalendarModule,
  LightboxModule,
  ConfirmDialogModule,
  DataListModule,
  AccordionModule,
  ScrollPanelModule,
  TableModule,
  CarouselModule,
  FileUploadModule,
  EditorModule,
  RadioButtonModule,
  MultiSelectModule,
  CheckboxModule,
  ChipsModule,
  AutoCompleteModule,
  GrowlModule,
  DataViewModule,
  GrowlModule,
  SelectButtonModule,
  TooltipModule,
  ReactiveFormsModule,
  TranslateModule,
  ToastModule,
  VirtualScrollerModule,
  TreeTableModule,
  ProgressSpinnerModule,
  DynamicDialogModule,
  TabMenuModule,
  TriStateCheckboxModule,
  GisViewerUIModule,
];

const DECLARATIONS = [
  PropercasePipe,
  EllipsisPipe,
  SafeHtmlPipe,
  SignAddressPipe,
  MunicipalityNamePipe,
  PageHeaderComponent,
  AccessDeniedComponent,
  PictureViewComponent,
  TitleHeaderComponent,
  SubstringPipe,
  RcDueDatePipe,
  RcLastTaskPipe,
  RcTaskDatePipe,
  RcTaskStatusPipe,
  RcLastCompletedTaskPipe,
  RcCurrentTaskPipe,
  SignImagePipe,
  SignTypeLabelPipe,
  SignTypeImagePipe,
  RichTextAreaComponent,
  HasNotMultipleOptionsPipe,
  BasemapComponent,
  BasemapListComponent,
  HelpdeskComponent
];

@NgModule({
  imports: [...MODULES],
  declarations: DECLARATIONS,
  exports: [...MODULES, ...DECLARATIONS],
  entryComponents: [BasemapComponent],
})
export class SharedModule {}
