import { Component } from '@angular/core';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './layout.header.component.html',
  styleUrls: ['./layout.header.component.scss']
})
export class LayoutHeaderComponent {
  appName = 'eSign';
  constructor(public userService: UserService) {}
}
