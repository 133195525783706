<div class="task-view">

  <p-panel>
    <p-header id="panel-task-view-header">
      <title-header [iconClass]="'fas fa-tasks'" [icon]="true" [title]="'TASK_VIEW:TITLE' | translate" [direction]="'RIGHT'"></title-header>
    </p-header>
    <div class="ui-g" style="background-color: white;">

      <div class="ui-g-12 noPaddingLeftRight" style="text-align: center; position: relative;">


        <div class="ui-g">
          <!-- STATUS -->
          <div class="ui-g-12 noPaddingLeftRight" style="text-align: center;">
            <div style="margin-right: 15px; margin-bottom: 5px;"><span style="margin-right: 5px;">{{"RC:TITLE" | translate: {title: rcTask.title} }}</span><p-button class="cerclebutton" icon="icon fas fa-fw fa-search" (onClick)="zoomToSigns()"></p-button></div>
            <div style="margin-right: 15px; margin-bottom: 5px;" *ngIf="getUsername(rcTask)" >{{"RC:CREATE_BY_USER" | translate: {user: getUsername(rcTask)} }}</div>
            <div style="margin-right: 15px; margin-bottom: 5px;">
              <b [title]="currentStatus" [ngStyle]="{'color': rcTask.status == RC_STATUS.active ? 'green': ( rcTask.status == RC_STATUS.reject ? 'red' : 'black')}">{{currentStatus | translate}}</b>
            </div>
            <p-button class="cerclebutton" icon="icon fas fa-fw fa-download" (onClick)="generateRcDocument($event)"></p-button>
          </div>



          <!-- CONTENT -->
          <div class="ui-g-12 noPaddingLeftRight overflow-wrap-anywhere">


            <!-- T01 -->
            <div class="ui-g" *ngIf="showFromStatus('COM_T01')">
              <!-- BODY -->
              <div class="ui-g-12 noPaddingLeftRight">
                <p-accordion>
                  <p-accordionTab class="customAccordionTab" header="{{'COMMUNAL_COUNCIL_DECISION' | translate}}" [selected]="(rcTask|rcLastTask).taskKey == 'COM_T01'">
                    <div class="ui-g">


                      <div class="ui-g-12" *ngIf="isInProgress('COM_T01'); else elseBlockDecision">
                        <div class="ui-g" style="text-align: left;">
                          <form [formGroup]="decisionForm">
                            <div class="ui-g-12">
                              <span>{{"TASK_VIEW:COM_T01:TASK_VIEW" | translate}}</span>
                            </div>

                            <div class="ui-g-12" *ngIf="userService.hasRole(userService.agentCode)">
                              <p-button  icon="fa  fa-edit" class="width100" label="{{'TASK_VIEW:COM_T01:CHANGE_RC'|translate}}" (onClick)="updateRC()"></p-button>
                            </div>

                            <div class="ui-g-12" *ngIf="userService.hasRole(userService.agentCode)">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T01:COMUNAL_DECISION' | translate }} *</b>
                                </div>
                                <div class="ui-g-6">
                                  <p-radioButton name="approvalRadioButton" label="{{'TASK_VIEW:COM_T01:APPROVE' | translate}}" value=true [(ngModel)]="selectedValueCOM_T01"
                                    formControlName="check"></p-radioButton>
                                </div>
                                <div class="ui-g-6">
                                  <p-radioButton name="approvalRadioButton" label="{{'TASK_VIEW:COM_T01:REFUSE' | translate}}" value=false [(ngModel)]="selectedValueCOM_T01"
                                    formControlName="check"></p-radioButton>
                                </div>
                              </div>
                            </div>

                            <div class="ui-g-12" *ngIf="userService.hasRole(userService.agentCode)">
                              <div class="ui-g">
                                <b>{{ 'TASK_VIEW:COM_T01:VERBAL_PROCES' | translate }} *</b>
                                <div class="ui-g-12">
                                  <div>
                                    <span>{{"TASK_VIEW:COM_T01:ATTACH_FILE" | translate}}</span>
                                  </div>
                                  <p-fileUpload mode="advanced" accept="application/pdf" customUpload="true" name="DecisionPV" [maxFileSize]="communalPvSize"
                                                auto="true" multiple="multiple" (uploadHandler)="addPvFile($event)" (onRemove)="removePvFile($event)"
                                                (onSelect)="addPvFile($event)" chooseLabel="{{'ADD' | translate}}"></p-fileUpload>
                                </div>
                              </div>
                            </div>
                            <div class="ui-g-5 ui-g-offset-7" *ngIf="userService.hasRole(userService.agentCode)">
                              <p-button class="width100" icon="fa fa-check" label="{{'VALIDATE'|translate}}" (onClick)="validateCommunalDecision()"></p-button>
                            </div>
                          </form>
                        </div>
                      </div>


                      <ng-template #elseBlockDecision>
                        <div class="ui-g-12">
                          <div class="ui-g">
                            <div class="ui-g-10">
                              <span>{{"TASK_VIEW:COM_T01:DOWNLOAD_ATTACH_FILE" |translate}}</span>
                            </div>
                            <div class="ui-g-2">
                              <p-button class="cerclebutton" icon="icon fas fa-fw fa-download" (onClick)="rcService.getGedDocument(rcTask).toPromise()"></p-button>
                            </div>


                            <div class="ui-g-10 ui-g-offset-1">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T01:DECISION' | translate }}</b>
                                </div>
                                <div class="ui-g-12" *ngIf="rcTask|rcTaskDate:'COM_T01'">
                                  <span style="color: green" *ngIf="(rcTask|rcTaskStatus:'COM_T01')">{{"TASK_VIEW:COM_T01:APPROVED_ON" | translate}} {{rcTask|rcTaskDate:"COM_T01" | date:'dd/MM/yyyy'}}</span>
                                  <span style="color: red" *ngIf="!(rcTask|rcTaskStatus:'COM_T01')">{{"TASK_VIEW:COM_T01:REFUSED_ON" | translate}} {{rcTask|rcTaskDate:"COM_T01" | date:'dd/MM/yyyy'}}</span>
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>
                      </ng-template>

                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <p-dialog id="infoMissingRC" modal="modal" [(visible)]="displayInfoMissingRC" appendTo="body" [width]="350">
                <p-header>
                  {{ 'CAUTION' | translate }}
                </p-header>
                <div class="ui-g">
                  <div class="ui-g-12" style="padding: 10px 0;">
                    <span>{{ "INFO_MISSING" | translate }}</span>
                  </div>
                  <div class="ui-g-12" style="padding: 10px 0;">
                    <div class="ui-g">
                      <div class="ui-g-4 ui-g-offset-4">
                        <p-button class="rcButton width100" label="{{ 'Ok' | translate }}" (click)="hideInfoMissingRC()"></p-button>
                      </div>
                    </div>
                  </div>
                </div>
              </p-dialog>
            </div>




            <!-- T02 -->
            <div class="ui-g" *ngIf="showFromStatus('COM_T02') || showFromStatus('REG_T01')">
              <!-- BODY -->
              <div class="ui-g-12 noPaddingLeftRight">
                <p-accordion>
                  <p-accordionTab class="customAccordionTab" header="{{'AVIS_DE_LA_COMMISSION_CONSULTATIVE' | translate}}" [selected]="(rcTask|rcLastTask).taskKey == 'COM_T02' || (rcTask|rcLastTask).taskKey == 'REG_T01'">
                    <div class="ui-g">
                      <div class="ui-g-12" *ngIf="isInProgress('COM_T02') || isInProgress('REG_T01'); else elseBlockOpinion">
                        <div class="ui-g" style="text-align: left;">


                          <form [formGroup]="opinionForm">
                            <div class="ui-g-12">
                              <span>{{"TASK_VIEW:COM_T02:TASK_VIEW" | translate}}</span>
                            </div>

                            <div class="ui-g-12" *ngIf="userService.hasRole(userService.secretaryCode)">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T02:COMMISION_OPINION' | translate }}</b>
                                </div>
                                <div class="ui-g-6">
                                  <p-radioButton name="opinionRadioButton"
                                                 label="{{'TASK_VIEW:COM_T02:FAVORABLE' | translate}}" value=true
                                                 [(ngModel)]="selectedValueCOM_T02"
                                                 formControlName="check"></p-radioButton>
                                </div>
                                <div class="ui-g-6">
                                  <p-radioButton name="opinionRadioButton"
                                                 label="{{'TASK_VIEW:COM_T02:UNFAVORABLE' | translate}}" value=false
                                                 [(ngModel)]="selectedValueCOM_T02"
                                                 formControlName="check"></p-radioButton>
                                </div>
                              </div>
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T02:COMMISION_DATE' | translate }}</b>
                                </div>
                                <div class="ui-g-12">
                                  <p-calendar class="width100 customCalendar" [showIcon]="true" [utc]="true"
                                              dateFormat="dd/mm/yy" [style]="{'width':'100%'}"
                                              [locale]="CALENDAR_LOCALE_EN"
                                              formControlName="secretariaNoteDate"></p-calendar>
                                </div>
                              </div>
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T02:COMMISION_MOTIVATION' | translate }} (FR)</b>
                                </div>
                                <div class="ui-g-12">
                                  <textarea formControlName="motivationFr" pInputTextarea [rows]="5"
                                            style="width: 100%;"></textarea>
                                </div>
                              </div>
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T02:COMMISION_MOTIVATION' | translate }} (NL)</b>
                                </div>
                                <div class="ui-g-12">
                                  <textarea formControlName="motivationNl" pInputTextarea [rows]="5"
                                            style="width: 100%;"></textarea>
                                </div>
                              </div>
                            </div>

                            <div class="ui-g-5 ui-g-offset-7" *ngIf="userService.hasRole(userService.secretaryCode)">
                              <p-button class="width100" icon="fa fa-check" label="{{'VALIDATE'|translate}}"
                                        (onClick)="communale_favorableOrUnfavorableOpinion()"></p-button>
                            </div>
                          </form>


                        </div>
                      </div>

                      <ng-template #elseBlockOpinion>
                        <div class="ui-g-12">
                          <div class="ui-g">
                            <div class="ui-g-10">
                              <span>{{"TASK_VIEW:COM_T02:DOWNLOAD_ATTACH_FILE" |translate}}</span>
                            </div>
                            <div class="ui-g-2">
                              <p-button class="cerclebutton" icon="icon fas fa-fw fa-download" (onClick)="rcService.getSingleGedDocument(rcTask.secretariaRcGedId).toPromise()"></p-button>
                            </div>


                            <div class="ui-g-10 ui-g-offset-1">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>
                                    {{ 'TASK_VIEW:COM_T02:DECISION' | translate:{date: rcTask.secretariaNoteDate | date:'dd/MM/yyyy'} }} :
                                    <ng-container *ngIf="showFromStatus('COM_T02')">
                                      <span style="color: green" *ngIf="(rcTask|rcTaskStatus:'COM_T02')">{{"TASK_VIEW:COM_T02:APPROVED_ON" | translate}}</span>
                                      <span style="color: red" *ngIf="!(rcTask|rcTaskStatus:'COM_T02')">{{"TASK_VIEW:COM_T02:REFUSED_ON" | translate}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="showFromStatus('REG_T01')">
                                      <span style="color: green" *ngIf="(rcTask|rcTaskStatus:'REG_T01')">{{"TASK_VIEW:COM_T02:APPROVED_ON" | translate}}</span>
                                      <span style="color: red" *ngIf="!(rcTask|rcTaskStatus:'REG_T01')">{{"TASK_VIEW:COM_T02:REFUSED_ON" | translate}}</span>
                                    </ng-container>
                                  </b>
                                </div>
                              </div>
                            </div>

                            <div class="ui-g-10 ui-g-offset-1" *ngIf="rcTask.secretariaNote">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T02:COMMISION_MOTIVATION_REFUSAL' | translate }}</b>
                                </div>
                                <div class="ui-g-12">
                                  <span>{{rcTask.secretariaNote}}</span>
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>




            <!-- T03 -->
            <div class="ui-g" *ngIf="showFromStatus('COM_T03') || showFromStatus('REG_T02')">
              <!-- BODY -->
              <div class="ui-g-12 noPaddingLeftRight">
                <p-accordion>
                  <p-accordionTab class="customAccordionTab" header="{{'APPROVAL_BY_THE_MINISTER' | translate}}" [selected]="(rcTask|rcLastTask).taskKey == 'COM_T03' || (rcTask|rcLastTask).taskKey == 'REG_T02'">
                    <div class="ui-g">
                      <div class="ui-g-12" *ngIf="isInProgress('COM_T03') || isInProgress('REG_T02'); else elseBlockDecisionMinister">
                        <div class="ui-g" style="text-align: left;">



                          <form [formGroup]="approvalForm">
                            <div class="ui-g-12">
                              <span>{{"TASK_VIEW:COM_T03:TASK_VIEW" | translate}}</span>
                            </div>

                            <div class="ui-g-12" *ngIf="userService.hasRole(userService.secretaryCode)">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T03:MINISTERIAL_DECISION' | translate }}</b>
                                </div>
                                <div class="ui-g-6">
                                  <p-radioButton name="approvalRadioButton"
                                                 label="{{'TASK_VIEW:COM_T03:FAVORABLE' | translate}}" value=true
                                                 [(ngModel)]="selectedValueCOM_T03"
                                                 formControlName="check"></p-radioButton>
                                </div>
                                <div class="ui-g-6">
                                  <p-radioButton name="approvalRadioButton"
                                                 label="{{'TASK_VIEW:COM_T03:UNFAVORABLE' | translate}}" value=false
                                                 [(ngModel)]="selectedValueCOM_T03"
                                                 formControlName="check"></p-radioButton>
                                </div>
                              </div>
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T02:COMMISION_DATE' | translate }}</b>
                                </div>
                                <div class="ui-g-12">
                                  <p-calendar class="width100 customCalendar" [showIcon]="true" [utc]="true"
                                              dateFormat="dd/mm/yy" [style]="{'width':'100%'}"
                                              [locale]="CALENDAR_LOCALE_EN"
                                              formControlName="effectiveDate"></p-calendar>
                                </div>
                              </div>
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T03:MINISTERIAL_MOTIVATION' | translate }} (FR)</b>
                                </div>
                                <div class="ui-g-12">
                                  <textarea formControlName="motivationFr" pInputTextarea [rows]="5"
                                            style="width: 100%;"></textarea>
                                </div>
                              </div>
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T03:MINISTERIAL_MOTIVATION' | translate }} (NL)</b>
                                </div>
                                <div class="ui-g-12">
                                  <textarea formControlName="motivationNl" pInputTextarea [rows]="5"
                                            style="width: 100%;"></textarea>
                                </div>
                              </div>
                            </div>

                            <div class="ui-g-5 ui-g-offset-7" *ngIf="userService.hasRole(userService.secretaryCode)">
                              <p-button class="width100" icon="fa fa-check" label="{{'VALIDATE'|translate}}" (onClick)="communale_ministerApproveOrNotCR()"></p-button>
                            </div>
                          </form>



                        </div>
                      </div>


                      <ng-template #elseBlockDecisionMinister>
                        <div class="ui-g-12">
                          <div class="ui-g">
                            <div class="ui-g-10">
                              <span>{{"TASK_VIEW:COM_T03:DOWNLOAD_ATTACH_FILE" |translate}}</span>
                            </div>
                            <div class="ui-g-2">
                              <p-button class="cerclebutton" icon="icon fas fa-fw fa-download" (onClick)="rcService.getSingleGedDocument(rcTask.ministerRcGedId).toPromise()" *ngIf="rcTask.ministerRcGedId; else buttonGenerateRcGid"></p-button>
                              <ng-template #buttonGenerateRcGid>
                                <p-button class="cerclebutton" icon="icon fas fa-fw fa-download" (onClick)="generateRcDocument($event)"></p-button>
                              </ng-template>
                            </div>


                            <div class="ui-g-10 ui-g-offset-1">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T03:DECISION' | translate: {date: rcTask.effectiveDate | date:'dd/MM/yyyy'} }}</b>
                                </div>
                                <div class="ui-g-12">
                                  <div *ngIf="showFromStatus('COM_T03')">
                                    <span style="color: green" *ngIf="(rcTask|rcTaskStatus:'COM_T03')">{{"APPROVED_ON" | translate}} {{rcTask|rcTaskDate:"COM_T03" | date:'dd/MM/yyyy'}}</span>
                                    <span style="color: red" *ngIf="!(rcTask|rcTaskStatus:'COM_T03')">{{"REFUSED_ON" | translate}} {{rcTask|rcTaskDate:"COM_T03" | date:'dd/MM/yyyy'}}</span>
                                  </div>
                                  <div *ngIf="showFromStatus('REG_T02')">
                                    <span style="color: green" *ngIf="(rcTask|rcTaskStatus:'REG_T02')">{{"APPROVED_ON" | translate}} {{rcTask|rcTaskDate:"REG_T02" | date:'dd/MM/yyyy'}}</span>
                                    <span style="color: red" *ngIf="!(rcTask|rcTaskStatus:'REG_T02')">{{"REFUSED_ON" | translate}} {{rcTask|rcTaskDate:"REG_T02" | date:'dd/MM/yyyy'}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>



                            <div class="ui-g-10 ui-g-offset-1" *ngIf="rcTask.ministerNote">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>{{ 'TASK_VIEW:COM_T03:MINISTERIAL_MOTIVATION_MINISTERIAL' | translate }}</b>
                                </div>
                                <div class="ui-g-12">
                                  <span>{{rcTask.ministerNote}}</span>
                                </div>
                              </div>
                            </div>



                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>




            <!-- T04 -->
            <div class="ui-g" *ngIf="showFromStatus('COM_T04') || showFromStatus('REG_T03')">
              <!-- BODY -->
              <div class="ui-g-12 noPaddingLeftRight">
                <p-accordion>
                  <p-accordionTab class="customAccordionTab" header="{{'RECEIPT_OF_THE_MINISTER_S_DECISION' | translate}}" [selected]="(rcTask|rcLastTask).taskKey == 'COM_T04' || (rcTask|rcLastTask).taskKey == 'REG_T03'">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g" style="text-align: left;">



                          <div class="ui-g-12" *ngIf="isInProgress('COM_T04') || isInProgress('REG_T03')">
                            <span>{{"TASK_VIEW:COM_T04:TASK_VIEW_1" | translate}}</span>
                            <span>{{"TASK_VIEW:COM_T04:TASK_VIEW_2" | translate}}</span>
                            <span>{{"TASK_VIEW:COM_T04:TASK_VIEW_3" | translate}}</span>
                          </div>

                          <div class="ui-g-12" *ngIf="isDone('COM_T04') || isDone('REG_T03')">
                            <span style="color: green">{{"TASK_VIEW:COM_T04:RECEIPT_OF" | translate}} {{(rcTask|rcLastTask).startDate | date:'dd/MM/yyyy'}}</span>
                          </div>

                          <div class="ui-g-12">
                            <p-table [value]="tableSigns | async" [columns]="cols">
                              <ng-template pTemplate="header">
                                <tr>
                                  <th style="width: 70%">{{"TASK_VIEW:COM_T04:COLUMN:SUPPORT" | translate}}</th>
                                  <th style="width: 30%">{{"TASK_VIEW:COM_T04:COLUMN:INTERVENTION" | translate}}</th>
                                </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-sign>
                                <tr>
                                  <td>
                                    <img src="{{sign.type| signTypeImage}}" alt="pic" style="width: 17px; margin-right: 5px;" />
                                    <span [ngStyle]="{'color':(sign.placement.code === 'FINAL')?'#119F19':((sign.placement.code === 'TEMPORARY')?'#FE9900':'black')}">{{sign.type.code}}</span>
                                    <span style="font-weight: normal"> - {{sign.support.number}} , {{getAddressLabel(sign.support.address)}}</span>
                                  </td>
                                  <td>
                                    <div *ngIf="!((rcTask|rcLastTask).isValid); else elseBlockHistory" [ngStyle]="{'color': sign.signStatus.id == 1 ? 'green': (sign.signStatus.id == 2 ? 'black' : 'red')}">
                                      <p-dropdown [style]="{'width':'100%', 'min-width':'unset'}" [disabled]="(rcTask.status === RC_STATUS.old) || !((isInProgress('COM_T04') && userService.hasRole(userService.agentCode)) || (isInProgress('REG_T03') && userService.hasRole(userService.sprbCode)))" [options]="statusOptions" [(ngModel)]="sign.signStatus"></p-dropdown>
                                    </div>
                                    <ng-template #elseBlockHistory>
                                      <div [ngStyle]="{'color': sign.signStatus.id == 1 ? 'green': (sign.signStatus.id == 2 ? 'black' : 'red')}">
                                        <span *ngIf="sign.signStatus.id == 1">{{intervention.add.code | translate}}</span>
                                        <span *ngIf="sign.signStatus.id == 2">{{intervention.none.code | translate}}</span>
                                        <span *ngIf="sign.signStatus.id == 3">{{intervention.remove.code | translate}}</span>
                                      </div>
                                    </ng-template>
                                  </td>
                                </tr>
                              </ng-template>
                            </p-table>
                          </div>

                          <div class="ui-g-12" *ngIf="(rcTask.status !== RC_STATUS.old) && (isInProgress('COM_T04') && userService.hasRole(userService.agentCode)) || (isInProgress('REG_T03') && userService.hasRole(userService.sprbCode))">
                            <p-button [ngStyle]="{'margin-left':'5px'}" class="right" icon="fa fa-check" label="{{'TASK_VIEW:COM_T04:RECEIPT'|translate}}"
                              (onClick)="communale_finalValidation()"></p-button>
                          </div>



                        </div>
                      </div>





                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>




            <!-- T05 -->
            <div class="ui-g" *ngIf="showFromStatus('COM_T05') || showFromStatus('REG_T04')">
              <!-- BODY -->
              <div class="ui-g-12 noPaddingLeftRight">
                <p-accordion>
                  <p-accordionTab class="customAccordionTab" header="{{'RECEIPT_OF_THE_MINISTER_S_DECISION' | translate}}" [selected]="(rcTask|rcLastTask).taskKey == 'COM_T05' || (rcTask|rcLastTask).taskKey == 'REG_T04'">
                    <div class="ui-g" *ngIf="userService.hasRole(userService.agentCode) || userService.hasRole(userService.sprbCode)">
                      <div class="ui-g-12">
                        <div class="ui-g" style="text-align: left;">

                          <div class="ui-g-12" *ngIf="isInProgress('COM_T05') || isInProgress('REG_T04')">
                            <span style="color: green">{{"TASK_VIEW:COM_T04:RECEIPT_OF" | translate}} {{(rcTask|rcLastTask).startDate | date:'dd/MM/yyyy'}}</span>
                          </div>

                          <div class="ui-g-12">
                            <p-table [value]="allSign" [columns]="cols">
                              <ng-template pTemplate="colgroup" let-columns>
                                <colgroup>
                                  <col *ngFor="let col of columns" [style.width]="col.width">
                                </colgroup>
                              </ng-template>
                              <ng-template pTemplate="header">
                                <tr>
                                  <th style="width: 70%">{{"TASK_VIEW:COM_T04:COLUMN:SUPPORT" | translate}}</th>
                                  <th style="width: 30%">{{"TASK_VIEW:COM_T04:COLUMN:INTERVENTION" | translate}}</th>
                                </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-sign>
                                <tr>
                                  <td>
                                    <img src="{{sign.type| signTypeImage}}" alt="pic" style="width: 17px; margin-right: 5px;" />
                                    <span [ngStyle]="{'color':(sign.placement.code === 'FINAL')?'#119F19':((sign.placement.code === 'TEMPORARY')?'#FE9900':'black')}">{{sign.type.code}}</span>
                                    <span style="font-weight: normal"> - {{sign.support.number}} , {{getAddressLabel(sign.support.address)}}</span>
                                  </td>
                                  <td>
                                    <div [ngStyle]="{'color': 'gray'}">
                                      {{intervention.none.code | translate}}
                                    </div>
                                  </td>
                                </tr>

                              </ng-template>
                            </p-table>
                          </div>
                          <div class="ui-g-12" *ngIf="(isInProgress('COM_T05') && userService.hasRole(userService.agentCode)) || (isInProgress('REG_T04') && userService.hasRole(userService.sprbCode))">
                            <p-button [ngStyle]="{'margin-left':'5px'}" class="right" icon="fa fa-check" label="{{'TASK_VIEW:COM_T04:RECEIPT'|translate}}"
                              (onClick)="communale_finalValidation()"></p-button>
                          </div>

                        </div>
                      </div>





                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>


          </div>

        </div>


      </div>
      <div class="ui-g-12 noPaddingLeftRight" *ngIf="taskService.fromSignView">
        <p-button icon="fa fa-arrow-left" class="right" label="{{'BACK_TO:SIGN'|translate}}" (onClick)="backToSignView()"></p-button>
      </div>
    </div>
  </p-panel>

</div>
