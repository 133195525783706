import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Article, Code, SignType, SignTypeUpdate} from '../../models/code.model';
import {BaseAbstractService} from './base-abstract.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Session} from '../../app.session';
import {ConfigService} from './config.service';
import {NotificationService} from './notification.service';
import {TranslationService} from './translation.service';
import {SignTypeMeasure} from '../../models/measure.model';
import {from, Observable, of} from 'rxjs';

@Injectable()
export class SignTypeService extends BaseAbstractService {
  private _codes: SignType[];

  private _signType: SignType;

  constructor(
    router: Router,
    http: HttpClient,
    session: Session,
    configService: ConfigService,
    errorService: NotificationService,
    @Inject(TranslationService) public translationService: TranslationService
  ) {
    super(router, http, session, configService, errorService);
  }

  get signType(): SignType {
    return this._signType;
  }

  set signType(value: SignType) {
    this._signType = value;
  }

  private _signTypeMeasure$: Observable<SignTypeMeasure[]>;

  get signTypeMeasure$(): Observable<SignTypeMeasure[]> {
    return this._signTypeMeasure$;
  }

  set signTypeMeasure$(value: Observable<SignTypeMeasure[]>) {
    this._signTypeMeasure$ = value;
  }

  private _article: Article;

  get article(): Article {
    return this._article;
  }

  set article(value: Article) {
    this._article = value;
  }

  _default: string = null;

  get codes(): SignType[] {
    return this._codes;
  }

  set codes(value: SignType[]) {
    this._codes = value;
  }

  private _showList = true;

  get showList(): boolean {
    return this._showList;
  }

  set showList(value: boolean) {
    this._showList = value;
  }

  get url(): string {
    return (
      environment.backendURL +
      environment.signPath +
      this.configService.appConfig.apiUrl +
      'Sign/SignTypes'
    );
  }

  generateSignTypeFile() {
    return this.get<SignType[]>(`${this.url}/signTypeReport/generate`);
  }

  downloadSignTypeFile() {
    return this.download(`${this.url}/signTypeReport/download`, false);
  }

  async findAll(force?: boolean): Promise<SignType[]> {
    if (force || !this.codes) {
      const codes = await this.get<Code[]>(`${this.url}`);
      this.codes = codes;
    }
    return this.codes;
  }

  async loadTranslations(): Promise<void> {
    await this.translationService.loadTranslation('fr');
    await this.translationService.loadTranslation('nl');
    return;
  }

  async filter(signCatIds: number[]): Promise<SignType[]> {
    return await this.post<SignType[]>(`${this.url}/filter`, signCatIds);
  }

  async save(signTypeUpdate: SignTypeUpdate) {
    this.signType = await this.put(`${this.url}/update`, signTypeUpdate);
    return this.signType;
  }

  get default(): SignType {
    return (
      this._codes.find(code => {
        return this._default === code.code ? true : false;
      }) || this._codes[0]
    );
  }

  getArticlesForSignType(signType: SignType) {
    this.signTypeMeasure$ = signType && signType.id ? from(this.get<SignTypeMeasure[]>(
      `${this.url}/articles/${signType.id}`
    )) : from(this.get<SignTypeMeasure[]>(
      `${this.url}/articles/new`
    ));
    return this.signTypeMeasure$;
  }
}
