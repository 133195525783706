<form [formGroup]="measureForm">
  <!--Article id, disabled-->
  <div class="ui-g ui-fluid">
    <div class="ui-g-12">
      <label class="custom-label">{{ 'DIALOG_FORM_ARTICLE_ID' | translate }}</label>
      <input type="text" class="editSupportInput" disabled pInputText formControlName="articleId" required/>
    </div>
  </div>
  <div class="ui-g ui-fluid">
    <!--text fr-->
    <div class="ui-g-12">
      <label class="custom-label">{{ 'DIALOG_FORM_TEXT_FR' | translate }}</label>
      <textarea
        type="text"
        class="measureFormTA"
        formControlName="textFr"
        [rows]="5"
        pInputTextarea
        required
      ></textarea>
    </div>
  </div>

  <div class="ui-g ui-fluid">
    <!--NEW PASSWORD 1-->
    <div class="ui-g-12">
      <label class="custom-label">{{ 'DIALOG_FORM_TEXT_NL' | translate }}</label>
      <textarea
        type="text"
        class="measureFormTA"
        formControlName="textNl"
        [rows]="5"
        pInputTextarea
        required
      ></textarea>
    </div>
  </div>
  <div class="ui-g-12">
    <button pButton (click)="save(measureForm.getRawValue())" [disabled]="measureForm.invalid" icon="fas fa-edit"
            class="right ml-5" label="{{'SAVE' | translate}}"></button>
    <button pButton (click)="cancel()" icon="fas fa-times" class="ui-button secondary right"
            label="{{'CANCEL' | translate}}"></button>
  </div>
</form>
