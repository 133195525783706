import { Injectable } from '@angular/core';
import { Subject } from '../../../../node_modules/rxjs';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class LayoutService {
  CENTRAL_PANEL_PROFIL = 'PROFIL';
  CENTRAL_PANEL_ADMINISTRATION = 'ADMINISTRATION';
  CENTRAL_PANEL_COMPONENTS: string[] = [
    this.CENTRAL_PANEL_PROFIL,
    this.CENTRAL_PANEL_ADMINISTRATION
  ];

  constructor() {

  }

  private _centerPanelComponent: string;

  get centerPanelComponent(): string {
    return this._centerPanelComponent;
  }

  set centerPanelComponent(value: string) {
    this._centerPanelComponent = value;
  }

  private leftPanelContentSource = new Subject<any>();
  leftPanelContentHandler: Observable<number[]> = <Observable<number[]>>(
    this.leftPanelContentSource.asObservable()
  );

  set leftPanelContent(content: any) {
    this.leftPanelContentSource.next(content);
  }

  _leftPanelVisible = false;
  private visibilityLeftPanelSource = new Subject<boolean>();

  visibilityLeftPanelHandler: Observable<boolean> = <Observable<boolean>>(
    this.visibilityLeftPanelSource.asObservable()
  );

  set leftPanelVisible(val: boolean) {
    this.leftPanelClosed = this.leftPanelClosed && !val;
    this._leftPanelVisible = val;
    this.visibilityLeftPanelSource.next(val);
  }
  get leftPanelVisible(): boolean {
    return this._leftPanelVisible && !this.leftPanelClosed;
  }

  _leftPanelFull = false;
  private leftPanelFullSource = new Subject<boolean>();

  leftPanelFullHandler: Observable<boolean> = <Observable<boolean>>(
    this.leftPanelFullSource.asObservable()
  );

  set leftPanelFull(val: boolean) {
    this._leftPanelFull = val;
    this.leftPanelFullSource.next(val);
  }
  get leftPanelFull(): boolean {
    return this._leftPanelFull && !this.leftPanelClosed;
  }

  _leftPanelClosed = true;
  private leftPanelClosedSource = new Subject<boolean>();

  leftPanelClosedHandler: Observable<boolean> = <Observable<boolean>>(
    this.leftPanelClosedSource.asObservable()
  );

  set leftPanelClosed(val: boolean) {
    this._leftPanelClosed = val;
    this.leftPanelClosedSource.next(val);
  }
  get leftPanelClosed(): boolean {
    return this._leftPanelClosed;
  }

  private rightPanelContentSource = new Subject<any>();
  rightPanelContentHandler: Observable<any> = <Observable<any>>(
    this.rightPanelContentSource.asObservable()
  );

  set rightPanelContent(content: any) {
    this.rightPanelContentSource.next(content);
  }

  _rightPanelVisible = false;
  private visibilityRightPanelSource = new Subject<boolean>();

  visibilityRightPanelHandler: Observable<boolean> = <Observable<boolean>>(
    this.visibilityRightPanelSource.asObservable()
  );

  set rightPanelVisible(val: boolean) {
    this.rightPanelClosed = this.rightPanelClosed && !val;
    this._rightPanelVisible = val;
    this.visibilityRightPanelSource.next(val);
  }
  get rightPanelVisible(): boolean {
    return this._rightPanelVisible;
  }

  _rightPanelFull = false;
  private rightPanelFullSource = new Subject<boolean>();

  rightPanelFullHandler: Observable<boolean> = <Observable<boolean>>(
    this.rightPanelFullSource.asObservable()
  );

  set rightPanelFull(val: boolean) {
    this._rightPanelFull = val;
    this.rightPanelFullSource.next(val);
  }
  get rightPanelFull(): boolean {
    return this._rightPanelFull;
  }

  _rightPanelStyle = {};
  set rightPanelStyle(val: any) {
    this._rightPanelStyle = val;
  }
  get rightPanelStyle(): any {
    return this._rightPanelStyle;
  }

  _rightPanelClosed = true;
  private rightPanelClosedSource = new Subject<boolean>();

  rightPanelClosedHandler: Observable<boolean> = <Observable<boolean>>(
    this.rightPanelClosedSource.asObservable()
  );

  set rightPanelClosed(val: boolean) {
    this._rightPanelClosed = val;
    this._rightPanelVisible = !val;
    this.rightPanelClosedSource.next(val);
  }
  get rightPanelClosed(): boolean {
    return this._rightPanelClosed;
  }

  private actionIconsContentSource = new Subject<any>();
  actionIconsContentHandler: Observable<any> = <Observable<any>>(
    this.actionIconsContentSource.asObservable()
  );

  set actionIconsContent(content: any) {
    this.actionIconsContentSource.next(content);
  }

  _actionIconsVisible = false;
  private visibilityActionIconsSource = new Subject<boolean>();

  visibilityActionIconsHandler: Observable<boolean> = <Observable<boolean>>(
    this.visibilityActionIconsSource.asObservable()
  );

  set actionIconsVisible(val: boolean) {
    this._actionIconsVisible = val;

    this.visibilityActionIconsSource.next(val);
  }
  get actionIconsVisible(): boolean {
    return this._actionIconsVisible;
  }

  // center panel
  private centerPanelContentSource = new Subject<any>();
  centerPanelContentHandler: Observable<number[]> = <Observable<number[]>>(
    this.centerPanelContentSource.asObservable()
  );

  set centerPanelContent(content: any) {
    this.centerPanelContentSource.next(content);
  }

  _centerPanelVisible = false;
  private visibilityCenterPanelSource = new Subject<boolean>();

  visibilityCenterPanelHandler: Observable<boolean> = <Observable<boolean>>(
    this.visibilityCenterPanelSource.asObservable()
  );

  set centerPanelVisible(val: boolean) {
    this.centerPanelClosed = this.centerPanelClosed && !val;
    this._centerPanelVisible = val;
    this.visibilityCenterPanelSource.next(val);
  }
  get centerPanelVisible(): boolean {
    return this._centerPanelVisible && !this.centerPanelClosed;
  }

  _centerPanelFull = false;
  private centerPanelFullSource = new Subject<boolean>();

  centerPanelFullHandler: Observable<boolean> = <Observable<boolean>>(
    this.centerPanelFullSource.asObservable()
  );

  set centerPanelFull(val: boolean) {
    this._centerPanelFull = val;
    this.centerPanelFullSource.next(val);
  }
  get centerPanelFull(): boolean {
    return this._centerPanelFull && !this.centerPanelClosed;
  }

  _centerPanelClosed = true;
  private centerPanelClosedSource = new Subject<boolean>();
  centerPanelClosedHandler: Observable<boolean> = <Observable<boolean>>(
    this.centerPanelClosedSource.asObservable()
  );

  _destroyCenterPanelContent = true;
  private destroyCenterPanelContentSource = new Subject<boolean>();
  destroyCenterPanelContentHandler: Observable<boolean> = <Observable<boolean>>(
    this.destroyCenterPanelContentSource.asObservable()
  );

  set centerPanelClosed(val: boolean) {
    this._centerPanelClosed = val;

    this.centerPanelClosedSource.next(val);
  }
  destroyCenterPanelComponent() {
    this.destroyCenterPanelContentSource.next(true);
  }
  get centerPanelClosed(): boolean {
    return this._centerPanelClosed;
  }

  closeRightPanel() {
    this.rightPanelFull = false;
    this.rightPanelVisible = false;
    this.rightPanelClosed = true;
  }
  closeLeftPanel() {
    this.leftPanelFull = false;
    this.leftPanelVisible = false;
    this.leftPanelClosed = true;
  }

  closeCenterPanel() {
    this.centerPanelComponent = '';
    this.centerPanelFull = false;
    this.centerPanelVisible = false;
    this.centerPanelClosed = true;
  }
}
