import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { LayoutService } from '../../core/services/layout.service';
import { SupportComponent } from '../supports/support/support.component';
import { SupportService } from '../../core/services/support.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'actions-panel',
  templateUrl: './layout.actions-panel.component.html',
  styleUrls: ['./layout.actions-panel.component.scss']
})
export class LayoutActionsPanelComponent implements OnInit {
  activeActionItem: any;
  constructor(
    public userService: UserService,
    private layoutService: LayoutService,
    private supportService: SupportService
  ) {}

  ngOnInit() {}

  tmp() {
    this.supportService.editMode = true;
    this.layoutService.rightPanelContent = SupportComponent;
    this.layoutService.rightPanelVisible = true;
  }
}
