import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {LoggedComponent} from '../../../shared/components/logged/logged.component';
import {UserService} from '../../../core/services/user.service';
import {LayoutService} from '../../../core/services/layout.service';
import {AdministrationComponent} from '../administration/administration.component';
import {SelectItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../core/services/notification.service';
import {MunicipalityService} from '../../../core/services/municipality.service';
import {ROLES} from '../../../models/role.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../models/user.model';
import {LoaderService} from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent extends LoggedComponent implements OnInit {
  languagesOptions: SelectItem[] = [];
  rolesOptions: SelectItem[] = [];
  municipalityOptions: SelectItem[] = [];
  loaded = false;
  translate: TranslateService = this.injector.get(TranslateService);

  @ViewChild('form', {static: true})
  form;

  userForm: FormGroup;
  surnameCtrl: FormControl;
  nameCtrl: FormControl;
  loginCtrl: FormControl;
  emailCtrl: FormControl;
  municipalityCtrl: FormControl;
  languageCtrl: FormControl;
  roleCtrl: FormControl;
  mobileCtrl: FormControl;
  isActiveCtrl: FormControl;
  desktopCtrl: FormControl;
  adminCtrl: FormControl;
  public MUNICIPALITY_AGENT_ROLE = ROLES.MUNICIPALITY_AGENT;
  public selectedRoleCode: string;

  constructor(
    public userService: UserService,
    private injector: Injector,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private municipalityService: MunicipalityService,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
  ) {
    super(userService);
  }

  async ngOnInit() {
    this.loaderService.showLoader();

    try {
      this.surnameCtrl = this.fb.control('', Validators.required);
      this.nameCtrl = this.fb.control('', Validators.required);
      this.loginCtrl = this.fb.control('', Validators.required);
      this.emailCtrl = this.fb.control('', [
        Validators.required,
        Validators.email
      ]);
      this.municipalityCtrl = this.fb.control('');
      this.languageCtrl = this.fb.control('', Validators.required);
      this.roleCtrl = this.fb.control('', Validators.required);
      this.mobileCtrl = this.fb.control('');
      this.isActiveCtrl = this.fb.control('');
      this.desktopCtrl = this.fb.control('');
      this.adminCtrl = this.fb.control('');

      this.userForm = this.fb.group({
        surname: this.surnameCtrl,
        name: this.nameCtrl,
        login: this.loginCtrl,
        email: this.emailCtrl,
        municipality: this.municipalityCtrl,
        language: this.languageCtrl,
        role: this.roleCtrl,
        mobile: this.mobileCtrl,
        isActive: this.isActiveCtrl,
        desktop: this.desktopCtrl,
        isAdmin: this.adminCtrl
      });
      await this.municipalityService.findAll();
      await this.userService.findAllRoles();
      this.userService.selectedUser = {
        id: null,
        roles: [this.userService.roles[0]],
        language: 'FR'
      };

      this.languagesOptions = [];
      await this.userService.languages.forEach(async languageCode => {
        const trad = await this.translate.get(languageCode).toPromise();
        this.languagesOptions.push({ label: trad, value: languageCode });
      });
      this.userService.findAllRoles();
      this.rolesOptions = [];
      await this.userService.roles.forEach(async role => {
        const trad = await this.translate.get(role.code).toPromise();
        this.rolesOptions.push({ label: trad, value: role });
      });
      this.rolesOptions.sort(function (a, b) {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }
        return 0;
      });
      this.selectedRoleCode = this.rolesOptions[0].value.code;
      this.municipalityOptions = [];
      this.municipalityOptions.push({ label: '', value: null });
      await this.municipalityService.municipality.forEach(async mun => {
        this.municipalityOptions.push({ label: mun.name, value: mun });
      });
      this.municipalityOptions.sort(function (a, b) {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }
        return 0;
      });
      this.loaded = true;
      const defaultValues = {
        language: this.languagesOptions[0],
        role: this.rolesOptions[0]
      };
      this.userForm.patchValue(defaultValues);
    } catch (error) {

    } finally {
      this.loaderService.hideLoader();
    }
  }

  roleChanged(role) {
    this.selectedRoleCode = role.value.code;
  }

  showMunicipalitiesDropdown(): boolean {
    return this.selectedRoleCode === this.MUNICIPALITY_AGENT_ROLE;
  }

  async save(value: User) {
    this.loaderService.showLoader();

    try {
      this.userService.selectedUser = await this.userService.createUser(value);
      this.userService.users.push(this.userService.selectedUser);
      this.cancel();
      await this.notificationService.addSingleSuccess(
        this.translateService.instant('COMMON_SUCESS'),
        this.translateService.instant('USER_SAVE_SUCESS')
      );
    } catch (error) {
      this.notificationService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  cancel() {
    this.layoutService.centerPanelContent = AdministrationComponent;
  }
}
