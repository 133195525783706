import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Sign } from '../../../models/sign.model';
import { SignService } from '../../../core/services/sign.service';

@Directive({
  selector: '[appSignSelected]'
})
export class SignSelectedDirective implements OnChanges {

  @HostBinding('style.font-weight')
  fontWeight;

  @Input()
  sign: Sign;

  constructor(
    private readonly signService: SignService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.signService.signsSelected.find(s => s.id === this.sign.id)) {
      this.fontWeight = 'bold';
    } else {
      this.fontWeight = 'normal';
    }
  }
}
