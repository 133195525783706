import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MeasureService} from '../../../../core/services/api/measure.service';
import {Measure} from '../../../../models/measure.model';

@Component({
  selector: 'app-measure-form',
  templateUrl: './measure-form.component.html'
})
export class MeasureFormComponent implements OnInit {
  form: FormGroup;

  constructor(private fb: FormBuilder,
              private measureService: MeasureService) {
    this.form = this.fb.group({
      id: [null],
      articleId: ['', Validators.required],
      textFr: ['', Validators.required],
      textNl: ['', Validators.required],
      isAbrogation: [{value: null, disabled: true}],
    });
  }

  ngOnInit(): void {
    this.form.patchValue({...this.measureService.selected});
    if (this.measureService.selected) {
      this.form.get('articleId').disable();
    }
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }
    this.measureService.saved$.next({...this.form.getRawValue()});
  }

  cancel(measure?: Measure): void {
    this.measureService.cancelled$.next();
  }
}
