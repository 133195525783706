<p-panel [header]="'GESTION_MEASURES' | translate">
  <form [formGroup]="form" (keydown.enter)="save()">
    <div class="ui-g ui-fluid form-group">
      <div class="ui-g-12">
        <label class="custom-label">{{ 'DIALOG_FORM_ARTICLE_ID' | translate }}</label>
        <input type="text" pInputText formControlName="articleId" required/>
      </div>
      <div class="ui-g-12">
        <label class="custom-label">{{ 'DIALOG_FORM_TEXT_FR' | translate }}</label>
        <textarea type="text" formControlName="textFr" [rows]="7" pInputTextarea required></textarea>
      </div>
      <div class="ui-g-12">
        <label class="custom-label">{{ 'DIALOG_FORM_TEXT_NL' | translate }}</label>
        <textarea type="text" formControlName="textNl" [rows]="7" pInputTextarea required></textarea>
      </div>
    </div>
  </form>
  <div class="ui-g sticky-bottom-buttons">
    <div class="ui-g-12">
      <p-button (click)="save()" [disabled]="form.invalid" icon="fas fa-edit" class="right ml-5"
                label="{{'SAVE' | translate}}"></p-button>
      <p-button (click)="cancel()" icon="fas fa-times" class="right secondary"
                label="{{'CANCEL' | translate}}"></p-button>
    </div>
  </div>
</p-panel>
