<div class="ui-g" style="text-align: center; margin-top: 30px;">
  <div class="ui-g-6">
    <div class="ui-g-4 xBold">{{'IMPORT_TRANSLATIONS'|translate}}</div>
    <div *ngIf="fileUploaded" class="ui-g-4">
      {{fileName | substring:20}}
    </div>
    <div class="ui-g-4">
      <input #input hidden="true" type="file" accept="application/json" (change)="onFileSelected($event)">
      <p-button [class]="fileUploaded?'secondary':''" (click)="input.click()" icon="fas fa-cloud-upload-alt" label="{{'IMPORT'|translate}}"></p-button>
      <p-button [ngStyle]="{'margin-left':'5px'}" *ngIf="fileUploaded" (click)="uploadTrad()" icon="fas fa-save" label="{{'SAVE'|translate}}"></p-button>
    </div>
  </div>
  <div class="ui-g-6">
    <div class="ui-g-4 xBold">{{'EXPORT_TRANSLATIONS'|translate}}</div>
    <div class="ui-g-4">
      <p-button (click)="exportTranslation()" icon="fas fa-cloud-download-alt" label="{{'EXPORT'|translate}}"></p-button>
    </div>
  </div>
</div>
