import { Sign } from './sign.model';
import * as ol from 'ol';
import * as olGeom from 'ol/geom';
import { Code } from './code.model';

export interface Support {
  id?: number;
  geom?: string;
  geomOffset?: string;
  gestion?: Code;
  type?: Code;
  typeOthers?: string;
  specificity?: Code;
  heightOffGround?: number;
  placementDate?: Date;
  removalDate?: Date;
  address?: string;
  number?: number;
  note?: string;
  other?: string;
  orientation?: number;
  placementUser?: string;
  removalUser?: string;
  placementNote?: string;
  removalNote?: string;
  deleteAt?: Date;
  signs?: Sign[];
  oldSigns?: Sign[];
  signOrders?: { id; order }[];
}

export class SupportHelper {
  static getFeature(support: Support): ol.Feature {
    const feature: ol.Feature = new ol.Feature(this.getGeometry(support));
    return feature;
  }
  static getGeometry(support): olGeom.Point {
    const geom: any = JSON.parse(support.geom);
    return new olGeom.Point(<[number, number]>geom.coordinates);
  }

  static getOffsetFeature(support: Support): ol.Feature {
    const feature: ol.Feature = new ol.Feature(this.getOffsetGeometry(support));
    return feature;
  }
  static getOffsetGeometry(support): olGeom.Point {
    try {
      const geom: any = JSON.parse(support.geomOffset);
      return new olGeom.Point(<[number, number]>geom.coordinates);
    } catch (e) {
      return SupportHelper.getGeometry(support);
    }
  }

  static duplicateSupportFrom(support: Support) : Support {
    return {
      gestion: support.gestion,
      type: support.type,
      typeOthers: support.typeOthers,
      specificity: support.specificity,
      heightOffGround: support.heightOffGround,
      orientation: 0,
      note: support.note,
      other: support.other
    }
  }
}
