import { Pipe, PipeTransform } from '@angular/core';
import { Task } from 'app/models/task.model';
import { RcHelper, Rc } from '../../models/rc.model';

@Pipe({ name: 'rcLastTask' })
export class RcLastTaskPipe implements PipeTransform {
  transform(value: Rc): Task {
    const last = RcHelper.getLastTask(value);
    return last ? last : <Task>{};
  }
}

@Pipe({ name: 'rcLastCompletedTask' })
export class RcLastCompletedTaskPipe implements PipeTransform {
  transform(value: Rc): Task {
    const last = RcHelper.getLastCompletedTask(value);
    return last ? last : <Task>{};
  }
}

@Pipe({ name: 'rcCurrentTask' })
export class RcCurrentTaskPipe implements PipeTransform {
  transform(value: Rc): Task {
    const last = RcHelper.getLastTask(value);
    return last ? last : <Task>{};
  }
}

@Pipe({ name: 'rcDueDate' })
export class RcDueDatePipe implements PipeTransform {
  transform(value: Rc): Date {
    return RcHelper.getDueDate(value);
  }
}

@Pipe({ name: 'rcTaskDate' })
export class RcTaskDatePipe implements PipeTransform {
  transform(value: Rc, code: string): Date {
    return RcHelper.getTaskDate(value, code);
  }
}

@Pipe({ name: 'rcTaskStatus' })
export class RcTaskStatusPipe implements PipeTransform {
  transform(value: Rc, code: string): boolean {
    return RcHelper.getTaskStatus(value, code);
  }
}
