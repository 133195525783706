<div class="panel-sign-list">
  <p-panel class="customPanel" [style]="{'height':'100%'}">
    <p-header id="panel-list-sign-view-header">
      <title-header [icon]="true" [iconClass]="'fas fa-list-ul'" [title]="'PANEL_SIGN_LIST:TITLE' | translate"
                    [iButton]="true" [iButtonIcon]="'fas fa-file-excel'" [iButtonAction]="export"
                    [direction]="'LEFT'"></title-header>
    </p-header>
    <div class="ui-g">
      <div class="ui-g-12">
        <p-virtualScroller id="sign-scroller" [value]="signService.signsSelected" scrollHeight="100%"
                           [style]="{'height':'100%'}">
          <ng-template let-sign let-last="index" pTemplate="item">
            <div class="scroller-template" pTooltip='{{sign?.support | signAddress}}' (mouseenter)="mouseEnter(sign)" (mouseleave)="mouseLeave(sign)"
                 appSignSelected [sign]="sign">
              <img src="{{sign.type| signTypeImage}}" alt="pic"/>
              <span appSignCode
                    [sign]="sign">{{sign.type.code}} -
              </span>
              <span class="sign-address">
                {{sign?.support | signAddress | ellipsis:28 - sign.type.code.length}}
              </span>
              <span class="unselect-sign" (click)="unselectSign(sign)">x</span>
              <span class="show-sign fa fa-search" (click)="showSign(sign)"></span>
            </div>
          </ng-template>
        </p-virtualScroller >
      </div>
    </div>
    <div class="ui-g sticky-bottom-buttons">
      <div class="ui-g-12">
        <p-button *ngIf="canCreateRc()" [ngStyle]="{'margin-right':'5px'}" class="right" icon="fas fa-cogs"
                  label="{{'GENERATE_RC' | translate}}" (click)="generateRC()"
                  [disabled]="signService.signsSelected.length==0"></p-button>
        <p-button [ngStyle]="{'margin-right':'5px'}" class="secondary right" icon="fas fa-trash-alt"
                  label="{{'RESET_SELECTION' | translate}}" (click)="resetSelect()"
                  [disabled]="signService.signsSelected.length==0"></p-button>
      </div>
    </div>
  </p-panel>
</div>

