import { Component, Injector, OnInit } from '@angular/core';
import { Message } from 'primeng/primeng';
import { ActivatedRoute, Router } from '@angular/router';
import { routerLink } from '../../../assets/config/routerLink';
import { UserService } from '../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../core/services/layout.service';
import { NotificationService } from '../../core/services/notification.service';
import { LoaderService } from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  translate: TranslateService = this.injector.get(TranslateService);

  public credentials: any = {};
  public resetCredentials: any = {};
  public messageLogin: Message[] = [];

  public displayResetMessage = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private notificationService: NotificationService,
    private injector: Injector,
    private layoutService: LayoutService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.layoutService.closeRightPanel();
    this.layoutService.closeLeftPanel();
  }

  public async login() {
    this.messageLogin = [];
    try {
      this.loaderService.showLoader();

      await this.userService.obtainAccessToken(this.credentials);
      await this.userService.checkLogin();

      if (this.userService.user && this.userService.user.language) {
        this.translate.use(this.userService.user.language.toLowerCase());
      }
      this.router.navigate([routerLink.map]);
    } catch (err) {
      this.notificationService.addSingleError(err);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  checkResetPassword() {
    this.displayResetMessage = true;
  }
  cancelResetPassword() {
    this.displayResetMessage = false;
  }
  async resetPassword() {
    if (this.resetCredentials.login && this.resetCredentials.mail) {
      try {
        this.loaderService.showLoader();

        await this.userService.resetPassword(
          this.resetCredentials.login,
          this.resetCredentials.mail
        );

        const resetSendHeader: string = await this.translate
          .get('RESET:SEND:HEADER')
          .toPromise();
        const resetSendMessage: string = await this.translate
          .get('RESET:SEND:MESSAGE')
          .toPromise();

        this.notificationService.addSingleInfo(
          resetSendHeader,
          resetSendMessage
        );
      } catch (err) {
        this.notificationService.addSingleError(err);
      } finally{
        this.loaderService.hideLoader();
      }

      this.displayResetMessage = false;
    }
  }
}
