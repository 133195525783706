import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Session } from '../../app.session';
import { environment } from '../../../environments/environment';
import { CryptoService } from './crypto.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { map, catchError } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class OauthService {
  constructor(protected _router: Router, protected _http: HttpClient, protected _session: Session) {}

  userSource = new Subject<User>();
  user$ = this.userSource.asObservable();

  async handleOAuthHeader(options: any = {}): Promise<any> {
    const auth: string = await this.getBearerAuthorization();
    const headers: HttpHeaders = options.headers
      ? options.headers
      : new HttpHeaders({
          Authorization: auth,
        });
    headers.set('Authorization', auth);
    options.headers = headers;
    return options;
  }

  get user(): User {
    return this._session.user;
  }
  set user(user: User) {
    this._session.user = user;
    this.userSource.next(user);
  }

  async getBearerAuthorization(): Promise<string> {
    this.checkCredentials();
    if (!this.isValid) {
      try {
        const data = await this.reloadAccessToken().toPromise();
        this.saveToken(data);
        if (!this.isValid) {
          this.logout();
          throw new Error('session.expired');
        }
      } catch (e) {
        this.logout();
        throw new Error('session.expired');
      }
    }
    return `Bearer ${this._session.oAuthtoken.access_token}`;
  }

  async obtainAccessToken(loginData): Promise<any> {
    const promise: Promise<any> = new Promise((resolve, reject) => {
      const params = new URLSearchParams();

      const cryptedPassword = btoa(CryptoService.cryptAsSHA256(loginData.password));
      params.append('username', loginData.username);
      params.append('password', cryptedPassword);
      params.append('grant_type', environment.grantType);
      params.append('client_id', environment.oAuthClientId);
      params.append('client_secret', environment.oAuthClientSecret);

      const headers = new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });

      this._http
        .post(`${environment.backendURL}${environment.accessTokenUri}`, params.toString(), { headers: headers })
        .pipe(
          map((res: Response) => {
            return res;
          })
        )
        .subscribe(
          data => {
            this.saveToken(data);
            resolve(null);
          },
          err => {
            reject('Invalid Credentials');
          }
        );
    });

    return promise;
  }

  async saveToken(token) {
    this._session.oAuthtoken = token;
  }

  reloadAccessToken(): Observable<any> {
    const params = new URLSearchParams();

    params.append('refresh_token', this._session.oAuthtoken.refresh_token);
    params.append('grant_type', environment.grantTypeReload);
    params.append('client_id', environment.oAuthClientId);
    params.append('client_secret', environment.oAuthClientSecret);

    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
    });
    return this._http.post(`${environment.backendURL}${environment.accessTokenUri}`, params.toString(), {
      headers: headers,
    });
  }

  get isValid(): boolean {
    if (this._session.oAuthtoken && new Date() < new Date(this._session.oAuthtoken['.expires'])) {
      return true;
    }
    return false;
  }

  getResource(resourceUrl): Observable<Response> {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      Authorization: 'Bearer ' + this._session.oAuthtoken.access_token,
    });

    return this._http.get(resourceUrl, { headers: headers }).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError((error: any) => Observable.throw(error.json().error || 'Server error'))
    );
  }

  checkCredentials() {
    if (!this._session.oAuthtoken) {
      this.logout();
    }
  }

  logout() {
    this.user = null;
    this._router.navigate(['/login']);
  }
}
