<div class="ui-g" *ngIf="!layoutService.centerPanelClosed">
  <div class="ui-g-1"></div>
  <div class="ui-g-10">
    <h2>{{'ADMINISTRATION'|translate}}</h2>
    <p-tabView (onChange)="tabViewChanged()" class="tabView">
      <p-tabPanel [header]="'USER_MANAGEMENT' | translate" [disabled]="administrationService.editMode">
        <user-management></user-management>
      </p-tabPanel>
      <p-tabPanel [header]="'SIGNS_MANAGEMENT'| translate" [disabled]="administrationService.editMode">
        <ng-template pTemplate="content">
          <sign-type-management></sign-type-management>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel [header]="'GESTION_MEASURES'| translate" [disabled]="administrationService.editMode">
        <ng-template pTemplate="content">
           <measure-management></measure-management>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel [header]="'RC_TEMPLATE_MANAGEMENT'| translate" [disabled]="administrationService.editMode">
        <ng-template pTemplate="content">
          <manage-rc-template></manage-rc-template>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel [header]="'TRANSLATION_IMPORT_EXPORT'| translate" [disabled]="administrationService.editMode">
        <ng-template pTemplate="content">
          <translation-management></translation-management>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel [header]=" 'ADMINISTRATION:TAB:IMPORT_REF_COM' | translate" [disabled]="administrationService.editMode">
        <ng-template pTemplate="content">
          <import-data></import-data>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel [header]="'ESIGN_PARAMETERS' |translate" [disabled]="administrationService.editMode">
        <ng-template pTemplate="content">
          <manage-parameters></manage-parameters>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div class="ui-g-1"></div>
</div>
