import {Injectable} from '@angular/core';
import {BaseAbstractService} from './base-abstract.service';
import {Code} from '../../models/code.model';
import {environment} from '../../../environments/environment';

@Injectable()
export class TypeCategorieService extends BaseAbstractService
{
  _codes:Code[];

  get url():string {
    return environment.backendURL + environment.signPath+ this.configService.appConfig.apiUrl + 'Sign/TypesCategorie';
  }

  async findAll(): Promise<Code[]> {
    this._codes = this._codes ? this._codes: await this.get<Code[]>(`${this.url}`);
    return this._codes;
  }

}
