import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RcService } from '../../core/services/rc.service';
import { LayoutService } from '../../core/services/layout.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SignService } from '../../core/services/sign.service';
import { SupportService } from '../../core/services/support.service';
import { UserService } from '../../core/services/user.service';
import { Rc, RC_STATUS } from '../../models/rc.model';
import { ROLES } from '../../models/role.model';
import { TranslateService } from '@ngx-translate/core';
import { TaskViewComponent } from '../tasks/task-view/task-view.component';
import { NotificationService } from '../../core/services/notification.service';
import { ParameterService } from '../../core/services/parameter.service';
import { SignTypeService } from '../../core/services/sign-type.service';
import { TranslationService } from 'app/core/services/translation.service';
import { Panel } from 'primeng/panel';
import { LoaderService } from 'app/core/services/loader.service';
import { RcMeasuresComponent } from './rc-measures/rc-measures.component';
import { MeasureService } from '../../core/services/api/measure.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'rc',
  templateUrl: './rc.component.html',
  styleUrls: ['./rc.component.scss'],
})
export class RcComponent implements OnInit, OnDestroy {
  @ViewChild('abrogations', { static: false }) abrogations: RcMeasuresComponent;
  @ViewChild('newClauses', { static: false }) newClauses: RcMeasuresComponent;

  translate: TranslateService = this.injector.get(TranslateService);

  rcForm: FormGroup;

  displayCancelRC = false;
  displayWordingMissingRC = false;
  displaySavingRC = false;
  displayUpdateRC = false;

  @ViewChild('panel', { static: true }) panel: Panel;

  get rc(): Rc {
    return this.rcService.rc;
  }

  set rc(rc: Rc) {
    this.rcService.rc = rc;
  }

  get RC_STATUS() {
    return RC_STATUS;
  }

  signsTypeAndRoad: { signType; roads }[] = [];

  constructor(
    public rcService: RcService,
    public layoutService: LayoutService,
    public signService: SignService,
    public signTypeService: SignTypeService,
    public supportService: SupportService,
    public userService: UserService,
    public parameterService: ParameterService,
    public translateService: TranslationService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private injector: Injector,
    private loaderService: LoaderService,
    public measureService: MeasureService,
    public cdr: ChangeDetectorRef,
  ) {
  }

  async ngOnInit() {
    this.rcForm = this.fb.group({
      wording: this.fb.control(''),
      rcHead: this.fb.control(''),
      rcBody: this.fb.control(''),
      rcFooter: this.fb.control(''),
    });

    if (!this.rcService.editMode) {
      this.rcForm.disable();
    }

    this.loaderService.showLoader();

    if (this.rcService.rc && this.rcService.rc.signs) {
      this.signService.signsSelected = this.rcService.rc.signs;
    }
    try {
      if (!this.rcService.rc || (this.rcService.rc && !this.rcService.rc.id)) {
        this.rcService.rc = <Rc>{};
        await this.parameterService.findAll();
        if (this.userService.role && this.userService.role.code === ROLES.MUNICIPALITY_AGENT) {
          this.rcService.rc.introductionFr = <string>(
            this.parameterService.find(this.parameterService.RC_TEMPLATE_COMM_INTRO_FR).value
          );
          this.rcService.rc.introductionNl = <string>(
            this.parameterService.find(this.parameterService.RC_TEMPLATE_COMM_INTRO_NL).value
          );
          this.rcService.rc.conclusionFr = <string>(
            this.parameterService.find(this.parameterService.RC_TEMPLATE_COMM_CONCL_FR).value
          );
          this.rcService.rc.conclusionNl = <string>(
            this.parameterService.find(this.parameterService.RC_TEMPLATE_COMM_CONCL_NL).value
          );
        } else {
          this.rcService.rc.introductionFr = <string>(
            this.parameterService.find(this.parameterService.RC_TEMPLATE_REG_INTRO_FR).value
          );
          this.rcService.rc.introductionNl = <string>(
            this.parameterService.find(this.parameterService.RC_TEMPLATE_REG_INTRO_NL).value
          );
          this.rcService.rc.conclusionFr = <string>(
            this.parameterService.find(this.parameterService.RC_TEMPLATE_REG_CONCL_FR).value
          );
          this.rcService.rc.conclusionNl = <string>(
            this.parameterService.find(this.parameterService.RC_TEMPLATE_REG_CONCL_NL).value
          );
        }
        this.rcService.rc.signs = this.signService.signsSelected;
      }
      this.cdr.markForCheck();
    } catch (error) {
      this.notificationService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  ngOnDestroy(): void {
    this.layoutService.rightPanelFull = false;
    this.layoutService.rightPanelStyle = {};
  }

  close() {
    this.layoutService.closeRightPanel();
  }

  delete() {
    this.notificationService.confirm({
      message: this.translateService.instant('RC:DELETE_CONFIRM'),
      accept: async () => {
        this.loaderService.showLoader();
        await this.rcService.deleteRCDraft(this.rcService.rc).toPromise();
        this.rcService.rcSearchResult = await this.rcService.lastSearch().toPromise();
        await this.notificationService.addSingleSuccess(this.translateService.instant('COMMON_SUCESS'), '');
        this.layoutService.closeRightPanel();
        this.loaderService.hideLoader();
      }
    });
  }

  async validate(createWF: boolean) {
    if (this.rcService.rc.title) {
      this.displaySavingRC = false;
      this.displayUpdateRC = false;

      var success: boolean = false;
      if (this.userService.role.code === ROLES.SPRB_AGENT || this.userService.role.code === ROLES.MUNICIPALITY_AGENT) {
        if (!this.rcService.rc.municipalityId) {
          if (this.userService.municipality) {
            this.rcService.rc.municipalityId = this.userService.municipality.id;
          }
        }

        if (!this.rcService.rc.signs || this.rcService.rc.signs.length < 1) {
          this.rcService.rc.signs = this.rcService.signs;
        }

        try {
          this.loaderService.showLoader();
          this.rcService.rc.abrogations = this.abrogations.rcMeasures;
          this.rcService.rc.newClauses = this.newClauses.rcMeasures;

          if (createWF) {
            delete this.rcService.rc.tasks;
            delete this.rcService.rc.status;
            this.rcService.rc = await this.rcService.createWF(this.rcService.rc).toPromise();
          } else {
            this.rcService.rc = await this.rcService.createOrUpdate(this.rcService.rc).toPromise();
          }
          success = true;
          await this.showSaveNotification();
        } catch (error) {
          this.notificationService.addSingleError(this.translateService.instant(error.error.ExceptionMessage, error.error.Parameters));
          success = false;
        } finally {
          this.loaderService.hideLoader();

          if (success && !this.backToTaskView()) {
            this.close();
          }
        }
      } else {
        // console.info('//TODO HANDLE ERROR', 'User not SPRB or Communal');
      }
    } else {
      this.displayWordingMissingRC = true;
    }
  }

  cancel() {
    if (this.rcService.rc && this.rcService.rc.id) {
      if (!this.backToTaskView()) {
        this.close();
      }
    } else {
      this.displayCancelRC = true;
    }
  }

  hideWordingMissingRC() {
    this.displayWordingMissingRC = false;
  }

  cancelRcYes() {
    this.layoutService.closeRightPanel();
    this.displayCancelRC = false;
  }

  cancelRcNo() {
    this.displayCancelRC = false;
  }

  async draftRc() {
    if (this.rcService.rc.title) {
      try {
        this.loaderService.showLoader();

        if (!this.rcService.rc.municipalityId) {
          if (this.userService.municipality) {
            this.rcService.rc.municipalityId = this.userService.municipality.id;
          }
        }
        if (!this.rcService.rc.signs) {
          this.rcService.rc.signs = this.rcService.signs;
        }
        this.rcService.rc.abrogations = this.abrogations.rcMeasures;
        this.rcService.rc.newClauses = this.newClauses.rcMeasures;
        this.rcService.rc = await this.rcService.createRCDraft(this.rcService.rc).toPromise();
        await this.showSaveNotification();
      } catch (error) {
        this.notificationService.addSingleError(this.translateService.instant(error.error.ExceptionMessage, error.error.Parameters));
      } finally {
        this.loaderService.hideLoader();
      }
    } else {
      this.displayWordingMissingRC = true;
    }
  }

  private async showSaveNotification() {
    const infoMessageTitle: string = await this.translate.get('INFORMATION_MESSAGE').toPromise();
    const infoMessageContent: string = await this.translate.get('RC_VIEW:INFO:SAVE_RC_OK').toPromise();

    this.notificationService.addSingleInfo(infoMessageTitle, infoMessageContent);
  }

  validRc() {
    if (this.rcService.rc.title) {
      if (this.rcService.rc.status === RC_STATUS.pending) {
        this.displayUpdateRC = true;
      } else {
        this.displaySavingRC = true;
      }
    } else {
      this.displayWordingMissingRC = true;
    }
  }

  updateRc() {
    if (this.rcService.rc.title) {
      this.displayUpdateRC = true;
    } else {
      this.displayWordingMissingRC = true;
    }
  }

  validRcNo() {
    this.displaySavingRC = false;
    this.displayUpdateRC = false;
  }

  backToTaskView(): boolean {
    if (this.rcService.fromTaskView) {
      this.layoutService.rightPanelContent = TaskViewComponent;
      this.rcService.fromTaskView = null;
      return true;
    }
    return false;
  }

  async downloadRc() {
    if (this.rcService.editMode) {
      await this.draftRc();
    }
    if (this.rcService.rc.title && this.rcService.rc.id) {
      this.rcService
        .generateRcDocumentFromTotem(
          this.rcService.rc.id,
          this.rcService.rc.title,
          this.userService.role.code === ROLES.MUNICIPALITY_AGENT ? 1 : 2,
        )
        .toPromise();
    }
  }
}
