<div class="ui-g">
  <div class="ui-g-12" style="text-align: right">
    <p-button (click)="addUser()" icon="fas fa-plus-square" label="{{'ADD'|translate}}"></p-button>
  </div>
  <div class="ui-g-12">
    <p-table class="data-view-paginator" [rows]="10" [paginator]="true" #table [value]="users">
      <ng-template pTemplate="header">
        <tr>
          <th>
            <span class="ui-g-12">{{'LOGIN' | translate}}</span>
            <span class="ui-g-12">
              <input pInputText class="width100" type="text" (input)="filter($event.target.value, 'login','contains')">
            </span>
          </th>
          <th>
            <span class="ui-g-12">{{'NAME' | translate}}</span>
            <span>
              <input pInputText class="width100" type="text" (input)="filter($event.target.value, 'name','contains')">
            </span>
          </th>
          <th>
            <span class="ui-g-12">{{'SURNAME' | translate}}</span>
            <span>
              <input pInputText class="width100" type="text" (input)="filter($event.target.value, 'surname','contains')">
            </span>
          </th>
          <th>
            <span class="ui-g-12">{{'ROLE' |translate}}</span>
            <span class="ui-g-12">
              <p-multiSelect [style]="{'width':'100%'}" [defaultLabel]="'CHOOSE'| translate" [options]="rolesOptions" (onChange)="filter($event.value, 'roleId','in')"></p-multiSelect>
            </span>
          </th>
          <th>
            <span class="ui-g-12">{{'TOWN'|translate}}</span>
            <span class="ui-g-12">
              <p-multiSelect [style]="{'width':'100%'}" [defaultLabel]="'CHOOSE'| translate" [options]="municipalityOptions" (onChange)="filter($event.value, 'municipalityId','in')"></p-multiSelect>
            </span>
          </th>
          <th>
            <div>{{'SHOW_USER:LABEL:DESKTOP'|translate}}</div>
            <p-triStateCheckbox (onChange)="table.filter($event.value, 'desktop', 'equals')"></p-triStateCheckbox>
          </th>
          <th>
            <div>{{'SHOW_USER:LABEL:MOBILE'|translate}}</div>
            <p-triStateCheckbox (onChange)="table.filter($event.value, 'mobile', 'equals')"></p-triStateCheckbox>
          </th>
          <th>
            <div>{{'ACTIVE'|translate}}</div>
            <p-triStateCheckbox (onChange)="table.filter($event.value, 'isActive', 'equals')"></p-triStateCheckbox>
          </th>
          <th>{{'ACTIONS'|translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{user.login}}</td>
          <td>{{user.name}}</td>
          <td>{{user.surname}}</td>
          <td>
            <span *ngFor="let role of user.roles">
              {{role.code | translate}}
            </span>
          </td>
          <td>
            <span *ngFor="let municipality of user.municipalities">
              {{municipality.name}}
            </span>
          </td>
          <td>
            <p-checkbox binary="true" disabled="{{true}}" [ngModel]="user.desktop"></p-checkbox>
          </td>
          <td>
            <p-checkbox binary="true" disabled="{{true}}" [ngModel]="user.mobile"></p-checkbox>
          </td>
          <td>
            <p-checkbox binary="true" disabled="{{true}}" [ngModel]="user.isActive"></p-checkbox>
          </td>
          <td style="text-align: center">
            <p-button (click)="editUser(user)" icon="fas fa-eye" label="{{'DETAILS'|translate}}"></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
