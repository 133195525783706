// Angular Modules
import { NgModule } from '@angular/core';

// Components
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserService } from './core/services/user.service';
import { LoaderService } from './core/services/loader.service';

const MODULES: any[] = [CoreModule, FeaturesModule, AppRoutingModule];

const DECLARATIONS: any[] = [AppComponent];

@NgModule({
  imports: MODULES,
  exports: MODULES,
  declarations: DECLARATIONS,
  bootstrap: [AppComponent],
  providers: [UserService, AppRoutingModule, LoaderService]
})
export class AppModule {}
