<p-progressSpinner *ngIf="loading"></p-progressSpinner>

<ng-container *ngIf="measureNodes$ | async as measureNode">
    <p-treeTable #tt [value]="measureNode" selectionMode="single" [(selection)]="selectedNode" scrollHeight="60vh" scrollable="true">
        <ng-template pTemplate="header">
            <tr>
                <th>
                    {{ 'ARTICLE_HEADER_MEASURES' | translate }}
                </th>
                <th>
                    {{ 'TEXTFR_HEADER_MEASURES' | translate }}
                </th>
                <th>
                    {{ 'TEXTNL_HEADER_MEASURES' | translate }}
                </th>
                <th></th>
            </tr>
            <tr>
              <th *ngFor="let col of cols">
                <input pInputText type="text" (input)="filter($event.target.value, col)">
              </th>
              <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-editing="editing">
            <tr
                [ttSelectableRow]="rowNode"
                [ttSelectableRowDisabled]="rowData.disabled"
                [class.isRowDisabled]="rowData.disabled"
                [pEditableRow]="rowData"
            >
                <td>
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{ rowData.articleId }}
                </td>
                <td ttEditableColumn>
                    <ng-container *ngIf="!editing">
                        {{ rowData.textFr }}
                    </ng-container>
                    <ng-container *ngIf="editing">
                        <textarea
                            (click)="$event.stopPropagation()"
                            [rows]="5"
                            [cols]="30"
                            pInputTextarea
                            [(ngModel)]="tmpClone.textFr"
                        ></textarea>
                    </ng-container>
                </td>
                <td ttEditableColumn>
                    <ng-container *ngIf="!editing">
                        {{ rowData.textNl }}
                    </ng-container>
                    <ng-container *ngIf="editing">
                        <textarea
                            (click)="$event.stopPropagation()"
                            [rows]="5"
                            [cols]="30"
                            pInputTextarea
                            [(ngModel)]="tmpClone.textNl"
                        ></textarea>
                    </ng-container>
                </td>
                <td class="action-line">
                    <button
                      *ngIf="!editing"
                      pButton
                      type="button"
                      class="ui-button"
                        label="{{'EDIT' | translate}}"
                        icon="fa fa-pencil"
                      (click)="onRowEditInit(measureNode, rowNode, $event)"
                    >
                    </button>
                    <button
                        *ngIf="!editing && rowData.disabled"
                        pButton
                        type="button"
                        class="ui-button-success"
                        icon="fa fa-check"
                        label="{{'ACTIVATE' | translate}}"
                        (click)="onRowToggle(rowData.id, measureNode, $event, rowData.disabled)"
                    >
                    </button>
                    <button
                        *ngIf="!editing && !rowData.disabled"
                        pButton
                        type="button"
                        class="ui-button-danger"
                        label="{{'DISABLE' | translate}}"
                        icon="fa fa-ban"
                        (click)="onRowToggle(rowData.id, measureNode, $event, rowData.disabled)"
                    >
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-treeTable>

    <ng-container>
        <div class="row action-line-bottom action-container">
            <div class="col-md-2 custom-offset">
                <button
                    pButton
                    type="button"
                    (click)="reorderMeasure(measureUp, measureNode)"
                    class="ui-button"
                    [disabled]="!hasSelectedNodes || isFirstCurrentNode(measureNode)"
                    icon="fa fa-arrow-up"
                    [label]="'ACTIONS_MEASURE_UP' | translate"
                ></button>
            </div>
            <div class="col-md-2 custom-offset">
                <button
                    pButton
                    type="button"
                    (click)="reorderMeasure(measureDown, measureNode)"
                    class="ui-button"
                    [disabled]="!hasSelectedNodes || isLastCurrentNode(measureNode)"
                    icon="fa fa-arrow-down"
                    [label]="'ACTIONS_MEASURE_DOWN' | translate"
                ></button>
            </div>
            <div class="col-md-3 custom-offset">
                <button
                  pButton
                  type="button"
                  (click)="insertMeasure(measureNode, insertMode)"
                  class="ui-button"
                  [disabled]="!hasSelectedNodes || selectedNode.data.level > 3"
                  icon="fa fa-plus"
                  [label]="'ACTIONS_MEASURE_INSERT_INSIDE' | translate"
                ></button>
            </div>
            <div class="col-md-3 custom-offset">
                <button
                    pButton
                    type="button"
                    (click)="insertMeasure(measureNode, addMode)"
                    class="ui-button"
                    icon="fa fa-plus"
                    [label]="'ACTIONS_MEASURE_ADD_MEASURE' | translate"
                ></button>
            </div>
        </div>
    </ng-container>
</ng-container>
