import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { SupportsRoutingModule } from './supports-routing.module';
import { SupportsComponent } from './supports.component';
import { SupportComponent } from './support/support.component';
import { DragulaModule } from 'ng2-dragula';
import { EditSignOrderComponent } from '../signs/edit-sign-order/edit-sign-order.component';
import { MapModule } from '../map/map.module';

const MODULES = [SharedModule, SupportsRoutingModule, MapModule, DragulaModule.forRoot()];

const DECLARATIONS = [
  SupportsComponent,
  SupportComponent,
  EditSignOrderComponent
];

@NgModule({
  imports: [MODULES],
  declarations: [...DECLARATIONS],
  entryComponents: [...DECLARATIONS]
})
export class SupportsModule {}
