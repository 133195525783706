import {Injectable} from '@angular/core';
import {BaseAbstractService} from '../base-abstract.service';
import {environment} from '../../../../environments/environment';
import {Measure, RcMeasure} from '../../../models/measure.model';
import {from, Observable, Subject} from 'rxjs';
import {Sign} from '../../../models/sign.model';

@Injectable()
export class MeasureService extends BaseAbstractService {
  get url(): string {
    return environment.backendURL + environment.rcPath + this.configService.appConfig.apiUrl + 'measure';
  }

  list(): Observable<Measure[]> {
    return from(this.get<Measure[]>(this.url));
  }

  save(measures: Measure[]): Observable<any> {
    return from(this.post<Measure[]>(this.url, measures));
  }

  toggleDisable(idMeasure: number): Observable<any> {
    return from(this.post<Measure[]>(`${this.url}/toggleDisable/${idMeasure}`, null));
  }

  private _selected: RcMeasure;

  get selected() {
    return this._selected;
  }

  set selected(selected: RcMeasure) {
    this._selected = selected;
  }

  private _editMode: boolean;

  get editMode() {
    return this._editMode;
  }

  set editMode(editMode: boolean) {
    this._editMode = editMode;
  }

  private _existingSubMeasureArticleIds: string[];

  get existingSubMeasureArticleIds() {
    return this._existingSubMeasureArticleIds;
  }

  set existingSubMeasureArticleIds(ids: string[]) {
    this._existingSubMeasureArticleIds = [...ids]
  }

  private _existingSubMeasureArticleIdsInMunicipality: string[];

  get existingSubMeasureArticleIdsInMunicipality() {
    return this._existingSubMeasureArticleIdsInMunicipality;
  }

  set existingSubMeasureArticleIdsInMunicipality(ids: string[]) {
    this._existingSubMeasureArticleIdsInMunicipality = [...ids]
  }

  public saved$: Subject<RcMeasure> = new Subject<RcMeasure>();
  public cancelled$: Subject<boolean> = new Subject<boolean>();

  getSigns(measureId: number): Observable<Sign[]> {
    return from(this.get(`${this.url}/${measureId}/signtype/code`));
  }
}
