import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import { TaskService } from '../../../core/services/task.service';
import { Rc } from '../../../models/rc.model';
import { RcService } from '../../../core/services/rc.service';
import { PanelShowMultiRcComponent } from '../../rc/panel-show-multi-rc/panel-show-multi-rc.component';
import { MunicipalityService } from '../../../core/services/municipality.service';
import { NotificationService } from '../../../core/services/notification.service';
import { LoaderService } from 'app/core/services/loader.service';


@Component({
   selector: 'day-order-rc-button',
   templateUrl: './layout.day-order-rc-button.component.html',
   styleUrls: ['./layout.day-order-rc-button.component.scss']
})
export class DayOrderRCButtonComponent implements OnInit {
  displayDialogDayOrder = false;

  get dayOrders() {
    return this.rcService.rcs;
  }
  set dayOrders(dayOrders: Rc[]) {
    this.rcService.rcs = dayOrders;
  }

  constructor(
      private taskService: TaskService,
      private layoutService: LayoutService,
      private rcService: RcService,
      private errorService: NotificationService,
      private userService: UserService,
      public municipalityService: MunicipalityService,
      public cdr: ChangeDetectorRef,
      private loaderService: LoaderService) {
  }

  ngOnInit() {
  }

  async downloadDayOrder() {
    this.loaderService.showLoader();

    try {
      await this.rcService.downloadDayOrder().toPromise();
    } catch (error) {
      this.errorService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async showDayOrder() {
    this.loaderService.showLoader();

    try {
      this.dayOrders = await this.rcService.getDayOrder().toPromise();
      this.setAllMunicipalities(this.dayOrders);
      this.displayDialogDayOrder = true;
      this.cdr.markForCheck();
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async setAllMunicipalities(rcs: Rc[]) {
    for (const r of rcs) {
      const id = r.municipalityId;
      const municipality = await this.municipalityService.getMunicipalityForId(id);
      r.municipalityName = municipality.name;
    }
  }

  close() {
    this.displayDialogDayOrder = false;
  }
}
