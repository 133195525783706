<div class="panel-search-sign">
  <p-panel>
    <p-header id="panel-search-rc-region-header">
      <title-header [title]="'PANEL_SEARCH_SIGN:TITLE' | translate" [icon]="true" [iconClass]="'fa fa-fw fa-search'" [direction]="'RIGHT'"></title-header>
    </p-header>
    <div style="margin-left: 10px; margin-right: 10px;">
      <form [formGroup]="searchSignForm">

        <div class="ui-g">
          <h3 style="margin: 25px 0 0 0;">{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_LOCALISATION' | translate }}</h3>
          <div class="ui-g-12 searchItem">
            <div class="ui-g">

              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_ADMINISTRATIF:MUNICIPALITY' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <p-multiSelect class="width100"
                               [options]="signMuncipalityOptions"
                               [(ngModel)]="signSearch.supportMunicipality"
                               defaultLabel="&nbsp;"
                               formControlName="supportMunicipality"></p-multiSelect>
              </div>
              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_SUPPORT:ADDRESS' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <p-autoComplete [(ngModel)]="signSearch.supportAddress" #autoComplete
                                formControlName="supportAddress"
                                [suggestions]="results"
                                (completeMethod)="search($event)"
                                [forceSelection]="true"
                                field="label"
                                [multiple]="true">
                </p-autoComplete>
              </div>

              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_SUPPORT:NUMBER' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <input class="width100"
                       formControlName="supportNumber"
                       pInputText
                       [(ngModel)]="signSearch.supportNumber">
              </div>
            </div>
          </div>
        </div>


        <div class="ui-g">
          <h3 style="margin: 25px 0 0 0;">{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_SIGNALISATION' | translate }}</h3>
          <div class="ui-g-12 searchItem">
            <div class="ui-g">

              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_ADMINISTRATIF:MANAGER' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <p-multiSelect class="width100"
                               [options]="signGestionOptions"
                               [(ngModel)]="signSearch.signGestion"
                               defaultLabel="&nbsp;"
                               formControlName="signGestion"></p-multiSelect>
              </div>



              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_ADMINISTRATIF:PLACEMENT' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <p-multiSelect class="width100"
                               [options]="signPlacementOptions"
                               [(ngModel)]="signSearch.signPlacement"
                               defaultLabel="&nbsp;"
                               formControlName="signPlacement"></p-multiSelect>
              </div>

              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_ADMINISTRATIF:ASKING_TYPE' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <input class="width100"
                       formControlName="signAskingType"
                       pInputText
                       [(ngModel)]="signSearch.signRequestType">
              </div>


              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_TECHNIC:TYPE_CATEGORY' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <p-multiSelect class="width100"
                               [options]="signTypeCategoryOptions"
                               [(ngModel)]="signSearch.signTypeCategory"
                               defaultLabel="&nbsp;"
                               (onChange)="updateType()"
                               formControlName="signTypeCategory"></p-multiSelect>
              </div>

              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_TECHNIC:SPECIFICITY' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <p-multiSelect class="width100"
                               [options]="signSpecificityOptions"
                               [(ngModel)]="signSearch.signSpecificity"
                               defaultLabel="&nbsp;"
                               formControlName="signSpecificity"></p-multiSelect>
              </div>

              <div class="ui-g-12">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_TECHNIC:TYPE' | translate }}</label>
              </div>
              <div class="ui-g-12">
                <p-multiSelect class="width100 toggleHide"
                               [options]="signTypeOptions"
                               [(ngModel)]="signSearch.signType"
                               filter="true"
                               filterBy="value.code,value.labelFr,value.labelNl"
                               defaultLabel="&nbsp;"
                               formControlName="signType">
                  <ng-template let-type let-i="index" pTemplate="item">
                    <img *ngIf="type.value.code!=('ALL'|translate)" src="{{type.value| signTypeImage}}" style="width:24px;"/>
                    <span style="font-size:14px; margin-top:4px; margin-left: 35px;">{{type.label + ' - ' + (type.value | signTypeLabel) | ellipsis:25}}</span>
                  </ng-template>
                </p-multiSelect>
              </div>

              <div class="ui-g-1">
                <p-checkbox name="life" binary="true" [(ngModel)]="signSearch.signNotPlacementBool" formControlName="signNotPlacementBool"></p-checkbox>
              </div>
              <div class="ui-g-11">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:LIFE:NOT_ON_FIELD' | translate }}</label>
              </div>

              <div class="ui-g-12 no-padding" *ngIf="!signSearch.signNotPlacementBool">

                <div class="ui-g">

                  <div class="ui-g-12">
                    {{'PANEL_SEARCH_SIGN:SEARCH:LIFE:DATE_PLACEMENT' | translate}}
                  </div>
                  <div class="ui-g-1">
                    {{'PANEL_SEARCH_SIGN:SEARCH:LIFE:DATE_PLACEMENT:START' | translate}}
                  </div>
                  <div class="ui-g-5">
                    <p-calendar class="width100 customCalendar"
                                [(ngModel)]="signSearch.signPlacementDateStart"
                                [showIcon]="true"
                                [utc]="true"
                                dateFormat="dd/mm/yy"
                                [style]="{'width':'100%'}"
                                [locale]="myCalendarLocal"
                                formControlName="signPlacementDateStart"></p-calendar>
                  </div>
                  <div class="ui-g-1">
                    {{'PANEL_SEARCH_SIGN:SEARCH:LIFE:DATE_PLACEMENT:END' | translate}}
                  </div>
                  <div class="ui-g-5">
                    <p-calendar class="width100 customCalendar"
                                [(ngModel)]="signSearch.signPlacementDateEnd"
                                [showIcon]="true"
                                [utc]="true"
                                dateFormat="dd/mm/yy"
                                [style]="{'width':'100%'}"
                                [locale]="myCalendarLocal"
                                formControlName="signPlacementDateEnd"></p-calendar>
                  </div>

                  <div class="ui-g-12">
                    {{'PANEL_SEARCH_SIGN:SEARCH:LIFE:DATE_REMOVAL' | translate}}
                  </div>
                  <div class="ui-g-1">
                    {{'PANEL_SEARCH_SIGN:SEARCH:LIFE:DATE_REMOVAL:START' | translate}}
                  </div>
                  <div class="ui-g-5">
                    <p-calendar class="width100 customCalendar"
                                [(ngModel)]="signSearch.signRemovalDateStart"
                                [showIcon]="true"
                                [utc]="true"
                                dateFormat="dd/mm/yy"
                                [style]="{'width':'100%'}"
                                [locale]="myCalendarLocal"
                                formControlName="signRemovalDateStart"></p-calendar>
                  </div>
                  <div class="ui-g-1">
                    {{'PANEL_SEARCH_SIGN:SEARCH:LIFE:DATE_REMOVAL:END' | translate}}
                  </div>
                  <div class="ui-g-5">
                    <p-calendar class="width100 customCalendar"
                                [(ngModel)]="signSearch.signRemovalDateEnd"
                                [showIcon]="true"
                                [utc]="true"
                                dateFormat="dd/mm/yy"
                                [style]="{'width':'100%'}"
                                [locale]="myCalendarLocal"
                                formControlName="signRemovalDateEnd"></p-calendar>
                  </div>


                </div>


              </div>

              <div class="ui-g-1">
                <p-checkbox name="life" binary="true" [(ngModel)]="signSearch.signOldBool" formControlName="signOldBool"></p-checkbox>
              </div>
              <div class="ui-g-11">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:LIFE:INCLUDE_REMOVED_SIGN' | translate }}</label>
              </div>
            </div>
          </div>
        </div>


        <div class="ui-g">
          <h3 style="margin: 25px 0 0 0;">{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_SUPPORT' | translate }}</h3>
          <div class="ui-g-12 searchItem">
            <div class="ui-g">
              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_SUPPORT:GESTIONNAIRE' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <p-multiSelect class="width100"
                               [options]="supportGestionOptions"
                               [(ngModel)]="signSearch.supportGestion"
                               defaultLabel="&nbsp;"
                               formControlName="supportGestion"></p-multiSelect>
              </div>

              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_SUPPORT:TYPE' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <p-multiSelect class="width100"
                               [options]="supportTypeOptions"
                               [(ngModel)]="signSearch.supportType"
                               defaultLabel="&nbsp;"
                               formControlName="supportType"></p-multiSelect>
              </div>

              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_SIGN:SEARCH:CRITERIA_SUPPORT:SPECIFICITY' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <p-multiSelect class="width100"
                               [options]="supportSpecificityOptions"
                               [(ngModel)]="signSearch.supportSpecificity"
                               defaultLabel="&nbsp;"
                               formControlName="supportSpecificity"></p-multiSelect>
              </div>
            </div>
          </div>
        </div>


        <div class="ui-g sticky-bottom-buttons">
          <div class="ui-g-12">
            <p-button icon="fa fa-fw fa-search" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'PANEL_SEARCH_SIGN:SEARCH:BUTTON:SEARCH' | translate}}" (click)="submit()" [disabled]="!searchSignForm || searchSignForm.invalid"></p-button>
            <p-button icon="fa fa-fw fa-times" [ngStyle]="{'margin-left':'5px'}" class="secondary right" label="{{'PANEL_SEARCH_SIGN:SEARCH:BUTTON:RESET' | translate}}" (click)="reset()"></p-button>
          </div>
        </div>

      </form>
    </div>
  </p-panel>

  <p-dialog header="{{'PANEL_SEARCH_SIGN:SEARCH:NO_RESULT' | translate}}" [(visible)]="displayNoResult" appendTo="body" [width]="350">
    {{'PANEL_SEARCH_SIGN:SEARCH:NO_RESULT:TEXT' | translate}}
  </p-dialog>
</div>
