export enum ROLES {
  MUNICIPALITY_AGENT = 'MUNICIPALITY_AGENT',
  SPRB_AGENT = 'SPRB_AGENT',
  SECRETARY_CC = 'SECRETARY_CC',
  PARTNER = 'PARTNER'
}

export interface Role {
  id?: number;
  code?: string;
}
