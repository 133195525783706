<div class="picture-view">
  <div class="ui-g">
    <div class="ui-g-12 edit" *ngIf="canEdit">

      <p-fileUpload #fileUpload
                    mode="advanced"
                    customUpload="true"
                    name="demo[]"
                    accept="image/*"
                    maxFileSize="1000000"
                    auto="true"
                    multiple="multiple"
                    (uploadHandler)="uploadhandler($event)"
                    (onUpload)="uploadhandler($event)"
                    chooseLabel="{{'ADD' | translate}}"></p-fileUpload>
    </div>
    <div class="ui-g-12" *ngIf="imgs">
      <p-carousel class="carrouselPicture" [value]="imgs">
        <ng-template let-img pTemplate="item">
          <div class="ui-g">
            <div class="ui-g-12 fill" style="position: relative;">
              <img [src]="img.file" width="100%" (click)="showPicture(img)" style="cursor: pointer">
              <img class="edit" src="assets/images/signEx/delete.png"
                   alt="delete" style="width: 20px;
                   position: absolute; top: 0px;
                   right: 0px; cursor: pointer;
                   border-radius: 50px;" (click)="deletePicture(img)"
                   *ngIf="canEdit"/>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>

  </div>
</div>
<p-dialog header="" [(visible)]="displayPicture" modal="modal" appendTo="body" [baseZIndex]="100000">
  <img *ngIf="img.file" [src]="img.file" style="max-width: 90%">
</p-dialog>
