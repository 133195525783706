<div class="title-header ui-g">
  <div [class]="uiG()">
    <i [class]="iconClass" style="margin: 0 5px 0 0;" *ngIf="icon"></i><span style="vertical-align: top;">{{title}}</span></div>
  <div class="ui-g-2" style="padding-top: 0; padding-bottom: 0" *ngIf="button">
    <button type="button" [class]="buttonClass" pButton [icon]="buttonIcon" (click)="buttonAction()" *ngIf="button && !buttonActionParameter"></button>
    <button type="button" [class]="buttonClass" pButton [icon]="buttonIcon" (click)="buttonAction(buttonActionParameter)" *ngIf="button && buttonActionParameter"></button>
  </div>
  <div class="ui-g-3">
    <i style="float: right; cursor:pointer; margin: 0 0 0 5px;" class="fa fa-times iconHover" (click)="close()"></i>
    <i style="float: right; cursor:pointer; margin: 0 0 0 10px;" class=" iconHover fa fa-expand-arrows-alt" (click)="expand()" *ngIf="left || right"></i>
    <i [class]="iButtonIcon + ' iconHover'" style="margin: 0 0 0 5px; font-size: 17px; float: right; cursor: pointer;" (click)="iButtonAction()" *ngIf="iButton && !iButtonActionParameter"></i>
    <i [class]="iButtonIcon + ' iconHover'" style="margin: 0 0 0 5px; font-size: 17px; float: right; cursor: pointer;" (click)="iButtonAction(iBbuttonActionParameter)" *ngIf="iButton && iButtonActionParameter"></i>
  </div>
</div>
