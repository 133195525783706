import { Pipe, PipeTransform } from '@angular/core';
import { Sign } from '../../models/sign.model';
import { Rc } from '../../models/rc.model';

@Pipe({ name: 'hasNotMultipleOptions', pure: true })
export class HasNotMultipleOptionsPipe implements PipeTransform {
  transform(sign: any, task: Rc): boolean {
    return !this.hasMultipleOptions(sign, task);
  }

  hasMultipleOptions(sign: Sign, task: Rc): boolean {
    return sign.rcs.length > 1 && task.signs.filter(s => s.id === sign.id).length === 0;
  }
}
