import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { version } from '../../../../package.json';
import { NotificationService } from '../../core/services/notification.service';
import { HelpdeskComponent } from '../../shared/components/helpdesk/helpdesk.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'action-icons',
  templateUrl: './layout.action-icons.component.html',
  styleUrls: ['./layout.action-icons.component.scss']
})
export class LayoutActionIconsComponent implements OnInit {
  activeActionItem: any;

  get version() {
   return version;
  }

  constructor(public userService: UserService, public notificationService: NotificationService ) {}

  ngOnInit() {}
}
