<div class="ui-g">
  <div class="ui-g-12">
    <div class="ui-g-4">

    </div>
    <div class="ui-g-4">
      <p-panel>
        <p-header>
          <span>{{'PASSWORD_MODIFICATION' | translate}}</span>
        </p-header>

          <form [formGroup]="passwordForm" (ngSubmit)="save()">
            <div class="ui-g">
              <!--OLD PASSWORD-->
              <div class="ui-g-6">
                <label class="custom-label">{{'ACTUAL_PASSWORD'|translate}}</label>
              </div>
              <div class="ui-g-6">
                <input type="password" class="editSupportInput" pInputText [(ngModel)]="old" formControlName="oldPass"
                  required>
              </div>
            </div>
            <div class="ui-g">
              <!--NEW PASSWORD 1-->
              <div class="ui-g-6">
                <label class="custom-label">{{'NEW_PASSWORD'|translate}}</label>
              </div>
              <div class="ui-g-6">
                <input type="password" class="editSupportInput" [(ngModel)]="pass1" formControlName="newPass1"
                  pInputText required>
              </div>
            </div>

            <!--NEW PASSWORD 2-->
            <div class="ui-g">
              <div class="ui-g-6">
                <label class="custom-label">{{'CONFIRM_NEW_PASSWORD'|translate}}</label>
              </div>
              <div class="ui-g-6">
                <input type="password" class="editSupportInput" [(ngModel)]="pass2" pInputText
                  formControlName="newPass2" required>
              </div>
              <div>
                <span class="error-message" *ngIf="newPass2Ctrl.dirty && !passwordsMatch()">
                  {{'PASSWORDS_MUST_MATCH'|translate}}
                </span>
              </div>
            </div>
          </form>
          <!--BUTTON-->
          <div class="ui-g-12" style="text-align: right; margin-top: 10px;">
            <p-button (click)="cancel()" icon="fas fa-times" [ngStyle]="{'margin-left':'5px', 'margin-right': '10px'}"
              class="secondary" label="{{'CANCEL'|translate}}"></p-button>
            <p-button (click)="save()" [disabled]="passwordForm.invalid || (newPass2Ctrl.dirty && !passwordsMatch())"
              icon="fas fa-edit" [ngStyle]="{'margin-left':'5px'}" label="{{'SAVE'|translate}}"></p-button>
          </div>
      </p-panel>
    </div>
    <div class="ui-g-4">

    </div>
  </div>
</div>
