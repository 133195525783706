import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedComponent} from '../../../shared/components/logged/logged.component';
import {UserService} from '../../../core/services/user.service';
import {LayoutService} from '../../../core/services/layout.service';
import {SignTypeService} from '../../../core/services/sign-type.service';
import {AdministrationService} from '../../../core/services/administration.service';
import {Code, SelectCode, SignTypeUpdate} from '../../../models/code.model';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../core/services/notification.service';
import {SignCategoryService} from '../../../core/services/sign-category.service';
import {Picture} from '../../../models/picture.model';
import {LoaderService} from 'app/core/services/loader.service';
import {MeasureListComponent} from '../measure-management/measure-list/measure-list.component';
import {map, switchMap} from 'rxjs/operators';
import {TreeNode} from 'primeng/api';
import {MeasureBl} from '../../../core/services/business/measure.business';
import {Observable} from 'rxjs';
import {TreeNodeSelectable} from '../../../models/tree-node-selectable';
import { filter as _filter } from 'lodash';

@Component({
  selector: 'sign-type-update',
  templateUrl: './sign-type-update.component.html',
  styleUrls: ['./sign-type-update.component.scss']
})
export class SignTypeUpdateComponent extends LoggedComponent
  implements OnDestroy, OnInit {
  @ViewChild(MeasureListComponent, {static: false}) measures: MeasureListComponent;

  measures$: Observable<TreeNode[]>;

  loaded = false;

  signUpdate: SignTypeUpdate;

  translate: TranslateService = this.injector.get(TranslateService);
  signTypeCategoryOptions: SelectCode[] = [];

  imageUploaded = false;
  newPicture: Picture;

  constructor(
    userService: UserService,
    private injector: Injector,
    private errorService: NotificationService,
    private administrationService: AdministrationService,
    public signTypeService: SignTypeService,
    private signCategoryService: SignCategoryService,
    private layoutService: LayoutService,
    private loaderService: LoaderService,
    private measureBl: MeasureBl,
    private notificationService: NotificationService) {
    super(userService);
  }

  async ngOnInit() {
    this.loaderService.showLoader();

    try {
      this.administrationService.editMode = true;
      this.signUpdate = this.signTypeService.signType;
      await this.signTypeService.getArticlesForSignType(
        this.signTypeService.signType
      );
      await this.loadCode(
        this.signCategoryService,
        this.signTypeCategoryOptions,
        null
      );
      if (this.signUpdate.typeCategorie == null) {
        this.signUpdate.typeCategorie = this.signTypeCategoryOptions[0].value;
      }
      this.loaded = true;

      this.measures$ = this.measuresAndSelection;
    } catch (error) {

    } finally {
      this.loaderService.hideLoader();
    }
  }

  ngOnDestroy() {
    this.administrationService.editMode = false;
  }

  goBack() {
    this.signTypeService.showList = true;
  }

  async loadCode(
    service,
    options: SelectCode[],
    other: string
  ): Promise<undefined> {
    try {
      options.splice(0, options.length);
      const values: Code[] = await service.findAll();

      const translatedValues = await Promise.all(
        values.map(async val => ({
          label: await this.translate.get(val.code).toPromise(),
          value: val
        }))
      );
      translatedValues.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        } else if (a.label < b.label) {
          return -1;
        } else {
          return 0;
        }
      });
      translatedValues.forEach(val => options.push(val));
      if (other) {
        const label = await this.translate.get(other).toPromise();
        const otherSelectCode: SelectCode = {
          label: label,
          value: {id: 0, code: label}
        };
        options.unshift(otherSelectCode);
      }
    } catch (error) {
      this.errorService.addSingleError(error);
    }
    return;
  }

  async onFileSelected(event) {
    this.loaderService.showLoader();

    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        const base64data = reader.result;
        this.newPicture = {
          file: base64data,
          name: event.target.files[0].name
        };
        this.signUpdate.picture = this.newPicture;
        this.imageUploaded = true;
        this.loaderService.hideLoader();
      };
    } else {
      this.loaderService.hideLoader();
    }
  }

  cancelUploadPicture() {
    this.imageUploaded = false;
  }

  async save(disabled) {
    if (!disabled) {
      this.loaderService.showLoader();

      try {
        const exists = (await this.signTypeService.findAll()).find(
          s => s.code === this.signUpdate.code
        );
        if (exists && exists !== this.signUpdate) {
          this.errorService.addSingleError('SIGNS_MANAGEMENT:CODE_EXISTS');
          return;
        }
        this.signUpdate.measures = this.measures.signTypeMeasure;
        this.signUpdate.fileName = `${this.signUpdate.code}.png`;
        try {
          await this.signTypeService.save(this.signUpdate);
          await this.notificationService.addSingleSuccess(
            this.translate.instant('COMMON_SUCESS'),
            this.translate.instant('SIGN_TYPE_SAVE_SUCESS')
          );
        } catch (e) {
          this.errorService.addSingleError(e);
        }
        this.signTypeService.showList = true;
      } catch (error) {

      } finally {
        this.loaderService.hideLoader();
      }
    }
  }

  get measuresAndSelection() {
    return this.signTypeService.signTypeMeasure$.pipe(
      map(measures => this.measureBl.getSignTypeMeasureTree(measures))
    );
  }

  private setPartialSelected(nodes: TreeNodeSelectable[], partialMeasure: TreeNodeSelectable): void {
    const measure = nodes.find(measure => measure.data.id === partialMeasure.data.id);
    if (measure) {
      measure.partialSelected = true;
      if (partialMeasure.selected) {
        measure.selected = true;
      }
    } else {
      nodes.forEach(node => this.setPartialSelected(node.children, partialMeasure));
    }
  }
}
