import { Component, OnInit } from '@angular/core';
import { LoggedComponent } from '../../../shared/components/logged/logged.component';
import { AdministrationService } from '../../../core/services/administration.service';
import { UserService } from '../../../core/services/user.service';
import { ParameterService } from '../../../core/services/parameter.service';
import { LayoutService } from '../../../core/services/layout.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ParameterModel } from '../../../models/parameter.model';
import { LoaderService } from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'manage-parameters',
  templateUrl: './manage-parameters.component.html',
  styleUrls: ['./manage-parameters.component.scss']
})
export class ManageParametersComponent extends LoggedComponent
  implements OnInit {
  editParameters: ParameterModel[] = [];

  constructor(
    userService: UserService,
    private layoutService: LayoutService,
    private notificationService: NotificationService,
    public administrationService: AdministrationService,
    public parameterService: ParameterService,
    private loaderService: LoaderService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.loadParams();
  }

  async loadParams() {
    this.loaderService.showLoader();

    try {
      this.parameterService.allParameters = null;
      await this.parameterService.findAll();
    }
    catch (error) {

    } finally {
      this.loaderService.hideLoader();
    }
  }

  editParam() {
    this.administrationService.editMode = true;
    this.editParameters = this.parameterService.parameters.map(p => {
      return new ParameterModel(p);
    });
  }

  async saveParam() {
    this.loaderService.showLoader();

    try {
      await this.parameterService.save(this.editParameters);
    } catch (e) {
      this.notificationService.addSingleError(e);
    } finally {
      this.loaderService.hideLoader();
    }

    this.administrationService.editMode = false;
  }

  cancelEdit() {
    this.administrationService.editMode = false;
  }
}
