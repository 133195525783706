import { Injectable } from '@angular/core';
import { NgViewerComponent } from '@nsi/gis-viewer-ui';
import { environment } from '../../../environments/environment';
import { BaseAbstractService } from './base-abstract.service';
import * as olGeom from 'ol/geom';
import olIntersects from 'ol/format/filter/Intersects';
import olWFS from 'ol/format/WFS';
import { WMS } from '@nsi/gis-core/dist/layer';
import { VMap, View, Viewer, ModulesMgr } from '@nsi/gis-core';
import { BasemapComponent } from 'app/shared/viewer/modules/basemap/basemap.component';

ModulesMgr.register(BasemapComponent);

@Injectable()
export class GeoserverService extends BaseAbstractService {
  _ngViewer: NgViewerComponent;

  set ngViewer(val: NgViewerComponent) {
    this._ngViewer = val;
  }
  get ngViewer(): NgViewerComponent {
    return this._ngViewer;
  }
  get viewer(): Viewer {
    return this.ngViewer.viewer;
  }
  get map(): VMap {
    return this.viewer.mapMgr.map;
  }
  get view(): View {
    return this.viewer.mapMgr.view;
  }
  refresh(id) {
    const layer: WMS = this.getLayer(id);
    layer.refresh();
  }
  getLayer(id): WMS {
    const layer: WMS = <WMS>this.map.mapMgr.layers.filter(_layer => {
      return _layer.id === id;
    })[0];
    return layer;
  }
  async identifyItems(coords: [number, number], layerId: String, projection, options): Promise<number[]> {
    const layer: WMS = this.getLayer(layerId);
    const source = layer.olLayer.getSource();
    const viewResolution = /** @type {number} */ this.view.olView.getResolution();
    const url = source.getGetFeatureInfoUrl(coords, viewResolution, projection, options);

    const result: any = await this.getAny(url);
    const features = result.features;
    const ids: number[] = (<any[]>features).map((feature: any) => {
      return feature.properties.id;
    });
    return ids;
  }

  async identifyPolygon(geom: olGeom.Polygon, layerId: String, projection): Promise<number[]> {
    const layer: WMS = this.getLayer(layerId);
    const source = layer.olLayer.getSource();
    // layer.identify()

    const featureRequest = new olWFS().writeGetFeature({
      srsName: projection,
      featureNS: source.getUrls()[0],
      featurePrefix: (<string>source.getParams()['LAYERS']).split(':')[0],
      featureTypes: [source.getParams()['LAYERS']],
      outputFormat: 'application/json',
      filter: new olIntersects('geom', geom),
    });
    const result: any = await fetch(source.getUrls()[0] + '?SERVICE=WFS', {
      method: 'POST',
      body: new XMLSerializer().serializeToString(featureRequest),
    });
    console.info('wfsResult', result);

    const ids: number[] = (<any[]>(await result.json()).features).map((feature: any) => {
      return feature.properties.id;
    });
    return ids;
  }

  get url(): string {
    return environment.backendURL + environment.resourcePath + 'geoserver/esign/';
  }

  getAny(url: String): Promise<any> {
    return this.get<any>(`${url}`);
  }
}
