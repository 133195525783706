import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Sign} from '../../models/sign.model';
import {environment} from '../../../environments/environment';
import {BaseAbstractService} from './base-abstract.service';
import {Support} from '../../models/support.model';
import {Subject} from 'rxjs';
import Utils from '../../shared/services/utils';
import {SignHistory} from '../../models/sign-history.model';
import {Picture} from '../../models/picture.model';
import * as _ from 'lodash';

@Injectable()
export class SignService extends BaseAbstractService {
  MOBILE_STATUS_ADD = 'ADD';
  MOBILE_STATUS_EDIT = 'EDIT';
  MOBILE_STATUS_DELETE = 'DELETE';

  _sign: Sign;
  private signSource = new Subject<Sign>();
  signHandler: Observable<Sign> = <Observable<Sign>>(
    this.signSource.asObservable()
  );

  set sign(sign: Sign) {
    this._sign = sign;
    this.signSource.next(sign);
  }

  get sign(): Sign {
    return Object.assign({}, this._sign);
  }

  _memorizedSign: Sign;
  set memorizedSign(sign: Sign) {
    this._memorizedSign = sign;
    this.memorizedSignSource.next(sign);
  }
  get memorizedSign(): Sign {
    return this._memorizedSign;
  }
  private memorizedSignSource = new Subject<Sign>();
  memorizedSignHandler: Observable<Sign> = <Observable<Sign>>(
    this.memorizedSignSource.asObservable()
  );

  _newSupport: Support;
  get newSupport(): Support {
    return this._newSupport;
  }

  set newSupport(support: Support) {
    this._newSupport = support;
  }

  _editMode: boolean;
  set editMode(val: boolean) {
    this._editMode = val;
  }

  get editMode(): boolean {
    return this._editMode;
  }

  _readMode: boolean;
  set readMode(val: boolean) {
    this._readMode = val;
  }

  get readMode(): boolean {
    return this._readMode;
  }

  _signs: Sign[];
  private signsSource = new Subject<Sign[]>();
  signsHandler: Observable<Sign[]> = <Observable<Sign[]>>(
    this.signsSource.asObservable()
  );

  get signs(): Sign[] {
    return this._signs;
  }

  set signs(signs: Sign[]) {
    // let tmpSigns = Array.from(new Set(signs.map(sign => new Sign(sign))));
    const tmpSigns = Utils.makeUniqueArray(signs);
    this._signs = tmpSigns;
    this.signsSelectedSource.next(tmpSigns);
  }

  _signsSelected: Sign[] = [];
  private signsSelectedSource = new Subject<Sign[]>();
  signsSelectedHandler: Observable<Sign[]> = <Observable<Sign[]>>(
    this.signsSelectedSource.asObservable()
  );

  get signsSelected(): Sign[] {
    return this._signsSelected;
  }

  set signsSelected(signs: Sign[]) {
    // let tmpSigns = Array.from(new Set(signs.map(sign => new Sign(sign))));
    const mapSigns = new Map<number, Sign>();
    signs.forEach(s => {
      mapSigns.set(s.id, s);
    });
    const tmpSigns = Array.from(mapSigns.values());
    this._signsSelected = tmpSigns;

    this.signsSelectedSource.next(tmpSigns);
  }

  _signOrder = new Array();

  set signOrder(signs) {
    this.signOrder
      .filter(so => !signs.find(s => `${s.id}` === `${so.id}`))
      .forEach(s => this.deleteSign(s.id));
    this._signOrder = signs.map(s => s);
  }

  get signOrder() {
    return this._signOrder;
  }

  deletedImgs: Picture[] = [];

  _signsRecense: Sign[] = [];
  private signsRecenseSource = new Subject<Sign[]>();
  signsRecenseHandler: Observable<Sign[]> = <Observable<Sign[]>>(
    this.signsRecenseSource.asObservable()
  );

  cleanPicture(sign: Sign) {
    if (sign.imgs) {
      sign.imgs.forEach(pic => {
        if (pic.gedId) {
          pic.file = null;
        }
      });
    }

    if (sign.placementImgs) {
      sign.placementImgs.forEach(pic => {
        if (pic.gedId) {
          pic.file = null;
        }
      });
    }
    if (sign.removalImgs) {
      sign.removalImgs.forEach(pic => {
        if (pic.gedId) {
          pic.file = null;
        }
      });
    }
  }

  get signsRecense(): Sign[] {
    return this._signsRecense;
  }

  set signsRecense(signs: Sign[]) {
    this._signsRecense = _.orderBy(signs, 'mobileStatusDate', 'desc');
    // let tmpSigns = Array.from(new Set(signs.map(sign => new Sign(sign))));
    let tmpSigns = Utils.makeUniqueArray(signs);

    tmpSigns = _.orderBy(tmpSigns, 'mobileStatusDate', 'desc');
    this.signsRecenseSource.next(tmpSigns);
  }

  get url(): string {
    return (
      environment.backendURL +
      environment.signPath +
      this.configService.appConfig.apiUrl +
      'Sign/'
    );
  }

  get urlHistory(): string {
    return (
      environment.backendURL +
      environment.signPath +
      this.configService.appConfig.apiUrl +
      'Sign/history/'
    );
  }

  async getSign(id: number): Promise<Sign> {
    return this.get<any>(`${this.url}${id}`);
  }

  async getSignFull(id: number): Promise<Sign> {
    return this.get<any>(`${this.url}full/${id}`);
  }

  async getSigns(ids: number[]): Promise<Sign[]> {
    return this.get<any[]>(`${this.url}/ids`, ids);
  }

  async getSignsFull(ids: number[]): Promise<Sign[]> {
    return this.post<any[]>(`${this.url}full/ids`, ids);
  }

  async getSignsForSupportId(supportId: number): Promise<Sign[]> {
    return this.get<any[]>(`${this.url}supportId/${supportId}`);
  }

  async getSignsFullForSupportId(supportId: number): Promise<Sign[]> {
    return this.get<any[]>(`${this.url}full/supportId/${supportId}`);
  }

  async getSignsForSupportsIds(supportIds: number[]): Promise<Sign[]> {
    return this.post<any[]>(`${this.url}supportIds`, supportIds);
  }

  async getSignsFullForSupportsIds(supportIds: number[]): Promise<Sign[]> {
    return this.post<any[]>(`${this.url}full/supportIds`, supportIds);
  }

  createOrUpdateSign(sign: Sign): Promise<Sign> {
    const tmpSign = Object.assign({}, sign);
    this.cleanPicture(tmpSign);

    if (!tmpSign.id) {
      return this.createSign(tmpSign);
    } else {
      return this.updateSign(tmpSign);
    }
  }

  async createSign(sign: Sign): Promise<Sign> {
    return this.post<any>(this.url, sign);
  }

  async updateSign(sign: Sign): Promise<Sign> {
    return this.put<Sign>(this.url, sign);
  }

  async findAllSign(): Promise<Sign[]> {
    return this.get<Sign[]>(`${this.url}all/`);
  }

  deleteSign(id: number) {
    this.signsRecense = this.signsRecense.filter(s => `${s.id}` !== `${id}`);
  }

  async findOldSignsForSupport(supportId: number): Promise<Sign[]> {
    return this.get<any[]>(`${this.url}old/supportId/${supportId}`);
  }

  async search(data): Promise<Sign[]> {
    return this.post<any[]>(`${this.url}search`, data);
  }

  async searchFull(data): Promise<Sign[]> {
    return this.post<any[]>(`${this.url}search/full`, data);
  }

  // async export(data:number[]): Promise<any>{
  //   return this.post<any[]>(`${this.url}export`, data);
  // }

  async export(data: number[]) {
    const response: Response = await this.postBlob(`${this.url}export`, data);
    const downloadUrl = URL.createObjectURL(response.body);
    const blob = await response.body;
    Utils.downloadBlob(blob, response.headers.get('filename'));
  }

  async getSignsForRcIds(rcIds: number[]): Promise<Sign[]> {
    return this.post<any[]>(`${this.url}rcIds`, rcIds);
  }

  async getSignsFullForRcIds(rcIds: number[]): Promise<Sign[]> {
    return this.post<any[]>(`${this.url}full/rcIds`, rcIds);
  }

  // HISTORY
  async historyGetSign(id: number): Promise<SignHistory> {
    return this.get<any>(`${this.urlHistory}${id}`);
  }

  async historyGetSignFull(id: number): Promise<SignHistory> {
    return this.get<any>(`${this.urlHistory}full/${id}`);
  }

  async historyGetSigns(ids: number[]): Promise<SignHistory[]> {
    return this.get<any[]>(`${this.urlHistory}/ids`, ids);
  }

  async historyGetSignsFull(ids: number[]): Promise<SignHistory[]> {
    return this.post<any[]>(`${this.urlHistory}full/ids`, ids);
  }

  async historyGetSignsByRcIds(rcIds: number[]): Promise<SignHistory[]> {
    return this.post<any[]>(`${this.urlHistory}rcIds`, rcIds);
  }

  async historyGetSignsFullByRcIds(rcIds: number[]): Promise<SignHistory[]> {
    return this.post<any[]>(`${this.urlHistory}full/rcIds`, rcIds);
  }

  // RECENSEMENT
  async getRecense(): Promise<Sign[]> {
    return this.get<any[]>(`${this.url}mobile`);
  }

  async updateSignRecense(id: number): Promise<Sign> {
    return this.post<Sign>(`${this.url}validateMobile/${id}`, {});
  }

  private _negativeIndex = -1;

  get negativeIndex() {
    return this._negativeIndex--;
  }
}
