import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoggedComponent } from '../../../shared/components/logged/logged.component';
import { AdministrationService } from '../../../core/services/administration.service';
import { UserService } from '../../../core/services/user.service';
import { SignTypeService } from '../../../core/services/sign-type.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../core/services/layout.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent extends LoggedComponent
  implements OnDestroy, OnInit {
  constructor(
    userService: UserService,
    private signTypeService: SignTypeService,
    public layoutService: LayoutService,
    public i18n: TranslateService,
    public administrationService: AdministrationService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.administrationService.editMode = false;
  }

  ngOnDestroy() {
    this.signTypeService.showList = true;
    this.userService.showList = true;
  }

  tabViewChanged(event) {
    this.signTypeService.showList = true;
    this.userService.showList = true;
  }
}
