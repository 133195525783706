import { Injectable } from '@angular/core';
import { BaseAbstractService } from './base-abstract.service';
import { environment } from '../../../environments/environment';
import { Rc } from '../../models/rc.model';
import { Sign } from '../../models/sign.model';
import Utils from '../../shared/services/utils';
import {BehaviorSubject, from, Observable} from 'rxjs';
import { catchError, flatMap, map, tap } from 'rxjs/operators';
import { RcMeasure } from '../../models/measure.model';
import { GetAbrogationsRequest } from '../../models/get-abrogations-request.model';

@Injectable()
export class RcService extends BaseAbstractService {
  _dayOrderMode = false;
  _ruMode = false;
  _lastSearchData = null;

  set ruMode(bol: boolean) {
    this._dayOrderMode = false;
    this._ruMode = bol;
  }
  set dayOrderMode(bol: boolean) {
    this._ruMode = false;
    this._dayOrderMode = bol;
  }
  get ruMode() {
    return this._ruMode;
  }
  get dayOrderMode() {
    return this._dayOrderMode;
  }

  _rc: Rc = null;
  get rc(): Rc {
    return this._rc;
  }
  set rc(rc: Rc) {
    this._rc = rc;
  }

  _rcs: Rc[] = null;
  get rcs(): Rc[] {
    return this._rcs;
  }
  set rcs(rcs: Rc[]) {
    this._rcs = rcs;
  }

  _signs: Sign[];
  get signs(): Sign[] {
    return this._signs;
  }
  set signs(signs: Sign[]) {
    this._signs = signs;
  }

  _editMode: boolean;
  set editMode(val: boolean) {
    this._editMode = val;
  }
  get editMode(): boolean {
    return this._editMode;
  }

  _rcSearchResult = new BehaviorSubject<Rc[]>([]);
  _rcSearchResult$ = this._rcSearchResult.asObservable();
  get rcSearchResult$(): Observable<Rc[]> {
    return this._rcSearchResult$;
  }
  set rcSearchResult(rcSearchResult: Rc[]) {
    this._rcSearchResult.next(rcSearchResult);
  }

  _dayOrders: Rc[] = new Array();
  get dayOrders(): Rc[] {
    return this._dayOrders;
  }

  set dayOrders(dayOrders: Rc[]) {
    this._dayOrders = dayOrders;
  }

  _municipalityId: number;
  get municipalityId(): number {
    return this._municipalityId;
  }

  set municipalityId(municipalityId: number) {
    this._municipalityId = municipalityId;
  }

  fromTaskView: Rc = null;

  get url(): string {
    return (
      environment.backendURL +
      environment.rcPath +
      this.configService.appConfig.apiUrl +
      'RC/'
    );
  }

  getRc(id: number): Observable<Rc> {
    return from(this.get<any>(`${this.url}${id}`));
  }

  getRCForSignId(signId: number): Observable<Rc[]> {
    return from(this.get<any[]>(`${this.url}signId/${signId}`));
  }

  getRCForSupportId(supportId: number): Observable<Rc[]> {
    return from(this.get<Rc[]>(`${this.url}supportId/${supportId}`));
  }

  getSingleGedDocument(gedId: string): Observable<any> {
    return from(this.getBlob(`${this.url}gedId/${gedId}`)).pipe(tap(response => {
      const blob = response.body;
      Utils.downloadBlob(blob, response.headers.get('filename'));
    }), catchError(e => this.errorService.addSingleError(e)));
  }

  getGedDocument(rc: Rc): Observable<any> {
    const hasMultipleDocuments: boolean = !rc.communalPvGedId;

    if (hasMultipleDocuments) {
      return this.getMultipleDocumentsInZip(rc.id);
    } else {
      return this.getSingleGedDocument(rc.communalPvGedId);
    }
  }

  private getMultipleDocumentsInZip(rcId: number) {
    return from(this.getBlob(`${this.url}${rcId}/files`)).pipe(tap(response => {
      const blob = response.body;
      Utils.downloadBlob(blob, response.headers.get('filename'));
    }), catchError(e => this.errorService.addSingleError(e)));
  }

  generateRcDocumentFromTotem(rcId: number, title: string, type: number, lang = 'FR'): Observable<any> {
    return from(this.handleOAuthHeader({})).pipe(
      map(options => {
        options.responseType = 'blob';
        options.observe = 'response';
        return options;
      }),
      flatMap(options => this._http.post(`${this.url}generate`, {rcId, title, type, lang}, options)),
      tap((response: any) => {
        const blob = response.body;
        Utils.downloadBlob(blob, response.headers.get('filename'));
      }), catchError(e => this.errorService.addSingleError(e)));
  }

  createOrUpdate(rc: Rc): Observable<Rc> {
    rc.body = rc.body
      ? rc.body.replace(new RegExp('class="ql-align-center"', 'g'), 'style="text-align:center;"')
      : rc.body;
    rc.header = rc.header
      ? rc.header.replace(new RegExp('class="ql-align-center"', 'g'), 'style="text-align:center;"')
      : rc.header;
    rc.footer = rc.footer
      ? rc.footer.replace(new RegExp('class="ql-align-center"', 'g'), 'style="text-align:center;"')
      : rc.footer;

    if (rc.id) {
      return this.updateRc(rc);
    } else {
      return this.createRCDraft(rc);
    }
  }

  deleteRCDraft(rc: Rc): Observable<Rc> {
    return from(this.delete<void>(`${this.url}draft/${rc.id}`));
  }

  createRCDraft(rc: Rc): Observable<Rc> {
    return from(this.post<any>(`${this.url}draft`, rc));
  }

  createWF(rc: Rc): Observable<Rc> {
    return from(this.post<Rc>(this.url, rc));
  }

  updateRc(rc: Rc): Observable<Rc> {
    return from(this.put<Rc>(this.url, rc));
  }

  getDayOrder(): Observable<Rc[]> {
    return from(this.get<any[]>(`${this.url}dayOrder/rc`));
  }

  generateDayOrderPDF(rcs: Rc[]): Observable<any> {
    return from(this.post<any>(`${this.url}generateDayOrderPDF`, rcs));
  }

  lastSearch(): Observable<Rc[]> {
    return this.search(this._lastSearchData);
  }

  search(data: any): Observable<Rc[]> {
    this._lastSearchData = data;
    return from(this.post<any[]>(`${this.url}search`, data));
  }

  getUniqueRegulation(municipalityId: number): Observable<Rc[]> {
    return this.download(`${this.url}uniqueRegulation/${municipalityId}`);
  }

  downloadDayOrder(): Observable<Rc[]> {
    return this.download(`${this.url}dayOrder`);
  }

  generateWord(text: string): Observable<any> {
    const data = {
      text: text.replace(new RegExp('class="ql-align-center"', 'g'), 'style="text-align:center;"')
    };

    return from(this.postBlob(`${this.url}generateWord`, data)).pipe(
      tap(response => {
        const blob = response.body;
        Utils.downloadBlob(blob, response.headers.get('filename'));
      }), catchError(e => this.errorService.addSingleError(e))
    );
  }

  getTasks(): Observable<Rc[]> {
    return from(this.get<any[]>(`${this.url}tasks`)).pipe(map((rcs: any[]) => {
      for (const rc of rcs) {
        rc.tasks = rc.task ? rc.task : rc.tasks;
      }
      return rcs;
    }));
  }

  getMeasures(signTypes: string[]): Observable<RcMeasure[]> {
    return from(this.post(`${this.url}measures`, signTypes || []));
  }

  getNewClauses(signTypes: string[]): Observable<RcMeasure[]> {
    return from(this.post(`${this.url}new-clauses`, signTypes || []));
  }

  getAbrogations(signTypes: string[], signIds: number[]): Observable<RcMeasure[]> {
    return from(this.post(`${this.url}abrogations`, {codes: signTypes, signIds: signIds} as GetAbrogationsRequest));
  }

  getExistingArticleIds() {
    return from(this.post(`${this.url}articleId`, this.rc));
  }

  getNextArticleId(parentRcMeasureId: string, existingArticleIds: string[]) {
    return from(this.post(`${this.url}articleId/next`, {
      rc: this.rc,
      articleId: parentRcMeasureId,
      childrenArticleIds: existingArticleIds
    }));
  }
}
