import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BaseAbstractService } from './base-abstract.service';
import { Sign } from '../../models/sign.model';
import { ImportProcessLogModel } from '../../models/import-process-log.model';

@Injectable()
export class AdministrationService extends BaseAbstractService {
  private _editMode = false;

  get editMode(): boolean {
    return this._editMode;
  }

  set editMode(value: boolean) {
    this._editMode = value;
  }

  private _selectedTabIndex: number;

  get selectedTabIndex(): number {
    return this._selectedTabIndex;
  }

  set selectedTabIndex(value: number) {
    this._selectedTabIndex = value;
  }

  get url(): string {
    return (
      environment.backendURL +
      environment.signPath +
      this.configService.appConfig.apiUrl +
      'Administration/'
    );
  }

  async uploadCommunalRef(fileBase64: any): Promise<any> {
    return await this.post<any[]>(`${this.url}uploadCommunalRef`, fileBase64);
  }

  async getImportProcessLog(): Promise<ImportProcessLogModel[]> {
    return await this.get<ImportProcessLogModel[]>(`${this.url}process`);
  }
}
