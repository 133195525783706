export class Layout {
  layoutCompact = false;
  layoutMode = 'STATIC'; // STATIC, OVERLAY, HORIZONTAL, SLIM
  darkMenu = false;
  profileMode = 'inline'; // inline, top
  profileView = true;
  demoMenu = false;
  layoutMenu = false;
  globalSearch = false;
  footerView = false;
  settingsView = true;
  msgView = false;
  notiView = false;
  rightPanel = true;
  breadcrumb = false;
  avatar = 'avatar.png';
}
