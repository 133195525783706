import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SignService } from '../../../core/services/sign.service';
import { Sign } from '../../../models/sign.model';

@Directive({
  selector: '[appSignOpacity]'
})
export class SignOpacityDirective implements OnChanges {

  @HostBinding('style.opacity')
  opacity;

  @Input()
  sign: Sign;

  constructor(
    private readonly signService: SignService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.opacity = this.sign.mobileStatus === this.signService.MOBILE_STATUS_DELETE ? 0.3 : 1;
  }
}
