import {NgModule} from '@angular/core';
import {Routes} from '@angular/router';

export const SupportsRoutes: Routes = [
];


@NgModule({
    providers: [],
    imports: []
})
export class SupportsRoutingModule {}
