import {Component, OnInit} from '@angular/core';
import {MeasureBl} from '../../../core/services/business/measure.business';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/api';
import {Measure} from 'app/models/measure.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter} from 'rxjs/operators';
import {TreeNodeUtils} from '../../../core/utils/treenode-utils';

/**
 * Measure dialog available modes
 */
export class MEASURE_DIALOG_MODES {
  static INSERT = 'INSERT';
  static ADD = 'ADD';
  static EDIT = 'EDIT';
}

@Component({
  selector: 'app-measure-management-dialog',
  templateUrl: `./measure-management-dialog.component.html`,
  styleUrls: ['./measure-management-dialog.component.scss'],
})
export class MeasureManagementDialogComponent implements OnInit {
  /**
   * Measure form group (https://angular.io/api/forms/FormGroup)
   */
  public measureForm: FormGroup;

  /**
   * constructor
   * @param measureBl the measure business logic
   * @param fb the angular form builder (https://angular.io/api/forms/FormBuilder)
   * @param ref the dialog reference (https://primefaces.org/primeng/#/dynamicdialog)
   * @param config the dialog config with params (data) (https://primefaces.org/primeng/#/dynamicdialog)
   */
  constructor(
    private measureBl: MeasureBl,
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
  }

  /**
   * Initialize the form group
   */
  public ngOnInit() {
    this.measureForm = this.fb.group({
      id: [{value: null, disabled: true}],
      articleId: this.fb.control('', Validators.required),
      textFr: this.fb.control('', Validators.required),
      textNl: this.fb.control('', Validators.required),
    });

    this.measureForm.patchValue(this.config.data.measure);
  }

  /**
   * Call bl for save
   */
  public save(measure: Measure) {
    let save$;
    if (this.config.data.mode === MEASURE_DIALOG_MODES.EDIT) {
      const flatMeasures = TreeNodeUtils.flattenTreeNode(this.config.data.allNodes).map(n => n.data);
      Object.assign(flatMeasures.find(m => m.id === measure.id), measure);

      save$ = this.measureBl.saveMeasures(flatMeasures);
    } else {
      measure = Object.assign({}, this.config.data.measure, measure);
      save$ = this.measureBl.addMeasure(this.config.data.allNodes, measure);
    }
    save$.pipe(filter((sucess: boolean) => sucess))
      .subscribe(() => this.ref.close(true));
  }

  /**
   * Close the dialog
   */
  public cancel() {
    this.ref.close(false);
  }
}
