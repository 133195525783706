import {Injectable} from '@angular/core';
import {BaseAbstractService} from './base-abstract.service';
import {environment} from '../../../environments/environment';
import {Picture} from '../../models/picture.model';

@Injectable()
export class GedService extends BaseAbstractService
{
  get url():string {
    return environment.backendURL + environment.gedPath+ this.configService.appConfig.apiUrl + 'Ged/';
  }

  getFile(gedId: number): Promise<any> {
    return this.get<any>(`${this.url}${gedId}`);
  }

}
