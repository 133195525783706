import { environment } from '../../environments/environment';
import { Picture } from './picture.model';
import {SignTypeMeasure} from './measure.model';

export interface Code {
  id?: number;
  code?: string;
}
export class SelectCodeHelper {
  static fromCode(code: Code): SelectCode {
    return {
      label: code.code,
      value: code
    };
  }
}
export interface SelectCode {
  label?: string;
  value?: Code;
}

export interface SignType extends Code {
  typeCategorie?: Code;
  generic: boolean;
  labelFr?: string;
  labelNl?: string;
}

export class SignTypeHelper {
  static getImg(signType): string {
    return `${environment.backendURL}${environment.imageTypePath}${
      encodeURIComponent(signType.code)
    }.png`;
  }
}

export interface Traduction {
  code: string;
  fr?: string;
  nl?: string;
}

export interface Article {
  fr?: string;
  nl?: string;
  id?: number;
}
export interface SignTypeUpdate extends SignType {
  measures?: SignTypeMeasure[];
  picture?: Picture;
  fileName?: string;
}
