<div class="panel-support-list" style="position: relative; height: 100%;">
  <p-panel class="customPanel">
    <p-header id="panel-list-support-view-header">
      <title-header [icon]="true" [iconClass]="'fas fa-list-ul'" [title]="'PANEL_SUPPORT_LIST:TITLE' | translate" [direction]="'LEFT'"></title-header>
    </p-header>
    <div class="ui-g">
      <div class="ui-g-12">
        <p-dataList [value]="supports"
                    [emptyMessage]="'NO_DATA_FOUND' | translate">
          <ng-template let-support let-last="index" pTemplate="item">
            <div (click)="onclick(support)" (mouseenter)="mouseEnter(support)" (mouseleave)="mouseLeave(support)" [ngStyle]="{'font-weight':(isSelected(support))?'bold':'normal','border-bottom': ((last < (supports.length-1)) ? '1px solid grey' : 'none')}" style="cursor: pointer; margin: 5px 15px; padding-top: 10px; padding-bottom: 5px;">
              <span>{{getAddressLabel(support.address)}}</span> <span>({{support.type.code | translate}} - {{support.specificity.code | translate}})</span>
            </div>
          </ng-template>
        </p-dataList>
      </div>
    </div>
    <div class="ui-g sticky-bottom-buttons">
      <div class="ui-g-12">
        <p-button [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'ADD_TO_THIS_SUPPORT' | translate}}" *ngIf="!multiSelect" [disabled]="!supportSelectedBool" (onClick)="addSignToSupport()"></p-button>
      </div>
    </div>
  </p-panel>
  <div style="margin-bottom: 140px"></div>
</div>
