import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../../core/services/layout.service';
import { SignService } from '../../../core/services/sign.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'title-header',
  templateUrl: './title-header.component.html',
  styleUrls: ['./title-header.component.scss']
})
export class TitleHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() icon = false;
  @Input() iconClass: string;
  @Input() button = false;
  @Input() iButton = false;
  @Input() buttonClass: string;
  @Input() buttonIcon: string;
  @Input() iButtonIcon: string;
  @Input() buttonAction;
  @Input() iButtonAction;
  @Input() buttonActionParameter: any = null;
  @Input() iButtonActionParameter: any = null;
  @Input() direction: string;

  constructor(
    private layoutService: LayoutService,
    private signService: SignService
  ) {}
  ngOnInit() {}

  get right() {
    if (this.direction === 'RIGHT') {
      return true;
    }
    return false;
  }
  get left() {
    if (this.direction === 'LEFT') {
      return true;
    }
    return false;
  }

  uiG(): string {
    let max = 12;
    const close = 3;
    const expand = 0;
    const icon = 0;
    const button = 2;

    max -= close;

    if (this.right || this.left) {
      max -= expand;
    }
    if (this.icon) {
      max -= icon;
    }
    if (this.button) {
      max -= button;
    }

    return `ui-g-${max}`;
  }

  expand() {
    // alert("Not implemented : expand");
    if (this.right) {
      this.layoutService.leftPanelFull = false;
      this.layoutService.rightPanelFull = !this.layoutService.rightPanelFull;
    } else if (this.left) {
      this.layoutService.rightPanelFull = false;
      this.layoutService.leftPanelFull = !this.layoutService.leftPanelFull;
    }
  }

  close() {
    console.log(this.direction);
    switch (this.direction) {
      case 'LEFT':
        this.layoutService.closeLeftPanel();
        break;
      case 'RIGHT':
        this.layoutService.closeRightPanel();
        break;
      case 'CENTER':
        this.layoutService.closeCenterPanel();
        break;
    }
  }
}
