import { NgModule } from '@angular/core';

import { ConfigService } from './services/config.service';
import { BreadcrumbService } from './services/breadcrumb.service';
import { SupportService } from './services/support.service';
import { SignService } from './services/sign.service';
import { SupportGestionService } from './services/support-gestion.service';
import { SupportTypeService } from './services/support-type.service';
import { SupportSpecificityService } from './services/support-specificity.service';
import { SignCategoryService } from './services/sign-category.service';
import { SignGestionService } from './services/sign-gestion.service';
import { SignPlacementService } from './services/sign-placement.service';
import { SignSpecificityService } from './services/sign-specificity.service';
import { SignFormatService } from './services/sign-format.service';
import { SignTypeService } from './services/sign-type.service';
import {
  PictureService,
  PictureServiceSign,
  PictureServiceSignPlacement,
  PictureServiceSignRemoval,
} from './services/picture.service';
import { UserService } from './services/user.service';
import { BaseAbstractService } from './services/base-abstract.service';
import { TaskService } from './services/task.service';
import { LayoutService } from './services/layout.service';
import { MapService } from './services/map.service';
import { GeoserverService } from './services/geoserver.service';
import { SignOrderService } from './services/sign-order.service';
import { RcService } from './services/rc.service';
import { TypeCategorieService } from './services/type-categorie.service';
import { GedService } from './services/ged.service';
import { MunicipalityService } from './services/municipality.service';
import { SignInterventionService } from './services/sign-intervention.service';
import { NotificationService } from './services/notification.service';
import { ParameterService } from './services/parameter.service';
import { AdministrationService } from './services/administration.service';
import { TranslationService } from './services/translation.service';
import { CryptoService } from './services/crypto.service';
import { MessageService, ConfirmationService, DialogService } from 'primeng/api';
import { MeasureBl } from './services/business/measure.business';
import { MeasureService } from './services/api/measure.service';

const PROVIDERS: any[] = [
  ConfigService,
  BreadcrumbService,
  SupportService,
  SignService,
  SupportGestionService,
  SupportTypeService,
  SupportSpecificityService,
  SignCategoryService,
  SignGestionService,
  SignPlacementService,
  SignSpecificityService,
  SignFormatService,
  SignTypeService,
  PictureService,
  UserService,
  BaseAbstractService,
  TaskService,
  LayoutService,
  MapService,
  GeoserverService,
  SignOrderService,
  PictureServiceSignPlacement,
  PictureServiceSignRemoval,
  PictureServiceSign,
  RcService,
  TypeCategorieService,
  GedService,
  MunicipalityService,
  SignInterventionService,
  NotificationService,
  ConfirmationService,
  ParameterService,
  AdministrationService,
  TranslationService,
  CryptoService,
  MessageService,
  MeasureBl,
  MeasureService,
  DialogService,
];

@NgModule({
  providers: PROVIDERS,
})
export class ServicesModule {}
