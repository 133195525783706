import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, Directive } from '@angular/core';
import { NgViewerModuleComponent } from '@nsi/gis-viewer-ui';
import { MatMenu } from '@angular/material';
import { OLOverviewModule, ModulesMgr } from '@nsi/gis-core';
import { filter, takeWhile, tap, map, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * BasemapComponent allows the use to show a little square on the map (like Google Map) with the picture of the current base map inside. Clicking this square
 * will show all the available basemaps to the user, allowing him to change it.
 * It is possible to integrate an Overview of the map inside this square with the specific property set.
 */
@Component({
  selector: 'nsi-viewer-basemap',
  templateUrl: './basemap.component.html',
  styleUrls: ['./basemap.component.scss'],
})
export class BasemapComponent extends NgViewerModuleComponent implements OnInit {
  public static className = 'esign/modules/Basemap';
  /**
   * Toggle the visibility of the basemaps list
   */
  basemapListVisible = false;

  /**
   * Instance of MatMenu where the basemap list is displayed in
   */
  @ViewChild('appMenu', { static: true }) basemapsMenu: MatMenu;

  /**
   * Instance of OLOverviewModule (NSI API object for the OpenLayers Overview module)
   */
  private _overviewModule: OLOverviewModule;

  /**
   * Collapse or not the basemap component
   */
  collapsed = false;

  overviewHolder$ = new Subject<ElementRef>();
  _holderOverview: ElementRef;
  /**
   * HTML element used as target for the OpenLayer module.
   */
  @ViewChild('holderOverview', { static: false })
  set holderOverview(holder: ElementRef) {
    this._holderOverview = holder;
    this.overviewHolder$.next(holder);
  }

  get holderOverview(): ElementRef {
    return this._holderOverview;
  }

  /**
   * Listener on mouse click on the map to hide the basemap list
   */
  click$;

  /**
   * If true, this property allow to integrate an overview to the basemap chooser
   */
  overviewIntegrated = true;
  /**
   * Style to apply on the basemap selector
   */
  containerStyle = 'basemap-selected right bottom';

  ngOnInit(): void {
    this.initComponent();
  }

  /**
   * Initialization of the component
   */
  initComponent() {
    const initialized$ = this.viewer.initialized$.pipe(filter(i => i));
    this.overviewHolder$
      .pipe(
        filter(holder => holder && this.overviewIntegrated),
        takeWhile(holder => !this._overviewModule),
        map(holder => holder.nativeElement),
        switchMap(element => initialized$.pipe(tap(() => this.initOverview(element))))
      )
      .subscribe();
    this.click$ = this.mapMgr.map.getEventListener('click');
    this.click$
      .pipe(
        filter(e => this.basemapListVisible),
        filter(e => (e ? true : false)),
        tap(() => {
          this.basemapListVisible = false;
        })
      )
      .subscribe();
  }

  initOverview(element) {
    this._overviewModule = new OLOverviewModule(this.viewer, {
      target: element,
      collapsible: false,
      collapsed: this.collapsed,
      activated: true,
    });
    this._overviewModule.init();
  }

  /**
   * Toggle the basemap list visibility
   */
  expandBasemaps() {
    this.basemapListVisible = !this.basemapListVisible;
  }
}
