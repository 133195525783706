<div class="ui-g" *ngIf="user">
  <div class="ui-g-12" *ngIf="loaded">
    <div class="ui-g-4">

    </div>
    <div class="ui-g-4">
      <p-panel>
        <p-header>
          <span>{{(editMode?userCopy.surname:user.surname)}} {{editMode?userCopy.name:user.name}}</span>
        </p-header>
        <form [formGroup]="userForm">
          <div class="ui-g">

            <!--LOGIN-->
            <div class="ui-g-5">
              <label class="custom-label">{{'LOGIN'|translate}}</label>
            </div>
            <div class="ui-g-7" *ngIf="!editMode">
              <span>{{user.login}}</span>
            </div>
            <div class="ui-g-7" *ngIf="editMode">
              <input type="text" class="editSupportInput" pInputText formControlName="login" [(ngModel)]="user.login" required>
            </div>

            <!--FIRSTNAME-->
            <div class="ui-g-5">
              <label class="custom-label">{{'SURNAME'|translate}}</label>
            </div>
            <div class="ui-g-7" *ngIf="!editMode">
              <span>{{user.surname}}</span>
            </div>
            <div class="ui-g-7" *ngIf="editMode">
              <input type="text" class="editSupportInput" pInputText formControlName="surname" [(ngModel)]="user.surname" required>
            </div>

            <!--NAME-->
            <div class="ui-g-5">
              <label class="custom-label">{{'NAME'|translate}}</label>
            </div>
            <div class="ui-g-7" *ngIf="!editMode">
              <span>{{user.name}}</span>
            </div>
            <div class="ui-g-7" *ngIf="editMode">
              <input type="text" class="editSupportInput" pInputText formControlName="name" [(ngModel)]="user.name" required>
            </div>

            <!--MAIL-->
            <div class="ui-g-5">
              <label class="custom-label">{{'MAIL_ADDRESS'|translate}}</label>
            </div>
            <div class="ui-g-7" *ngIf="!editMode">
              <span>{{user.email}}</span>
            </div>
            <div class="ui-g-7" *ngIf="editMode">
              <input type="email" class="editSupportInput" pInputText formControlName="email" [(ngModel)]="user.email" required>
            </div>

          </div>
          <div class="ui-g" *ngIf="userService.manageUserMode">
            <!--LANGUE-->
            <div class="ui-g-5">
              <label class="custom-label">{{'LANGUAGE'|translate}}</label>
            </div>
            <div class="ui-g-7" *ngIf="!editMode">
              <span>{{user.language | translate}}</span>
            </div>
            <div class="ui-g-7" *ngIf="editMode">
              <p-dropdown formControlName="language" [style]="{'width':'100%'}" [options]="languagesOptions" [(ngModel)]="user.language"></p-dropdown>
            </div>

            <!--ROLE-->
            <div class="ui-g-5">
              <label class="custom-label">{{'ROLE'|translate}}</label>
            </div>
            <div class="ui-g-7" *ngIf="!editMode">
              <span>{{user.roles[0].code | translate}}</span>
            </div>
            <div class="ui-g-7" *ngIf="editMode">
              <p-dropdown formControlName="role" [style]="{'width':'100%'}" [options]="rolesOptions" [(ngModel)]="user.roles[0]"></p-dropdown>
            </div>

            <!--MUNICIPALITY-->
            <div class="ui-g-12">
              <div class="ui-g-5" *ngIf="user.roles[0].code == MUNICIPALITY_AGENT_ROLE || !editMode">
                <label class="custom-label">{{'TOWN'|translate}}</label>
              </div>
              <div class="ui-g-7" *ngIf="!editMode">
                <span>{{user.municipality?user.municipality.name:('NO_MUNICIPALITY'|translate)}}</span>
              </div>
              <div class="ui-g-7" *ngIf="editMode && user.roles[0].code == MUNICIPALITY_AGENT_ROLE">
                <p-dropdown formControlName="municipality" dataKey="id" [style]="{'width':'100%'}" [options]="municipalityOptions" [(ngModel)]="user.municipality"></p-dropdown>
              </div>
            </div>


            <!--ADMIN-->
            <div class="ui-g-5">
              <label class="custom-label">{{"ADMINISTRATOR" | translate}}</label>
            </div>
            <div class="ui-g-7" *ngIf="!editMode">
              <span>{{(user.isAdmin ? 'YES': 'NO')| translate}}</span>
            </div>
            <div class="ui-g-7" *ngIf="editMode">
              <p-checkbox formControlName="admin" [(ngModel)]="user.isAdmin" binary="true"></p-checkbox>
            </div>

            <!--MOBILE-->
            <div class="ui-g-5">
              <label class="custom-label">{{"SHOW_USER:LABEL:MOBILE" | translate}}</label>
            </div>
            <div class="ui-g-7" *ngIf="!editMode">
              <span>{{(user.mobile ? 'YES': 'NO')| translate}}</span>
            </div>
            <div class="ui-g-7" *ngIf="editMode">
              <p-checkbox formControlName="mobile" [(ngModel)]="user.mobile" binary="true"></p-checkbox>
            </div>

            <!--DESKTOP-->
            <div class="ui-g-5">
              <label class="custom-label">{{"SHOW_USER:LABEL:DESKTOP" | translate}}</label>
            </div>
            <div class="ui-g-7" *ngIf="!editMode">
              <span>{{(user.desktop ? 'YES': 'NO')| translate}}</span>
            </div>
            <div class="ui-g-7" *ngIf="editMode">
              <p-checkbox formControlName="desktop" [(ngModel)]="user.desktop" binary="true"></p-checkbox>
            </div>

            <!--ACTIVITY-->
            <div class="ui-g-5">
              <label class="custom-label">{{'ACTIVE'|translate}}</label>
            </div>
            <div class="ui-g-7" *ngIf="!editMode">
              <span>{{(user.isActive ? 'YES': 'NO')| translate}}</span>
            </div>
            <div class="ui-g-7" *ngIf="editMode">
              <p-checkbox formControlName="isActive" [(ngModel)]="user.isActive" binary="true"></p-checkbox>
            </div>
          </div>

          <div class="ui-g-">

            <!--BUTTON-->
            <div *ngIf="!userService.editMode" class="ui-g-12" style="text-align: right; margin-top: 10px;">
              <p-button *ngIf="userService.manageUserMode" (click)="backToUserManagement()" icon="fas fa-long-arrow-alt-left" [ngStyle]="{'margin-left':'5px', 'margin-right': '10px'}"
                class="secondary" label="{{'BACK'|translate}}"></p-button>
              <p-button *ngIf="!userService.manageUserMode" (click)="changePassword()" icon="fas fa-key" [ngStyle]="{'margin-left':'5px', 'margin-right': '10px'}"
                class="secondary" label="{{'CHANGE_PASSWORD'|translate}}"></p-button>
              <p-button (click)="switchMode(true)" icon="fas fa-edit" [ngStyle]="{'margin-left':'5px'}" label="{{'EDIT'|translate}}"></p-button>
            </div>

            <div *ngIf="editMode" class="ui-g-12" style="text-align: right; margin-top: 10px;">
              <p-button (click)="cancel()" icon="fas fa-times" [ngStyle]="{'margin-left':'5px', 'margin-right': '10px'}" class="secondary" label="{{'CANCEL'|translate}}"></p-button>
              <p-button [disabled]="userForm.invalid" (click)="save()" icon="fas fa-save" [ngStyle]="{'margin-left':'5px'}" label="{{'SAVE'|translate}}"></p-button>
            </div>
          </div>
        </form>



      </p-panel>
    </div>
    <div class="ui-g-4">

    </div>
  </div>
</div>
