import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Measure, RcMeasure} from '../../../models/measure.model';
import {MeasureListComponent} from '../../administration/measure-management/measure-list/measure-list.component';
import {MeasureBl} from '../../../core/services/business/measure.business';
import { Observable, Subject } from 'rxjs';
import {TreeNodeSelectable} from '../../../models/tree-node-selectable';
import {MeasureService} from '../../../core/services/api/measure.service';
import { takeUntil, tap } from 'rxjs/operators';
import {SignService} from '../../../core/services/sign.service';
import {NotificationService} from '../../../core/services/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from '../../../core/services/loader.service';

@Component({
  selector: 'app-rc-measures',
  templateUrl: './rc-measures.component.html'
})
export class RcMeasuresComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selection: RcMeasure[];
  @Input() isAbrogation: boolean;
  @Input() disabled = false;
  @ViewChild(MeasureListComponent, {static: false}) measures: MeasureListComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();
  treeNode$: Observable<TreeNodeSelectable[]>;
  editMeasure: boolean;
  editSubMeasure: boolean;
  private indexUpdating;

  constructor(private measureBl: MeasureBl,
              public measureService: MeasureService,
              private signService: SignService,
              private notificationService: NotificationService,
              private i18n: TranslateService,
              private loaderService: LoaderService) {
  }

  ngOnInit(): void {
    this.measureService.editMode = false;

    this.measureService.cancelled$.pipe(tap(
      _ => {
        this.measureService.editMode = false;
        this.editMeasure = false;
        this.editSubMeasure = false;
      }),
      takeUntil(this.destroy$)
      ).subscribe();

    this.measureService.saved$.pipe(
      tap(async measure => {

        if (measure && this.isAbrogation === measure.isAbrogation) {
          const allExistingIds = this.measureService.existingSubMeasureArticleIdsInMunicipality;
          this.selection = this.rcMeasures;

          const initialState = this.measureService.selected;

          const isAdding = (measure.parentId !== initialState.parentId) && (measure.id !== initialState.id);
          const isEditing = (measure.parentId === initialState.parentId) && (measure.id === initialState.id);
          const existsInMunicipality = allExistingIds.findIndex((m) => m === measure.articleId) !== -1;

          const duplicateIndex = this.selection.findIndex(m => {
            if(isAdding) {
              return m.articleId === measure.articleId || existsInMunicipality;
            } else if(isEditing) {
              const existingArticleIdIndex = this.selection.findIndex(item => item.articleId === initialState.articleId);
              const newArticleIdIndex = this.selection.findIndex(item => item.articleId === measure.articleId);
              // Make sure the "duplicated item" index is not the index of the one article with the articleId we're currently editing
              return (newArticleIdIndex !== -1 || existsInMunicipality) && existingArticleIdIndex !== newArticleIdIndex;
            } else {
              return false;
            }
          });

          if (duplicateIndex !== -1) {
            await this.notificationService.addSingleInfo(
              this.i18n.instant('ERROR'),
              this.i18n.instant('MEASURE_DUPLICATE_ERROR')
            );
            return ;
          }

          let index = this.indexUpdating !== -1 ? this.indexUpdating
              : this.selection.findIndex(m => m.articleId === measure.articleId);

          if (index != null && index !== -1) {
            this.selection[index] = {...this.selection[index], ...measure};
          } else {
            this.selection.push({...measure});
          }

          this.treeNode$ = this.measureBl.getRcMeasureTreeNode(this.signService.signsSelected, this.isAbrogation, this.selection);

          await this.notificationService.addSingleSuccess(
            this.i18n.instant('COMMON_SUCESS'),
            this.i18n.instant(measure.signId ? 'SUB_MEASURE_SAVE_SUCESS' : 'MEASURE_SAVE_SUCESS')
          );

          if(isEditing) {
            const existingIndex = allExistingIds.indexOf(initialState.articleId);
            this.measureService.existingSubMeasureArticleIdsInMunicipality.splice(existingIndex, 1);
          }
          this.measureService.existingSubMeasureArticleIdsInMunicipality.push(measure.articleId);

          this.measureService.editMode = false;
          this.editMeasure = false;
          this.editSubMeasure = false;
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnChanges(): void {
    this.treeNode$ = this.measureBl.getRcMeasureTreeNode(this.signService.signsSelected, this.isAbrogation, this.selection);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  add(parent: Measure): void {
    var children = this.findSubMeasures(parent);

    this.indexUpdating = -1;
    this.measureService.editMode = true;
    this.measureService.selected = {...parent, isAbrogation: this.isAbrogation};
    this.measureService.existingSubMeasureArticleIds = children.map(c => c.articleId);
    this.editMeasure = false;
    this.editSubMeasure = true;
  }

  update(measure: RcMeasure): void {
    this.measureService.editMode = true;
    this.measureService.selected = {...measure, isAbrogation: this.isAbrogation};
    if (measure.signId) {
      this.indexUpdating = measure.index;
      this.editMeasure = false;
      this.editSubMeasure = true;
    } else {
      this.indexUpdating = -1;
      this.editSubMeasure = false;
      this.editMeasure = true;
    }
  }

  findSubMeasures(parent: Measure) {
    const parentArticleId = parent.articleId;
    const children = [];
    const measures = this.rcMeasures;

    const parentNode = measures.find(measure => measure.articleId === parentArticleId);
    measures.forEach(measure => {
      if (measure.parentId === parentNode.id) {
        children.push(measure);
      }
    });

    return children;
  }

  delete(measure: Measure): void {
    this.notificationService.confirm({
      message: this.i18n.instant('SUB_MEASURE:DELETE'),
      accept: async () => {
        let index = this.selection.findIndex(m => m.articleId === measure.articleId);
        if (index !== -1) {
          this.selection.splice(index, 1);

          // Remove Article ID from the used ids list
          const existingIndex = this.measureService.existingSubMeasureArticleIdsInMunicipality.indexOf(measure.articleId);
          this.measureService.existingSubMeasureArticleIdsInMunicipality.splice(existingIndex, 1);

          await this.notificationService.addSingleSuccess(
            this.i18n.instant('COMMON_SUCESS'),
            this.i18n.instant('SUB_MEASURE_DELETE_SUCESS')
          );
          this.ngOnChanges();
        }
      }
    });
  }

  get rcMeasures() {
    return this.measures.rcMeasures;
  }
}
