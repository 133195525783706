import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {AdministrationComponent} from './administration/administration.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {ShowUserComponent} from './show-user/show-user.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {ImportDataComponent} from './import-data/import-data.component';
import {ManageParametersComponent} from './manage-parameters/manage-parameters.component';
import {ManageRcTemplateComponent} from './manage-rc-template/manage-rc-template.component';
import {SignTypeManagementComponent} from './sign-type-management/sign-type-management.component';
import {SignTypeUpdateComponent} from './sign-type-update/sign-type-update.component';
import {SignTypeListComponent} from './sign-type-list/sign-type-list.component';
import {UserListComponent} from './user-list/user-list.component';
import {TranslationManagementComponent} from './translation-management/translation-management.component';
import {CreateUserComponent} from './create-user/create-user.component';
import {MeasureManagementComponent} from './measure-management/measure-management.component';
import {MeasureManagementDialogComponent} from './measure-management-dialog/measure-management-dialog.component';
import {MeasureListComponent} from './measure-management/measure-list/measure-list.component';
import {MeasureFormComponent} from './measure-management/measure-form/measure-form.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {SubMeasureFormComponent} from './sub-measure-form/sub-measure-form.component';

const MODULES = [
  SharedModule,
  InputTextareaModule,
];

const DECLARATIONS = [
  AdministrationComponent,
  ShowUserComponent,
  ChangePasswordComponent,
  UserManagementComponent,
  ManageParametersComponent,
  UserManagementComponent,
  ImportDataComponent,
  ManageRcTemplateComponent,
  SignTypeManagementComponent,
  MeasureManagementComponent,
  SignTypeUpdateComponent,
  SignTypeListComponent,
  UserListComponent,
  TranslationManagementComponent,
  CreateUserComponent,
  MeasureManagementDialogComponent,
  MeasureListComponent,
  MeasureFormComponent,
  SubMeasureFormComponent,
];

const EXPORTS = [
  MeasureListComponent,
  SubMeasureFormComponent,
  MeasureFormComponent,
];

@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  entryComponents: DECLARATIONS,
  exports: EXPORTS,
})
export class AdministrationModule {
}
