import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Sign } from '../../../models/sign.model';
import { SignService } from '../../../core/services/sign.service';

@Directive({
  selector: '[appSignCode]'
})
export class SignCodeDirective implements OnChanges {

  @HostBinding('style.color')
  color;

  @Input()
  sign: Sign;

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.sign.placement.code === 'FINAL') {
      this.color = '#119F19';
    } else if (this.sign.placement.code === 'TEMPORARY') {
      this.color = '#FE9900';
    } else {
      this.color = 'black';
    }
  }

}
