<div class="ui-g topbar clearfix" style="z-index: 10000;">
  <div class="topbarLeft">
    <img _ngcontent-c1="" class="bigScreen" src="assets/images/sprb_big_2.png">
  </div>
  <div class="ui-g-2 ui-g-offset-5 topbarCenter">
    {{appName}}
  </div>
  <div class="ui-g-5 topbarRight">
    <action-icons></action-icons>
  </div>
</div>

