<div class="panel-search-rc-result">
  <p-panel [style]="{'height':'100%'}">
    <p-header id="panel-search-rc-region-header">
      <title-header [icon]="true" [iconClass]="'fas fa-list-ul'" [title]="'PANEL_SEARCH_RC_RESULT:TITLE' | translate:{ X: (this.rcService.rcSearchResult$ | async).length }"
        [direction]="'LEFT'"></title-header>
    </p-header>
    <div class="ui-g">
      <div class="ui-g-12">
        <p-table id="rc-scroller"
                 [value]="rcService.rcSearchResult$ | async"
                 [scrollable]="true"
                 [virtualScroll]="true"
                 [virtualRowHeight]="20">
          <ng-template pTemplate="header">
            <tr>
              <th [pSortableColumn]="'municipalityName'" *ngIf="userService.role.code === ROLES.SECRETARY_CC || userService.role.code === ROLES.SPRB_AGENT">{{"PANEL_SEARCH_RC_RESULT:TABLE:MUNICIPALITY" | translate}}
                <p-sortIcon [field]="'municipalityName'"></p-sortIcon>
              </th>
              <th [pSortableColumn]="'title'">{{"PANEL_SEARCH_RC_RESULT:TABLE:TITLE" | translate}}
                <p-sortIcon [field]="'title'"></p-sortIcon>
              </th>
              <th [pSortableColumn]="'status'">{{"PANEL_SEARCH_RC_RESULT:TABLE:STATUS" | translate}}
                <p-sortIcon [field]="'status'"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rc>
            <tr class="display-rc" (click)="displayRC(rc)">
              <td *ngIf="userService.role.code === ROLES.SECRETARY_CC || userService.role.code === ROLES.SPRB_AGENT">{{rc.municipalityName | ellipsis:20}}</td>
              <td>{{rc.title | ellipsis:20}}</td>
              <td>{{rc.status | translate }}
                <b class="remaing-days" *ngIf=" (rc | rcLastTask) && (rc | rcLastTask).remainingDays && (rc.status != 'RC_REJECT' && rc.status != 'RC_OLD')">
                  ({{ "PANEL_SEARCH_RC_RESULT:TABLE:DAY_REST" | translate:{ X: (rc | rcLastTask).remainingDays } }})</b>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-panel>
</div>
