<div class="panel-search-rc">
  <p-panel>
    <p-header id="panel-search-rc-region-header">
      <title-header [title]="'PANEL_SEARCH_RC:TITLE' | translate" [icon]="true" [iconClass]="'fa fa-fw fa-search'" [direction]="'RIGHT'"></title-header>
    </p-header>
    <div style="margin-left: 10px; margin-right: 10px;">
      <form [formGroup]="searchRcForm">

        <div class="ui-g" *ngIf="showCommune">
          <h3 style="margin: 25px 0 0 0;">{{ 'PANEL_SEARCH_RC:SEARCH:CRITERIA_ADMINISTRATIF' | translate }}</h3>
          <div class="ui-g-12 searchItem">
            <div class="ui-g">
              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_RC:SEARCH:CRITERIA_ADMINISTRATIF:MUNICIPALITY' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <p-multiSelect class="width100"
                               [options]="municipalityOptions"
                               [(ngModel)]="rcSearch.municipality"
                               defaultLabel="&nbsp;"
                               formControlName="municipalities"></p-multiSelect>
              </div>
            </div>
          </div>
        </div>

        <div class="ui-g">
          <h3 style="margin: 25px 0 0 0;">{{ 'PANEL_SEARCH_RC:SEARCH:CRITERIA_IDENTIFICATION' | translate }}</h3>
          <div class="ui-g-12 searchItem">
            <div class="ui-g">
              <div class="ui-g-5">
                <label>{{ 'PANEL_SEARCH_RC:SEARCH:CRITERIA_IDENTIFICATION:ENTITLED' | translate }}</label>
              </div>
              <div class="ui-g-7">
                <input class="width100"
                       formControlName="title"
                       pInputText
                       [(ngModel)]="rcSearch.title">
              </div>
            </div>
          </div>
        </div>

        <div class="ui-g">
          <h3 style="margin: 25px 0 0 0;">{{ 'PANEL_SEARCH_RC:SEARCH:CRITERIA_STATUS' | translate }}</h3>
          <div class="ui-g-12 searchItem">
            <p-accordion>
              <p-accordionTab header="{{'PANEL_SEARCH_RC:SEARCH:CRITERIA_STATUS:STATUS' | translate}}"
                              [selected]="true">
                <div class="ui-g">
                  <div *ngFor="let status_code of status" class="ui-g-12">
                    <p-checkbox name="status" [value]="status_code"
                                label="{{'PANEL_SEARCH_RC:SEARCH:STATUS:'+status_code | translate}}"
                                [(ngModel)]="rcSearch.status" formControlName="status"></p-checkbox>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
            <br>
            <p-accordion>
              <p-accordionTab header="{{'PANEL_SEARCH_RC:SEARCH:CRITERIA_STATUS:TASK' | translate}}"
                              [selected]="true">
                <div class="ui-g">
                  <div *ngFor="let task of tasks" class="ui-g-12">
                    <p-checkbox name="task" [value]="task"
                                label="{{'PANEL_SEARCH_RC:SEARCH:TASKS:'+task | translate}}"
                                [(ngModel)]="rcSearch.tasks" formControlName="tasks"></p-checkbox>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
            <br>
            <p-accordion>
              <p-accordionTab header="{{'PANEL_SEARCH_RC:SEARCH:CRITERIA_STATUS:DATE_INTRO' | translate}}"
                              [selected]="true">
                <div class="ui-g">
                  <div class="ui-g-4">
                    {{'PANEL_SEARCH_RC:SEARCH:CRITERIA_STATUS:DATE_INTRO:START' | translate}}
                  </div>
                  <div class="ui-g-8">
                    <p-calendar class="width100 customCalendar"
                                [(ngModel)]="rcSearch.introductionDateStart"
                                [showIcon]="true"
                                [utc]="true"
                                dateFormat="dd/mm/yy"
                                [style]="{'width':'100%'}"
                                [locale]="myCalendarLocal"
                                formControlName="introductionDate"></p-calendar>
                  </div>
                  <div class="ui-g-4">
                    {{'PANEL_SEARCH_RC:SEARCH:CRITERIA_STATUS:DATE_INTRO:END' | translate}}
                  </div>
                  <div class="ui-g-8">
                    <p-calendar class="width100 customCalendar"
                                [(ngModel)]="rcSearch.introductionDateEnd"
                                [showIcon]="true"
                                [utc]="true"
                                dateFormat="dd/mm/yy"
                                [style]="{'width':'100%'}"
                                [locale]="myCalendarLocal"
                                formControlName="introductionDate"></p-calendar>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>

        <div class="ui-g sticky-bottom-buttons">
          <div class="ui-g-12">
            <p-button icon="fa fa-fw fa-search" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'PANEL_SEARCH_RC:SEARCH:BUTTON:SEARCH' | translate}}" (click)="submit()" [disabled]="!searchRcForm || searchRcForm.invalid"></p-button>
            <p-button icon="fa fa-fw fa-times" [ngStyle]="{'margin-left':'5px'}" class="secondary right" label="{{'PANEL_SEARCH_RC:SEARCH:BUTTON:RESET' | translate}}" (click)="reset()"></p-button>
          </div>
        </div>

      </form>
    </div>
  </p-panel>


  <p-dialog header="{{'PANEL_SEARCH_RC:SEARCH:NO_RESULT' | translate}}" [(visible)]="displayNoResult" appendTo="body" [width]="350">
    {{'PANEL_SEARCH_RC:SEARCH:NO_RESULT:TEXT' | translate}}
  </p-dialog>
</div>
