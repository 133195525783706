import { NgModule, Injectable } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FEATURES_ROUTES } from './features/features.module';
import { LayoutComponent } from './features/layout/layout.component';
import { LayoutModule } from './features/layout/layout.module';

export const ROUTES: Routes = [
  { path: '', redirectTo: '/map', pathMatch: 'full' },
  {
    path: '',
    component: LayoutComponent,
    children: [...FEATURES_ROUTES]
  },
  { path: '**', redirectTo: '/map' }
];

@Injectable()
@NgModule({
  providers: [],
  imports: [
    RouterModule.forRoot(ROUTES, { useHash: true, enableTracing: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
