import {AfterViewChecked, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Sign} from '../../../models/sign.model';
import {LoggedComponent} from '../../../shared/components/logged/logged.component';
import {UserService} from '../../../core/services/user.service';
import {SignService} from '../../../core/services/sign.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Code, SelectCode} from '../../../models/code.model';
import {Message} from 'primeng/components/common/api';
import {SignFormatService} from '../../../core/services/sign-format.service';
import {SignPlacementService} from '../../../core/services/sign-placement.service';
import {SignTypeService} from '../../../core/services/sign-type.service';
import {SignGestionService} from '../../../core/services/sign-gestion.service';
import {SignSpecificityService} from '../../../core/services/sign-specificity.service';
import {ConfirmationService} from 'primeng/api';
import {LayoutService} from '../../../core/services/layout.service';
import {SupportComponent} from '../../supports/support/support.component';
import {SupportService} from '../../../core/services/support.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Rc} from '../../../models/rc.model';
import {RcService} from '../../../core/services/rc.service';
import {
  PictureServiceSign,
  PictureServiceSignPlacement,
  PictureServiceSignRemoval
} from '../../../core/services/picture.service';
import {Support} from '../../../models/support.model';
import {TaskViewComponent} from '../../tasks/task-view/task-view.component';
import {TaskService} from '../../../core/services/task.service';
import {SignOrderService} from '../../../core/services/sign-order.service';
import {environment} from '../../../../environments/environment';
import * as olFormat from 'ol/format';
import MultiPoint from 'ol/geom/MultiPoint';
import {MapService} from '../../../core/services/map.service';
import {NotificationService} from '../../../core/services/notification.service';
import {TranslationService} from '../../../core/services/translation.service';
import {CALENDAR_LOCALE_EN} from '../../../shared/services/utils';
import {SignCategoryService} from '../../../core/services/sign-category.service';
import {LoaderService} from 'app/core/services/loader.service';
import { ROLES } from '../../../models/role.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss'],
  providers: [ConfirmationService]
})
export class SignComponent extends LoggedComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  translate: TranslateService = this.injector.get(TranslateService);
  myCalendarLocal: any = CALENDAR_LOCALE_EN;

  get environment() {
    return environment;
  }

  _editSign: Sign = <Sign>{};

  signForm: FormGroup;

  errorMessages: Message[] = [];

  tmp: boolean;
  showDuplicateButton: boolean;

  rcsPending: Rc[];
  rcsOk: Rc[];
  rcsRemoved: Rc[];

  _geom: string = null;

  /*------SELECT OPTIONS------*/
  signGestionOptions: SelectCode[] = new Array();
  signPlacementOptions: SelectCode[] = new Array();
  signSpecificityOptions: SelectCode[] = new Array();
  signFormatOptions: SelectCode[] = new Array();
  signTypeOptions: SelectCode[] = new Array();
  filteredSignTypeOptions: SelectCode[] = new Array();
  signCategoryOptions: SelectCode[] = new Array();

  constructor(
    private cdRef: ChangeDetectorRef,
    public userService: UserService,
    public signService: SignService,
    private signOrderService: SignOrderService,
    private signCategoryService: SignCategoryService,
    private signTypeService: SignTypeService,
    private signGestionService: SignGestionService,
    private signSpecificityService: SignSpecificityService,
    private signFormatService: SignFormatService,
    private signPlacementService: SignPlacementService,
    private supportService: SupportService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private errorService: NotificationService,
    private layoutService: LayoutService,
    private injector: Injector,
    public rcService: RcService,
    public pictureServiceSign: PictureServiceSign,
    public pictureServiceSignPlacement: PictureServiceSignPlacement,
    public pictureServiceSignRemoval: PictureServiceSignRemoval,
    private taskService: TaskService,
    private translationService: TranslationService,
    private mapService: MapService,
    private loaderService: LoaderService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    super(userService);
  }

  signChanges: Subscription;

  async ngOnInit() {
    this.initComponent();

    this.rcsPending = [];
    this.rcsOk = [];
    this.rcsRemoved = [];

    this.signForm = this.fb.group({
      gestion: this.fb.control(''),
      placement: this.fb.control(''),
      requestType: this.fb.control(''),
      type: this.fb.control(''),
      genericBool: this.fb.control(''),
      genericText: this.fb.control(''),
      genericCom: this.fb.control(''),
      format: this.fb.control(''),
      specificity: this.fb.control(''),
      note: this.fb.control(''),
      other: this.fb.control(''),
      placementDate: this.fb.control(''),
      removalDate: this.fb.control(''),
      placementUser: this.fb.control(''),
      removalUser: this.fb.control(''),
      placementNote: this.fb.control(''),
      removalNote: this.fb.control(''),
      category: this.fb.control('')
    });

    this.signChanges = this.signService.signHandler.subscribe(() => {
      this.editSign = this.signService.sign;
      this.setCurrentCategory();
      this.filterSignTypeOptions(null, true);
    });

    this.showDuplicateButton = !this.mapService.duplicateModeActivated;
    this.mapService.duplicateModeHandler.subscribe(duplicateModeEnabled => {
      if(!duplicateModeEnabled) {
        this.showDuplicateButton = true;
      }
    })
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.signChanges) {
      this.signChanges.unsubscribe();
    }
  }

  get mode(): string {
    return this.signService.sign && this.signService.sign.id ? 'EDIT' : 'NEW';
  }

  isNew(): boolean {
    return this.mode === 'NEW';
  }

  get id(): number {
    return this.signService.sign.id;
  }

  get supportId(): number {
    return this.signService.sign.supportId;
  }

  get editSign(): Sign {
    return this._editSign;
  }

  set editSign(sign: Sign) {
    this._editSign = Object.assign({}, sign);
    if (this.isNew()) {
      this.editSign.supportId = this.supportId;

      if (!this.editSign.gestion) {
        this.editSign.gestion = this.signGestionService.default;
      }
      if (!this.editSign.placement) {
        this.editSign.placement = this.signPlacementService.default;
      }
      if (!this.editSign.specificity) {
        this.editSign.specificity = this.signSpecificityService.default;
      }
      if (!this.editSign.format) {
        this.editSign.format = this.signFormatService.default;
      }
      if (!this.editSign.type) {
        this.editSign.type = this.signTypeService.default;
      }
      this.editSign.support = this.supportService.support;
    }
  }

  async initComponent() {
    this.myCalendarLocal = await this.translationService.localeCalendar();
    this.signService.deletedImgs = [];

    try {
      this.loaderService.showLoader();

      await this.loadLists();
      if (!this.signService.sign) {
        this.signService.sign = <Sign>{};
      } else {
        if (!this.signService.sign.support) {
          if (
            this.signService.sign.supportId ||
            this.supportService.support.id === this.signService.sign.supportId
          ) {
            this.signService.sign.support = this.supportService.support;
          } else {
            this.signService.sign.support = await this.supportService.getSupport(
              this.signService.sign.supportId
            );
          }
        }
      }
      this.editSign = this.signService.sign;
      this.setDate();
      const sign = this.signService.sign;
      const id = sign.id;

      if (id) {
        const rcs = await this.rcService.getRCForSignId(id).toPromise();
        this.rcsPending = rcs
          .filter(rc => rc.status === 'RC_PENDING')
          .sort((a, b) => {
            return (
              new Date(b.creationDate).getTime() -
              new Date(a.creationDate).getTime()
            );
          });
        this.rcsOk = rcs
          .filter(rc => rc.status === 'RC_ACTIVE')
          .sort((a, b) => {
            return (
              new Date(b.creationDate).getTime() -
              new Date(a.creationDate).getTime()
            );
          });
        this.rcsRemoved = [
          ...rcs.filter(rc => rc.status === 'RC_OLD'),
          ...rcs.filter(rc => rc.status === 'RC_REJECT_COMM')
        ].sort((a, b) => {
          return (
            new Date(b.creationDate).getTime() -
            new Date(a.creationDate).getTime()
          );
        });
        const imgs = await this.pictureServiceSign.getPictureFull(id);
        sign.imgs = imgs;
        this.editSign.imgs = Array.from(imgs);

        const placementImgs = await this.pictureServiceSignPlacement.getPictureFull(
          id
        );
        sign.placementImgs = placementImgs;
        this.editSign.placementImgs = Array.from(placementImgs);
        const removalImgs = await this.pictureServiceSignRemoval.getPictureFull(
          id
        );
        sign.removalImgs = removalImgs;
        this.editSign.removalImgs = Array.from(removalImgs);
        this.signService.sign = sign;
      }
    } catch (error) {
      this.errorService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }

    this.translate.use(this.userService.user.language);
  }

  async loadLists() {
    return await Promise.all([
      this.loadCode(this.signGestionService, this.signGestionOptions, null),
      this.loadCode(this.signPlacementService, this.signPlacementOptions, null),
      this.loadCode(
        this.signSpecificityService,
        this.signSpecificityOptions,
        null
      ),
      this.loadCode(this.signFormatService, this.signFormatOptions, 'EMPTY'),
      this.loadCode(this.signCategoryService, this.signCategoryOptions, null),
      this.loadSignTypes()
    ]).then(() => {
      this.filterSignTypeOptions(null);
    });
  }

  async loadSignTypes() {
    const types = await this.signTypeService.findAll();
    this.signTypeOptions = [];

    for (const type of types) {
      this.signTypeOptions.push({
        label:  `${type.code} - ${(this.translate.currentLang === 'FR' ? type.labelFr : type.labelNl) || ''}`,
        value: type
      });
    }
  }

  async loadCode(
    service,
    options: SelectCode[],
    empty: string
  ): Promise<undefined> {
    options.splice(0, options.length);
    const values: Code[] = await service.findAll();

    const translatedValues = await Promise.all(
      values.map(async val => {
        return {
          label: await this.translate.get(val.code).toPromise(),
          value: val
        };
      })
    );
    const map = new Map<number, any>();
    translatedValues.sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      } else if (a.label < b.label) {
        return -1;
      } else {
        return 0;
      }
    });
    translatedValues.forEach(val => map.set(val.value.id, val));
    options.push(...Array.from(map.values()));

    if (empty) {
      options.push({label: '', value: {id: null, code: empty}});
    }
    return;
  }

  async submit() {
    try {
      this.loaderService.showLoader();

      if (!this.signForm.invalid) {
        this.errorMessages = [];

        if (
          this.editSign.placementDate &&
          this.editSign.removalDate &&
          this.editSign.placementDate > this.editSign.removalDate
        ) {
          console.error('Problème entre les dates');
        }

        this.setDateBeforeSubmit();

        // partie suppression des images
        this.editSign.deletedImgs = [];
        this.signService.deletedImgs.forEach(pic => {
          this.editSign.deletedImgs.push(pic);
        });
        this.signService.deletedImgs = [];

        // partie ajout des nouvelles images
        this.editSign.imgs = this.editSign.imgs ? this.editSign.imgs : [];
        this.pictureServiceSign.imgs.forEach(pic => {
          this.editSign.imgs.push(pic);
        });
        this.pictureServiceSign.imgs = [];

        this.editSign.placementImgs = this.editSign.placementImgs
          ? this.editSign.placementImgs
          : [];
        this.pictureServiceSignPlacement.imgs.forEach(pic => {
          this.editSign.placementImgs.push(pic);
        });
        this.pictureServiceSignPlacement.imgs = [];
        this.editSign.removalImgs = this.editSign.removalImgs
          ? this.editSign.removalImgs
          : [];
        this.pictureServiceSignRemoval.imgs.forEach(pic => {
          this.editSign.removalImgs.push(pic);
        });
        this.pictureServiceSignRemoval.imgs = [];

        if (
          this.signService.newSupport &&
          this.signService.newSupport.id === undefined
        ) {
          await this.createSupportWithSign();
        } else {
          await this.createOrUpdate();
        }
        this.mapService.refresh();
        this.signOrderService.signs = this.supportService.support.signs;

        this.initComponent();

        await this.notificationService.addSingleSuccess(
          this.translateService.instant('COMMON_SUCESS'),
          this.translateService.instant('SIGN_SAVE_SUCESS')
        );
      }
    } finally {
      this.loaderService.hideLoader();
    }
  }

  setDate() {
    //To avoid using ngModel and formControl at the same time
    //Fix p-calendar issue see at https://github.com/primefaces/primeng/issues/5074
    if (this.editSign.placementDate) {
      this.signForm.get('placementDate').patchValue(new Date(this.editSign.placementDate));
    }
    if (this.editSign.removalDate) {
      this.signForm.get('removalDate').patchValue(new Date(this.editSign.removalDate));
    }
  }

  setDateBeforeSubmit() {
    this.editSign.placementDate = this.signForm.get('placementDate').value;
    this.editSign.removalDate = this.signForm.get('removalDate').value;
  }

  async createSupportWithSign() {
    try {

      //mobileStatus
      if (this.userService.hasRole(this.userService.contractorCode)) {
        this.editSign.mobileStatus = this.signService.MOBILE_STATUS_ADD;
        this.editSign.mobileStatusDate = new Date();
      }

      const result: Support = await this.supportService.createWithSigns(
        this.signService.newSupport,
        [this.editSign]
      );
      if (result && result.id) {
        this.signService.newSupport = null;
        this.supportService.support = result;
        this.signService.sign = result.signs[0];
        this.supportService.editMode = false;
        this.layoutService.rightPanelContent = SupportComponent;
        this.layoutService.rightPanelVisible = true;
      }
    } catch (error) {
      this.errorService.addSingleError(error);
    }
  }

  setEditMobileStatus(sign: Sign) {
    if (this.userService.hasRole(this.userService.contractorCode)) {
      sign.mobileStatus = sign.mobileStatus != null ? sign.mobileStatus : this.signService.MOBILE_STATUS_EDIT;
      sign.mobileStatusDate = new Date();
    }
  }

  setAddMobileStatus(sign: Sign) {
    if (this.userService.hasRole(this.userService.contractorCode)) {
      sign.mobileStatus = this.signService.MOBILE_STATUS_ADD;
      sign.mobileStatusDate = new Date();
    }
  }

  async createOrUpdate() {
    try {
      if (!this.editSign.id) {
        this.setAddMobileStatus(this.editSign);
      } else {
        this.setEditMobileStatus(this.editSign);
      }

      const sign: Sign = await this.signService.createOrUpdateSign(
        this.editSign
      );
      this.supportService.support = await this.supportService.getSupportFull(
        sign.supportId
      );
      this.signService.sign = sign;
      this.signService.editMode = false;
    } catch (error) {
      this.errorService.addSingleError(error);
    }
  }

  cancel() {
    if (
      this.signService.newSupport &&
      this.signService.newSupport.id === undefined
    ) {
      this.signService.sign = this.editSign;
      this.supportService.support = this.signService.newSupport;
      this.layoutService.rightPanelContent = SupportComponent;
      this.layoutService.rightPanelVisible = true;
    }
    this.signService.editMode = false;
    this.supportService.editMode = false;
  }

  remove() {
    this.confirmationService.confirm({
      message: '',
      accept: () => this.removeAccept()
    });
  }

  async removeAccept() {
    try {
      try {
        const support = await this.supportService.getSupport(this.supportId);
        this.supportService.support = support;
        this.layoutService.rightPanelVisible = true;
        this.layoutService.rightPanelContent = SupportComponent;
      } catch (error) {
        this.errorService.addSingleError(error);
        this.layoutService.closeRightPanel();
      }
    } catch (error) {
      this.errorService.addSingleError(error);
    }
  }

  async backSupport() {
    try {
      this.loaderService.showLoader();

      const support = await this.supportService.getSupport(
        this.signService.sign.supportId
      );
      this.supportService.support = support;
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = SupportComponent;
    } catch (error) {
      this.errorService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  enableEdit() {
    this.signService.editMode = true;
  }

  async enableDuplicate() {
    try {
      this.loaderService.showLoader();

      const support = await this.supportService.getSupport(this.signService.sign.supportId);
      const sign = this.signService.sign;

      this.signService.memorizedSign = sign;
      this.supportService.memorizedSupport = support;

      this.showDuplicateButton = false;

      const infoMessageTitle: string = await this.translateService
        .get('INFORMATION_MESSAGE').toPromise();
      const infoMessageContent: string = await this.translateService
        .get('DUPLICATE_MODE_IS_ENABLED').toPromise();
      this.notificationService.addSingleInfo(infoMessageTitle, infoMessageContent);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  close() {
    this.layoutService.closeRightPanel();
  }

  expand() {
    this.layoutService.leftPanelFull = false;
    this.layoutService.rightPanelFull = !this.layoutService.rightPanelFull;
  }

  async showTaskRc(rc: Rc) {
    try {
      this.loaderService.showLoader();

      this.taskService.rcTask = await this.rcService.getRc(rc.id).toPromise();
      this.taskService.fromSignView = this.signService.sign;
      this.layoutService.rightPanelContent = TaskViewComponent;
      this.layoutService.rightPanelVisible = true;
    } catch (error) {
      this.errorService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async getRcDocument(rc: Rc) {
    event.stopPropagation();
    try {
      this.loaderService.showLoader();
      await this.rcService.getSingleGedDocument(this.getRcDocumentId(rc)).toPromise();
    } catch (error) {

    } finally {
      this.loaderService.hideLoader();
    }
  }

  getRcDocumentId(rc: Rc) {
    return rc.communalPvGedId ? rc.communalPvGedId : rc.rcGedId;
  }

  async showSupportForRc(rc: Rc, event) {
    event.stopPropagation();
    const signIds: number[] = rc.signs.map(sign => sign.id);
    const supports = await this.supportService.getSupportsForSignIds(signIds);
    const multi = new MultiPoint([]);
    supports.forEach(support => {
      const featureList = new olFormat.GeoJSON().readFeatures(support.geom);
      const geom = featureList[0].getGeometry();
      multi.appendPoint(geom);
    });
    this.mapService.zoomToFeatures(multi.getExtent());
  }

  get edit() {
    return this.signService.editMode;
  }

  get canUpdate() {
    if (this.signService.sign.deleteAt) {
      return false;
    }
    const support = this.editSign.support
      ? this.editSign.support
      : this.signService.sign.support
        ? this.signService.sign.support
        : this.supportService.support;
    if (support) {
      return (
        this.userService.ROLE_EDIT &&
        this.userService.isInMunicipalities(support.geom)
      );
    }
    return false;
  }

  filterSignTypeOptions($event, firstFilter = false) {
    let categoryCode;
    let changeType = false;
    if (firstFilter && this.editSign.type && this.editSign.type.typeCategorie) {
      categoryCode = this.editSign.type.typeCategorie.code;
    } else if ($event && $event.value) {
      categoryCode = $event.value.code;
      changeType = true;
    }
    if (categoryCode) {
      this.filteredSignTypeOptions = this.signTypeOptions.filter(selectItem => {
        const type: any = selectItem.value;
        return type.typeCategorie ? type.typeCategorie.code === categoryCode : false;
      });
    }
    if (changeType) {
      this.editSign.type = this.signTypeService.codes.find(code => code.id === this.filteredSignTypeOptions[0].value.id);
    }
  }

  setCurrentCategory() {
    if (this.editSign.type && this.editSign.type.typeCategorie) {
      this.signForm.get('category').patchValue(this.editSign.type.typeCategorie);
    }
  }

}
