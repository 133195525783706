import {Pipe, PipeTransform} from '@angular/core';
import {SignType, SignTypeHelper} from '../../models/code.model';
import {Sign} from '../../models/sign.model';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'signTypeImage'})
export class SignTypeImagePipe implements PipeTransform {
  transform(value: SignType): string {
    return SignTypeHelper.getImg(value);
  }
}

@Pipe({name: 'signImage'})
export class SignImagePipe implements PipeTransform {
  transform(value: Sign): string {
    return SignTypeHelper.getImg(value.type);
  }
}

@Pipe({ name: 'signTypeLabel' })
export class SignTypeLabelPipe implements PipeTransform {

  constructor(
    private readonly i18n: TranslateService
  ) {}

  transform(value: SignType): string {
    return this.i18n.currentLang === 'FR' ? (value.labelFr || '') : (value.labelNl || '');
  }
}
