import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { MapRoutingModule } from './map-routing.module';
import { MapComponent } from './map.component';
import { GisViewerUIModule } from '@nsi/gis-viewer-ui';
import { PanelSupportListComponent } from '../supports/panel-support-list/panel-support-list.component';
import { PanelSignListComponent } from '../signs/panel-sign-list/panel-sign-list.component';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MapMenuContentDirective } from './map.menu.content.directive';
import { RcComponent } from '../rc/rc.component';
import { LayoutActionsPanelModule } from '../actions-panel/layout.actions-panel.module';
import { SignsModule } from '../signs/signs.module';
import { SignSelectedDirective } from '../signs/panel-sign-list/sign-selected.directive';
import { SignBorderDirective } from '../signs/panel-sign-list/sign-border.directive';
import { SignOpacityDirective } from '../signs/edit-sign-order/sign-opacity.directive';
import { SignCodeDirective } from '../signs/panel-sign-list/sign-code.directive';
import { RcIntroConcluComponent } from '../rc/rc-intro-conclu/rc-intro-conclu.component';
import { RcMeasuresComponent } from '../rc/rc-measures/rc-measures.component';
import { AdministrationModule } from '../administration/administration.module';

const MODULES = [SharedModule, MapRoutingModule, GisViewerUIModule, ContextMenuModule, LayoutActionsPanelModule];

const DECLARATIONS = [
  MapComponent,
  PanelSupportListComponent,
  PanelSignListComponent,
  RcComponent,
  RcIntroConcluComponent,
  RcMeasuresComponent,
];

@NgModule({
  imports: [MODULES, SignsModule, AdministrationModule],
  declarations: [
    MapMenuContentDirective,
    SignCodeDirective,
    SignSelectedDirective,
    SignBorderDirective,
    SignOpacityDirective,
    ...DECLARATIONS,
  ],
  exports: [SignSelectedDirective, SignBorderDirective, SignOpacityDirective, SignCodeDirective],
  entryComponents: DECLARATIONS,
})
export class MapModule {}
