import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'signAddress'
})
export class SignAddressPipe implements PipeTransform {

  transform({ number, address}: any, args?: any): any {
    let results = '';
    if (address && address !== '') {
      const addressParsed = JSON.parse(address);
      results += `${addressParsed.name}, ${addressParsed.postCode} ${addressParsed.municipality}`;

      if (number) {
        results = `${number} ${results}`;
      }
    }
    return results;
  }
}
