import {TreeNode} from 'primeng/api';
import {findIndex as _findIndex, flatten as _flatten} from 'lodash';

export class TreeNodeUtils {
  public static flattenTreeNode(nodes: TreeNode[]): TreeNode[] {
    const n = nodes.map(n => {
      const flat: TreeNode[] =
        n.children && n.children.length > 0 ? this.flattenTreeNode(n.children) : ([] as TreeNode[]);
      return [n, ...flat];
    });
    return _flatten(n);
  }

  public static isFirst(nodes: TreeNode[], node: TreeNode): boolean {
    if (node.parent) {
      return _findIndex(node.parent.children, n => n.data.id === node.data.id) === 0;
    } else {
      return _findIndex(nodes, n => n.data.id === node.data.id) === 0;
    }
  }

  public static isLast(nodes: TreeNode[], node: TreeNode): boolean {
    if (node.parent) {
      return _findIndex(node.parent.children, n => n.data.id === node.data.id) === node.parent.children.length - 1;
    } else {
      return _findIndex(nodes, n => n.data.id === node.data.id) === nodes.length - 1;
    }
  }

  /**
   * Restore the previous treenode states (expanded node)
   * @param nodes all nodes (states)
   * @param node current checked node (updated node)
   */
  static restoreState(nodes: TreeNode[], node: TreeNode) {
    if (!nodes || !node) {
      return;
    }
    const state = nodes.find(s => s.data.id === node.data.id);
    node.expanded = state ? state.expanded : false;
    if (state) {
      node.children.forEach(c => this.restoreState(state.children, c));
    }
  }
}

/**
 * Every filter match modes available see: https://www.primefaces.org/primeng/#/treetable
 * => filtering
 */
export class FILTER_MATCH_MODES {
  static START_WIDTH = 'startsWith';
  static CONTAINS = 'contains';
  static END_WIDTH = 'endsWith';
  static EQUALS = 'equals';
  static NOT_EQUALS = 'notEquals';
  static IN = 'in';
  static LT = 'lt';
  static LTE = 'lte';
  static GT = 'gt';
  static GTE = 'gte';
}
