import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {Sign} from '../../../models/sign.model';
import {SignService} from '../../../core/services/sign.service';
import {ConfirmationService} from 'primeng/api';
import {LayoutService} from '../../../core/services/layout.service';
import {SignComponent} from '../sign/sign.component';
import {Subscription} from 'rxjs';
import {SignOrderService} from '../../../core/services/sign-order.service';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {SupportService} from '../../../core/services/support.service';
import {UserService} from '../../../core/services/user.service';
import {DragulaService} from 'ng2-dragula';
import {PanelSignListComponent} from '../panel-sign-list/panel-sign-list.component';
import {RcService} from '../../../core/services/rc.service';
import {Rc} from '../../../models/rc.model';
import * as _ from 'lodash';
import { LoaderService } from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'edit-sign-order',
  templateUrl: './edit-sign-order.component.html',
  styleUrls: ['./edit-sign-order.component.scss'],
  providers: [ConfirmationService]
})
export class EditSignOrderComponent implements OnInit, OnDestroy {
  translate: TranslateService = this.injector.get(TranslateService);

  @Input()
  supportId: number;
  @Input()
  edit: boolean;
  @Input()
  signs: Sign[];

  deletedSignIds: Array<number> = [];
  deleteSelect: number = null;

  get environment() {
    return environment;
  }

  divSignGroup;
  dragulaDropChanges: Subscription;

  reloadPage = false;
  signsChanges: Subscription;

  constructor(
    private signService: SignService,
    private supportService: SupportService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private layoutService: LayoutService,
    private injector: Injector,
    private signOrderService: SignOrderService,
    private dragulaService: DragulaService,
    private rcService: RcService,
    private loaderService: LoaderService
  ) {
    this.dragulaDropChanges = this.dragulaService
      .drop('SIGNS')
      .subscribe(value => {
        this.apply();
      });

    this.signsChanges = this.signOrderService.signsHandler.subscribe(() => {
      this.reload();
    });
  }

  ngOnInit(): void {
    this.reload();
  }

  ngOnDestroy() {
    this.dragulaDropChanges.unsubscribe();
    this.signsChanges.unsubscribe();
  }

  reload() {
    this.signs = this.signOrderService.signs.sort((a, b) => b.order - a.order);
    this.signService.signOrder = this.signs;
  }

  showSign(sign: Sign) {
    this.signService.sign = sign;
    this.layoutService.rightPanelVisible = true;
    this.signService.editMode = false;
    this.layoutService.rightPanelContent = SignComponent;
  }

  async displayDelete(id: number) {
    this.deleteSelect = id;
    // await this.translate.get('ADD_SIGN').toPromise()
    const message =
      this.signs.length === 1 && !this.userService.hasRole(this.userService.contractorCode)
        ? await this.translate.get('SIGN:DELETE_SIGN_AND_SUPPORT').toPromise()
        : await this.translate.get('SIGN:DELETE_SIGN').toPromise();
    this.confirmationService.confirm({
      message: message,
      icon: 'icon fa fa-trash',
      accept: async () => {
        if(!this.userService.hasRole(this.userService.contractorCode)) {
          await this.deleteSign();
          this.checkIfSignHaveValidatedRcs(id);
        } else {
          let sign: Sign = this.signs.find(s => s.id === id);
          sign.mobileStatus = this.signService.MOBILE_STATUS_DELETE;
          sign.mobileStatusDate = new Date();
        }
        this.deleteSelect = null;
      },
      reject: () => {
        this.deleteSelect = null;
      }
    });
  }

  async deleteSign() {
    try {
      this.loaderService.showLoader();

      const removedId = this.deleteSelect;

      const place = this.signs.findIndex(s => s.id === removedId);
      if (place >= 0) {
        this.signs.splice(place, 1);
      }
      this.apply();
    } catch (error) {
    }
    finally{
      this.loaderService.hideLoader();
    }
  }

  getOpacity(sign: Sign){
    return sign.mobileStatus == this.signService.MOBILE_STATUS_DELETE ? 0.3 : 1;
  }

  apply() {
    this.signs.forEach((s, i) => {
      s.order = this.signs.length - i;
    });
    this.signService.signOrder = this.signs.map(s => {
      return {
        id: s.id,
        order: s.order
      };
    });
  }

  checkIfSignHaveValidatedRcs(signId: number) {
    this.deletedSignIds.push(signId);
    this.rcService.getRCForSignId(signId).toPromise().then(async rcs => {
      if (rcs && rcs.length) {
        const activeRc: Rc = rcs.filter(value => value.status === 'RC_ACTIVE')[0];
        if (activeRc && activeRc.signs) {
          const rcSigns = activeRc.signs.filter(s => !this.deletedSignIds.some(value => s.id === value));

          if (rcSigns.length > 1) {
            const message = await this.translate.get('SIGN:CONFIRM_ABROGRATION').toPromise();
            this.confirmationService.confirm({
              message: message,
              icon: 'icon fa fa-check',
              accept: async () => {
                const signs = await Promise.all(rcSigns.map(async (sign: Sign) => {
                  sign.support = await this.supportService.getSupport(sign.supportId);
                  return sign;
                }));
                this.signService.signsSelected = signs;
                this.layoutService.leftPanelContent = PanelSignListComponent;
                this.layoutService.leftPanelVisible = true;
              }
            });
          }
        }
      }
    });
  }


  newSign() {
    this.signService.editMode = true;
    this.signService.sign = {supportId: this.supportId};
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = SignComponent;
  }

  get canUpdate(): boolean {
    return this.supportService.support.deleteAt == null && this.userService.isInMunicipalities(
      this.supportService.support.geom
    );
  }
}
