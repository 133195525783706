import {Injectable} from '@angular/core';
import {Code} from '../../models/code.model';
import {environment} from '../../../environments/environment';
import {BaseAbstractService} from './base-abstract.service';
import {Observable} from 'rxjs/Observable';
import {HttpEvent} from '@angular/common/http';

@Injectable()
export class SignSpecificityService extends BaseAbstractService
{
  _codes:Code[];

  _default:string = "REFLECTIVE";

  get url():string {
    return environment.backendURL + environment.signPath+ this.configService.appConfig.apiUrl + 'Sign/SignSpecificities';
  }

  async findAll(): Promise<Code[]> {
    this._codes= this._codes ? this._codes: await this.get<Code[]>(`${this.url}`);
    return this._codes;
  }

  get default():Code{
    return this._codes.find((code)=>{
      return this._default==code.code?true:false;
    })||this._codes[0];
  }
}
