import { Component, Injector, OnInit } from '@angular/core';
import { TaskService } from '../../../core/services/task.service';
import { LayoutService } from '../../../core/services/layout.service';
import { PanelShowMultiRcComponent } from '../../rc/panel-show-multi-rc/panel-show-multi-rc.component';
import { RcService } from '../../../core/services/rc.service';
import { UserService } from '../../../core/services/user.service';
import { Code, SelectCode } from '../../../models/code.model';
import { MunicipalityService } from '../../../core/services/municipality.service';
import { TranslateService } from '@ngx-translate/core';
import { Municipality } from '../../../models/municipality.models';
import { NotificationService } from '../../../core/services/notification.service';
import { LoaderService } from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'unique-regulation-button',
  templateUrl: './layout.unique-regulation-button.component.html',
  styleUrls: ['./layout.unique-regulation-button.component.scss']
})
export class UniqueRegulationComponent implements OnInit {
  translate: TranslateService = this.injector.get(TranslateService);

  displayCreateUniqueRegelement = false;

  municipalitiesOptions: SelectCode[] = new Array();
  municipality: Code;

  constructor(
    private taskService: TaskService,
    private layoutService: LayoutService,
    public rcService: RcService,
    private errorService: NotificationService,
    public userService: UserService,
    private municipalityService: MunicipalityService,
    private injector: Injector,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {}

  async createUniqueReglement() {
    this.loaderService.showLoader();

    try {
      if (this.userService.municipality) {
        this.municipality = this.userService.municipality;
        await this.createUniqueReglementMunicipality();
      } else {
        try {
          await this.loadMunicipality();
        } catch (error) {
          this.errorService.addSingleError(error);
        }
        this.municipality = this.municipalitiesOptions[0].value;
        this.displayCreateUniqueRegelement = true;
      }
    } catch (error) {

    } finally {
      this.loaderService.hideLoader();
    }
  }

  async loadMunicipality(): Promise<any> {
    const promise: Promise<any> = new Promise(async (resolve, reject) => {
      this.municipalitiesOptions.splice(0, this.municipalitiesOptions.length);
      try {
        const values: Municipality[] = await this.municipalityService.findAll();
        const translatedValues = await Promise.all(
          values.map(async val => {
            return { label: val.name, value: { id: val.id, code: val.name } };
          })
        );
        translatedValues.sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          } else if (a.label < b.label) {
            return -1;
          } else {
            return 0;
          }
        });
        this.municipalitiesOptions = this.municipalitiesOptions.concat(
          translatedValues
        );
      } catch (error) {
        this.errorService.addSingleError(error);
      }
      resolve();
    });
    return promise;
  }

  async createUniqueReglementMunicipality() {
    if (this.municipality) {
      try {
        this.loaderService.showLoader();
        await this.rcService.getUniqueRegulation(this.municipality.id).toPromise();
        this.displayCreateUniqueRegelement = false;
      } finally {
        this.loaderService.hideLoader();
      }
    }
  }

  close() {
    this.displayCreateUniqueRegelement = false;
  }
}
