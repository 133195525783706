import {Component, OnInit} from '@angular/core';
import {LayoutService} from '../../../core/services/layout.service';
import {UserService} from '../../../core/services/user.service';
import {PanelSearchSignComponent} from '../../signs/panel-search-sign/panel-search-sign.component';

@Component({
   selector: 'search-sign-button',
   templateUrl: './layout.search-sign-button.component.html',
   styleUrls: ['./layout.search-sign-button.component.scss']
})
export class SearchSignButtonComponent implements OnInit
{
   constructor(
     private layoutService: LayoutService,
     private userService:UserService) {}
   ngOnInit() {}

  async showSearchSign() {
    this.layoutService.rightPanelContent = PanelSearchSignComponent;
    this.layoutService.rightPanelVisible = true;
  }
}
