import {LoggedComponent} from '../../../shared/components/logged/logged.component';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MEASURE_ORDER, MeasureBl} from '../../../core/services/business/measure.business';
import {UserService} from '../../../core/services/user.service';
import {TreeNode} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep as _cloneDeep} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, flatMap, tap} from 'rxjs/operators';
import {FILTER_MATCH_MODES, TreeNodeUtils} from '../../../core/utils/treenode-utils';
import {MEASURE_DIALOG_MODES} from '../measure-management-dialog/measure-management-dialog.component';
import {Column, TreeTable} from 'primeng/primeng';

@Component({
  selector: 'measure-management',
  templateUrl: './measure-management.component.html',
  styleUrls: ['./measure-management.component.scss'],
})
export class MeasureManagementComponent extends LoggedComponent implements OnInit {
  /**
   * List of measure nodes (value of the tree table)
   */
  public measureNodes$: Observable<TreeNode[]>;

  /**
   * For reload
   */
  public refresh$: BehaviorSubject<TreeNode[]> = new BehaviorSubject<TreeNode[]>([]);

  /**
   * Value of the selected row
   */
  public selectedNode: TreeNode;

  /**
   * Temporary variable used for edit logic
   */
  public tmpClone: any;

  /**
   * Cols definition of the tree table see: https://www.primefaces.org/primeng/#/treetable
   */
  public cols: any;

  /**
   * used to show loader while measures are loading
   */
  public loading = false;

  @ViewChild('tt', { static: false })
  treeTable: TreeTable;

  /**
   * constructor
   * @param userService user for base class
   * @param measureBl every bussiness logic linked with measure shoud be here
   * @param translateService for translation utilities
   */
  constructor(
    public userService: UserService,
    protected measureBl: MeasureBl,
    protected translateService: TranslateService
  ) {
    super(userService);
  }

  /**
   * Return true if a row is selected
   */
  public get hasSelectedNodes(): boolean {
    return this.selectedNode !== null && this.selectedNode !== undefined;
  }

  public get insertMode(): MEASURE_DIALOG_MODES {
    return MEASURE_DIALOG_MODES.INSERT;
  }

  public get addMode(): MEASURE_DIALOG_MODES {
    return MEASURE_DIALOG_MODES.ADD;
  }

  /**
   * Return the measur order up
   */
  public get measureUp(): MEASURE_ORDER {
    return MEASURE_ORDER.UP;
  }

  /**
   * Return the measur order down
   */
  public get measureDown(): MEASURE_ORDER {
    return MEASURE_ORDER.DOWN;
  }

  /**
   * Angular ng init method
   * Initialize datable header add call measure bl to populatte the table
   */
  public async ngOnInit() {
    this.cols = [
      {
        field: 'articleId',
        header: this.translateService.instant('ARTICLE_HEADER_MEASURES'),
        filterMatchMode: FILTER_MATCH_MODES.CONTAINS,
        editable: false,
      },
      {
        field: 'textFr',
        header: this.translateService.instant('TEXTFR_HEADER_MEASURES'),
        filterMatchMode: FILTER_MATCH_MODES.CONTAINS,
        editable: true,
      },
      {
        field: 'textNl',
        header: this.translateService.instant('TEXTNL_HEADER_MEASURES'),
        filterMatchMode: FILTER_MATCH_MODES.CONTAINS,
        editable: true,
      },
    ];

    this.measureNodes$ = this.refresh$.pipe(
      tap(states => (this.loading = true)),
      flatMap(states =>
        this.measureBl.getAllMeasuresTreeNodes().pipe(
          tap(measures => {
            return measures.map(m => this.stateRecur(states, m));
          })
        )
      ),
      tap(measures => (this.loading = false))
    );
  }

  /**
   * Restore the previous treenode states (expanded and selected node)
   * @param nodes all nodes (states)
   * @param node current checked node (updated node)
   */
  private stateRecur(nodes: TreeNode[], node: TreeNode) {
    const id = node.data.id;
    const state = nodes.find(s => s.data.id === id);
    node.expanded = state ? state.expanded : false;
    if (this.selectedNode && `${this.selectedNode.data.id}` === `${id}`) {
      this.selectedNode = node;
    }
    if (state) {
      node.children.forEach(c => this.stateRecur(state.children, c));
    }
  }

  /**
   * Click on the edit button
   * @param measureNodes all measures
   * @param data the edited node
   * @param event click event use to stop the propagation, prevent this event to be propagated to treeview ,
   * resulting by selecting the row (unwanted)
   * see: https://developer.mozilla.org/fr/docs/Web/API/Event/preventDefault
   */
  public onRowEditInit(measureNodes: TreeNode[], data: any, event: any) {
    this.measureBl
      .insertMeasure(_cloneDeep(data.node), measureNodes, MEASURE_DIALOG_MODES.EDIT)
      .pipe(filter(sucess => sucess))
      .subscribe(measures => this.refresh$.next(measureNodes));

    if (event) {
      event.stopPropagration
        ? event.stopPropagration()
        : event.preventDefault
        ? event.preventDefault()
        : console.log('cant stop vent');
    }
  }

  /**
   * Call business to change measure order
   */
  public reorderMeasure(measureOrder: MEASURE_ORDER, measureNodes: TreeNode[]) {
    this.measureBl
      .changeMeasureArticleOrder(measureOrder, measureNodes, this.selectedNode)
      .pipe(filter(sucess => sucess))
      .subscribe(() => this.refresh$.next(measureNodes));
  }

  /**
   * Check if the selected node can be reordered up
   * @param measureNodes all measure
   */
  public isFirstCurrentNode(measureNodes: TreeNode[]) {
    if (this.hasSelectedNodes) {
      return TreeNodeUtils.isFirst(measureNodes, this.selectedNode);
    }
    return false;
  }

  /**
   * Check if the selected node can be reordered down
   * @param measureNodes all measure
   */
  public isLastCurrentNode(measureNodes: TreeNode[]) {
    if (this.hasSelectedNodes) {
      return TreeNodeUtils.isLast(measureNodes, this.selectedNode);
    }
    return false;
  }

  /**
   * Call business to delete measure
   * @param idMeasure the id of the measure
   * @param measureNodes the current tree node state
   */
  public onRowToggle(idMeasure: number, measureNodes: TreeNode[], event: any, disabled: boolean) {
    this.measureBl
      .toggleMeasure(idMeasure, disabled)
      .pipe(filter(sucess => sucess))
      .subscribe(() => this.refresh$.next(measureNodes));
    if (event) {
      event.stopPropagration
        ? event.stopPropagration()
        : event.preventDefault
        ? event.preventDefault()
        : console.log('cant stop event');
    }
  }

  /**
   * Call business to insert a measure
   */
  public insertMeasure(measureNodes: TreeNode[], mode: MEASURE_DIALOG_MODES) {
    this.measureBl
      .insertMeasure(this.selectedNode, measureNodes, mode)
      .pipe(filter(sucess => sucess))
      .subscribe(() => this.refresh$.next(measureNodes));
  }

  public filter(value: string, col: Column): void {
    this.treeTable.filter(value, col.field, col.filterMatchMode);
    this.treeTable.value = [...this.treeTable.value];
  }
}
