import {NgModule} from '@angular/core';

import {SharedModule} from 'app/shared/shared.module';
import { LanguageIconComponent } from './language/layout.language-icon.component';
import { ProfilIconComponent } from './profile/layout.profil-icon.component';
import { LogoutIconComponent } from './logout/layout.logout-icon.component';
import { LayoutActionIconsComponent } from './layout.action-icons.component';
import { AdministrationIconComponent } from './administration/layout.administration-icon.component';
import { DocumentationIconComponent } from './documentation/layout.documentation-icon.component';
import { HelpdeskComponent } from '../../shared/components/helpdesk/helpdesk.component';
import { HelpdeskIconComponent } from './helpdesk/layout.helpdesk-icon.component';
/* yang-add-component-import - Yang will add components imports here */

const MODULES = [
    SharedModule
  ];

  const DECLARATIONS = [
    LanguageIconComponent,
    LogoutIconComponent,
    ProfilIconComponent,
    AdministrationIconComponent,
    DocumentationIconComponent,
    LayoutActionIconsComponent,
    HelpdeskIconComponent

  ];

@NgModule({
    declarations: DECLARATIONS,
    imports: MODULES,
    exports: [LayoutActionIconsComponent],
  entryComponents: [HelpdeskComponent]
})
export class LayoutActionIconsModule {}
