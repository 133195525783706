import { Component, Injector, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LayoutService } from '../../../core/services/layout.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '../../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/components/common/api';
import { Code, SelectCode } from '../../../models/code.model';
import { SignTypeService } from '../../../core/services/sign-type.service';
import { SignCategoryService } from '../../../core/services/sign-category.service';
import { SignGestionService } from '../../../core/services/sign-gestion.service';
import { SignPlacementService } from '../../../core/services/sign-placement.service';
import { SignSpecificityService } from '../../../core/services/sign-specificity.service';
import { SupportGestionService } from '../../../core/services/support-gestion.service';
import { SupportTypeService } from '../../../core/services/support-type.service';
import { SupportSpecificityService } from '../../../core/services/support-specificity.service';
import { Municipality } from '../../../models/municipality.models';
import { MunicipalityService } from '../../../core/services/municipality.service';
import { SupportService } from '../../../core/services/support.service';
import { PanelSignListComponent } from '../panel-sign-list/panel-sign-list.component';
import { SignService } from '../../../core/services/sign.service';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../core/services/notification.service';
import { CALENDAR_LOCALE_EN } from '../../../shared/services/utils';
import { TranslationService } from '../../../core/services/translation.service';
import { LoaderService } from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'panel-search-sign',
  templateUrl: './panel-search-sign.component.html',
  styleUrls: ['./panel-search-sign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelSearchSignComponent implements OnInit {
  translate: TranslateService = this.injector.get(TranslateService);

  myCalendarLocal: any = CALENDAR_LOCALE_EN;

  get environment() {
    return environment;
  }
  errorMessages: Message[] = [];

  searchSignForm: FormGroup;

  signGestionOptions: SelectCode[] = new Array();
  signMuncipalityOptions: SelectCode[] = new Array();
  signPlacementOptions: SelectCode[] = new Array();
  signTypeCategoryOptions: SelectCode[] = new Array();
  signTypeOptions: SelectCode[] = new Array();
  signSpecificityOptions: SelectCode[] = new Array();
  supportGestionOptions: SelectCode[] = new Array();
  supportTypeOptions: SelectCode[] = new Array();
  supportSpecificityOptions: SelectCode[] = new Array();

  displayNoResult = false;

  signSearch = {
    signGestion: [],
    supportMunicipality: [],
    signPlacement: [],
    signRequestType: null,

    signTypeCategory: [],
    signType: [],
    signSpecificity: [],

    signNotPlacementBool: false,
    signPlacementDateStart: null,
    signPlacementDateEnd: null,
    signRemovalDateStart: null,
    signRemovalDateEnd: null,
    signOldBool: false,

    supportGestion: [],
    supportType: [],
    supportSpecificity: [],
    supportAddress: [],
    supportNumber: null,
  };

  signSearchSend = {
    signGestionId: [],
    supportMunicipalityId: [],
    signPlacementId: [],
    signRequestType: null,

    signTypeCategoryId: [],
    signTypeId: [],
    signSpecificityId: [],

    signNotPlacementBool: false,
    signPlacementDateStart: null,
    signPlacementDateEnd: null,
    signRemovalDateStart: null,
    signRemovalDateEnd: null,
    signOldBool: false,

    supportGestionId: [],
    supportTypeId: [],
    supportSpecificityId: [],
    supportAddress: [],
    supportNumber: null,
  };

  address: string;
  newData: any;
  results: string[] = [];

  constructor(
    private layoutService: LayoutService,
    private signGestionService: SignGestionService,
    private municipalityService: MunicipalityService,
    private signPlacementService: SignPlacementService,
    private notificationService: NotificationService,
    private signCategoryService: SignCategoryService,
    private signTypeService: SignTypeService,
    private signSpecificityService: SignSpecificityService,
    private supportGestionService: SupportGestionService,
    private supportTypeService: SupportTypeService,
    private supportSpecificityService: SupportSpecificityService,
    private supportService: SupportService,
    private signService: SignService,
    private injector: Injector,
    private translationService: TranslationService,
    private fb: FormBuilder,
    private loaderService: LoaderService
  ) {}
  ngOnInit() {
    this.initComponent();

    this.searchSignForm = this.fb.group({
      signGestion: this.fb.control(''),
      supportMunicipality: this.fb.control(''),
      signPlacement: this.fb.control(''),
      signAskingType: this.fb.control(''),

      signTypeCategory: this.fb.control(''),
      signType: this.fb.control(''),
      signSpecificity: this.fb.control(''),

      signNotPlacementBool: this.fb.control(''),
      signPlacementDateStart: this.fb.control(''),
      signPlacementDateEnd: this.fb.control(''),
      signRemovalDateStart: this.fb.control(''),
      signRemovalDateEnd: this.fb.control(''),
      signOldBool: this.fb.control(''),

      supportGestion: this.fb.control(''),
      supportType: this.fb.control(''),
      supportSpecificity: this.fb.control(''),
      supportAddress: this.fb.control(''),
      supportNumber: this.fb.control(''),
    });
  }

  async initComponent() {
    this.myCalendarLocal = await this.translationService.localeCalendar();

    try {
      this.loaderService.showLoader();

      this.signSearch = {
        signGestion: [],
        supportMunicipality: [],
        signPlacement: [],
        signRequestType: null,

        signTypeCategory: [],
        signType: [],
        signSpecificity: [],

        signNotPlacementBool: false,
        signPlacementDateStart: null,
        signPlacementDateEnd: null,
        signRemovalDateStart: null,
        signRemovalDateEnd: null,
        signOldBool: false,

        supportGestion: [],
        supportType: [],
        supportSpecificity: [],
        supportAddress: [],
        supportNumber: null,
      };
      await this.loadLists();
    } catch (e) {
      this.notificationService.addSingleError(e);
    }
    finally{
      this.loaderService.hideLoader();
    }
  }

  async loadLists() {
    return await Promise.all([
      this.loadCode(this.signGestionService, this.signGestionOptions, null),
      this.loadMunicipality(this.municipalityService, this.signMuncipalityOptions),
      this.loadCode(this.signPlacementService, this.signPlacementOptions, null),
      this.loadCode(this.signCategoryService, this.signTypeCategoryOptions, null),
      this.loadCode(this.signTypeService, this.signTypeOptions, null),
      this.loadCode(this.signSpecificityService, this.signSpecificityOptions, null),
      this.loadCode(this.supportGestionService, this.supportGestionOptions, null),
      this.loadCode(this.supportTypeService, this.supportTypeOptions, null),
      this.loadCode(this.supportSpecificityService, this.supportSpecificityOptions, null),
    ]);
  }

  async loadCode(service, options: SelectCode[], other: string): Promise<undefined> {
    try {
      options.splice(0, options.length);
      const values: Code[] = await service.findAll();

      const translatedValues = await Promise.all(
        values.map(async val => ({
          label: await this.translate.get(val.code).toPromise(),
          value: val,
        }))
      );

      const map = new Map<number, any>();
      translatedValues.forEach(val => map.set(val.value.id, val));
      options.push(...Array.from(map.values()));
      options.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        } else if (a.label < b.label) {
          return -1;
        } else {
          return 0;
        }
      });

      if (other) {
        const otherSelectCode: SelectCode = {
          label: await this.translate.get(other).toPromise(),
          value: {
            id: 0,
            code: await this.translate.get(other).toPromise(),
          },
        };
        options.unshift(otherSelectCode);
      }
    } catch (error) {
      this.notificationService.addSingleError(error);
    }

    return;
  }

  async loadMunicipality(service, options: SelectCode[]): Promise<undefined> {
    try {
      options.splice(0, options.length);
      const values: Municipality[] = await service.findAll();
      values.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        } else {
          return 0;
        }
      });
      values.forEach(val => {
        options.push({
          label: val.name,
          value: { id: val.id, code: val.name },
        });
      });
    } catch (error) {
      this.notificationService.addSingleError(error);
    }
    return;
  }

  async updateType() {
    // clean Array
    this.signTypeOptions.splice(0, this.signTypeOptions.length);
    try {
      const catIds = this.signSearch.signTypeCategory.map(c => c.id);
      const values: Code[] = await this.signTypeService.filter(catIds);

      const translatedValues = await Promise.all(
        values.map(async val => ({
          label: await this.translate.get(val.code).toPromise(),
          value: val,
        }))
      );
      translatedValues.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        } else if (a.label < b.label) {
          return -1;
        } else {
          return 0;
        }
      });
      translatedValues.forEach(val => this.signTypeOptions.push(val));
      const other = 'ALL';
      const otherSelectCode: SelectCode = {
        label: await this.translate.get(other).toPromise(),
        value: {
          id: 0,
          code: await this.translate.get(other).toPromise(),
        },
      };
      this.signTypeOptions.unshift(otherSelectCode);
    } catch (error) {
      this.notificationService.addSingleError(error);
    }
  }

  public async search(event: any) {
    try {
      const data: any[] = await this.supportService.getStreets(this.translate.currentLang, event.query);

      if (data && data.length === 0) {
        this.newData = event.query;
      } else {
        this.newData = null;
      }

      this.results = data;
    } catch (error) {
      this.notificationService.addSingleError(error);
    }
  }

  set editAddress(street: any) {
    this.address = JSON.stringify(street);
  }
  get editAddress(): any {
    let address: any;
    try {
      address = JSON.parse(this.address);
    } catch (e) {
      console.error(e);
      this.notificationService.addSingleError(e);
    }
    return address;
  }

  async submit() {
    if (!this.searchSignForm.invalid) {
      if (this.signSearch.signNotPlacementBool) {
        this.signSearch.signPlacementDateStart = null;
        this.signSearch.signPlacementDateEnd = null;
        this.signSearch.signRemovalDateStart = null;
        this.signSearch.signRemovalDateEnd = null;
      }

      this.errorMessages = [];

      try {
        this.loaderService.showLoader();

        this.signService.signsSelected = [];

        this.signSearchSend.signGestionId = this.signSearch.signGestion.map(data => data.id);
        this.signSearchSend.supportMunicipalityId = this.signSearch.supportMunicipality.map(data => data.id);
        this.signSearchSend.signPlacementId = this.signSearch.signPlacement.map(data => data.id);
        this.signSearchSend.signRequestType = this.signSearch.signRequestType;
        this.signSearchSend.signTypeCategoryId = this.signSearch.signTypeCategory.map(data => data.id);
        this.signSearchSend.signTypeId = this.signSearch.signType.map(data => data.id);
        this.signSearchSend.signSpecificityId = this.signSearch.signSpecificity.map(data => data.id);
        this.signSearchSend.signNotPlacementBool = this.signSearch.signNotPlacementBool;
        this.signSearchSend.signPlacementDateStart = this.signSearch.signPlacementDateStart;
        this.signSearchSend.signPlacementDateEnd = this.signSearch.signPlacementDateEnd;
        this.signSearchSend.signRemovalDateStart = this.signSearch.signRemovalDateStart;
        this.signSearchSend.signRemovalDateEnd = this.signSearch.signRemovalDateEnd;
        this.signSearchSend.signOldBool = this.signSearch.signOldBool;
        this.signSearchSend.supportGestionId = this.signSearch.supportGestion.map(data => data.id);
        this.signSearchSend.supportTypeId = this.signSearch.supportType.map(data => data.id);
        this.signSearchSend.supportSpecificityId = this.signSearch.supportSpecificity.map(data => data.id);
        this.signSearchSend.supportAddress = this.signSearch.supportAddress.map(data => JSON.stringify(data));
        this.signSearchSend.supportNumber = this.signSearch.supportNumber;

        this.signService.signsSelected = await this.signService.searchFull(this.signSearchSend);
        if (this.signService.signsSelected.length === 0) {
          this.displayNoResult = true;
        } else {
          console.log(this.signService.signsSelected.length);
          if (this.signService.signsSelected.length === 1000) {
            const resetSendHeader: string = await this.translate.get('INFORMATION_MESSAGE').toPromise();
            const resetSendMessage: string = await this.translate
              .get('PANEL_SEARCH_SIGN:SEARCH:LOT_OF_RESPONSE')
              .toPromise();

            this.notificationService.addSingleInfo(resetSendHeader, resetSendMessage);
          }
          this.layoutService.leftPanelContent = PanelSignListComponent;
          this.layoutService.leftPanelVisible = true;
        }
      } catch (error) {
        this.notificationService.addSingleError(error);
      }
      finally {
        this.loaderService.hideLoader();
      }
    }
  }

  reset() {
    this.initComponent();
  }

  close() {
    this.layoutService.closeRightPanel();
  }
}
