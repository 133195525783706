import {Injectable} from '@angular/core';
import {Code} from '../../models/code.model';
import {BaseAbstractService} from './base-abstract.service';

@Injectable()
export class SignInterventionService extends BaseAbstractService
{
  _codes:Code[];

}
