import { Component } from '@angular/core';
import { LoggedComponent } from '../../shared/components/logged/logged.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'supports',
  templateUrl: './supports.component.html',
  styleUrls: ['./supports.component.scss']
})
export class SupportsComponent extends LoggedComponent {}
