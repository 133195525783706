import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from '../../../core/services/map.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'language-icon',
  templateUrl: './layout.language-icon.component.html',
  styleUrls: ['./layout.language-icon.component.scss']
})
export class LanguageIconComponent implements OnInit, AfterViewInit {
  activeActionItem: any;
  constructor(
    public userService: UserService,
    public i18n: TranslateService,
    protected mapService: MapService
  ) {}
  ngAfterViewInit(): void {
    if (this.userService.user) {
      this.changeLanguage(this.userService.user.language.toLocaleLowerCase());
    }
  }

  get isMultiLang(): boolean {
    return this.i18n.langs && this.i18n.langs.length > 1;
  }

  onLangChange(selectedLang: string) {
    this.i18n.setDefaultLang(selectedLang);
    this.i18n.use(selectedLang);
    this.userService.changeUserLanguage(selectedLang.toUpperCase());
    this.mapService.language = selectedLang;
  }
  changeLanguage(lang: string) {
    this.onLangChange(lang);
  }
  ngOnInit() {
    this.userService.user$.subscribe(user => {
      if (user) {
        this.i18n.use(user.language);
      }
    });
  }

  get frSelected() {
    return this.i18n.currentLang &&
      this.i18n.currentLang.toLocaleLowerCase() === 'fr'
      ? true
      : false;
  }
}
