<div class="login">
  <div class="card login-panel ui-fluid">
    <div class="ui-g">
      <div class="ui-g-12">

        <img style="width: 100%;" src="assets/images/SPRB_2.png"/>
        <img style="width: 70%;" src="assets/images/CCCR-RCWV.png"/>
      </div>

      <p-messages class="width100" [value]="messageLogin"></p-messages>

      <form #f="ngForm" (ngSubmit)="login()">
        <div class="ui-card-body">
          <div class="ui-g-12">
            <span class="md-inputfield">
              <input id="username"
                     type="text"
                     required pInputText
                     [(ngModel)]="credentials.username"
                     name="username"
                     class="ui-inputtext ui-corner-all ui-state-default ui-widget ui-state-filled">
                <label for="username">{{ 'LOGIN' | translate }}</label>
            </span>
          </div>
          <div class="ui-g-12">
            <span class="md-inputfield">
              <input id="password"
                     type="password"
                     required pInputText
                     [(ngModel)]="credentials.password"
                     name="password"
                     class="ui-inputtext ui-corner-all ui-state-default ui-widget ui-state-filled">
              <label for="password">{{ 'PASSWORD' | translate }}</label>
            </span>
          </div>
          <p-button class="loginButton" label="{{ 'LOG_IN' | translate }}" type="submit"></p-button>
          <span style="float: right; margin-top: 10px; cursor: pointer;" (click)="checkResetPassword()">{{"RESET:MESSAGE:FORGET_MESSAGE" | translate}}</span>
        </div>

      </form>

    </div>
  </div>
  <p-dialog header="{{'RESET:MESSAGE:HEADER' | translate}}" [(visible)]="displayResetMessage" modal="true" width="450">
    <div style="margin-bottom: 15px;"><span>{{"RESET:MESSAGE:CONFIRMATION" | translate}}</span></div>
    <div class="ui-g-12">
        <label for="username">{{ 'RESET:LOGIN' | translate }} : </label>
        <input id="loginReset"
               type="text"
               required pInputText
               [(ngModel)]="resetCredentials.login"
               name="loginReset"
               class="ui-inputtext ui-corner-all ui-state-default ui-widget ui-state-filled">

    </div>
    <div class="ui-g-12">
        <label for="password">{{ 'RESET:EMAIL' | translate }} : </label>
        <input id="emailReset"
               type="text"
               required pInputText
               [(ngModel)]="resetCredentials.mail"
               name="emailReset"
               class="ui-inputtext ui-corner-all ui-state-default ui-widget ui-state-filled">

    </div>
    <div class="ui-g-12">
      <p-button icon="fas fa-unlock" class="right" [ngStyle]="{'margin-left':'5px', 'width':'initial'}" label="{{ 'RESET:BUTTON:RESET' | translate }}" (click)="resetPassword()"></p-button>
      <p-button icon="fa fa-times" class="secondary right" [ngStyle]="{'margin-left':'5px', 'width':'initial'}" label="{{ 'RESET:BUTTON:CANCEL' | translate }}" (click)="cancelResetPassword()"></p-button>
    </div>
  </p-dialog>
</div>
