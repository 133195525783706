import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import 'tinymce/plugins/placeholder/index';

import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import {isEmpty, stringComparer, uniqueId} from '../../../app.helpers';

declare var tinymce: any;

export const RichTextToolbarLayout = {
  MINIMAL: ['bold italic forecolor | alignleft aligncenter alignright'],
  MEDIUM: ['bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'],
  FULL: ['bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent']
};

@Component({
  selector: 'app-rich-text-area',
  templateUrl: './rich-text-area.component.html',
  styleUrls: ['./rich-text-area.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RichTextAreaComponent),
    multi: true
  }]
})
export class RichTextAreaComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  private _value: string;
  private _editor: any;
  private _onChange: (e: any) => void;
  private _onTouched: () => void;

  @HostBinding('class.disabled') private _isDisabled = false;

  @Input() set value(value: string) {
    this.innerHtml = this._value = value;

    if (this._editor) {
      this._editor.setContent(this._value);
    }
  }

  @Input() active = false;
  @Input() toolbar: string[] = RichTextToolbarLayout.MEDIUM;
  @Input() placeholder: string;
  @Input() height:number = 300;

  @Output() blur = new EventEmitter<Event>();
  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('tiny', {static: true}) tinySelector: ElementRef;

  readonly id = `tiny_mce_${uniqueId()}`;
  innerHtml: string;

  constructor(private elementRef: ElementRef) {
  }

  get isContentEdited(): boolean {
    return this.elementRef.nativeElement.getElementsByClassName('x-content-edited').length > 0;
  }

  ngAfterViewInit() {
    this.showPlaceholder();

    if (this.active)
      this._initEditor();
  }

  ngOnDestroy() {
    this._removeEditor();
  }

  writeValue(obj: any) {
    this.value = obj;

    this.showPlaceholder();

    if (isEmpty(obj)) {
      const node = this.tinySelector.nativeElement;
      // Clear the editor div
      if (node && node.firstChild)
        node.removeChild(node.firstChild);
    }

    // if (this._editor && this._value && node && node[0])
    //   this._editor.setContent(node[0], this._value);
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._isDisabled = isDisabled;

    if (this._isDisabled)
      this._removeEditor();
  }

  onClick(e: Event) {
    this._initEditor(true);
  }

  private setContentEdited(edit: boolean): void {
    const classRichTextArea = this.elementRef.nativeElement.getElementsByClassName('x-rich-text-area');
    if (classRichTextArea && classRichTextArea.length === 1) {
      const elm = classRichTextArea[0] as HTMLElement;
      if (edit)
        elm.classList.add('x-content-edited');
      else
        elm.classList.remove('x-content-edited');
    }
  }

  private showPlaceholder(): void {
    const classPlaceholder = this.elementRef.nativeElement.getElementsByClassName('x-rich-text-area-placeholder');
    if (classPlaceholder && classPlaceholder.length === 1) {
      const elm = classPlaceholder[0] as HTMLElement;
      if (isEmpty(this._value) && !this.isContentEdited)
        elm.style.display = 'block';
      else
        elm.style.display = 'none';
    }
  }

  private _initEditor(focus?: boolean) {
    if (this._editor || this._isDisabled)
      return;

    this.setContentEdited(true);
    this.showPlaceholder();

    // Initialization is done in the ngAfterViewInit() to make sure the div element has been assigned with the generated unique Id
    // If the id is fixed, initialization may be done in ngOnInit()
    tinymce.init({
      selector: `#${this.id}`,
      inline: false,
      skin_url: 'tinymce/skins/ui/oxide',
      content_css: 'tinymce/tiny-mce.css',
      language_url: 'tinymce/langs/fr_FR.js',
      language: 'fr_FR',
      placeholder: this.active ? this.placeholder : ' ',
      menubar: false,
      statusbar: false,
      browser_spellcheck: true,
      // forced_root_block : '',
      height: this.height || 300,
      plugins: [
        'placeholder', 'save', 'paste',
        'link', 'image', 'table',
        'code', 'lists',
        'searchreplace',
        'fullscreen'
      ],
      contextmenu: '',
      // toolbar: this.toolbar,
      toolbar:
        'styleselect | undo redo | bold italic forecolor underline strikethrough subscript superscript | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image table | removeformat code fullscreen'
      ,

      // valid_elements: 'h,p[style],strong,em,span[style],a[href],ul,ol,li',
      // valid_styles: {
      //   '*': 'font-size,font-family,color,text-decoration,text-align'
      // },
      // powerpaste_word_import: 'clean',
      // powerpaste_html_import: 'clean',

      // Links
      link_context_toolbar: true,
      default_link_target: '_blank',

      // Drag&drop image & convert to base64
      paste_data_images: true,
      images_upload_handler: (blobInfo, success, failure) => {
        // No upload, just return the blobInfo.blob() as base64 data
        success('data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64());
      },

      setup: editor => {
        this._editor = editor;
        const self = this;

        editor.on('init', () => {
          if (!focus)
            return;

          // self.setTinyHeight();
          editor.focus();

          // Set cursor at the end of the box
          editor.selection.select(editor.getBody(), true);
          editor.selection.collapse(false);
        });

        editor.on('blur ', (evt: Event) => {
          this.blur.emit(evt);

          this._triggerChange();
          this._removeEditor();
        });

        editor.on('remove', (evt: Event) => {
          self.setContentEdited(false);
          self.showPlaceholder();
        });
      }
    });
  }

  private _removeEditor() {
    if (!this._editor || this.active)
      return;

    setTimeout(() => {
      tinymce.remove(this._editor);
      this._editor = undefined;
    }, 100);
  }

  private _triggerChange() {
    if (!this._editor)
      return;

    const newValue = this._editor.getContent();
    if (stringComparer(this._value || '', newValue) === 0)
      return;

    this._value = newValue;

    // Triggers the events for the FormControl and ControlValueAccessor
    // Pay attention that the order of events here is really important !
    // The FormControl change its value with the "_onChange()" and trigger the value change on "_onTouched()"
    if (this._onChange) {
      this._onChange(newValue);

      if (this._onTouched)
        this._onTouched();
    }

    this.valueChange.emit(this._value);
  }
}
