<div class="ui-g">
    <div class="ui-g-12">
        <ul class="topbar-items"  *ngIf="userService.user">
            <profil-icon style="margin: 0 3px"></profil-icon>
            <administration-icon style="margin: 0 3px"></administration-icon>
            <logout-icon style="margin: 0 3px"></logout-icon>
        </ul>
    </div>

    <!-- Language - Custom NSI -->
    <div class="topbar-items ui-g-12">
      <documentation-icon></documentation-icon>
      <helpdesk-icon></helpdesk-icon>
      <language-icon></language-icon>
      <span class="version">V{{version}}</span>
    </div>
</div>
