import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { ContainerContentDirective } from './container.content.directive';
import { Observable, Subscription } from 'rxjs';

export abstract class ContainerComponent implements OnInit, OnDestroy {
  reset: boolean;
  component: ComponentRef<any>;

  @ViewChild(ContainerContentDirective, {static: true}) content: ContainerContentDirective;

  constructor(protected componentFactoryResolver: ComponentFactoryResolver) {}

  abstract get contentHandler(): Observable<any>;
  contentChanges: Subscription;
  setContent(component) {
    const compFact = this.componentFactoryResolver.resolveComponentFactory(
      component
    );
    const viewContainerRef = this.content.viewContainerRef;
    if (this.reset) {
      viewContainerRef.clear();
    }
    if (this.component) {
      // TODO remove the component because the handler is still active
      this.component.destroy();
    }
    this.component = viewContainerRef.createComponent(compFact);
  }

  public destroyComponent() {
    if (this.component) {
      // TODO remove the component because the handler is still active
      this.component.destroy();
    }
  }

  ngOnInit() {
    this.contentChanges = this.contentHandler.subscribe((component: any) => {
      this.setContent(component);
    });
  }
  ngOnDestroy() {
    this.contentChanges.unsubscribe();
  }
}
