<div class="support" style="background-color: white;" *ngIf="editSupport">

  <p-panel class="customPanel">
    <p-header id="panel-support-view-header">
      <title-header [icon]="true" [iconClass]="'fas fa-map-signs'" [title]="'SUPPORT' | translate" [direction]="'RIGHT'"></title-header>
    </p-header>

    <p-tabView class="tabView tabView-no-padding">
      <p-tabPanel header="{{'PANEL:SUPPORT:DETAILS' | translate }}">

        <form [formGroup]="supportForm" [ngClass]="{'view-support': !supportService.editMode, 'edit-support': supportService.editMode}">

          <div class="ui-g">
            <h3 style="margin: 25px 0 0 0;">{{ 'ADMINISTRATIVE_DETAILS' | translate }}</h3>
            <div class="ui-g-12 supportItem">
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'MANAGER' | translate }}<span class="edit"> *</span></label>
                </div>
                <div class="ui-g-7 view">
                  <span *ngIf="supportService.support.gestion">{{supportService.support.gestion.code | translate }}</span>
                </div>
                <div class="ui-g-7 edit">
                  <p-dropdown class="editSupportInput" [options]="supportGestionOptions" [(ngModel)]="editSupport.gestion"
                    [style]="{'width':'100%'}" formControlName="supportGestion" [filter]="true" required></p-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="ui-g">
            <h3 style="margin: 25px 0 0 0;">{{ 'TECHNICAL_DETAILS' | translate }}</h3>
            <div class="ui-g-12 supportItem">

              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'TYPE' | translate }}<span class="edit"> *</span></label>
                </div>
                <div class="ui-g-7 view">
                  <span *ngIf="supportService.support.type">{{supportService.support.type.code | translate }}</span>
                </div>
                <div class="ui-g-7 edit">
                  <p-dropdown class="editSupportInput" [options]="supportTypeOptions" [(ngModel)]="editSupport.type"
                    [style]="{'width':'100%'}" formControlName="supportType" [filter]="true" required></p-dropdown>
                </div>
              </div>

              <!-- OTHER TYPE -->
              <div class="ui-g" [class.hidden]="!editSupport.type || editSupport.type.code !== 'OTHERS'">
                <div class="ui-g-5">
                  <label>{{ 'ANOTHER_TYPE' | translate }}<span class="edit"> *</span></label>
                </div>
                <div class="ui-g-7 view">
                  <span>{{editSupport.typeOthers}}</span>
                </div>
                <div class="ui-g-7 edit">
                  <div class="ui-inputgroup">
                    <input class="editSupportInput"
                           formControlName="typeOthers"
                           pInputText
                           [(ngModel)]="editSupport.typeOthers"
                           [required]="editSupport.type && editSupport.type.code == 'OTHERS'">
                  </div>
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'SPECIFICITY' | translate }}<span class="edit"> *</span></label>
                </div>
                <div class="ui-g-7 view">
                  <span *ngIf="supportService.support.specificity">{{supportService.support.specificity.code |
                    translate }}</span>
                </div>
                <div class="ui-g-7 edit">
                  <p-dropdown class="editSupportInput" [options]="supportSpecificityOptions" [(ngModel)]="editSupport.specificity"
                    [style]="{'width':'100%'}" formControlName="supportSpecificity" [filter]="true" required></p-dropdown>
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'HEIGHT_ABOVE_GROUND' | translate }}<span class="edit"><span class="edit"> *</span></span></label>
                </div>
                <div class="ui-g-7 view">
                  <span>{{supportService.support.heightOffGround}} {{ 'CM' | translate }}</span>
                </div>
                <div class="ui-g-7 edit">
                  <div class="ui-inputgroup">
                    <input type="number" class="editSupportInput" formControlName="heightOffGround" pInputText
                      placeholder="{{ 'CM' | translate }}" [(ngModel)]="editSupport.heightOffGround" required>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ui-g">
            <h3 style="margin: 25px 0 0 0;">{{ 'GEOGRAPHICAL_DETAILS' | translate }}</h3>
            <div class="ui-g-12 supportItem">
              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'ORIENTATION' | translate }}</label>
                </div>
                <div class="ui-g-7 view">
                  <span>{{supportService.support.orientation}}</span>
                </div>
                <div class="ui-g-5 edit">
                  <div class="ui-inputgroup">
                    <input type="number" class="editSupportInput" formControlName="orientation" pInputText [(ngModel)]="editSupport.orientation">
                  </div>
                </div>
                <div class="ui-g-2 edit">
                  <p-button [title]="'ORIENTATION' | translate" class="cerclebutton" icon="fas fa-location-arrow" (onClick)="activeOrientation()"></p-button>
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'ADDRESS' | translate }}<span class="edit"> *</span></label>
                </div>
                <div class="ui-g-7 view">
                  <span>{{getAddressLabel(supportService.support.address)}}</span>
                </div>
                <div class="ui-g-7 edit">
                  <div class="ui-inputgroup">
                    <p-autoComplete (onSelect)="selectAddress($event)" class="editSupportInput" formControlName="address"
                      [suggestions]="results" (completeMethod)="search($event)" [forceSelection]="true" field="label"
                      required>
                    </p-autoComplete>
                  </div>
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-5">
                  <label>{{ 'NUMBER' | translate }}<span class="edit"> *</span></label>
                </div>
                <div class="ui-g-7 view">
                  <span>{{supportService.support.number}}</span>
                </div>
                <div class="ui-g-2 edit">
                  <div class="ui-inputgroup">
                    <input class="editSupportInput" formControlName="number" pInputText [(ngModel)]="editSupport.number"
                      required>
                  </div>
                </div>
                <div class="ui-g-1 edit geobutton">
                  <p-button [title]="'REFRESH' | translate" class="cerclebutton" icon=" fas fa-sync-alt" (onClick)="refreshAddress()"></p-button>
                </div>
                <div class="ui-g-1 edit geobutton">
                  <p-button [title]="'REPOSITIONING' | translate" class="cerclebutton" icon="fas fa-map-marker-alt"
                    (onClick)="activeRepositioning()"></p-button>
                </div>
                <div class="ui-g-1 edit geobutton">
                  <p-button [title]="'TRANSLATION' | translate" class="cerclebutton" icon=" fas fa-arrow-alt-from-left"
                    (onClick)="activeTranslating()"></p-button>
                </div>
              </div>
            </div>
          </div>


          <div class="ui-g">
            <h3 style="margin: 25px 0 0 0;">{{ 'OTHER_INFORMATIONS' | translate }}</h3>
            <div class="ui-g-12 supportItem">


              <div class="ui-g view">
                <div class="ui-g-5">
                  <label>{{ 'REMARKS' | translate }}</label>
                </div>
                <div class="ui-g-7">
                  <span>{{editSupport.note}}</span>
                </div>
              </div>

              <div class="ui-g edit">
                <div class="ui-g-12">
                  <label>{{ 'REMARKS' | translate }}</label>
                </div>
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <textarea class="editSupportInput" formControlName="note" pInputTextarea [rows]="5" [(ngModel)]="editSupport.note"></textarea>
                  </div>
                </div>
              </div>

              <div class="ui-g view">
                <div class="ui-g-5">
                  <label>{{ 'OTHERS' | translate }}</label>
                </div>
                <div class="ui-g-7">
                  <span>{{editSupport.other}}</span>
                </div>
              </div>

              <div class="ui-g edit">
                <div class="ui-g-12">
                  <label>{{ 'OTHERS' | translate }}</label>
                </div>
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <textarea class="editSupportInput" formControlName="other" pInputTextarea [rows]="5" [(ngModel)]="editSupport.other"></textarea>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="ui-g" *ngIf="editSupport.id">
            <h3 style="margin: 25px 0 0 0;">{{ 'PANELS_PRESENT_ON_THIS_SUPPORT' | translate }}<span class="edit"> *</span></h3>
            <div class="ui-g-12 supportItem">
              <edit-sign-order [supportId]="editSupport.id" [edit]="supportService.editMode" [signs]="signOrderService.signs"></edit-sign-order>
            </div>
          </div>



          <div class="ui-g view sticky-bottom-buttons" *ngIf="userService.ROLE_EDIT && canUpdate()">
            <div class="ui-g-12">
              <p-button icon="fa fa-edit" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'EDIT' | translate}}"
                (click)="edit()"></p-button>
            </div>
          </div>

          <div class="ui-g edit" *ngIf="userService.ROLE_EDIT">
            <div class="ui-g-12">
              <p-messages [(value)]="errorMessages"></p-messages>
            </div>
          </div>
          <div class="ui-g edit sticky-bottom-buttons" *ngIf="userService.ROLE_EDIT && canUpdate()">
            <div class="ui-g-12">
              <p-button icon="fa fa-check" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{ (editSupport.id ? 'VALIDATE' : 'NEXT') | translate }}"
                (click)="submit()" [disabled]="supportForm.invalid"></p-button>
              <p-button icon="fa fa-fw fa-times" [ngStyle]="{'margin-left':'5px'}" class="secondary right" label="{{'CANCEL' | translate}}"
                (click)="cancel()"></p-button>
            </div>
          </div>

        </form>

      </p-tabPanel>

      <p-tabPanel *ngIf="editSupport.id" header="{{'PANEL:SUPPORT:OLD_SIGNS' | translate }}">

        <p-dataView [value]="supportService.support.oldSigns" [paginator]="true" [rows]="5" paginatorPosition="bottom"
          class="data-view-paginator data-view-noBorder" *ngIf="supportService.support.id && supportService.support.oldSigns && supportService.support.oldSigns.length > 0">
          <p-header>
            <div class="ui-helper-clearfix">
            </div>
          </p-header>
          <ng-template let-oldSign pTemplate="listItem">
            <div class="ui-g">
              <div class="ui-g-12">
                <p-panel class="panel-task-list-panel" (click)="showOldSign(oldSign)">
                  <p-header>
                    <div><b>{{"DELETE_AT" | translate}} {{oldSign.deleteAt | date:'dd/MM/yyyy'}}</b></div>
                  </p-header>
                  <div class="ui-g">
                    <div class="ui-g-9 ui-g-nopad">
                      <div class="ui-g-12">
                        <div style="font-size:12px;" *ngIf="oldSign.type">{{oldSign.type.code}}<span *ngIf="oldSign.type.label">
                            - {{oldSign.type.label | translate | ellipsis:20}}</span></div>
                      </div>
                    </div>
                    <div class="ui-g-3">
                      <img *ngIf="oldSign.type" src="{{oldSign.type| signTypeImage}}" alt="pic" class="recencement-img" />
                    </div>
                  </div>
                </p-panel>
              </div>
            </div>
          </ng-template>
        </p-dataView>
      </p-tabPanel>
    </p-tabView>
  </p-panel>
</div>
