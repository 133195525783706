<p-button class="full-width" icon="icon fas fa-download" label="{{ 'LAYOUT:UNIQUE_REGULATION:BUTTON:UNIQUE_REGULATION' | translate }}" (onClick)="createUniqueReglement()"></p-button>

<p-dialog id="dialogCreateUniqueRegelement" header="" modal="modal" [(visible)]="displayCreateUniqueRegelement"  [width]="500">
  <p-header>
    {{ 'LAYOUT:UNIQUE_REGULATION:CONFIRME:UNIQUE_REGULATION' | translate }}
  </p-header>
  <div class="ui-g">
    <div class="ui-g-3">
      <span style="float: right; margin-right: 10px;">{{ "LAYOUT:UNIQUE_REGULATION:LABEL:MUNICIPALITY" | translate}} :</span>
    </div>
    <div class="ui-g-9">
      <p-dropdown appendTo="body" [options]="municipalitiesOptions" [(ngModel)]="municipality" [style]="{'width':'100%'}"></p-dropdown>
    </div>
    <div class="ui-g-12" style="padding: 10px 0;">
      <div class="ui-g">
        <div class="ui-g-12">
          <p-button class="right" [ngStyle]="{'margin-left':'5px', 'width':'initial'}" label="{{ 'LAYOUT:UNIQUE_REGULATION:BUTTON:CREATE' | translate }}" (click)="createUniqueReglementMunicipality()"></p-button>
          <p-button class="secondary right" [ngStyle]="{'margin-left':'5px', 'width':'initial'}" label="{{ 'LAYOUT:UNIQUE_REGULATION:BUTTON:CLOSE' | translate }}" (click)="close()"></p-button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
