import {Injectable} from '@angular/core';
import {MeasureService} from '../api/measure.service';
import {Measure, RcMeasure, SignTypeMeasure} from '../../../models/measure.model';
import {ConfirmationService, TreeNode} from 'primeng/api';
import {cloneDeep as _cloneDeep, filter as _filter, findIndex as _findIndex, last as _last} from 'lodash';
import {AsyncSubject, Observable, of} from 'rxjs';
import { catchError, finalize, flatMap, map } from 'rxjs/operators';
import {NotificationService} from '../notification.service';
import {TranslateService} from '@ngx-translate/core';
import {TreeNodeUtils} from 'app/core/utils/treenode-utils';
import {
  MEASURE_DIALOG_MODES,
  MeasureManagementDialogComponent,
} from 'app/features/administration/measure-management-dialog/measure-management-dialog.component';
import {TreeNodeSelectable} from '../../../models/tree-node-selectable';
import {RcService} from '../rc.service';
import { Sign } from '../../../models/sign.model';
import { LoaderService } from '../loader.service';

/**
 * All business logic to manage measure screens
 */
@Injectable()
export class MeasureBl {
  constructor(
    private measureService: MeasureService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private rcService: RcService,
  ) {
    // constructor
  }

  /**
   * Call http services to get all measure from backend then transform them to build treenodes object (primeng object for treeview)
   */
  public getAllMeasuresTreeNodes(): Observable<TreeNode[]> {
    const measures$: Observable<Measure[]> = this.measureService.list();

    return measures$.pipe(
      map(allMeasure => {
        return _filter(allMeasure, measure => !measure.parentId).map(m => this.asNodeRecursively(m, allMeasure));
      }),
      catchError(error => {
        this.notificationService.addSingleError(this.translateService.instant('ERROR_LOADING_MEASURES'));
        return of<TreeNode[]>();
      })
    );
  }

  public getSignTypeMeasureTree(measures: SignTypeMeasure[]) {
    return _filter(measures, measure => !measure.parentId).map(m => this.addSignTypeMeasureTreeNodeRecursively(m, measures));
  }

  private addSignTypeMeasureTreeNodeRecursively(measure: SignTypeMeasure, measures: SignTypeMeasure[]): TreeNodeSelectable {
    return {
      data: measure,
      selected: measure.checked,
      partialSelected: measure.partialChecked,
      children: _filter(measures, c => c.parentId === measure.id).map(c => this.addSignTypeMeasureTreeNodeRecursively(c, measures)),
    } as TreeNodeSelectable;
  }

  public getRcMeasureTreeNode(sign: Sign[], isAbrogation: boolean, selection?: RcMeasure[]): Observable<TreeNodeSelectable[]> {
    if (selection && selection.length) {
      return of(selection.filter(measure => !measure.parentId).map(measure => this.addChildrenAndPreSelect(measure, selection)));
    }

    let getMeasures: Observable<RcMeasure[]>;
    if (isAbrogation) {
      getMeasures = this.rcService.getAbrogations(sign.map(s => s.type.code), sign.map(s => s.id));
    } else {
      getMeasures = this.rcService.getNewClauses(sign.map(s => s.type.code));
    }

    return getMeasures.pipe(
      map(measures => measures.filter(measure => measures.find(m => !m.parentId || measure.measureId === m.parentId)).map(measure => <RcMeasure>{
        ...measure, measureId: measure.id
      })),
      map(allMeasure => allMeasure.filter(measure => !measure.parentId).map(m => this.addChildRecursively(m, allMeasure))),
      catchError(() => {
        this.notificationService.addSingleError(this.translateService.instant('ERROR_LOADING_MEASURES'));
        return of<TreeNode[]>();
      })
    );
  }

  /**
   * Create TreeNode recursively and preselect parents nodes.
   */
  private addChildrenAndPreSelect(measure: RcMeasure, measures: Measure[]): TreeNodeSelectable {
    if (!measures || !measures.length) {
      return;
    }
    const node = <TreeNodeSelectable>{
      data: measure,
      selected: measure && measure.checked
    };
    node.children = measures.filter(child => child.parentId === measure.id).map(child => {
      const childNode = this.addChildrenAndPreSelect(child, measures.filter(m => m.level >= child.level));
      if (childNode.partialSelected || childNode.selected) {
        node.partialSelected = true;
      }
      return childNode;
    });
    return node;
  }

  private addChildRecursively(measure: RcMeasure, measures: RcMeasure[]): TreeNode {
    return {
      data: measure,
      children: _filter(measures, c => c.parentId === measure.measureId && measure.measureId != null).map(c => this.addChildRecursively(c, measures)),
    } as TreeNode;
  }

  /**
   * Confirm warning then aply reorder
   * @param measureOrder UP or DOWN
   * @param measureNodes all the nodes
   * @param selectedNode the selected nodes
   */
  public changeMeasureArticleOrder(measureOrder: MEASURE_ORDER, measureNodes: TreeNode[], selectedNode: TreeNode) {
    return this.confirmReorder().pipe(
      flatMap(result => {
        let ret$ = of(false);
        if (result) {
          ret$ = this.applyReorder(measureOrder, measureNodes, selectedNode);
        }
        return ret$;
      })
    );
  }

  /**
   * Show confirm dialog to delete
   */
  public confirmReorder(): Observable<boolean> {
    const result$ = new AsyncSubject<boolean>();
    const message = this.translateService.instant('REORDDER_MEASURE_CONFIRM_MSG');

    this.confirmationService.confirm({
      message: message,
      accept: () => {
        result$.next(true);
        result$.complete();
      },
      reject: () => {
        result$.next(false);
        result$.complete();
      },
    });
    return result$;
  }

  /**
   * Change measure order
   * @param measureOrder UP or DOWN
   * @param measureNodes all the nodes
   * @param selectedNode the selected nodes
   */
  public applyReorder(
    measureOrder: MEASURE_ORDER,
    measureNodes: TreeNode[],
    selectedNode: TreeNode
  ): Observable<boolean> {
    const currentNode = selectedNode.parent ? selectedNode.parent.children : measureNodes;
    const selectedIndex = _findIndex(currentNode, node => node.data.id === selectedNode.data.id);
    const impactedIndex = measureOrder === MEASURE_ORDER.UP ? selectedIndex - 1 : selectedIndex + 1;

    const selected = currentNode[selectedIndex];
    const impacted = currentNode[impactedIndex];

    const clonedSelected = _cloneDeep(selected);
    const clonedImpacted = _cloneDeep(impacted);

    this.changeOrderRecursively(
      clonedSelected,
      clonedSelected.data.level,
      measureOrder === MEASURE_ORDER.UP ? INDEX_OPERATOR.MINUS : INDEX_OPERATOR.PLUS
    );
    this.changeOrderRecursively(
      clonedImpacted,
      clonedImpacted.data.level,
      measureOrder === MEASURE_ORDER.UP ? INDEX_OPERATOR.PLUS : INDEX_OPERATOR.MINUS
    );

    currentNode[selectedIndex] = clonedImpacted;
    currentNode[impactedIndex] = clonedSelected;
    const flatMeasures = TreeNodeUtils.flattenTreeNode(measureNodes).map(n => n.data);
    return this.saveMeasures(flatMeasures);
  }

  /**
   * Save nodes
   * @param measureNodes All node who need to be saved
   */
  public saveMeasures(measures: Measure[]): Observable<boolean> {
    this.loaderService.showLoader();
    return this.measureService.save(measures).pipe(
      map(sucess => {
        this.notificationService.addSingleSuccess(
          this.translateService.instant('COMMON_SUCESS'),
          this.translateService.instant('MEASURE_SAVED_SUCCESSFULLY_DETAIL')
        );
        return true;
      }),
      catchError(error => {
        this.notificationService.addSingleError(this.translateService.instant('MEASURE_SAVED_ERROR_DETAIL'));
        return of(false);
      }),
      finalize(() => this.loaderService.hideLoader())
    );
  }

  /**
   * Show confirm message then delete measure
   */
  public toggleMeasure(idMeasure: number, disabled: boolean): Observable<boolean> {
    return this.confirmToggleDisable(disabled).pipe(
      flatMap(result => {
        let ret$ = of(false);
        if (result) {
          ret$ = this.applyToggle(idMeasure);
        }
        return ret$;
      })
    );
  }

  /**
   * Show confirm dialog to delete
   */
  public confirmToggleDisable(disabled: boolean): Observable<boolean> {
    const result$ = new AsyncSubject<boolean>();
    this.notificationService.confirm({
      message: disabled ?
        this.translateService.instant('TOGGLE_MEASURE_CONFIRM_MSG_REACTIVATION') :
        this.translateService.instant('TOGGLE_MEASURE_CONFIRM_MSG'),
      accept: () => {
        result$.next(true);
        result$.complete();
      },
      reject: () => {
        result$.next(false);
        result$.complete();
      },
    });
    return result$;
  }

  /**
   * Call http service to delete measure
   * @param idMeasure the id of the measure
   */
  public applyToggle(idMeasure: number): Observable<boolean> {
    return this.measureService.toggleDisable(idMeasure).pipe(
      map(() => {
        this.notificationService.addSingleSuccess(
          this.translateService.instant('COMMON_SUCESS'),
          this.translateService.instant('MEASURE_SAVED_SUCCESSFULLY_DETAIL')
        );
        return true;
      }),
      catchError(error => {
        this.notificationService.addSingleError(this.translateService.instant('MEASURE_SAVED_ERROR_DETAIL'));
        return of(false);
      })
    );
  }

  /**
   * Open measure edit/add dialog
   * @param node the selected node
   * @param nodes all nodes
   * @param mode INSERT || ADD || EDIT
   */
  public insertMeasure(node: TreeNode, nodes: TreeNode[], mode: MEASURE_DIALOG_MODES): Observable<boolean> {
    const newMeasure =
      mode === MEASURE_DIALOG_MODES.EDIT
        ? node.data
        : this.initNewMeasure(
        mode === MEASURE_DIALOG_MODES.INSERT ? node.children : this.getBrotherNodes(node, nodes),
        node
        );

    if (newMeasure.level > 4) {
      return of();
    }

    const ref = this.notificationService.openDialog(MeasureManagementDialogComponent, {
      data: {
        mode: mode,
        parentNode: node,
        allNodes: nodes,
        measure: newMeasure,
      },
      header:
        mode === MEASURE_DIALOG_MODES.INSERT
          ? this.translateService.instant('DIALOG_MEASURE_TITLE_INSERT')
          : mode === MEASURE_DIALOG_MODES.ADD
          ? this.translateService.instant('DIALOG_MEASURE_TITLE_ADD')
          : this.translateService.instant('DIALOG_MEASURE_TITLE_EDIT'),
      width: '40%',
    });
    return ref.onClose;
  }

  public addMeasure(allNodes: TreeNode[], measure: Measure): Observable<boolean> {
    const flatMeasures = TreeNodeUtils.flattenTreeNode(allNodes).map(n => n.data);
    flatMeasures.push(measure);
    return this.saveMeasures(flatMeasures);
  }

  /**
   * Recursive method to build treenodes childreen
   */
  private asNodeRecursively(measure: Measure, measures: Measure[]): TreeNode {
    return {
      data: measure,
      children: _filter(measures, c => c.parentId === measure.id).map(c => this.asNodeRecursively(c, measures)),
    } as TreeNode;
  }

  /**
   * Update article ids and call recursively for children
   * @param measure the current node
   * @param changedNodeLevel the level (1, 2, 3)
   * @param operator operator applied
   */
  private changeOrderRecursively(measure: TreeNode, changedNodeLevel: number, operator: INDEX_OPERATOR) {
    const splittedId = measure.data.articleId.split('.');

    splittedId[changedNodeLevel - 1] =
      operator === INDEX_OPERATOR.PLUS
        ? parseInt(splittedId[changedNodeLevel - 1], 10) + 1
        : parseInt(splittedId[changedNodeLevel - 1], 10) - 1;

    measure.data.articleId = splittedId.join('.');
    measure.children.map(c => this.changeOrderRecursively(c, changedNodeLevel, operator));
    return measure;
  }

  /**
   * initialize a new Measure for insert or add
   * @param nodes all nodes
   * @param selectedNode the selected node
   */
  private initNewMeasure(nodes: TreeNode[], selectedNode: TreeNode): Measure {
    const result = {} as Measure;
    let node = _last(nodes);
    if (node) {
      // means new array of children
      const level = node.data.level;
      const splittedId = node.data.articleId.split('.');
      splittedId[level - 1] = parseInt(splittedId[level - 1], 10) + 1;
      result.articleId = splittedId.join('.');
      result.level = node.data.level;
      result.parentId = node.data.parentId;
    } else {
      result.articleId = selectedNode.data.articleId + '.1';
      result.level = selectedNode.data.level + 1;
      result.parentId = selectedNode.data.id;
      node = selectedNode;
    }
    result.num = parseInt(node.data.num, 10) + 1;
    result.disabled = node.data.disabled ? node.data.disabled : false;
    result.autoInclude = node.data.autoInclude;
    return result;
  }

  /**
   * Return this brothers of the selected node or root node if none
   */
  private getBrotherNodes(selecteNode: TreeNode, nodes: TreeNode[]) {
    if (selecteNode && selecteNode.parent) {
      return selecteNode.parent.children;
    }
    return nodes;
  }
}

export class MEASURE_ORDER {
  static UP = 'UP';
  static DOWN = 'DOWN';
}

export class INDEX_OPERATOR {
  static MINUS = 'MINUS';
  static PLUS = 'PLUS';
}
