import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import { ShowUserComponent } from '../../administration/show-user/show-user.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'profil-icon',
  templateUrl: './layout.profil-icon.component.html',
  styleUrls: ['./layout.profil-icon.component.scss']
})
export class ProfilIconComponent implements OnInit {
  constructor(
    public userService: UserService,
    private layoutService: LayoutService
  ) {}

  async showProfile() {
    if (
      this.layoutService.centerPanelComponent !==
      this.layoutService.CENTRAL_PANEL_PROFIL
    ) {
      this.layoutService.closeRightPanel();
      this.layoutService.closeLeftPanel();
      this.userService.manageUserMode = false;
      this.userService.selectedUser = this.userService.user;
      this.layoutService.centerPanelContent = ShowUserComponent;
      this.layoutService.centerPanelVisible = true;
    }
  }
  ngOnInit() {}
}
