import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Support } from '../../models/support.model';
import { Extent, MapMgr, View, Viewer, VMap } from '@nsi/gis-core';
import Utils from '../../shared/services/utils';
import { WMTS } from '@nsi/gis-core/dist/layer';

@Injectable()
export class MapService {
  _addSignModeActivated: boolean;
  _selectSignModeActivated: boolean;
  _highlightedSupports: Support[];
  _selectedSupports: Support[];

  _viewer: Viewer;
  private _language: string;
  private _languageSource: Subject<string> = new Subject<string>();
  private _languageHandler: Observable<string> = this._languageSource.asObservable();

  private addSignModeActivatedSource = new Subject<boolean>();

  addSignModeActivatedHandler: Observable<boolean> = <Observable<boolean>>(
    this.addSignModeActivatedSource.asObservable()
  );

  private highlightedSupportsSource = new Subject<Support[]>();
  highlightedSupportsHandler: Observable<Support[]> = <Observable<Support[]>>(
    this.highlightedSupportsSource.asObservable()
  );

  private selectedSupportsSource = new Subject<Support[]>();
  selectedSupportsHandler: Observable<Support[]> = <Observable<Support[]>>this.highlightedSupportsSource.asObservable();

  private duplicateModeSource = new Subject<boolean>();
  duplicateModeHandler: Observable<boolean> = <Observable<boolean>>(
    this.duplicateModeSource.asObservable()
  );

  set viewer(viewer: Viewer) {
    this._viewer = viewer;
  }
  get viewer(): Viewer {
    return this._viewer;
  }

  get mapManager(): MapMgr {
    return this.viewer.mapMgr;
  }
  get map(): VMap {
    return this.mapManager.map;
  }
  get view(): View {
    return this.mapManager.view;
  }
  get olMap() {
    return this.map.olMap;
  }
  get olView() {
    return this.view.olView;
  }

  get mapClickedListener(): Observable<any> {
    return this.map.getEventListener('click');
  }
  get mapContextMenuListener(): Observable<any> {
    return this.map.getEventListener('contextmenu');
  }

  refresh() {
    this.map.refresh();
  }

  zoomToFeatures(extent) {
    this.olView.fit(extent);
  }

  zoomToSupport(support: Support) {
    const point = Utils.getGeomPointFromString(support.geom);
    this.view.extent = new Extent(point.getExtent(), {
      projection: 'EPSG:31370',
    });
    this.highlightedSupports = [support];
  }
  set highlightedSupports(val: Support[]) {
    this._highlightedSupports = val;
    this.highlightedSupportsSource.next(val);
  }
  get highlightedSupports(): Support[] {
    return this._highlightedSupports;
  }

  set selectedSupports(val: Support[]) {
    this._selectedSupports = val;
    this.selectedSupportsSource.next(val);
  }
  get selectedSupports(): Support[] {
    return this._selectedSupports;
  }

  set addSignModeActivated(val: boolean) {
    this._addSignModeActivated = val;
  }
  get addSignModeActivated(): boolean {
    return this._addSignModeActivated;
  }

  set selectSignModeActivated(val: boolean) {
    this._selectSignModeActivated = val;
  }
  get selectSignModeActivated(): boolean {
    return this._selectSignModeActivated;
  }

  _duplicateModeActivated: boolean;
  set duplicateModeActivated(val: boolean) {
    this._duplicateModeActivated = val;
    this.duplicateModeSource.next(val);
  }

  get duplicateModeActivated(): boolean {
    return this._duplicateModeActivated;
  }

  /**
   * Getter language
   * @return {string}
   */
  public get language(): string {
    return this._language;
  }

  /**
   * Setter language
   * @param {string} value
   */
  public set language(value: string) {
    this._language = value.toLowerCase();
    if (this.viewer && this.viewer.mapMgr.basemaps.length > 0) {
      this.viewer.language = this.language;
      const urbisPlan = <WMTS>this.viewer.mapMgr.basemaps.find(basemap => {
        return basemap.id === 'urbis_plan';
      });
      urbisPlan.child.id = 'urbis' + this.language.toUpperCase();
      urbisPlan.applyParams();
    }

    this.languageSource.next(value);
  }

  /**
   * Getter languageSource
   * @return {Subject<string> }
   */
  public get languageSource(): Subject<string> {
    return this._languageSource;
  }

  /**
   * Setter languageSource
   * @param {Subject<string> } value
   */
  public set languageSource(value: Subject<string>) {
    this._languageSource = value;
  }

  /**
   * Getter languageHandler
   * @return {Observable<string> }
   */
  public get languageHandler(): Observable<string> {
    return this._languageHandler;
  }

  /**
   * Setter languageHandler
   * @param {Observable<string> } value
   */
  public set languageHandler(value: Observable<string>) {
    this._languageHandler = value;
  }

  constructor() {}
}
