import {Component, Injector, OnInit} from '@angular/core';
import {LoggedComponent} from '../../../shared/components/logged/logged.component';
import {UserService} from '../../../core/services/user.service';
import {User} from '../../../models/user.model';
import {LayoutService} from '../../../core/services/layout.service';
import {ChangePasswordComponent} from '../change-password/change-password.component';
import {SelectItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../core/services/notification.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MunicipalityService} from '../../../core/services/municipality.service';
import {ROLES} from '../../../models/role.model';
import {LoaderService} from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'show-user',
  templateUrl: './show-user.component.html',
  styleUrls: ['./show-user.component.scss']
})
export class ShowUserComponent extends LoggedComponent implements OnInit {
  userCopy: User;
  loaded = false;
  languagesOptions: SelectItem[] = [];
  rolesOptions: SelectItem[] = [];
  municipalityOptions: SelectItem[] = [];
  translate: TranslateService = this.injector.get(TranslateService);

  userForm: FormGroup;
  surnameCtrl: FormControl;
  nameCtrl: FormControl;
  loginCtrl: FormControl;
  emailCtrl: FormControl;
  municipalityCtrl: FormControl;
  languageCtrl: FormControl;
  roleCtrl: FormControl;
  mobileCtrl: FormControl;
  isActiveCtrl: FormControl;
  desktopCtrl: FormControl;
  adminCtrl: FormControl;
  public MUNICIPALITY_AGENT_ROLE = ROLES.MUNICIPALITY_AGENT;

  constructor(
    userService: UserService,
    private fb: FormBuilder,
    private injector: Injector,
    private municipalityService: MunicipalityService,
    private layoutService: LayoutService,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
  ) {
    super(userService);
  }
  _user;
  get user(): User {
    return this._user;
  }
  set user(user: User) {
    this._user = user;
  }

  get editMode(): boolean {
    return this.userService.editMode;
  }

  async ngOnInit() {
    this.surnameCtrl = this.fb.control('', Validators.required);
    this.nameCtrl = this.fb.control('', Validators.required);
    this.loginCtrl = this.fb.control('', Validators.required);
    this.emailCtrl = this.fb.control('', [
      Validators.required,
      Validators.email
    ]);
    this.municipalityCtrl = this.fb.control('');
    this.languageCtrl = this.fb.control('', Validators.required);
    this.roleCtrl = this.fb.control('', Validators.required);
    this.mobileCtrl = this.fb.control('');
    this.isActiveCtrl = this.fb.control('');
    this.desktopCtrl = this.fb.control('');
    this.adminCtrl = this.fb.control('');

    this.userForm = this.fb.group({
      surname: this.surnameCtrl,
      name: this.nameCtrl,
      login: this.loginCtrl,
      email: this.emailCtrl,
      municipality: this.municipalityCtrl,
      language: this.languageCtrl,
      role: this.roleCtrl,
      mobile: this.mobileCtrl,
      isActive: this.isActiveCtrl,
      desktop: this.desktopCtrl,
      admin: this.adminCtrl
    });
    this.loadCodes();
    this.user = Object.assign({}, this.userService.selectedUser);
  }

  async loadCodes() {
    this.loaderService.showLoader();

    try {
      await this.municipalityService.findAll();
      await this.userService.findAllRoles();
      this.languagesOptions = [];
      await this.userService.languages.forEach(async languageCode => {
        const trad = await this.translate.get(languageCode).toPromise();
        this.languagesOptions.push({ label: trad, value: languageCode });
      });
      this.rolesOptions = [];
      await this.userService.roles.forEach(async role => {
        const trad = await this.translate.get(role.code).toPromise();
        this.rolesOptions.push({ label: trad, value: role });
      });
      this.municipalityOptions = [];
      this.municipalityOptions.push({ label: '', value: null });
      await this.municipalityService.municipality.forEach(async mun => {
        this.municipalityOptions.push({ label: mun.name, value: mun });
      });
      this.municipalityOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        } else if (a.label > b.label) {
          return 1;
        } else {
          return 0;
        }
      });
      this.userCopy = this.userService.selectedUser;
      this.loaded = true;
    } catch (error) {

    } finally {
      this.loaderService.hideLoader();
    }
  }

  async switchMode(value) {
    this.userService.editMode = value;
  }

  async save() {
    this.loaderService.showLoader();

    try {
      console.info('user', this.user);
      this.userService.selectedUser = await this.userService.updateUser(
        this.user
      );

      this.user = Object.assign({}, this.userService.selectedUser);
      this.userService.editMode = false;
      await this.notificationService.addSingleSuccess(
        this.translateService.instant('COMMON_SUCESS'),
        this.translateService.instant('USER_SAVE_SUCESS')
      );
    } catch (error) {
      this.notificationService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  cancel() {
    this.userService.editMode = false;
    this.userService.selectedUser = this.userCopy;
  }

  changePassword() {
    this.layoutService.centerPanelContent = ChangePasswordComponent;
  }

  backToUserManagement() {
    this.userService.showList = true;
  }
}
