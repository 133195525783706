<div class="ui-g ui-g-nopad">
  <div class="ui-g-12">
    <div class="ui-g-3 tmpl-tab-horizontal">
      <div *ngFor="let param of richTextParameters" class="tmpl-tab-horizontal-element" (click)="select(param)" [ngClass]="{'active': param == richTextParameter.code, 'disabled': editMode}">{{param|translate}}</div>
    </div>
    <div class="ui-g-9 tmpl-content">
      <p-panel header="{{richTextParameter.code|translate}}" *ngIf="richTextParameter">
        <p [innerHTML]="richTextParameter.value | safeHtml" *ngIf="!editMode && richTextParameter.value"></p>
        <div class="md-inputfield">
          <app-rich-text-area *ngIf="editMode" [active]="true" [(ngModel)]="richTextParameter.value"></app-rich-text-area>
        </div>
      </p-panel>
      <div style="text-align: right; margin-top: 10px;">
        <p-button *ngIf="!editMode" icon="fas fa-edit" label="{{'EDIT'|translate}}" (click)="edit()"></p-button>
        <p-button *ngIf="editMode" class="secondary" icon="fas fa-times" (click)="cancelEdit()" label="{{'CANCEL'|translate}}"></p-button>
        <p-button *ngIf="editMode" icon="fas fa-save" label="{{'SAVE'|translate}}" (click)="save()"></p-button>
      </div>
      <br>
    </div>
  </div>
</div>
