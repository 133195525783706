import { Component, Injector, OnInit } from '@angular/core';
import { RcService } from '../../../core/services/rc.service';
import { LayoutService } from '../../../core/services/layout.service';
import { Rc, RC_STATUS } from '../../../models/rc.model';
import { RcComponent } from '../rc.component';
import { TranslateService } from '@ngx-translate/core';
import { TaskService } from '../../../core/services/task.service';
import { TaskViewComponent } from '../../tasks/task-view/task-view.component';
import { NotificationService } from '../../../core/services/notification.service';
import { UserService } from '../../../core/services/user.service';
import { ROLES } from '../../../models/role.model';
import { LoaderService } from 'app/core/services/loader.service';
import { tap } from 'rxjs/operators';
import { CorruptedTaskComponent } from '../../tasks/task-view/corrupted-task/corrupted-task.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'panel-search-rc-result',
  templateUrl: './panel-search-rc-result.component.html',
  styleUrls: ['./panel-search-rc-result.component.scss'],
})
export class PanelSearchRcResultComponent implements OnInit {
  public readonly ROLES = ROLES;
  translate: TranslateService = this.injector.get(TranslateService);

  constructor(
    private layoutService: LayoutService,
    public rcService: RcService,
    private errorService: NotificationService,
    private taskService: TaskService,
    public userService: UserService,
    private injector: Injector,
    private loaderService: LoaderService,
  ) {
  }

  async ngOnInit() {
  }

  displayRC(rc: Rc) {
    if (rc.status === RC_STATUS.draft) {
      this.rcService.getRc(rc.id).pipe(
        tap(rc => this.showRC(rc)),
      ).subscribe();
    } else {
      this.showTaskRC(rc);
    }
  }

  async showTaskRC(rc: Rc) {
    try {
      this.loaderService.showLoader();

      const rcWithLostTask: boolean  = rc.processDefinitionId === null || rc.processInstanceId === null;

      if (rcWithLostTask) {
        this.layoutService.rightPanelContent = CorruptedTaskComponent;
        this.layoutService.rightPanelVisible = true;
        return;
      }

      this.taskService.rcTask = await this.rcService.getRc(rc.id).toPromise();
      this.layoutService.rightPanelContent = TaskViewComponent;
      this.layoutService.rightPanelVisible = true;
    } catch (error) {
      this.errorService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async showRC(rc: Rc) {
    try {
      this.loaderService.showLoader();

      this.rcService.rc = rc;
      this.rcService.editMode = this.canEditRc(rc);
      this.layoutService.rightPanelContent = RcComponent;
      this.layoutService.rightPanelFull = true;
      this.layoutService.rightPanelStyle = { width: '800px' };
      this.layoutService.rightPanelVisible = true;
    } catch (error) {
      this.errorService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  canEditRc(rc: Rc) {
    if (rc.id == null) {
      return true;
    } else if (rc.isRegional === false) {
      return this.userService.municipality != null && this.userService.municipality.id === rc.municipalityId
        && this.userService.hasRole(this.userService.agentCode);
    } else {
      return this.userService.hasRole(this.userService.sprbCode);
    }
  }

  close() {
    this.layoutService.closeLeftPanel();
  }
}
