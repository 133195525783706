import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corrupted-task',
  template: `
    <p style='text-align: center; font-size: large;'>
      {{ 'TASK_NOT_FOUND' | translate }}
    </p>
  `,
  styles: []
})
export class CorruptedTaskComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
