<p-toast [style]="{ marginTop: '80px' }" styleClass="custom-toast" key="custom" position="bottom-center"
         [baseZIndex]="9999"></p-toast>

<p-confirmDialog class="confirmDialog" header="{{ 'CONFIRMATION' | translate }}" appendTo="body" message="" #deletesign
                 [baseZIndex]="9999">
  <p-footer>
    <div>
      <p-button
        icon="fa fa-check"
        [ngStyle]="{ 'margin-left': '5px' }"
        class="right"
        label="{{ 'YES' | translate }}"
        (click)="deletesign.accept()"
      ></p-button>
      <p-button
        icon="fa fa-times"
        [ngStyle]="{ 'margin-left': '5px' }"
        class="secondary right"
        label="{{ 'NO' | translate }}"
        (click)="deletesign.reject()"
      ></p-button>
    </div>
  </p-footer>
</p-confirmDialog>
