import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../core/services/layout.service';

@Component({
  selector: 'app-center-panel',
  templateUrl: './center-panel.component.html',
  styleUrls: ['./center-panel.component.scss']
})
export class CenterPanelComponent implements OnInit {
  constructor(public layoutService: LayoutService) {}

  ngOnInit() {}

  close() {
    this.layoutService.closeCenterPanel();
  }
}
