<div class="block100 sprb layout-wrapper" [ngClass]="{ 'layout-compact': layoutCompact }" (click)="onLayoutClick()">
    <div
        #layoutContainer
        class="block100 nsi-layout layout-container"
        [ngClass]="{
            'menu-layout-static': !isOverlay(),
            'menu-layout-overlay': isOverlay(),
            'layout-menu-overlay-active': overlayMenuActive,
            'menu-layout-horizontal': isHorizontal(),
            'menu-layout-slim': isSlim(),
            'left-layout-menu-visible': layoutService.leftPanelVisible,
            'left-layout-menu-full': layoutService.leftPanelFull,
            'right-layout-menu-visible': layoutService.rightPanelVisible,
            'right-layout-menu-full': layoutService.rightPanelFull
        }"
    >
        <app-header></app-header>

        <div class="block100 layout-main">
            <app-center-panel></app-center-panel>
            <p-sidebar
                class="block100 sidebarLeft left-layout-menu layout-menu"
                [visible]="layoutService.leftPanelVisible"
                [fullScreen]="layoutService.leftPanelFull"
                position="left"
            >
                <div class="expandLeft" (click)="hideLeft()" *ngIf="!layoutService.leftPanelClosed">
                    <div style="margin: auto" *ngIf="!layoutService.leftPanelVisible">
                        <i style="margin-top: 15px; margin-left: 3px;" class="icon fal fa-chevron-double-right"></i>
                    </div>
                    <div style="margin: auto" *ngIf="layoutService.leftPanelVisible">
                        <i style="margin-top: 15px; margin-left: 3px;" class="icon fal fa-chevron-double-left"></i>
                    </div>
                </div>
                <p-scrollPanel #scrollPanel [style]="{ height: '100%' }" styleClass="scrollLeftPanel">
                    <layout-left-panel></layout-left-panel>
                </p-scrollPanel>
            </p-sidebar>

            <div class="block100 layout-content">
                <router-outlet [style]="{ height: '100%' }"></router-outlet>
                <app-footer *ngIf="layout.footerView"></app-footer>
            </div>
            <p-sidebar
                class="block100 sidebarRight right-layout-menu layout-menu"
                [visible]="layoutService.rightPanelVisible"
                [fullScreen]="layoutService.rightPanelFull"
                [style]="layoutService.rightPanelStyle"
                position="right"
            >
                <div class="expandRight" (click)="hideRight()" *ngIf="!layoutService.rightPanelClosed">
                    <div style="margin: auto" *ngIf="!layoutService.rightPanelVisible">
                        <i style="margin-top: 15px; margin-left: 3px;" class="icon fal fa-chevron-double-left"></i>
                    </div>
                    <div style="margin: auto" *ngIf="layoutService.rightPanelVisible">
                        <i style="margin-top: 15px; margin-left: 3px;" class="icon fal fa-chevron-double-right"></i>
                    </div>
                </div>
                <p-scrollPanel #scrollPanel [style]="{ height: '100%' }" styleClass="scrollRightPanel">
                    <layout-right-panel></layout-right-panel>
                </p-scrollPanel>
            </p-sidebar>
        </div>
        <div class="layout-mask"></div>
    </div>
</div>
<loader></loader>
<app-notification></app-notification>
