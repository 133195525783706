import { Component, OnInit } from '@angular/core';
import { LoggedComponent } from '../../../shared/components/logged/logged.component';
import { UserService } from '../../../core/services/user.service';
import { SignTypeService } from '../../../core/services/sign-type.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sign-type-management',
  templateUrl: './sign-type-management.component.html',
  styleUrls: ['./sign-type-management.component.scss']
})
export class SignTypeManagementComponent extends LoggedComponent
  implements OnInit {
  constructor(
    userService: UserService,
    public signTypeService: SignTypeService
  ) {
    super(userService);
  }

  ngOnInit() {}
}
