import { Code, SignType } from './code.model';
import { Support } from './support.model';
import { Rc, RC_STATUS } from './rc.model';
import { Picture } from './picture.model';

export enum SIGN_MOBILE_STATUS {
  add = 'ADD',
  edit = 'EDIT',
  delete = 'DELETE'
}
export interface Sign {
  id?: number;
  type?: SignType;
  address?: string;
  supportId?: number;
  order?: number;
  gestion?: Code;
  placement?: Code;
  requestType?: string;
  format?: Code;
  specificity?: Code;
  note?: string;
  other?: string;
  placementDate?: Date;
  removalDate?: Date;
  placementUser?: string;
  removalUser?: string;
  placementNote?: string;
  removalNote?: string;
  imgs?: Picture[];
  deletedImgs?: Picture[];
  placementImgs?: any[];
  removalImgs?: any[];
  genericTextFr?: string;
  genericTextNl?: string;
  support?: Support;
  deleteAt?: Date;
  rcs?: Rc[];
  signStatus?: Code;
  mobileStatus?:string;
  mobileStatusDate?:Date;
}

export class SignHelper {
  static getFullType(sign: Sign) {
    let name = '';
    if (sign.type) {
      name += sign.type.code;
      if (sign.type.typeCategorie) {
        name += '. ' + sign.type.typeCategorie.code;
      }
    }
    return name;
  }

  static getStatus(sign): number {
    if (sign.rcs) {
      if (sign.rcs.find(rc => rc.status === RC_STATUS.pending)) {
        return 0;
      }
      if (sign.rcs.find(rc => rc.status === RC_STATUS.active)) {
        return 1;
      }
      if (sign.rcs.find(rc => rc.status === RC_STATUS.old)) {
        return -1;
      }
    }
    return null;
  }

  static isGenerique(sign: Sign): boolean {
    if (sign.type) {
      return sign.type.generic;
    }
    return false;
  }

  static duplicateSignFrom(sign: Sign) : Sign {
    return {
      gestion: sign.gestion,
      placement: sign.placement,
      requestType: sign.requestType,
      type: sign.type,
      format: sign.format,
      specificity: sign.specificity,
      genericTextFr: sign.genericTextFr,
      genericTextNl: sign.genericTextNl,
      note: sign.note,
      other: sign.other,
    };
  }
}
