import { Injectable } from '@angular/core';
import { BaseAbstractService } from './base-abstract.service';
import { environment } from '../../../environments/environment';
import { Municipality } from '../../models/municipality.models';
import * as ol from 'ol';
import * as olGeom from 'ol/geom';

@Injectable()
export class MunicipalityService extends BaseAbstractService {
  _municipality: Municipality[];
  static parseFeature(municipality: Municipality): ol.Feature {
    const geom = municipality
      ? MunicipalityService.parseGeom(municipality)
      : null;
    return geom ? new ol.Feature(geom) : null;
  }
  static parseGeom(municipality: Municipality) {
    let geom = null;
    try {
      geom = new olGeom.MultiPolygon(JSON.parse(municipality.geom).coordinates);
    } catch (e) {
      console.error(e);
    }
    return geom;
  }
  get municipality(): Municipality[] {
    return this._municipality;
  }

  async getMunicipalityForId(id: number): Promise<Municipality> {
    await this.findAll();
    return this.municipality.find(muni => muni.id === id);
  }

  get url(): string {
    return (
      environment.backendURL +
      environment.identityPath +
      this.configService.appConfig.apiUrl +
      'User/'
    );
  }

  async findAll(): Promise<Municipality[]> {
    if (!this._municipality) {
      this._municipality = await this.get<Municipality[]>(
        `${this.url}municipalities/all`
      );
    }
    return this._municipality;
  }
}
