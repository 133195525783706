import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {LoggedComponent} from '../../shared/components/logged/logged.component';
import {UserService} from '../../core/services/user.service';
import {LayoutService} from '../../core/services/layout.service';
import {NgViewerComponent} from '@nsi/gis-viewer-ui';
import {PanelSupportListComponent} from '../supports/panel-support-list/panel-support-list.component';
import {SupportService} from '../../core/services/support.service';
import {MapService} from '../../core/services/map.service';
import {MenuItem} from 'primeng/api';
import {environment} from 'environments/environment';
import {ContextMenu} from 'primeng/primeng';
import {PanelSignListComponent} from '../signs/panel-sign-list/panel-sign-list.component';
import {GeoserverService} from '../../core/services/geoserver.service';
import {Support, SupportHelper} from '../../models/support.model';
import {SupportComponent} from '../supports/support/support.component';
import {TaskService} from '../../core/services/task.service';
import {TranslateService} from '@ngx-translate/core';
import * as olLayer from 'ol/layer';
import * as olSource from 'ol/source';
import * as olInteraction from 'ol/interaction';
import * as olStyle from 'ol/style';
import {SignService} from '../../core/services/sign.service';
import {MunicipalityService} from '../../core/services/municipality.service';
import {NotificationService} from '../../core/services/notification.service';
import {Extent} from '@nsi/gis-core';
import {ParameterService} from '../../core/services/parameter.service';
import {filter} from 'rxjs/operators';
import {LoaderService} from 'app/core/services/loader.service';
import { SignHelper } from 'app/models/sign.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent extends LoggedComponent implements OnInit, AfterViewInit {
  translate: TranslateService = this.injector.get(TranslateService);

  _ngViewer: NgViewerComponent;

  @ViewChild('EsignViewer', {static: true})
  set ngViewer(val: NgViewerComponent) {
    this._ngViewer = val;
    this._ngViewer.language = this.userService.user ? this.userService.user.language : 'fr';
    this.geoserverService.ngViewer = this.ngViewer;
    this._ngViewer.initializedHandler.subscribe(() => {
      if (this.geoserverService.ngViewer.initialized) {
        this.mapService.viewer = this.geoserverService.viewer;
        this.changeDetector.detectChanges();
      }
    });
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    switch (event.key) {
      case "Escape":
        if(this.mapService.duplicateModeActivated) {
          this.disableDuplicateMode();
        }
        break;
      default:
        return;
    }
  }

  get ngViewer(): NgViewerComponent {
    return this._ngViewer;
  }

  displayInfoAddSign = false;
  displayAddSign = false;
  displayDisableDuplicateMode = false;

  showTask = true;

  get viewerConfig(): any {
    const conf = {
      viewer: './assets/viewer-config/viewer.json',
      modules: './assets/viewer-config/modules.json',
      layers: './assets/viewer-config/' + environment.code + '/layers.json',
    };
    return conf;
  }

  constructor(
    public userService: UserService,
    public layoutService: LayoutService,
    protected supportService: SupportService,
    private errorService: NotificationService,
    protected taskService: TaskService,
    protected signService: SignService,
    private mapService: MapService,
    private geoserverService: GeoserverService,
    private municipalityService: MunicipalityService,
    private injector: Injector,
    public parameterService: ParameterService,
    public changeDetector: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {
    super(userService);

    this.mapService.addSignModeActivated = false;
    this.layoutService.closeRightPanel();
    this.layoutService.closeLeftPanel();

    this.mapService.highlightedSupportsHandler.subscribe(supports => {
      this.highlightVectorSource.clear();
      const features = supports.map(support => {
        return SupportHelper.getFeature(support);
      });
      this.highlightVectorLayer.setZIndex(16);
      this.highlightVectorSource.addFeatures(features);
    });

    this.signService.memorizedSignHandler.subscribe(sign => {
      if(sign != null) {
        this.layoutService.rightPanelVisible = false;
        this.mapService.duplicateModeActivated = true;
        this.displayDisableDuplicateMode = true;
        this.activatePointSelect();
      } else {
        this.mapService.duplicateModeActivated = false;
        this.displayDisableDuplicateMode = false;
        this.deactivatePointSelect();
      }
    });

    this.signService.signsSelectedHandler.subscribe(signs => {
      this.selectedVectorSource.clear();
      const features = signs.map(sign => {
        return SupportHelper.getFeature(sign.support);
      });
      this.selectedVectorLayer.setZIndex(15);
      this.selectedVectorSource.addFeatures(features);
    });
  }

  selectedVectorSource = new olSource.Vector({
    wrapX: false,
  });
  selectedVectorLayer = new olLayer.Vector({
    source: this.selectedVectorSource,
    style: new olStyle.Style({
      image: new olStyle.Circle({
        radius: 5,
        fill: new olStyle.Fill({color: '#ffee00'}),
        stroke: new olStyle.Stroke({color: '#ffee00', width: 1}),
      }),
    }),
  });
  highlightVectorSource = new olSource.Vector({
    wrapX: false,
  });
  highlightVectorLayer = new olLayer.Vector({
    source: this.highlightVectorSource,
    style: new olStyle.Style({
      image: new olStyle.Circle({
        radius: 10,
        fill: new olStyle.Fill({color: '#00ffff'}),
        stroke: new olStyle.Stroke({color: '#00ffff', width: 1}),
      }),
    }),
  });

  municipalitiesVectorSource = new olSource.Vector({
    wrapX: false,
  });
  municipalitiesVectorLayer = new olLayer.Vector({
    source: this.municipalitiesVectorSource,
    style: new olStyle.Style({
      stroke: new olStyle.Stroke({
        color: '#466794',
        width: 3,
      }),
      fill: new olStyle.Fill({
        color: 'rgba(70, 103, 148, 0.1)',
      }),
    }),
  });

  userMunicipalitiesVectorSource = new olSource.Vector({
    wrapX: false,
  });
  userMunicipalitiesVectorLayer = new olLayer.Vector({
    source: this.userMunicipalitiesVectorSource,
    style: new olStyle.Style({
      stroke: new olStyle.Stroke({
        color: '#FFE510',
        width: 3,
      }),
      fill: new olStyle.Fill({
        color: 'rgba(255, 229, 16,0.1)',
      }),
    }),
  });

  _contextMenu: ContextMenu;

  public contextMenuItems: MenuItem[];

  public menuLeft = 0;
  public menuTop = 0;

  polygonVectorSource = new olSource.Vector({
    wrapX: false,
  });
  polygonVectorLayer = new olLayer.Vector({
    source: this.polygonVectorSource,
  });
  polygonDrawInteraction = new olInteraction.Draw({
    source: this.polygonVectorSource,
    type: 'Polygon',
  });
  polygonActive = false;

  pointVectorSource = new olSource.Vector({
    wrapX: false,
  });
  pointVectorLayer = new olLayer.Vector({
    source: this.pointVectorSource,
  });
  pointDrawInteraction = new olInteraction.Draw({
    source: this.pointVectorSource,
    type: 'Point',
  });
  pointActive = false;


  supportPosition: any;
  supportPositionClicked: any;

  async ngOnInit() {
    super.ngOnInit();
    await this.parameterService.findAll();
  }

  @ViewChild('contextMenu', {static: true})
  set contextMenu(contextM: ContextMenu) {
    this._contextMenu = contextM;
  }

  get contextMenu(): ContextMenu {
    return this._contextMenu;
  }

  ngAfterViewInit() {
    this.mapService.viewer = this.geoserverService.ngViewer.viewer;
    this.geoserverService.ngViewer.viewer.initialized$.pipe(filter(i => (i ? true : false))).subscribe(() => {
      this.initViewer();
    });
  }

  onMapClicked() {
    this._contextMenu.hide();
  }

  async onMapContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuItems = [];
    this.supportPositionClicked = this.mapService.olMap.getEventCoordinate(event);
    if (this.userService.ROLE_CREATE) {
      const features = this.userMunicipalitiesVectorSource.getFeaturesAtCoordinate(this.supportPositionClicked);
      if (features && features.length > 0) {
        await this.addSupportOnMap();
      } else {
        this.contextMenuItems.push({
          label: await this.translate.get('CANT_ADD_SIGN_HERE').toPromise(),
        });
      }
    }
    const ids = await this.identifySupport(this.supportPositionClicked);

    if (ids.length > 0) {
      this.contextMenuItems.push({
        label: await this.translate.get('SHOW_SUPPORT').toPromise(),
        command: () => {
          // event.originalEvent: Browser event
          // event.item: menuitem metadata
          this._contextMenu.hide();
          this.showSupportFromId(ids[0]);
        },
        icon: 'icon fa fa-eye marginR5',
      });
      this.contextMenuItems.push({
        label: await this.translate.get('SELECT_SIGNS').toPromise(),
        command: () => {
          // event.originalEvent: Browser event
          // event.item: menuitem metadata
          this._contextMenu.hide();
          this.showSelectedSigns(ids);
        },
        icon: 'icon fa fa-plus-hexagon marginR5',
      });
    }

    this.changeDetector.markForCheck();
    this._contextMenu.show(event);
  }

  async initViewer() {
    const map = this.mapService.olMap;
    map.addLayer(this.userMunicipalitiesVectorLayer);
    map.addLayer(this.municipalitiesVectorLayer);
    map.addLayer(this.selectedVectorLayer);
    map.addLayer(this.highlightVectorLayer);
    this.userMunicipalitiesVectorLayer.setZIndex(8);
    this.municipalitiesVectorLayer.setZIndex(7);
    const municipalities = await this.municipalityService.findAll();
    const userMunicipality = this.userService.municipality;
    municipalities.forEach(municipality => {
      const feature = MunicipalityService.parseFeature(municipality);
      if (!userMunicipality || (userMunicipality && userMunicipality.id === municipality.id)) {
        this.userMunicipalitiesVectorSource.addFeature(feature);
      } else {
        this.municipalitiesVectorSource.addFeature(feature);
      }
    });


    if (this.userService.hasRole(this.userService.agentCode)) {
      this.mapService.view.extent = new Extent(this.userMunicipalitiesVectorSource.getExtent(), {
        projection: 'EPSG:31370',
      });
    }

    this.mapService.mapClickedListener.subscribe(e => {
      this.onMapClicked();
    });
    this.mapService.mapContextMenuListener.subscribe(e => {
      this.onMapContextMenu(e);
    });

    this.polygonDrawInteraction.on('drawend', async (e: olInteraction.Draw.Event) => {
      const ids: number[] = await this.identifyPolygon(e.feature.getGeometry());
      e.preventDefault();
      this.showSelectedSigns(ids);
    });

    this.pointDrawInteraction.on('drawend', async (e: olInteraction.Draw.Event) => {
      const coords = e.feature.getGeometry().getCoordinates();
      e.preventDefault();
      this.duplicateSign(coords);
    });

    this.mapService.language = this.translate.currentLang;
  }

  async addSupportOnMap() {
    this.contextMenuItems.push({
      label: await this.translate.get('ADD_SIGN').toPromise(),
      command: () => {
        // event.originalEvent: Browser event
        // event.item: menuitem metadata
        this._contextMenu.hide();
        this.handleAddSignAtCoordinates();
      },
      icon: 'fa fa-plus marginR5',
    });
  }

  showSelectedSigns(ids: number[]) {
    this.supportService.selectedSupportsIds = ids;
    this.layoutService.leftPanelContent = PanelSignListComponent;
    this.mapService.selectSignModeActivated = true;
    this.layoutService.leftPanelVisible = true;
  }

  async identifyPolygon(geometry): Promise<number[]> {
    try {
      this.loaderService.showLoader();

      // console.info('indentify polygon', geometry);
      const projection = 'EPSG:31370';
      const ids: number[] = await this.geoserverService.identifyPolygon(
        geometry,
        'esign:v_identify_supports',
        projection
      );
      this.deactivatePolygonSelect();
      return ids;
    } finally {
      this.loaderService.hideLoader();
    }
  }

  togglePolygonSelect(): void {
    this.polygonActive = !this.polygonActive;
    // console.info('indentify polygon', this.polygonActive);
    if (this.polygonActive) {
      this.activatePolygonSelect();
    } else {
      this.deactivatePolygonSelect();
    }
  }

  activatePolygonSelect(): void {
    this.polygonActive = true;
    const map = this.mapService.olMap;
    this.polygonVectorSource.clear();
    map.addLayer(this.polygonVectorLayer);
    map.addInteraction(this.polygonDrawInteraction);
  }

  deactivatePolygonSelect(): void {
    this.polygonActive = false;
    const map = this.mapService.olMap;
    map.removeLayer(this.polygonVectorLayer);
    map.removeInteraction(this.polygonDrawInteraction);
  }

  async duplicateSign(coords: [number, number]) {
    this.supportPositionClicked = coords;

    this.loaderService.showLoader();
    const ids = await this.identifySupport(coords);
    this.loaderService.hideLoader();

    if (ids.length > 0) {
      this.supportService.selectedSupportsIds = ids;
      this.displayAddSign = true;
    } else {
      this.newSupport();
    }

    this.mapService.refresh();
  }

  activatePointSelect(): void {
    this.pointActive = true;
    const map = this.mapService.olMap;
    this.pointVectorSource.clear();
    map.addLayer(this.pointVectorLayer);
    map.addInteraction(this.pointDrawInteraction);
  }

  deactivatePointSelect(): void {
    this.pointActive = false;
    const map = this.mapService.olMap;
    map.removeLayer(this.pointVectorLayer);
    map.removeInteraction(this.pointDrawInteraction);
  }

  async showSupportFromId(id: number) {
    const support = await this.supportService.getSupport(id);
    return this.showSupport(support);
  }

  async showSupport(support: Support) {
    this.supportService.editMode = false;

    try {
      this.loaderService.showLoader();

      this.supportService.support = support;
      this._contextMenu.hide();
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = SupportComponent;
    } catch (error) {
      // TODO handle error
      this.errorService.addSingleError(error);
      this._contextMenu.hide();
    } finally {
      this.loaderService.hideLoader();
    }
  }

  infoAddSign() {
    this.displayInfoAddSign = true;
  }

  addSign() {
    this.mapService.addSignModeActivated = true;
    this.displayInfoAddSign = false;

    this.mapService.mapClickedListener.subscribe(event => {
      // console.info('click on map', event);
      if (this.mapService.addSignModeActivated && !this.displayAddSign) {
        this.supportPositionClicked = (<any>event).coordinate;
        this.handleAddSignAtCoordinates();
      }
    });
  }

  async handleAddSignAtCoordinates() {
    this.mapService.addSignModeActivated = true;

    const ids = await this.identifySupport(this.supportPositionClicked);

    if (ids.length > 0) {
      this.supportService.selectedSupportsIds = ids;
      this.displayAddSign = true;
    } else {
      this.newSupport();
    }
  }

  async identifySupport(coords: [number, number]): Promise<number[]> {
    const buffer = this.parameterService.allParameters.find(
      param => param.code === 'ADMINISTRATION:PARAMETER:BUFFER_SIZE'
    ).value;
    const projection = 'EPSG:31370';
    const options = {
      SRS: 'EPSG:31370',
      INFO_FORMAT: 'application/json',
      FEATURE_COUNT: 50,
      BUFFER: buffer,
    };
    return await this.geoserverService.identifyItems(coords, 'esign:v_identify_supports', projection, options);
  }

  addToExistingSupport() {
    this.displayAddSign = false;
    this.layoutService.leftPanelContent = PanelSupportListComponent;
    this.layoutService.leftPanelVisible = true;
  }

  selectSigns() {
    this.mapService.selectSignModeActivated = true;
    this.layoutService.leftPanelVisible = true;
    this.layoutService.leftPanelContent = PanelSignListComponent;
  }

  async newSupport() {
    this.layoutService.rightPanelVisible = false;
    this.displayAddSign = false;
    this.supportPosition = this.supportPositionClicked;
    const x = this.supportPosition[0];
    const y = this.supportPosition[1];
    const geom = '{"type":"Point","coordinates":[' + x + ',' + y + ']}';
    const support = {geom: geom};

    if(this.mapService.duplicateModeActivated) {
      try {
        this.loaderService.showLoader();

        this.supportService.support = SupportHelper.duplicateSupportFrom(this.supportService.memorizedSupport)
        this.supportService.support.geom = geom;
        await this.supportService.setAddressFromPoint(this.supportService.support);
        const newSupport = await this.supportService.createWithSigns(this.supportService.support, [
          SignHelper.duplicateSignFrom(this.signService.memorizedSign)
        ]);

        this.supportService.support = newSupport;
        this.signService.sign = newSupport.signs[0];
        this.layoutService.rightPanelVisible = true;
        this.layoutService.rightPanelContent = SupportComponent;

        this.mapService.refresh();
      } finally {
        this.loaderService.hideLoader();
      }
    } else {
      await this.supportService.setAddressFromPoint(support);
      this.supportService.support = support;
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = SupportComponent;
      this.supportService.editMode = true;
    }
  }

  async disableDuplicateMode() {
    this.signService.memorizedSign = null;
    this.supportService.memorizedSupport = null;

    try {
      this.loaderService.showLoader();

      const infoMessageTitle: string = await this.translate
        .get('INFORMATION_MESSAGE').toPromise();
      const infoMessageContent: string = await this.translate
        .get('DUPLICATE_MODE_IS_DISABLED').toPromise();
      this.errorService.addSingleInfo(infoMessageTitle, infoMessageContent);
    } finally {
      this.loaderService.hideLoader();
    }
  }
}
