import { Component, OnInit } from '@angular/core';
import { ParameterService } from '../../../core/services/parameter.service';

@Component({
  selector: 'app-helpdesk',
  template: `
    <p>
      {{"HELPDESK:TECH" | translate}}
    </p>
    <p>
      {{"HELPDESK:APP" | translate}}
    </p>
  `,
  styles: ['.mail{ font-weight: bold; color: #466794;}'],
})
export class HelpdeskComponent implements OnInit {

  constructor(public parameterService: ParameterService) {
  }

  async ngOnInit() {
  }

}
