import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'substring' })
export class SubstringPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string, maxLength: number): any {
    if (typeof value === 'string') {
      return value.length > maxLength
        ? value.substr(0, maxLength) + '...'
        : value;
    }
  }
}
