export const environment = {
  production: false,
  backendURL: 'https://esign-services.sprb-gob.brussels/',
  identityPath: 'ESign.Identity.Host/',
  supportPath: 'ESign.Support.Host/',
  signPath: 'Esign.Sign.Host/',
  taskPath: 'ESign.Task.Host/',
  picturePath: 'Esign.Picture.Host/',
  rcPath: 'Esign.Sign.Host/',
  municipalityPath: 'Esign.Municipality.Host/',
  gedPath: 'Esign.GED.Host/',
  resourcePath: 'Esign.Resource.Host/',
  imageTypePath: 'Esign.Sign.Host/api/sign/getSignTypeFile?path=',
  translationPath: 'api/resource/translations/',
  oAuthClientId: 'Esign',
  oAuthClientSecret: 'A5123671-04FC-45FA-858C-86B7E43638BB',
  accessTokenUri: 'ESign.Identity.Host/OAuth/Token',
  grantType: 'password',
  grantTypeReload: 'refresh_token',
  code: 'prod'
};
