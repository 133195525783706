import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'logout-icon',
  templateUrl: './layout.logout-icon.component.html',
  styleUrls: ['./layout.logout-icon.component.scss']
})
export class LogoutIconComponent implements OnInit {
  constructor(
    public userService: UserService,
    private layoutService: LayoutService
  ) {}

  disconnect() {
    this.layoutService.closeCenterPanel();
    this.userService.logout();
  }
  ngOnInit() {}
}
