import { Component, OnInit } from '@angular/core';
import { LoggedComponent } from '../../../shared/components/logged/logged.component';
import { UserService } from '../../../core/services/user.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent extends LoggedComponent {
  constructor(public userService: UserService) {
    super(userService);
  }
}
