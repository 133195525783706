<p-panel [header]="'SUB_MEASURE:ADD' | translate">
  <form [formGroup]="form">
    <div class="ui-g ui-fluid form-group">
      <p-panel styleClass="parent-panel" class="ui-g-12" header="{{ 'DIALOG_FORM_PARENT' | translate }}" [toggleable]="true">
        <div class="ui-g">
          <div class="ui-g-2">
            <label class="custom-label">{{ 'DIALOG_FORM_ARTICLE_ID' | translate }}</label>
            <div>{{parent.articleId}}</div>
          </div>
          <div class="ui-g-5">
            <label class="custom-label">{{ 'DIALOG_FORM_TEXT_FR' | translate }}</label>
            <div>{{parent.textFr}}</div>
          </div>
          <div class="ui-g-5">
            <label class="custom-label">{{ 'DIALOG_FORM_TEXT_NL' | translate }}</label>
            <div>{{parent.textNl}}</div>
          </div>
        </div>
      </p-panel>
      <div class="ui-g-12">
        <label class="custom-label">{{'SIGN:MEASURE' | translate}}</label>
        <p-dropdown [options]="signs$ | async" formControlName="signId" dataKey="id" optionLabel="id">
          <ng-template let-item pTemplate="selectedItem">
            <img [src]="item.value | signImage" alt="logo"
                 style="width:20px;position:absolute;top:1px;left:5px"/>
            <div style="font-size:14px; margin-top:4px; margin-left: 35px;">
              <b>{{item.value.type.code}}</b><span *ngIf="item.value.support">
              - {{item.value.support | signAddress}}</span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <b>{{item.value.type.code}}</b><span *ngIf="item.value.support">
            - {{item.value.support | signAddress}}</span>
          </ng-template>
        </p-dropdown>
      </div>
      <ng-container *ngIf="form.get('signId').value as sign">
        <div *ngIf="sign.type?.generic" class="pt-0">
          <div class="ui-g-12" *ngIf="sign.genericTextFr">
            <label>FR: {{sign.genericTextFr}}</label>
          </div>
          <div class="ui-g-12" *ngIf="sign.genericTextNl">
            <label>NL: {{sign.genericTextNl}}</label>
          </div>
        </div>
      </ng-container>
      <div class="ui-g-12">
        <label class="custom-label">{{ 'DIALOG_FORM_ARTICLE_ID' | translate }}</label>
        <input formControlName="articleId" maxlength="255" pInputText/>
      </div>
      <div class="ui-g-12">
        <label class="custom-label">{{ 'DIALOG_FORM_TEXT_FR' | translate }}</label>
        <textarea type="text" formControlName="textFr" [rows]="5" pInputTextarea required></textarea>
      </div>
      <div class="ui-g-12">
        <label class="custom-label">{{ 'DIALOG_FORM_TEXT_NL' | translate }}</label>
        <textarea type="text" formControlName="textNl" [rows]="5" pInputTextarea required></textarea>
      </div>
    </div>
  </form>
  <div class="ui-g sticky-bottom-buttons">
    <div class="ui-g-12">
      <button pButton (click)="submit()" [disabled]="form.invalid" icon="fas fa-edit" class="right ml-5"
              label="{{'SAVE' | translate}}"></button>
      <button pButton (click)="cancel()" icon="fas fa-times" class="right secondary"
              label="{{'CANCEL' | translate}}"></button>
    </div>
  </div>
</p-panel>
