import {Component, OnInit} from '@angular/core';
import {LoggedComponent} from '../../../shared/components/logged/logged.component';
import {AdministrationService} from '../../../core/services/administration.service';
import {UserService} from '../../../core/services/user.service';
import {ParameterService} from '../../../core/services/parameter.service';
import {LayoutService} from '../../../core/services/layout.service';
import {ParameterModel} from '../../../models/parameter.model';
import {NotificationService} from '../../../core/services/notification.service';
import {LoaderService} from 'app/core/services/loader.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'manage-rc-template',
  templateUrl: './manage-rc-template.component.html',
  styleUrls: ['./manage-rc-template.component.scss']
})
export class ManageRcTemplateComponent extends LoggedComponent
  implements OnInit {

  editMode = false;
  richTextParameter: ParameterModel;

  richTextParameters: string[] = [];

  constructor(
    userService: UserService,
    public parameterService: ParameterService,
    private layoutService: LayoutService,
    public administrationService: AdministrationService,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
  ) {
    super(userService);
  }

  async ngOnInit() {
    this.loaderService.showLoader();

    try {
      this.richTextParameters = [
        this.parameterService.RC_TEMPLATE_COMM_INTRO_FR,
        this.parameterService.RC_TEMPLATE_COMM_INTRO_NL,
        this.parameterService.RC_TEMPLATE_COMM_CONCL_FR,
        this.parameterService.RC_TEMPLATE_COMM_CONCL_NL,
        this.parameterService.RC_TEMPLATE_REG_INTRO_FR,
        this.parameterService.RC_TEMPLATE_REG_INTRO_NL,
        this.parameterService.RC_TEMPLATE_REG_CONCL_FR,
        this.parameterService.RC_TEMPLATE_REG_CONCL_NL,
      ];
      await this.parameterService.findAll();
      this.select(this.parameterService.RC_TEMPLATE_COMM_INTRO_FR);
    } catch (error) {

    } finally {
      this.loaderService.hideLoader();
    }
  }

  edit() {
    this.switchEditMode(true);
  }

  cancelEdit() {
    this.switchEditMode(false);
  }

  async save() {
    this.loaderService.showLoader();

    try {
      this.parameterService.saveSingleParameter(this.richTextParameter);
      this.parameterService.saveLocaly(this.richTextParameter);
      this.cancelEdit();

      await this.notificationService.addSingleSuccess(
        this.translateService.instant('COMMON_SUCESS'),
        this.translateService.instant('INTRO_CONCLU_SAVED_SUCCESS')
      );
    } catch (error) {

    } finally {
      this.loaderService.hideLoader();
    }
  }

  switchEditMode(value: boolean) {
    this.editMode = this.administrationService.editMode = value;
  }

  select(field:string){
    if(!this.editMode) {
      this.richTextParameter = JSON.parse(
        JSON.stringify(this.parameterService.find(field))
      );
    }
  }
}
