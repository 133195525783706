// Rxjs
import 'rxjs';
import { Observable } from 'rxjs/Observable';

// Angular Modules
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Constants
import { AppConfig } from 'app/models/app-config.model';
import { Session } from 'app/app.session';

// Services
import { CoreInitializer } from './core.initializer';

// Modules
import { InterceptorsModule } from './core.interceptors';
import { ServicesModule } from './core.services';
import { environment } from '../../environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ViewerTranslationLoader } from '@nsi/gis-viewer-ui';
import { zip, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// App init
export function coreInitFactory(coreInit: CoreInitializer) {
  return () => coreInit.init();
}

const PROVIDERS = [
  // Initialisation de l'application
  CoreInitializer,
  {
    provide: APP_INITIALIZER,
    multi: true,
    // Doit retourner une fonction qui renvoie une Promise
    useFactory: coreInitFactory,
    deps: [CoreInitializer]
  },

  AppConfig,
  Session
];
// Translation
export class CoreTranslationLoader extends ViewerTranslationLoader {
  public getTranslation(lang: string): Observable<any> {
    return zip(
      super.getTranslation(lang.toLocaleLowerCase()),
      this.http
        .get(
          `${environment.backendURL}${environment.resourcePath}${
            environment.translationPath
          }${lang.toLocaleLowerCase()}`
        )
        .pipe(catchError(e => of({})))
    ).pipe(map(val => Object.assign({}, val[0], val[1])));
  }
}

export function httpLoaderFactory(http: HttpClient) {
  return new CoreTranslationLoader(http);
}

const MODULES = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: httpLoaderFactory,
      deps: [HttpClient]
    }
  }),
  CommonModule,
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  InterceptorsModule,
  ServicesModule
];

@NgModule({
  providers: PROVIDERS,
  imports: MODULES
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        `CoreModule has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
