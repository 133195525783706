<div class="rc">

  <p-panel #panel [style]="{'min-width': '600px'}">
    <p-header id="panel-sign-view-header">
      <title-header [title]="'RC_VIEW:TITLE:EDIT' | translate" [direction]="'RIGHT'"
                    *ngIf="rcService.rc && rcService.rc.id; else rcNew"></title-header>
      <ng-template #rcNew>
        <title-header [title]="'RC_VIEW:TITLE:NEW' | translate" [direction]="'RIGHT'"></title-header>
      </ng-template>
    </p-header>
    <form [formGroup]="rcForm" [ngClass]="{'view-rc': !rcService.editMode, 'edit-rc': rcService.editMode}">
      <div class="ui-g mb-16">
        <div class="ui-g-12">
          <label class="custom-label xBold">{{'RC_VIEW:REFERENCE' | translate}}</label>
        </div>
        <div class="ui-g-12">
          <input style="width: 100%"
                 placeholder="{{'RC_VIEW:REFERENCE_PLACEHOLDER' | translate}}"
                 pInputText
                 [(ngModel)]="rc.title"
                 formControlName="wording"/>
        </div>
      </div>
      <p-tabView class="tabViewBlue">
        <p-tabPanel [header]="'RC_VIEW:INTRODUCTION' | translate" [disabled]="measureService.editMode">
          <app-rc-intro-conclu [label]="'RC_VIEW:INTRODUCTION' | translate" [(modelFr)]="rc.introductionFr" [disabled]="!rcService.editMode"
                               [(modelNl)]="rc.introductionNl"></app-rc-intro-conclu>
        </p-tabPanel>
        <p-tabPanel [header]="'RC_VIEW:ABROGATIONS' | translate" [disabled]="measureService.editMode">
          <app-rc-measures #abrogations [selection]="rc.abrogations" [isAbrogation]="true" [disabled]="!rcService.editMode"></app-rc-measures>
        </p-tabPanel>
        <p-tabPanel [header]="'RC_VIEW:DISPOSITIONS' | translate" [disabled]="measureService.editMode">
          <app-rc-measures #newClauses [selection]="rc.newClauses" [isAbrogation]="false" [disabled]="!rcService.editMode"></app-rc-measures>
        </p-tabPanel>
        <p-tabPanel [header]="'RC_VIEW:CONCLUSION' | translate" [disabled]="measureService.editMode">
          <app-rc-intro-conclu [label]="'RC_VIEW:CONCLUSION' | translate" [(modelFr)]="rc.conclusionFr" [disabled]="!rcService.editMode"
                               [(modelNl)]="rc.conclusionNl"></app-rc-intro-conclu>
        </p-tabPanel>
      </p-tabView>

      <div class="ui-g sticky-bottom-buttons" *ngIf="!measureService.editMode">
        <div class="ui-g-12">
          <p-button [ngStyle]="{'margin-left':'5px'}" class="right" icon="fa fa-fw fa-download"
                    label="{{ 'RC_VIEW:BUTTON:DOWNLOAD' | translate }}" (click)="downloadRc()"
                    [disabled]="!rcForm || rcForm.invalid"></p-button>
          <p-button [ngStyle]="{'margin-left':'5px'}" class="right" icon="fa fa-check"
                    label="{{ 'RC_VIEW:BUTTON:SAVE_AND_START_RC' | translate }}" (click)="validRc()"
                    [disabled]="!rcForm || rcForm.invalid || !rcService.editMode"
                    *ngIf="!rc.status || rc.status == RC_STATUS.draft"></p-button>
          <p-button [ngStyle]="{'margin-left':'5px'}" class="right" icon="fas fa-save"
                    label="{{ 'RC_VIEW:BUTTON:SAVE_RC' | translate }}" (click)="draftRc()"
                    [disabled]="!rcForm || rcForm.invalid || !rcService.editMode" *ngIf="!rc.id || rc.status == RC_STATUS.draft"></p-button>
          <p-button [ngStyle]="{'margin-left':'5px'}" class="right" icon="fas fa-save"
                    label="{{ 'UPDATE' | translate }}" (click)="validate(false)" [disabled]="!rcForm || rcForm.invalid || !rcService.editMode"
                    *ngIf="rc.status == RC_STATUS.pending"></p-button>
          <p-button [ngStyle]="{'margin-left':'5px'}" class="secondary right" icon="fa fa-fw fa-times" [disabled]="!rcService.editMode"
                    label="{{ 'CANCEL' | translate }}" (click)="cancel()"></p-button>
          <button   pButton type="button" [ngStyle]="{'margin-left':'5px'}" class="ui-button-danger right" icon="fa fa-trash" [disabled]="!rcService.editMode"
                    label="{{ 'DELETE' | translate }}" (click)="delete()"></button>
        </div>
      </div>
    </form>

  </p-panel>

  <p-dialog id="dialogCancelRC" modal="modal" [(visible)]="displayCancelRC" appendTo="body" [width]="500" [baseZIndex]="10000">
    <p-header>
      {{ 'CAUTION' | translate }}
    </p-header>
    <div class="ui-g">
      <div class="ui-g-12" style="padding: 10px 0;"><span>{{ "RC_VIEW:CANCEL:TEXT1" | translate }}</span></div>
      <div class="ui-g-12" style="padding: 10px 0;"><span>{{ "RC_VIEW:CANCEL:TEXT2" | translate }}</span></div>
      <div class="ui-g-12" style="padding: 10px 0;">
        <div class="ui-g">
          <div class="ui-g-12">
            <p-button [ngStyle]="{'margin-left':'5px'}" class="right" icon="fa fa-fw fa-check" label="{{ 'BUTTON:CLOSE' | translate }}" (click)="cancelRcYes()"></p-button>
            <p-button [ngStyle]="{'margin-left':'5px'}" class="secondary right" icon="fa fa-fw fa-times" label="{{ 'BUTTON:CANCEL' | translate }}" (click)="cancelRcNo()"></p-button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>

  <p-dialog id="dialogWordignMissingRC" modal="modal" [(visible)]="displayWordingMissingRC" appendTo="body" [width]="350" [baseZIndex]="10000">
    <p-header>
      {{ 'CAUTION' | translate }}
    </p-header>
    <div class="ui-g font-size-12-px">
      <div class="ui-g-12" style="padding: 10px 0;"><span>{{ "RC_VIEW:SAVING:ERROR:WORDIGN" | translate }}</span></div>
      <div class="ui-g-12" style="padding: 10px 0;">
        <div class="ui-g">
          <div class="ui-g-12">
            <p-button [ngStyle]="{'margin-left':'5px'}" class="right" icon="fa fa-fw fa-check" label="{{ 'BUTTON:OK' | translate }}" (click)="hideWordingMissingRC()"></p-button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>

  <p-dialog id="dialogSaveRC" modal="modal" [(visible)]="displaySavingRC" appendTo="body" [width]="350" [baseZIndex]="10000">
    <p-header>
      {{ 'CAUTION' | translate }}
    </p-header>
    <div class="ui-g font-size-12-px">
      <div class="ui-g-12" style="padding: 10px 0;">
        <span>{{ "RC_VIEW:SAVING:MESSAGE1" | translate }}</span>
        <br>
        <span>{{ "RC_VIEW:SAVING:MESSAGE2" | translate }}</span>
      </div>
      <div class="ui-g-12" style="padding: 10px 0;">

        <div class="ui-g-12">
          <p-button [ngStyle]="{'margin-left':'5px'}" class="right" icon="fa fa-fw fa-check" label="{{ 'BUTTON:START_WF' | translate }}" (click)="validate(true)"></p-button>
          <p-button [ngStyle]="{'margin-left':'5px'}" class="secondary right" icon="fa fa-fw fa-times" label="{{ 'BUTTON:CANCEL' | translate }}" (click)="validRcNo()"></p-button>
        </div>
      </div>
    </div>
  </p-dialog>

  <p-dialog id="dialogUpdateRC" modal="modal" [(visible)]="displayUpdateRC" appendTo="body" [width]="350" [baseZIndex]="10000">
    <p-header>
      {{ 'CAUTION' | translate }}
    </p-header>
    <div class="ui-g font-size-12-px">
      <div class="ui-g-12" style="padding: 10px 0;">
        <span>{{ "RC_VIEW:UPDATE:MESSAGE1" | translate }}</span>
      </div>
      <div class="ui-g-12" style="padding: 10px 0;">

        <div class="ui-g-12">
          <p-button [ngStyle]="{'margin-left':'5px'}" class="right" icon="fa fa-fw fa-check" label="{{ 'BUTTON:UPDATE_WF' | translate }}" (click)="validate(false)"></p-button>
          <p-button [ngStyle]="{'margin-left':'5px'}" class="secondary right" icon="fa fa-fw fa-times" label="{{ 'BUTTON:CANCEL' | translate }}" (click)="validRcNo()"></p-button>
        </div>
      </div>
    </div>
  </p-dialog>
</div>
