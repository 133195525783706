import { Injectable } from '@angular/core';
import { User } from './models/user.model';
import { Role } from './models/role.model';

@Injectable()
export class Session {
  private STORAGE_USER = 'user';
  private STORAGE_TOKEN = 'token';
  private STORAGE_OAUTH_TOKEN = 'oauth_token';

  public lastErrorCode: number;
  public lastErrorDetails: string;
  private _user: User;

  get userStorage(): User {
    const user = <User>(
      this.parseJson(sessionStorage.getItem(this.STORAGE_USER))
    );
    return this.isUserValid(user) ? user : null;
  }
  get user(): User {
    if (!this._user) {
      this._user = this.userStorage;
    }
    return this._user;
  }

  set user(user: User) {
    if (!this.isUserValid(user)) {
      this.invalidate();
    } else {
      this._user = user;
      sessionStorage.setItem(this.STORAGE_USER, JSON.stringify(user));
    }
  }

  isUserValid(user: User): boolean {
    return user && user.id ? true : false;
  }

  get role(): Role {
    return this.user.roles[0];
  }

  get roles(): Role[] {
    return this.user.roles;
  }

  get token(): string {
    return sessionStorage.getItem(this.STORAGE_TOKEN);
  }

  set token(token: string) {
    sessionStorage.setItem(this.STORAGE_TOKEN, token);
  }

  get oAuthtoken() {
    return this.parseJson(sessionStorage.getItem(this.STORAGE_OAUTH_TOKEN));
  }

  set oAuthtoken(token) {
    sessionStorage.setItem(this.STORAGE_OAUTH_TOKEN, JSON.stringify(token));
  }

  parseJson(txt: string) {
    let json = null;
    try {
      json = JSON.parse(txt);
    } catch (e) {}
    return json;
  }

  get isLogged(): boolean {
    return !!sessionStorage.getItem(this.STORAGE_TOKEN);
  }

  private invalidate(): void {
    this._user = null;
    this.oAuthtoken = null;
    sessionStorage.removeItem(this.STORAGE_USER);
    sessionStorage.removeItem(this.STORAGE_TOKEN);
    sessionStorage.removeItem(this.STORAGE_OAUTH_TOKEN);
  }
}
