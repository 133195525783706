import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
} from '@angular/core';
import { LayoutService } from '../../../core/services/layout.service';
import { ContainerComponent } from '../../../shared/components/container/container.component';
import { Observable } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'layout-left-panel',
  template: `<ng-template nsi-container-content></ng-template>`
})
export class LayoutLeftPanelComponent extends ContainerComponent {
  constructor(
    public layoutService: LayoutService,
    protected compFactoryResolver: ComponentFactoryResolver
  ) {
    super(compFactoryResolver);
  }
  get contentHandler(): Observable<any> {
    return this.layoutService.leftPanelContentHandler;
  }
}
