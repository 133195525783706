<div class="panel-task-list" style="position: relative; height: 100%;">
  <p-panel class="customPanel">
    <p-header id="panel-task-list-header">
      <title-header [icon]="true" [iconClass]="'fas fa-tasks'" [title]="'PANEL_TASK_LIST:MY_TASKS' | translate" [direction]="'LEFT'"></title-header>
    </p-header>


    <p-tabView class="tabView" (onChange)="tabChanged()">


      <p-tabPanel header="{{'PANEL_TASK_LIST:TAB:RC' | translate:{ X: taskService.rcTasks.length} }}">

        <p-dataView [value]="this.taskService.rcTasks"
                    [paginator]="true" [rows]="5" paginatorPosition="bottom"
                    [sortField]="sortFieldTask" [sortOrder]="sortOrderTask"
                    class="data-view-paginator data-view-noBorder">
          <p-header>
            <p-dropdown [options]="sortOptionsTask" [(ngModel)]="sortKeyTask" [placeholder]="'FILTERING:SORTING_BY' | translate"
                        (onChange)="onSortChangeTask($event)" [autoWidth]="false" [style]="{'min-width':'15em'}"></p-dropdown>
          </p-header>
          <ng-template let-rc pTemplate="listItem">
            <div class="ui-g">
              <div class="ui-g-12">
                <p-panel [ngClass]="(isOver(rc)?'panel-task-list-panel-exceed':'panel-task-list-panel')" (click)="selectTask(rc)">
                  <p-header>
                    <div *ngIf="statusIn(rc, ['COM_T01'])"><b>{{"PANEL_TASK_LIST:COM_T01:TITLE" | translate}}</b>
                      <span style="float: right; color: #f61a11;" *ngIf="!isOver(rc)">{{'PANEL_TASK_LIST:DAYS:REMAINING' | translate:{ X: (rc|rcLastTask).remainingDays} }}</span>
                      <span style="float: right;" *ngIf="isOver(rc) && (rc|rcLastTask).remainingDays">{{'PANEL_TASK_LIST:DAYS:OVER' | translate:{ X: (rc|rcLastTask).remainingDays * -1} }}</span>
                      <span style="float: right;" *ngIf="isOver(rc) && !(rc|rcLastTask).remainingDays">{{'PANEL_TASK_LIST:DAYS:OVER_NO_DATE' | translate}}</span>
                    </div>
                    <div *ngIf="statusIn(rc, ['COM_T02','REG_T01'])">
                      <div>{{rc.municipalityId | municipalityName | async}}</div>
                      <div>
                        <b>{{"PANEL_TASK_LIST:COM_T02:TITLE" | translate}}</b>
                        <span style="float: right; color: #f61a11;">{{'PANEL_TASK_LIST:DAYS:REMAINING' | translate:{ X: (rc|rcLastTask).remainingDays} }}</span>
                      </div>
                    </div>
                    <div *ngIf="statusIn(rc, ['COM_T03','REG_T02'])">
                      <div>{{rc.municipalityId | municipalityName | async}}</div>
                      <div>
                        <b>{{"PANEL_TASK_LIST:COM_T03:TITLE" | translate}}</b>
                        <span style="float: right; color: #f61a11;">{{'PANEL_TASK_LIST:DAYS:REMAINING' | translate:{ X: (rc|rcLastTask).remainingDays} }}</span>
                      </div>
                    </div>
                    <div *ngIf="statusIn(rc, ['COM_T04','REG_T03'])"><b>{{"PANEL_TASK_LIST:COM_T04:TITLE" | translate}}</b></div>
                    <div *ngIf="statusIn(rc, ['COM_T05','REG_T04'])"><b>{{"PANEL_TASK_LIST:COM_T05:TITLE" | translate}}</b></div>

                  </p-header>
                  <div>{{rc.title}}</div>
                  <hr class="ui-widget-content" style="border-top:0">
                  <div class="truncate">
                    <span *ngFor="let signType of signsTypeUnique(rc);let last = last">{{signType.code}} {{signType.typeCategorie ? (signType.typeCategorie.code | translate) : ""}}{{last ? "" : ", "}}</span>
                  </div>
                  <div class="truncate" style="font-style: italic;">
                    <span *ngFor="let street of streetsUniqueRc(rc);let last = last">{{street}}{{last ? "" : ", "}}</span>
                  </div>
                </p-panel>
              </div>
            </div>
          </ng-template>

        </p-dataView>




      </p-tabPanel>



      <p-tabPanel header="{{'PANEL_TASK_LIST:TAB:CENSUS' | translate:{ X: signService.signsRecense.length} }}" *ngIf="userService.ROLE_RECENSE">
        <p-dataView [value]="signService.signsRecense"
                    [paginator]="true" [rows]="5" paginatorPosition="bottom"
                    [sortField]="sortFieldRecense" [sortOrder]="sortOrderRecense"
                    class="data-view-paginator data-view-noBorder">
          <p-header>
            <p-dropdown [options]="sortOptionsRecense" [(ngModel)]="sortKeyRecense" [placeholder]="'FILTERING:SORTING_BY' | translate"
                        (onChange)="onSortChangeRecense($event)" [autoWidth]="false" [style]="{'min-width':'15em'}"></p-dropdown>
          </p-header>
          <ng-template let-recense pTemplate="listItem">
            <div class="ui-g">
              <div class="ui-g-12">
                <p-panel [ngClass]="(recense.mobileStatus == SIGN_MOBILE_STATUS.add ?'panel-task-list-panel-green': (recense.mobileStatus == SIGN_MOBILE_STATUS.edit ? 'panel-task-list-panel' : 'panel-task-list-panel-red'))" (click)="showSign(recense)">
                  <p-header>
                    <div><b>{{('MOBILESTATUS:LABEL:'+recense.mobileStatus) | translate}}</b> ({{recense.mobileStatusDate | date:'dd/MM/yyyy' }}) <i style="float: right; cursor:pointer; margin: 0 0 0 5px;" class="material-icons iconHover-darker" (click)="terminateRecense(recense, $event)">done</i></div>
                  </p-header>
                  <div class="ui-g">
                    <div class="ui-g-9 ui-g-nopad">
                      <div class="ui-g-12">
                        <div style="font-size:12px;" *ngIf="recense.type">{{recense.type.code}}<span *ngIf="recense.type.label"></span><span *ngIf="(recense.type.label | translate) != recense.type.label"> - {{recense.type.label | translate | ellipsis:20}}</span></div>
                        <div class="truncate" style="font-style: italic;">
                          <span>{{getAddressLabel(recense.support.address)}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="ui-g-3">
                      <img src="{{recense.type| signTypeImage}}" alt="pic" class="recencement-img"/>
                    </div>
                  </div>
                </p-panel>
              </div>
            </div>
          </ng-template>
        </p-dataView>
      </p-tabPanel>


    </p-tabView>

  </p-panel>
</div>
