import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MapComponent} from './map.component';



export const MapRoutes: Routes = [
  
  { path: '', redirectTo: '/map', pathMatch: 'full' },
];


@NgModule({
    providers: [],
    imports: [ RouterModule.forChild(MapRoutes) ]
})
export class MapRoutingModule {}
