<div class="ui-g">
  <div class="ui-g-12" style="text-align: right">
    <p-button (click)="downloadSignTypeFile()" [ngStyle]="{'margin-right':'5px'}" icon="fas fa-cogs" label="{{'SIGN_TYPE_VIEW:BUTTON:DOWNLOAD'|translate}}"></p-button>
    <p-button (click)="generateSignTypeFile()" [ngStyle]="{'margin-right':'5px'}" icon="fas fa-cogs" label="{{'SIGN_TYPE_VIEW:BUTTON:GENERATE'|translate}}"></p-button>
    <p-button (click)="addSignType()" icon="fas fa-plus-square" label="{{'ADD'|translate}}"></p-button>
  </div>
  <div class="ui-g-12">
    <p-table class="data-view-paginator" #table [rows]="10" [paginator]="true" [value]="signTypes">
      <ng-template pTemplate="header">
        <tr>
          <th>
            {{'IMAGE' | translate}}
          </th>
          <th>
            <span class="ui-g-12">{{'CATEGORY'|translate}}</span>
            <span class="ui-g-12">
              <p-multiSelect [style]="{'width':'100%'}" [defaultLabel]="'CHOOSE'| translate" [options]="categoriesOption" (onChange)="table.filter($event.value, 'categoryId','in')"></p-multiSelect>
            </span>
          </th>
          <th>
            <span class="ui-g-12">{{'LABEL' | translate}} FR</span>
            <span class="ui-g-12">
              <input pInputText class="width100" type="text" (input)="table.filter($event.target.value, 'labelFr','contains')">
            </span>
          </th>
          <th>
            <span class="ui-g-12">{{'LABEL' | translate}} NL</span>
            <span class="ui-g-12">
              <input pInputText class="width100" type="text" (input)="table.filter($event.target.value, 'labelNl','contains')">
            </span>
          </th>
          <th>
            <span class="ui-g-12">{{'SIGN_CODE' | translate}}</span>
            <span class="ui-g-12">
              <input pInputText class="width100" type="text" (input)="table.filter($event.target.value, 'code','contains')">
            </span>
          </th>
          <th>
            {{'GENERICITY'|translate}}
          </th>
          <th width="10%">
            {{'ACTIONS'|translate}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-signType>
        <tr>
          <td style="text-align: center">
            <img style="height: 30px;" [src]="signType| signTypeImage" />
          </td>
          <td>
            {{(signType.typeCategorie.code) | translate}}
          </td>
          <td>
            <span>{{signType.labelFr}}</span>
          </td>
          <td>
            <span>{{signType.labelNl}}</span>
          </td>
          <td>
            {{signType.code}}
          </td>
          <td style="text-align: center">
            <p-checkbox [disabled]="true" [(ngModel)]="signType.generic" binary="true"></p-checkbox>
          </td>
          <td style="text-align: center">
            <p-button (click)="updateSignType(signType)" icon="fas fa-edit" label="{{'EDIT'|translate}}"></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
