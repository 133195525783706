<p-contextMenu class="map-context-menu" [model]="contextMenuItems" #contextMenu></p-contextMenu>
<div class="block100 map" #map>
  <nsi-gis-viewer #EsignViewer [config]='viewerConfig'></nsi-gis-viewer>
  <actions-panel style="background-color: transparent;"></actions-panel>
  <div class="map-actions-panel">
    <button pButton class="cerclebutton iconFixPosition" icon="icon far fa-plus-hexagon fa-lg" (click)="togglePolygonSelect()"></button>
    <button pButton class="cerclebutton iconFixPosition ui-button-danger" icon="icon fal fa-clone fa-lg" (click)="disableDuplicateMode()" *ngIf="this.displayDisableDuplicateMode"></button>
  </div>


  <p-dialog id="dialogInfoAddSign" *ngIf="displayInfoAddSign" header="" modal="modal" [(visible)]="displayInfoAddSign" [contentStyle]="{'overflow':'visible'}">
    <p-header>
      {{ 'ADD_SIGN' | translate }}
    </p-header>
    <div class="ui-g">
      <div class="ui-g-12" style="padding: 10px 0;"><span>{{ "CLICK_ON_THE_MAP_WHERE_YOU_WANT_TO_ADD_THE_SIGNAGE" | translate }}</span></div>
      <div class="ui-g-12" style="padding: 10px 0;"><span>{{ "YOU_CAN_ALSO_RIGHT-CLICK_DIRECTLY_ON_THE_MAP_WHERE_YOU_WANT_TO_ADD_IT" | translate }}</span></div>
      <div class="ui-g-12" style="padding: 10px 0;"><p-button class="menu-signs-button" label="{{ 'GO_FOR_IT' | translate }}" (click)="addSign()"></p-button></div>
    </div>
  </p-dialog>

  <p-dialog id="dialogAddSign" *ngIf="displayAddSign" header="" modal="modal" [(visible)]="displayAddSign">
    <p-header>
      {{ 'ADD_SIGN' | translate }}
    </p-header>
    <div class="ui-g">
      <div class="ui-g-12" style="padding: 10px 0;">
        <span *ngIf="supportService.selectedSupportsIds">{{ "THERE_IS_ALREADY_X_SUPPORT_NEARBY" | translate:{ X: supportService.selectedSupportsIds.length } }}</span>
      </div>
      <div class="ui-g-12" style="padding: 10px 0;"><span>{{ "WHAT_DO_YOU_WANT_TO_DO" | translate }}</span></div>
      <div class="ui-g-12" style="padding: 10px 0;">
        <p-button [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'CREATE_A_NEW_SUPPORT' | translate}}" (click)="newSupport()"></p-button>
        <p-button [ngStyle]="{'margin-left':'5px'}" class="secondary right" label="{{'ADD_TO_EXISTING_SUPPORT' | translate}}" (click)="addToExistingSupport()"></p-button>
      </div>
    </div>
  </p-dialog>

</div>
