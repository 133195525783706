<div class="import-data">
  <div class="ui-g">
    <div class="ui-g-12">
      {{"FILE_TO_UPLOAD_CHOICE"|translate}} :
    </div>
    <div class="ui-g-3">
      <p-fileUpload mode="advanced"
                    customUpload="true"
                    name="DecisionPV"
                    auto="true"
                    (uploadHandler)="uploadhandler($event)"
                    (onRemove)="uploadhandler($event)"
                    (onSelect)="uploadhandler($event)"
                    chooseLabel="{{'ADD' | translate}}"></p-fileUpload>
    </div>
    <div class="ui-g-12">
      <p-button icon="fa fa-fw fa-upload" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'IMPORT_DATA' | translate}}" (click)="uploadFile()" *ngIf="file"></p-button>
    </div>
    <div class="ui-g-12" *ngIf="logs$ | async as logs">
      <p-table [value]="logs">
        <ng-template pTemplate="header">
          <tr>
            <th>{{"INFORMATION" | translate}}</th>
            <th>{{"LINE" | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-log>
          <tr>
            <td>{{log.message | translate: getLogParamters(log)}}</td>
            <td>{{log.line}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <p-button label="{{'REFRESH' | translate}}" icon=" fas fa-sync-alt" (click)="getImportProcessLog()"></p-button>
  </div>
</div>
