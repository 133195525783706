import { Injectable } from '@angular/core';
import { Subject } from '../../../../node_modules/rxjs';
import { Observable } from 'rxjs/Observable';
import { Sign } from '../../models/sign.model';

@Injectable()
export class SignOrderService {
  _signs = [] as Sign[];
  private signsSource = new Subject<Sign[]>();
  signsHandler: Observable<Sign[]> = <Observable<Sign[]>>(
    this.signsSource.asObservable()
  );

  set signs(signs: Sign[]) {
    signs = signs ? signs : [];
    signs = signs.sort((a, b) => {
      return b.order - a.order;
    });
    this._signs = signs;
    this.signsSource.next(this._signs);
  }
  get signs(): Sign[] {
    if (!this._signs) {
      this._signs = [];
    }
    this._signs.sort((a, b) => {
      return b.order - a.order;
    })
    return this._signs;
  }

  constructor() {}
}
