import {Injectable} from '@angular/core';
import {Code} from '../../models/code.model';
import {environment} from '../../../environments/environment';
import {BaseAbstractService} from './base-abstract.service';

@Injectable()
export class SupportSpecificityService extends BaseAbstractService
{
  _codes: Code[];

  _defaults = {
    SPRB_AGENT: 'URBAN',
    CONTRACTOR: 'GOLDEN',
    MUNICIPALITY_AGENT: 'GALVANIZED'
  };

  get url(): string {
    return environment.backendURL + environment.supportPath+ this.configService.appConfig.apiUrl + 'Support/SupportSpecificities/';
  }

  async findAll(): Promise<Code[]> {
    this._codes = this._codes ? this._codes: await this.get<Code[]>(`${this.url}`);
    return this._codes;
  }

  get default(): Code {
    const role = this._session.roles[0].code;
    return this._codes.find((gestion) => {
      return this._defaults[role] ? gestion.code === this._defaults[role] : true;
    });
  }
}
