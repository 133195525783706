import {NgModule} from '@angular/core';

import {SharedModule} from 'app/shared/shared.module';
import {LayoutActionsPanelComponent} from './layout.actions-panel.component';
import {TasksButtonComponent} from './tasks/layout.tasks-button.component';
import {SearchRCButtonComponent} from './search-rc/layout.search-rc-button.component';
import {SearchSignButtonComponent} from './search-sign/layout.search-sign-button.component';
import {DayOrderRCButtonComponent} from './day-order-rc/layout.day-order-rc-button.component';
import {UniqueRegulationComponent} from './unique-regulation/layout.unique-regulation-button.component';

const MODULES = [
    SharedModule
  ];

const DECLARATIONS = [
  LayoutActionsPanelComponent,
  TasksButtonComponent,
  SearchRCButtonComponent,
  SearchSignButtonComponent,
  DayOrderRCButtonComponent,
  UniqueRegulationComponent
];

@NgModule({
    declarations: DECLARATIONS,
    imports: MODULES,
    exports: [LayoutActionsPanelComponent]
})
export class LayoutActionsPanelModule {}
