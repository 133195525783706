import {Component, Injector, OnInit} from '@angular/core';
import {LayoutService} from '../../../core/services/layout.service';
import {MunicipalityService} from '../../../core/services/municipality.service';
import {SelectCode} from '../../../models/code.model';
import {Municipality} from '../../../models/municipality.models';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Message} from 'primeng/components/common/api';
import {RcService} from '../../../core/services/rc.service';
import {ROLES} from '../../../models/role.model';
import {UserService} from '../../../core/services/user.service';
import {PanelSearchRcResultComponent} from '../panel-search-rc-result/panel-search-rc-result.component';
import {NotificationService} from '../../../core/services/notification.service';
import {TranslationService} from '../../../core/services/translation.service';
import {CALENDAR_LOCALE_EN} from '../../../shared/services/utils';
import {LoaderService} from 'app/core/services/loader.service';
import {map} from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'panel-search-rc',
  templateUrl: './panel-search-rc.component.html',
  styleUrls: ['./panel-search-rc.component.scss']
})
export class PanelSearchRcComponent implements OnInit {
  translate: TranslateService = this.injector.get(TranslateService);

  myCalendarLocal: any = CALENDAR_LOCALE_EN;
  errorMessages: Message[] = [];

  status: string[] = [
    'DRAFT',
    'PENDING',
    'ACTIVE',
    'ABROGATED',
    'REFUSED_CC',
    'REFUSED_MINISTER',
  ];

  tasks: string[] = [
    'CC',
    'COM_CONSULT',
    'MINISTER',
    'RECEIVED',
  ];

  rcSearch = {
    municipality: [],
    title: null,
    status: null,
    tasks: null,
    introductionDateStart: null,
    introductionDateEnd: null
  };

  showCommune = true;

  searchRcForm: FormGroup;

  municipalityOptions: SelectCode[] = new Array();

  displayNoResult = false;

  constructor(
    private layoutService: LayoutService,
    private rcService: RcService,
    private errorService: NotificationService,
    private municipalityService: MunicipalityService,
    private userService: UserService,
    private injector: Injector,
    private translationService: TranslationService,
    private fb: FormBuilder,
    private loaderService: LoaderService
  ) {
  }

  ngOnInit() {
    this.initComponent();

    this.searchRcForm = this.fb.group({
      municipalities: this.fb.control(''),
      title: this.fb.control(''),
      status: this.fb.control(''),
      tasks: this.fb.control(''),
      introductionDate: this.fb.control('')
    });
  }

  async initComponent() {
    this.myCalendarLocal = await this.translationService.localeCalendar();

    try {
      this.loaderService.showLoader();

      this.rcSearch = {
        municipality: [],
        title: null,
        status: null,
        tasks: null,
        introductionDateStart: null,
        introductionDateEnd: null
      };
      await this.loadLists();
    } catch (e) {
      this.errorService.addSingleError(e);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async loadLists() {
    return await Promise.all([
      this.loadMunicipality(this.municipalityService, this.municipalityOptions)
    ]);
  }

  async loadMunicipality(service, options: SelectCode[]): Promise<undefined> {
    try {
      options.splice(0, options.length);
      if (this.userService.role.code === ROLES.MUNICIPALITY_AGENT) {
        this.showCommune = false;
        this.rcSearch.municipality = [
          {
            id: this.userService.municipality.id,
            code: this.userService.municipality.name
          }
        ];
      } else {
        const values: Municipality[] = await service.findAll();
        values.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } else {
            return 0;
          }
        });
        values.forEach(val => {
          const selectCode: SelectCode = {
            label: val.name,
            value: {id: val.id, code: val.name}
          };
          options.push(selectCode);
        });
      }
    } catch (error) {
      this.errorService.addSingleError(error);
    }

    return;
  }

  async submit() {
    if (!this.searchRcForm.invalid) {

      this.errorMessages = [];

      try {
        this.loaderService.showLoader();

        const results = await this.rcService.search(
          this.rcSearch
        ).toPromise();
        this.rcService.rcSearchResult = results;

        if (results.length === 0) {
          this.displayNoResult = true;
        } else {
          this.layoutService.leftPanelContent = PanelSearchRcResultComponent;
          this.layoutService.leftPanelVisible = true;
        }
      } catch (error) {
        this.errorService.addSingleError(error);
      } finally {
        this.loaderService.hideLoader();
      }
    }
  }

  reset() {
    this.initComponent();
  }

  close() {
    this.layoutService.closeRightPanel();
  }
}
