<div class="ui-g">
  <div class="ui-g-12">
    <p-table [value]="administrationService.editMode?editParameters:parameterService.parameters">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'CODE'|translate}}</th>
          <th width="20%">{{'VALUE'|translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-param>
        <tr>
          <td>{{param.code | translate}}</td>
          <td style="text-align: center">
              <span *ngIf="!administrationService.editMode">
                {{param.value}}
              </span>
            <span *ngIf="administrationService.editMode">
                <input class="editSupportInput" [type]="param.type" pInputText [(ngModel)]="param.value" />
              </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div style="text-align: right; margin-top: 10px;">
      <p-button *ngIf="administrationService.editMode" class="secondary" [ngStyle]="{'margin-right':'5px'}" icon="fas fa-times" label="{{'CANCEL'|translate}}" (click)="cancelEdit()"></p-button>
      <p-button *ngIf="administrationService.editMode" icon="fas fa-save" label="{{'SAVE'|translate}}" (click)="saveParam()"></p-button>
      <p-button *ngIf="!administrationService.editMode" [ngStyle]="{'margin-right':'5px'}" icon="fas fa-edit" label="{{'EDIT'|translate}}" (click)="editParam()"></p-button>
    </div>
  </div>
</div>
