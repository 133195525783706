import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from '../../../core/services/map.service';
import { HelpdeskComponent } from '../../../shared/components/helpdesk/helpdesk.component';
import { NotificationService } from '../../../core/services/notification.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'helpdesk-icon',
  templateUrl: './layout.helpdesk-icon.component.html',
  styleUrls: ['./layout.helpdesk-icon.component.scss']
})
export class HelpdeskIconComponent {
  constructor(
    public notificationService: NotificationService
  ) {}
  showHelpdeskInfo() {
    this.notificationService.openDialog(HelpdeskComponent, {
      header: 'Helpdesk',
      width: '40%'
    });
  }
}
