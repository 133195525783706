import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { OauthService } from './oauth.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Session } from '../../app.session';
import { NotificationService } from './notification.service';
import {from} from 'rxjs';
import {catchError, flatMap, map, tap} from 'rxjs/operators';
import Utils from '../../shared/services/utils';

@Injectable()
export class BaseAbstractService extends OauthService {
  constructor(
    protected _router: Router,
    protected _http: HttpClient,
    protected _session: Session,
    protected configService: ConfigService,
    protected errorService: NotificationService
  ) {
    super(_router, _http, _session);
  }

  async get<T>(url: string, options?): Promise<any> {
    options = await this.handleOAuthHeader(options);

    return this._http.get<T>(url, options).toPromise();
  }

  download<T>(url, shouldCatchError = true) {
    const downloadTask = from(this.handleOAuthHeader({})).pipe(
      map(options => {
        options.responseType = 'blob';
        options.observe = 'response';
        return options;
      }),
      flatMap(options => this.get<any[]>(url, options)),
      tap((response: any) => {
        const blob = response.body;
        Utils.downloadBlob(blob, response.headers.get('filename'));
      }));

    if (shouldCatchError) {
      return downloadTask.pipe(catchError(e => this.errorService.addSingleError(e)));
    } else {
      return  downloadTask;
    }
  }

  async getBlob(url: string, options?): Promise<any> {
    options = await this.handleOAuthHeader(options);
    options.responseType = 'blob';
    options.observe = 'response';

    return this._http.get<Response>(url, options).toPromise();
  }
  async postBlob(url: string, data, options?): Promise<any> {
    options = await this.handleOAuthHeader(options);
    options.responseType = 'blob';
    options.observe = 'response';

    return this._http.post<Response>(url, data, options).toPromise();
  }

  async post<T>(url: string, data, options?): Promise<any> {
    options = await this.handleOAuthHeader(options);

    return this._http
      .post<T>(url, data, options)
      .toPromise();
  }

  async postNoAuth<T>(url: string, data, options?): Promise<any> {
    return this._http.post<T>(url, data, options).toPromise();
  }

  async put<T>(url: string, data, options?): Promise<any> {
    options = await this.handleOAuthHeader(options);

    return this._http
      .put<T>(url, data, options)
      .toPromise();
  }

  async delete<T>(url: string, options?): Promise<any> {
    options = await this.handleOAuthHeader(options);

    return this._http.delete<T>(url, options).toPromise();
  }
}
