import { Component, Injector, OnInit } from '@angular/core';
import { NotificationService } from '../../../core/services/notification.service';
import { AdministrationService } from '../../../core/services/administration.service';
import { Picture } from '../../../models/picture.model';
import { LayoutService } from '../../../core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'app/core/services/loader.service';
import { ImportProcessLogModel } from '../../../models/import-process-log.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss']
})
export class ImportDataComponent implements OnInit {
  translate: TranslateService = this.injector.get(TranslateService);

  file: File = null;

  uploadResult: any = null;
  logs$: BehaviorSubject<ImportProcessLogModel[]> = new BehaviorSubject<ImportProcessLogModel[]>(null);

  constructor(
    private notificationService: NotificationService,
    private administrationService: AdministrationService,
    private injector: Injector,
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {}

  uploadhandler($event) {
    try {
      if ($event.files && $event.files.length > 0) {
        this.file = $event.files[0];
      } else {
        this.file = null;
      }
    } catch (error) {
      this.notificationService.addSingleError(error);
    }
  }

  async readFile(file: File): Promise<any> {
    const reader = new FileReader();
    const def = new Promise<Picture>(resolve => {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve({ file: base64data, name: file.name });
      };
    });
    return def;
  }

  async uploadFile() {
    this.loaderService.showLoader();

    try {
      if (this.file) {
        const fileBase64 = await this.readFile(this.file);
        this.uploadResult = await this.administrationService.uploadCommunalRef(fileBase64);
        this.getImportProcessLog();


        // if (this.uploadResult) {
        //   const infoMessageTitle: string = await this.translate
        //     .get('INFORMATION_MESSAGE')
        //     .toPromise();
        //   const infoMessageContent: string = await this.translate
        //     .get('ADMINISTRATION:DATA_UPDATE')
        //     .toPromise();
        //
        //   this.notificationService.addSingleInfo(
        //     infoMessageTitle,
        //     infoMessageContent
        //   );
        // }
      }
    } catch (error) {
      this.notificationService.addSingleError(this.translateService.instant(error.error.ExceptionMessage, error.error.Parameters));
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async getImportProcessLog() {
     this.administrationService.getImportProcessLog().then(results => this.logs$.next(results));
  }

  getLogParamters(log: ImportProcessLogModel) {
    if (log.parameters == null) {
      return {};
    }

    const param = {};
    log.parameters.forEach(p => {
      param[p.paramKey] = p.paramValue;
    });
    return param;
  }
}
