<p-button class="width100 full-width" icon="icon fas fa-fw fa-download" label="{{ 'LAYOUT:BUTTON:DAY_ORDER_RC' | translate }}" (onClick)="showDayOrder()"></p-button>

<p-dialog id="dialogDayOrder" header="" modal="modal" [(visible)]="displayDialogDayOrder" [width]="500" >
  <p-header>
    {{ 'LAYOUT:DAY_ORDER:CONFIRME:DAY_ORDER' | translate }}
  </p-header>
  <div class="ui-g">
    <div class="ui-g-12">
      <p-table [value]="dayOrders">
        <ng-template pTemplate="header">
          <tr>
            <th>{{"RC" | translate}}</th>
            <th>{{"TOWN" | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dayOrder>
          <tr>
            <td>{{dayOrder.title}}</td>
            <td>{{dayOrder.municipalityName}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="ui-g-12">
      <p-button icon="fas fa-cogs" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'LAYOUT:DAY_ORDER:BUTTON:SHOW' | translate}}" (click)="downloadDayOrder()"></p-button>
      <p-button icon="fa fa-fw fa-times" [ngStyle]="{'margin-left':'5px'}" class="secondary right" label="{{'LAYOUT:DAY_ORDER:BUTTON:CLOSE' | translate}}" (click)="close()"></p-button>
    </div>

  </div>
</p-dialog>
