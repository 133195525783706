export const routerLink = {
  login: "/login",

  map: "/map",

//  support: "/supports/show/{id}",
//  supportEdit: "/supports/edit/{id}",
//  supportNew: "/supports/new/{x}/{y}",

//  sign: "/signs/{idSupp}/show/{id}",
//  signEdit: "/signs/{idSupp}/edit/{id}",
//  signNew: "/signs/{idSupp}/new",
//  signNewOrder: "/signs/{idSupp}/new/{order}",

};
