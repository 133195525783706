<div class="edit-sign-order">
  <div class="edit-sign-order-container" >
      <div class="ui-g" *ngIf="!reloadPage">

        <div *ngIf="!edit" class="ui-g-12 signs-container">
          <div *ngFor="let sign of signs">
            <img appSignOpacity
                 [sign]="sign"
                 src="{{sign.type| signTypeImage}}"
                 class="sign-img edit"
                 (click)="showSign(sign)"/>
          </div>
        </div>
        <div *ngIf="edit" id="signGroupVisuel" class="ui-g-12 signs-container" dragula="SIGNS" [(dragulaModel)]="signs">
          <div class="sign-container" *ngFor="let sign of signs">
            <img appSignOpacity
                 [sign]="sign"
                 src="{{sign.type| signTypeImage}}"
                 class="sign-img"
                 [title]="sign.order"/>
            <i class="pi pi-times sign-del"
              *ngIf="sign.mobileStatus !== this.signService.MOBILE_STATUS_DELETE || !this.userService.hasRole(this.userService.contractorCode)"
              (click)="displayDelete(sign.id)"></i>
          </div>
        </div>
      </div>
  </div>
  <div class="ui-g-4" *ngIf="supportId && canUpdate">
    <p-button icon="fas fa-plus-square" label="{{'ADD' | translate}}" (click)="newSign()"></p-button>
  </div>
</div>


<p-confirmDialog class="confirmDialog" header="{{'CONFIRMATION' | translate }}" appendTo="body" message="" #deletesign>
  <p-footer>
    <div>
      <p-button icon="fa fa-check" [ngStyle]="{'margin-left':'5px'}" class="right" label="{{'YES' | translate}}"
                (click)="deletesign.accept()"></p-button>
      <p-button icon="fa fa-times" [ngStyle]="{'margin-left':'5px'}" class="secondary right"
                label="{{'NO' | translate}}"
                (click)="deletesign.reject()"></p-button>
    </div>
  </p-footer>
</p-confirmDialog>
