import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Column} from 'primeng/shared';
import {TreeNode} from 'primeng/api';
import {TreeTable} from 'primeng/treetable';
import {Measure, RcMeasure, SignTypeMeasure} from '../../../../models/measure.model';
import {TreeNodeSelectable} from '../../../../models/tree-node-selectable';
import {TreeNodeUtils} from '../../../../core/utils/treenode-utils';

@Component({
  selector: 'app-measure-list',
  templateUrl: './measure-list.component.html'
})
export class MeasureListComponent implements OnChanges {
  @Input() measures: TreeNodeSelectable[];
  @Input() editMode: boolean;
  @Input() disabled = false;
  @Output() add: EventEmitter<Measure> = new EventEmitter<Measure>();
  @Output() update: EventEmitter<Measure> = new EventEmitter<Measure>();
  @Output() delete: EventEmitter<Measure> = new EventEmitter<Measure>();
  @ViewChild('tt', {static: false}) tt: TreeTable;

  cols: Partial<Column | any>[];
  selection: TreeNode[];

  constructor() {
    this.cols = [
      {header: 'ARTICLE_HEADER_MEASURES', field: 'articleId', class: 'w-140', filterMatchMode: 'startsWith'},
      {header: 'TEXTFR_HEADER_MEASURES', field: 'textFr', filterMatchMode: 'contains'},
      {header: 'TEXTNL_HEADER_MEASURES', field: 'textNl', filterMatchMode: 'contains'},
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.measures) {
      this.selection = [];
      this.select(this.measures);
      this.measures.map(measure => TreeNodeUtils.restoreState(changes.measures.previousValue, measure));
    }
  }

  private select(nodes: TreeNodeSelectable[]): void {
    if (!nodes || !nodes.length) {
      return;
    }
    this.selection.push(...nodes.filter(measure => measure.selected || measure.data.checked));
    nodes.forEach(node => this.select(node.children));
  }

  get signTypeMeasure(): SignTypeMeasure[] {
    return TreeNodeUtils.flattenTreeNode(this.measures).map(node => <SignTypeMeasure>{
      ...node.data,
      checked: !!this.selection.find(measure => measure.data.id === node.data.id)
    });
  }

  get rcMeasures(): RcMeasure[] {
    return TreeNodeUtils.flattenTreeNode(this.measures).map(node => <RcMeasure>{
      ...node.data,
      id: node.data.id < 0 ? null : node.data.id,
      checked: !!this.selection.find(measure => measure.data.measureId === node.data.measureId)
    });
  }

  filter(value: string, col: Column): void {
    this.tt.filter(value, col.field, col.filterMatchMode);
    this.measures = [...this.measures]; // because PrimeNG is a total shitty library (https://www.primefaces.org/primeng/#/treetable)
  }

  setParentAndUpdate(node: any) {
    const index = this.rcMeasures.findIndex(measure =>
      measure.articleId === node.node.data.articleId && measure.parentId === node.node.data.parentId && measure.signId === node.node.data.signId
    );
    this.update.emit({...node.node.data, parent: node.parent ? node.parent.data : null, index: index});
  }
}
