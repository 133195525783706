import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import {Support, SupportHelper} from '../../../models/support.model';
import {SupportService} from '../../../core/services/support.service';
import {Router} from '@angular/router';
import {Sign} from '../../../models/sign.model';
import {routerLink} from '../../../../assets/config/routerLink';
import {UserService} from '../../../core/services/user.service';
import {Code, SelectCode} from '../../../models/code.model';
import {Message} from 'primeng/components/common/api';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SignService} from '../../../core/services/sign.service';
import {LayoutComponent} from '../../layout/layout.component';
import {SupportTypeService} from '../../../core/services/support-type.service';
import {SupportGestionService} from '../../../core/services/support-gestion.service';
import {ConfirmationService} from 'primeng/api';
import {SupportSpecificityService} from '../../../core/services/support-specificity.service';
import {LayoutService} from '../../../core/services/layout.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SignOrderService} from '../../../core/services/sign-order.service';
import {MapService} from '../../../core/services/map.service';
import * as ol from 'ol';
import * as olGeom from 'ol/geom';
import * as olInteraction from 'ol/interaction';
import * as olLayer from 'ol/layer';
import * as olStyle from 'ol/style';
import * as olSource from 'ol/source';
import {SignComponent} from '../../signs/sign/sign.component';
import {NotificationService} from '../../../core/services/notification.service';
import Utils from '../../../shared/services/utils';
import {LoaderService} from 'app/core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  providers: [ConfirmationService]
})
export class SupportComponent implements OnInit, OnDestroy {
  translate: TranslateService = this.injector.get(TranslateService);

  get id(): number {
    return this.supportService.support ? this.supportService.support.id : null;
  }

  _editSupport: Support = <Support>{};

  get editSupport(): Support {
    return this._editSupport;
  }

  set editSupport(support: Support) {
    this._editSupport = Object.assign({}, support);
    if (this.editSupport.address) {
      const address = this.editSupport.address
        ? JSON.parse(this.editSupport.address)
        : '';
      this.supportForm.get('address').patchValue(address);
    }
    if (this.supportService.editMode) {
      if (!this.editSupport.orientation) {
        this.editSupport.orientation = 0;
      }
      if (!this.editSupport.gestion) {
        this.editSupport.gestion = this.supportGestionService.default;
      }
      if (!this.editSupport.specificity) {
        this.editSupport.specificity = this.supportSpecificityService.default;
      }
      if (!this.editSupport.type) {
        this.editSupport.type = this.supportTypeService.default;
      }
    } else {
      this.updateSupportSigns();
    }
  }

  typeOther = 'OTHERS';

  placementDate: Date = null;
  removalDate: Date = null;

  oldSigns: Sign[] = new Array();

  supportForm: FormGroup;

  errorMessages: Message[] = [];

  /*------SELECT OPTIONS------*/
  supportGestionOptions: SelectCode[] = new Array();
  supportTypeOptions: SelectCode[] = new Array();
  supportSpecificityOptions: SelectCode[] = new Array();

  supportChanges: Subscription;

  supportVectorSource = new olSource.Vector({
    wrapX: false
  });
  supportOffsetVectorSource = new olSource.Vector({
    wrapX: false
  });
  supportVectorLayer = new olLayer.Vector({
    source: this.supportVectorSource,
    style: new olStyle.Style({
      image: new olStyle.Circle({
        radius: 5,
        fill: new olStyle.Fill({color: '#ffee00'}),
        stroke: new olStyle.Stroke({color: '#ffee00', width: 2})
      })
    })
  });
  supportOffsetVectorLayer = new olLayer.Vector({
    source: this.supportOffsetVectorSource,
    style: new olStyle.Style({
      image: new olStyle.Circle({
        radius: 5,
        fill: new olStyle.Fill({color: '#00ee00'}),
        stroke: new olStyle.Stroke({color: '#00ee00', width: 1})
      })
    })
  });
  supportVectorModify = new olInteraction.Modify({
    source: this.supportVectorSource
  });
  supportOffsetVectorModify = new olInteraction.Modify({
    source: this.supportOffsetVectorSource
  });

  newData: any;
  results: string[] = [];

  supportOrientationVectorSource = new olSource.Vector();
  supportOrientationVectorLayer = new olLayer.Vector({
    source: this.supportOrientationVectorSource,
    style: new olStyle.Style({
      stroke: new olStyle.Stroke({
        color: '#ffcc33',
        width: 2
      })
    })
  });

  _orientationActive = false;

  constructor(
    public supportService: SupportService,
    public userService: UserService,
    private router: Router,
    private fb: FormBuilder,
    private signService: SignService,
    private supportTypeService: SupportTypeService,
    private supportGestionService: SupportGestionService,
    private supportSpecificityService: SupportSpecificityService,
    protected layout: LayoutComponent,
    private layoutService: LayoutService,
    private confirmationService: ConfirmationService,
    private injector: Injector,
    private mapService: MapService,
    private errorService: NotificationService,
    private signOrderService: SignOrderService,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.initComponent();

    this.supportForm = this.fb.group({
      geom: this.fb.control(''),
      supportGestion: this.fb.control(''),
      supportType: this.fb.control(''),
      typeOthers: this.fb.control(''),
      supportSpecificity: this.fb.control(''),
      heightOffGround: this.fb.control(''),
      placementDate: this.fb.control(''),
      removalDate: this.fb.control(''),
      address: this.fb.control(''),
      number: this.fb.control(''),
      note: this.fb.control(''),
      other: this.fb.control(''),
      orientation: this.fb.control(''),
    });

    this.layoutService.rightPanelClosedHandler.subscribe(e => {
      this.ngOnDestroy();
    });

    this.supportChanges = this.supportService.supportHandler.subscribe(
      (support: Support) => {
        this.updateSupportSigns();
        this.updateDate();
      }
    );
    this.updateDate();
    this.supportService.editMode$.subscribe(val => {
      if (val) {
        /*this.supportVectorModify.on(
          'modifyend',
          (e: olInteraction.Modify.Event) => {
            const feature = e.features.getArray()[0];
            this.applyGeom(feature);
            console.info('geom');
          }
        );
        this.supportOffsetVectorModify.on(
          'modifyend',
          (e: olInteraction.Modify.Event) => {
            const feature = e.features.getArray()[0];
            this.applyOffsetGeom(feature);
            console.info('geomOffset');
          }
        );*/
      }
    });
  }

  getAddressLabel(address) {
    return Utils.getAddressLabel(address);
  }

  ngOnDestroy() {
    this.supportService.editMode = false;
    this.supportChanges.unsubscribe();
    const map = this.mapService.mapManager.map.olMap;
    map.removeLayer(this.supportVectorLayer);
    map.removeLayer(this.supportOffsetVectorLayer);
    this.supportVectorSource.clear();
    this.supportOffsetVectorSource.clear();
    this.supportVectorModify.setActive(false);
    this.supportOffsetVectorModify.setActive(false);
    this.deactivateOrientation();
  }

  async initComponent() {
    try {
      this.loaderService.showLoader();

      await this.loadLists();
      if (!this.supportService.support) {
        this.supportService.support = {};
      }
      this.editSupport = this.supportService.support;
      this.showSupportOnMap();
      if (this.supportService.support.id) {
        this.supportService.support.oldSigns = await this.signService.findOldSignsForSupport(
          this.supportService.support.id
        );
      }
    } catch (e) {
      this.errorService.addSingleError(e);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async loadLists() {
    return await Promise.all([
      this.loadCode(
        this.supportGestionService,
        this.supportGestionOptions,
        null
      ),
      this.loadCode(this.supportTypeService, this.supportTypeOptions, null),
      this.loadCode(
        this.supportSpecificityService,
        this.supportSpecificityOptions,
        null
      )
    ]);
  }

  async loadCode(
    service,
    options: SelectCode[],
    empty: string
  ): Promise<undefined> {
    let values: Code[] = await service.findAll();
    values = empty
      ? (<Code[]>[{id: undefined, code: empty}]).concat(values)
      : values;
    const translatedValues = await Promise.all(
      values.map(async val => {
        return {
          label: await this.translate.get(val.code).toPromise(),
          value: val
        };
      })
    );
    translatedValues.sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      } else if (a.label < b.label) {
        return -1;
      } else {
        return 0;
      }
    });
    translatedValues.forEach(val => options.push(val));
    return;
  }

  updateDate() {
    if (
      this.supportService.support &&
      this.supportService.support.placementDate
    ) {
      this.placementDate = new Date(this.supportService.support.placementDate);
    }
    if (
      this.supportService.support &&
      this.supportService.support.removalDate
    ) {
      this.removalDate = new Date(this.supportService.support.removalDate);
    }
  }

  async updateSupportSigns() {
    this.signOrderService.signs.splice(0, this.signOrderService.signs.length);

    if (this.supportService.support && this.supportService.support.id) {

      try {
        this.loaderService.showLoader();

        this.signOrderService.signs = (await this.signService.getSignsForSupportId(
          this.supportService.support.id
        )).sort((a, b) => b.order - a.order);
      } catch (error) {
        this.errorService.addSingleError(error);
      } finally {
        this.loaderService.hideLoader();
      }
    }
  }

  edit() {
    this.supportService.editMode = true;
  }

  featureToJSON(feature) {
    const geometry = feature.getGeometry();
    const coords = geometry.getCoordinates();
    const json =
      '{"type":"Point","coordinates":[' + coords[0] + ',' + coords[1] + ']}';
    return json;
  }

  applyGeom(feature) {
    this.editSupport['geom'] = this.featureToJSON(feature);
  }

  applyOffsetGeom(feature) {
    this.editSupport['geomOffset'] = this.featureToJSON(feature);
  }

  async submit() {
    try {
      this.loaderService.showLoader();

      if (!this.supportForm.invalid) {
        this.errorMessages = [];

        if (
          this.editSupport.placementDate &&
          this.editSupport.removalDate &&
          this.editSupport.placementDate > this.editSupport.removalDate
        ) {
          console.error('error entre les dates');
        }

        // Si c'est un nouveau support, il faut absolument lui associer une signalisation.
        if (!this.editSupport.id) {
          this.createNew();
        } else {
          await this.update();
        }

        this.deactivateOrientation();

        this.supportVectorModify.setActive(false);
        this.supportOffsetVectorModify.setActive(false);
      }
    } finally {
      this.loaderService.hideLoader();
    }
  }

  createNew() {
    this.signService.newSupport = this.editSupport;
    this.signService.editMode = true;
    if (!(this.signService.sign && !this.signService.sign.id)) {
      this.signService.sign = <Sign>{};
    }
    this.layoutService.rightPanelContent = SignComponent;
    this.layoutService.rightPanelVisible = true;
  }

  async update() {
    const supportUpdate = this.editSupport;

    let deletedSigns = this.signOrderService.signs.filter((s, i) => s.mobileStatus === this.signService.MOBILE_STATUS_DELETE);
    for (let s of deletedSigns) {
      this.signService.updateSign(s);
    }

    supportUpdate.signOrders =
      this.signOrderService.signs &&
      this.signOrderService.signs.map(s => {
        return {order: s.order, id: s.id};
      });

    if (this.signService.signOrder && this.signService.signOrder.length > 0) {
      // il y a eu une modification au niveau de l'ordre des signalisation
      supportUpdate.signOrders = this.signService.signOrder;
    }

    try {
      const support: Support = await this.supportService.createOrUpdate(
        supportUpdate
      );
      if (support && support.signOrders && support.signOrders.length > 0) {
        this.supportService.support = support;
        this.editSupport = support;
        await this.updateSupportSigns();
        this.supportService.editMode = false;
      } else {
        // support supprimer
        this.close();
      }
      this.mapService.refresh();
      this.supportService.support.oldSigns = await this.signService.findOldSignsForSupport(
        this.supportService.support.id
      );
      this.supportService.editMode = false;
    } catch (error) {
      this.errorService.addSingleError(error);
    }
  }

  async cancel() {
    this.supportVectorModify.setActive(false);
    this.supportOffsetVectorModify.setActive(false);
    if (!this.editSupport.id) {
      this.close();
    }
    await this.updateSupportSigns();
    if (
      this.supportService.editMode === true &&
      this.editSupport === this.supportService.support
    ) {
      console.log('support has change...');
    }
    this.editSupport = this.supportService.support;
    this.supportService.editMode = false;

    this.mapService.mapManager.map.olMap.removeInteraction(
      this.supportVectorModify
    );
    this.mapService.mapManager.map.olMap.removeInteraction(
      this.supportOffsetVectorModify
    );

    this.showSupportOnMap();
  }

  remove() {
    this.confirmationService.confirm({
      message: '',
      accept: () => {
        this.confirmationRemove();
      }
    });
  }

  async confirmationRemove() {
    try {
      const rep = await this.supportService.deleteSupport(
        this.supportService.support.id
      );
      this.router.navigate([routerLink.map]);
    } catch (error) {
      this.errorService.addSingleError(error);
    }
  }

  showSupportOnMap() {
    const map = this.mapService.mapManager.map.olMap;
    map.addLayer(this.supportVectorLayer);
    map.addLayer(this.supportOffsetVectorLayer);
    this.supportVectorSource.clear();
    this.supportOffsetVectorSource.clear();
    this.supportVectorLayer.setZIndex(20);
    this.supportOffsetVectorLayer.setZIndex(21);
    const geom = SupportHelper.getFeature(this.editSupport);
    const geomOffset = SupportHelper.getOffsetFeature(this.editSupport);
    this.applyGeom(geom);
    this.applyOffsetGeom(geomOffset);
    this.supportVectorSource.addFeature(geom);
    this.supportOffsetVectorSource.addFeature(geomOffset);
  }

  public async search(event: any) {
    try {
      const data: any[] = await this.supportService.getStreets(
        this.translate.currentLang,
        event.query
      );

      if (data && data.length === 0) {
        this.newData = event.query;
      } else {
        this.newData = null;
      }

      this.results = data;
    } catch (error) {
      this.errorService.addSingleError(error);
    }
  }

  close() {
    this.ngOnDestroy();
    this.layoutService.closeRightPanel();
  }

  modifyOffsetEndHandler = (e: olInteraction.Modify.Event) => {
    const feature = e.features.getArray()[0];
    this.applyOffsetGeom(feature);
    console.info('geomOffset');
  }

  activeTranslating() {
    this.deactivateOrientation();
    this.deactivateRepositioning();
    this.mapService.mapManager.map.olMap.addInteraction(
      this.supportOffsetVectorModify
    );
    this.supportOffsetVectorModify.setActive(true);
    this.supportOffsetVectorModify.un('modifyend', this.modifyOffsetEndHandler);
    this.supportOffsetVectorModify.on('modifyend', this.modifyOffsetEndHandler);
  }

  deactivateTranslating() {
    this.mapService.mapManager.map.olMap.removeInteraction(
      this.supportOffsetVectorModify
    );
    this.supportOffsetVectorModify.setActive(false);
  }

  deactivateRepositioning() {
    this.mapService.mapManager.map.olMap.removeInteraction(
      this.supportVectorModify
    );
    this.supportVectorModify.setActive(false);
  }

  modifyEndHandler = (e: olInteraction.Modify.Event) => {
    const feature = e.features.getArray()[0];
    this.applyGeom(feature);
    console.info('geom');
  };

  activeRepositioning() {
    this.deactivateOrientation();
    this.deactivateTranslating();
    this.mapService.mapManager.map.olMap.addInteraction(
      this.supportVectorModify
    );
    this.supportVectorModify.setActive(true);
    this.supportVectorModify.un('modifyend', this.modifyEndHandler);
    this.supportVectorModify.on('modifyend', this.modifyEndHandler);
  }

  deactivateOrientation() {
    this._orientationActive = false;
    this.supportOrientationVectorSource.clear();
    this.mapService.olMap.removeLayer(this.supportOrientationVectorLayer);
  }

  activeOrientation() {
    const base = SupportHelper.getOffsetGeometry(
      this.editSupport
    ).getCoordinates();
    const geom = new olGeom.LineString([base, [base[0], base[1] + 10]]);
    geom.rotate(-this.toRadians(this.editSupport.orientation), base);
    const feature = new ol.Feature(geom);
    this.supportOrientationVectorSource.clear();
    this.supportOrientationVectorSource.addFeature(feature);
    this.supportOrientationVectorLayer.setZIndex(20);
    try {
      this.mapService.olMap.addLayer(this.supportOrientationVectorLayer);
    }
    catch(error) {
      this.mapService.olMap.removeLayer(this.supportOrientationVectorLayer);
      this.mapService.olMap.addLayer(this.supportOrientationVectorLayer);
    }
    this._orientationActive = true;
    this.mapService.mapClickedListener.subscribe(event => {
      if (this._orientationActive) {
        const point = this.mapService.olMap.getEventCoordinate(event);
        const dx = point[0] - base[0];
        const dy = point[1] - base[1];
        const angle = Math.atan2(dx, dy);
        const degrees = (angle * 180) / Math.PI;
        this.editSupport.orientation = Math.round(degrees);
        geom.setCoordinates([base, [base[0], base[1] + 10]]);
        geom.rotate(-this.toRadians(this.editSupport.orientation), base);
      }
    });
  }

  toRadians = function (degrees) {
    return (degrees * Math.PI) / 180;
  };

  // Converts from radians to degrees.
  toDegrees = function (radians) {
    return (radians * 180) / Math.PI;
  };

  async refreshAddress() {
    try {
      await this.supportService.setAddressFromPoint(this.editSupport);
      this.supportForm.get('address').patchValue(JSON.parse(this.editSupport.address));
      this.cdr.detectChanges();
    } catch (error) {
      this.errorService.addSingleError(error);
    }
  }

  canUpdate(): boolean {
    return this.supportService.support.deleteAt == null && this.userService.isInMunicipalities(
      this.supportService.support.geom
    );
  }

  showOldSign(sign: Sign) {
    this.signService.sign = sign;
    this.layoutService.rightPanelVisible = true;
    this.signService.editMode = false;
    this.layoutService.rightPanelContent = SignComponent;
  }

  selectAddress($event) {
    this.editSupport.address = JSON.stringify($event);
  }
}
