import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { SignsComponent } from './signs.component';
import { SignComponent } from './sign/sign.component';
import { SignsRoutingModule } from './signs-routing.module';
import { PanelSearchSignComponent } from './panel-search-sign/panel-search-sign.component';
import { TooltipModule } from 'primeng/tooltip';

const MODULES = [SharedModule, SignsRoutingModule, TooltipModule];

const DECLARATIONS = [SignsComponent, SignComponent, PanelSearchSignComponent];

@NgModule({
  imports: MODULES,
  declarations: [...DECLARATIONS],
  entryComponents: [...DECLARATIONS],
})
export class SignsModule {}
