import { Injectable } from '@angular/core';
import { Support } from '../../models/support.model';
import { environment } from '../../../environments/environment';
import { BaseAbstractService } from './base-abstract.service';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { Sign } from '../../models/sign.model';
import Utils from '../../shared/services/utils';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Session } from '../../app.session';
import { ConfigService } from './config.service';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SupportService extends BaseAbstractService {
  constructor(
    protected _router: Router,
    protected _http: HttpClient,
    protected _session: Session,
    protected configService: ConfigService,
    protected errorService: NotificationService,
    protected translate: TranslateService
  ) {
    super(_router, _http, _session, configService, errorService);
  }
  _selectedSupportsIds: number[];
  private selectedSupportsIdsSource = new Subject<number[]>();
  selectedSupportsIdsHandler: Observable<number[]> = <Observable<number[]>>(
    this.selectedSupportsIdsSource.asObservable()
  );

  set selectedSupportsIds(ids: number[]) {
    this._selectedSupportsIds = Array.from(new Set(ids.map(id => id)));
    this.selectedSupportsIdsSource.next(ids);
  }
  get selectedSupportsIds(): number[] {
    return this._selectedSupportsIds;
  }

  _support: Support;
  private supportSource = new Subject<Support>();
  supportHandler: Observable<Support> = <Observable<Support>>this.supportSource.asObservable();

  set support(support: Support) {
    this._support = support;
    this.supportSource.next(support);
  }
  get support(): Support {
    return this._support;
  }

  _memorizedSupport: Support;
  set memorizedSupport(support: Support) {
    this._memorizedSupport = support;
  }
  get memorizedSupport(): Support {
    return this._memorizedSupport;
  }

  _editMode: boolean;
  private editModeSource = new Subject<boolean>();
  public editMode$: Observable<boolean> = <Observable<boolean>>this.editModeSource.asObservable();
  set editMode(val: boolean) {
    this._editMode = val;
    this.editModeSource.next(val);
  }
  get editMode(): boolean {
    return this._editMode;
  }

  get url(): string {
    return environment.backendURL + environment.supportPath + this.configService.appConfig.apiUrl + 'Support/';
  }

  async getSupport(id: number): Promise<Support> {
    return await await this.get<any>(`${this.url}${id}`);
  }

  async getSupportFull(id: number): Promise<Support> {
    return await this.get<any>(`${this.url}full/${id}`);
  }

  async getSupports(ids: number[]): Promise<Support[]> {
    return await this.post<any>(`${this.url}/ids`, ids);
  }

  async getSupportsFull(ids: number[]): Promise<Support[]> {
    return await this.post<any>(`${this.url}full/ids`, ids);
  }

  async getSupportForSignId(signId: number): Promise<Support> {
    return await this.get<any>(`${this.url}signId/${signId}`);
  }

  async getSupportFullForSignId(signId: number): Promise<Support> {
    return await this.get<any>(`${this.url}full/signId/${signId}`);
  }

  async getSupportsForSignIds(signIds: number[]): Promise<Support[]> {
    return await this.post<any[]>(`${this.url}signIds`, signIds);
  }

  async getSupportsFullForSignIds(signIds: number[]): Promise<Support[]> {
    return await this.post<any[]>(`${this.url}full/signIds`, signIds);
  }

  createOrUpdate(supportUpdate: Support): Promise<Support> {
    if (supportUpdate.id) {
      return this.updateSupportAndSign(supportUpdate);
    } else {
      return this.createSupport(supportUpdate);
    }
  }

  async createSupport(support: Support): Promise<Support> {
    return await this.post<any>(this.url, support);
  }

  async updateSupport(support: Support): Promise<Support> {
    return await this.put<any>(this.url, support);
  }

  async updateSupportAndSign(supportUpdate: Support): Promise<Support> {
    return await this.put<any>(`${this.url}supportandsignsorder`, supportUpdate);
  }

  async findAllSupports(): Promise<Support[]> {
    return await this.get<any[]>(`${this.url}all`);
  }

  async findAllSupportsFull(): Promise<Support[]> {
    return await this.get<any[]>(`${this.url}full/all`);
  }

  async getStreets(lang: string, address: string): Promise<any> {
    const res = await new Promise<any>((resolve, reject) =>
      this._http
        .get<any>(
          '//geoservices.irisnet.be/localization/Rest/Localize/getaddresses?spatialReference=31370&language=' +
            lang +
            '&address=' +
            address +
            '&shortModeActive=false'
        )
        .subscribe(
          val => resolve(val),
          reason => reject(reason)
        )
    );
    const data: any[] = res.result.map(resVal => {
      const street = resVal.address.street;
      street.label = street.name + ' ( ' + street.postCode + ' )';
      return street;
    });
    return data;
  }
  async getAddressFromXY(lang: string, x, y): Promise<any> {
    const res = await new Promise<any>((resolve, reject) =>
      this._http
        .get<any>(
          '//geoservices.irisnet.be/localization/Rest/Localize/getaddressfromxy?json={%22language%22:%22' +
            lang +
            '%22,%22point%22:{%22x%22:%22' +
            x +
            '%22,%22y%22:%22' +
            y +
            '%22},%22SRS_In%22:%2231370%22}'
        )
        .subscribe(
          val => resolve(val),
          reason => reject(reason)
        )
    );
    if (!res.error && res.status === 'success') {
      const address = res.result.address;
      address.street.label = address.street.name + ' ( ' + address.street.postCode + ' )';
      return address;
    } else {
      throw new Error('ERROR return by geocoder');
    }
  }

  async deleteSupport(id: number): Promise<Support> {
    return await this.delete<any>(`${this.url}/${id}`);
  }

  async createWithSigns(support: Support, signs: Sign[]): Promise<Support> {
    support.signs = signs;
    return await this.post<any>(`${this.url}createWithSigns`, support);
  }

  async setAddressFromPoint(support: Support) {
    const supportGeometry = Utils.getGeomPointFromString(support.geom);
    const x = supportGeometry.getCoordinates()[0];
    const y = supportGeometry.getCoordinates()[1];

    const address = await this.getAddressFromXY(this.translate.currentLang, x, y);
    support.address = JSON.stringify(address.street);
    if (address.number) {
      support.number = parseInt(address.number, undefined);
    }
  }
}
