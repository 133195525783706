import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {Sign} from '../../../models/sign.model';
import {SignService} from '../../../core/services/sign.service';
import {SupportService} from '../../../core/services/support.service';
import {SignComponent} from '../../../features/signs/sign/sign.component';
import {MapService} from '../../../core/services/map.service';
import {LayoutService} from '../../../core/services/layout.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../../core/services/user.service';
import {RcComponent} from '../../rc/rc.component';
import {Rc} from '../../../models/rc.model';
import {RcService} from '../../../core/services/rc.service';
import Utils from '../../../shared/services/utils';
import {environment} from '../../../../environments/environment';
import {NotificationService} from '../../../core/services/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from '../../../core/services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'panel-sign-list',
  templateUrl: './panel-sign-list.component.html',
  styleUrls: ['./panel-sign-list.component.scss']
})
export class PanelSignListComponent implements OnInit, OnDestroy {
  get environment() {
    return environment;
  }

  multiSelect = true;

  signSelectedBool = false;
  selectedSupportsIdsChanges: Subscription;

  constructor(
    public signService: SignService,
    private supportService: SupportService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private layoutService: LayoutService,
    private mapService: MapService,
    private userService: UserService,
    private rcService: RcService,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (!this.signService.signsSelected || !this.signService.signsSelected.length) {
      this.addSupportsSigns(this.supportService.selectedSupportsIds);
    }
    this.selectedSupportsIdsChanges = this.supportService.selectedSupportsIdsHandler.subscribe(
      (ids: number[]) => {
        this.addSupportsSigns(ids);
      }
    );

    this.layoutService.leftPanelClosedHandler.subscribe(() => {
      if (this.layoutService.leftPanelClosed) {
        this.ngOnDestroy();
        this.signService.signsSelected = new Array();
      }
    });
  }

  getAddressLabel(address) {
    return Utils.getAddressLabel(address);
  }

  ngOnDestroy() {
    this.selectedSupportsIdsChanges.unsubscribe();
  }

  async addSupportsSigns(ids: number[]) {
    try {
      if (ids) {
        let signs = await this.signService.getSignsFullForSupportsIds(ids);
        signs = this.signService.signsSelected.concat(signs);
        this.signService.signsSelected = signs;
        this.cdr.markForCheck();
      }
    } catch (error) {
      this.notificationService.addSingleError(error);
    }
  }

  isSelected(sign: Sign): boolean {
    return !!this.signService.signsSelected.find(s => s.id === sign.id);
  }

  unselectSign(sign: Sign) {
    if (this.multiSelect) {
      const signsSelected = this.signService.signsSelected;
      const index = signsSelected.indexOf(
        signsSelected.find(s => s.id === sign.id)
      );
      if (index !== -1) {
        signsSelected.splice(index, 1);
        this.signService.signsSelected = signsSelected;
      }
      if (!(this.signService.signsSelected.length > 0)) {
        this.signSelectedBool = false;
      }
    } else {
      this.signService.signsSelected = new Array();
      this.signSelectedBool = false;
    }
  }

  get numberOfElements(): number {
    return this.signService.signs.length;
  }

  async showSign(selectedSign: Sign) {
    try {
      this.loaderService.showLoader();

      const sign: Sign = await this.signService.getSign(selectedSign.id);

      if (!sign.support) {
        sign.support = await this.supportService.getSupportFullForSignId(sign.id);
      }
      const supportGeometry = Utils.getGeomPointFromString(sign.support.geom);
      this.mapService.zoomToFeatures(supportGeometry.getExtent());
      this.mapService.refresh();
      this.signService.sign = sign;
      this.layoutService.rightPanelVisible = true;
      this.signService.editMode = false;
      this.layoutService.rightPanelContent = SignComponent;
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async generateRC() {
    if (this.signService.signsSelected.length > 0) {

      if (this.signService.signsSelected.filter(s => s.deleteAt != null).length > 0) {
        const infoMessageTitle: string = await this.translateService
          .get('INFORMATION_MESSAGE')
          .toPromise();
        const infoMessageContent: string = await this.translateService
          .get('NO_RC_WITH_DELETED_SIGNS')
          .toPromise();

        this.notificationService.addSingleInfo(
          infoMessageTitle,
          infoMessageContent
        );

        return;
      }

      this.rcService.signs = this.signService.signsSelected;
      this.rcService.rc = <Rc>{};
      this.rcService.editMode = true;
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = RcComponent;
      this.layoutService.rightPanelStyle = {width: '800px'};
      this.layoutService.rightPanelFull = true;
      this.mapService.selectSignModeActivated = false;
      this.close();
    }
  }

  cancelSelect() {
    this.mapService.selectSignModeActivated = false;
    this.resetSelect();
    this.supportService.selectedSupportsIds = new Array();
    this.signService.signsSelected = new Array();
    this.layoutService.leftPanelVisible = false;
  }

  resetSelect() {
    this.signService.signsSelected = new Array();
    this.signSelectedBool = false;
  }

  mouseEnter(sign) {
    this.mapService.highlightedSupports = [sign.support];
  }

  mouseLeave(sign) {
    this.mapService.highlightedSupports = [];
  }

  showSignComponent() {
    this.layoutService.rightPanelVisible = true;
    this.signService.sign = this.signService.signsSelected[0];
    this.signService.editMode = false;
    this.layoutService.rightPanelContent = SignComponent;
  }

  hideSignComponent() {
    this.layoutService.closeRightPanel();
  }

  canCreateRc() {
    let ret = false;
    if (this.userService.ROLE_CREATE_RC) {
      if (this.userService.hasRole(this.userService.sprbCode)) {
        ret = true;
      } else {
        if (this.userService.hasRole(this.userService.agentCode)) {
          if (this.userService.municipality) {
            const municipalityGeometry = this.userService.geom;
            if (
              this.signService.signsSelected.findIndex(sign => {
                if (sign.support) {
                  const supportGeometry = Utils.getGeomPointFromString(
                    sign.support.geom
                  );

                  if (
                    !municipalityGeometry.intersectsCoordinate(
                      supportGeometry.getCoordinates()
                    )
                  ) {
                    // si un support n'est pas dans la municipalité
                    return true;
                  }
                } else {
                  return true;
                }
              }) === -1
            ) {
              ret = true;
            }
          }
        }
      }
    }
    return ret;
  }

  async export() {
    try {
      const ids: number[] = [];
      this.signService.signsSelected.forEach(sign => {
        ids.push(sign.id);
      });
      this.signService.export(ids);
    } catch (error) {
      this.notificationService.addSingleError(error);
    }
  }

  close() {
    this.layoutService.leftPanelVisible = false;
  }
}
