import {toString} from 'lodash';
import * as uuiv4 from 'uuid/v4';

export function isNullOrUndefined(val: any): boolean {
  return val === null || val === undefined;
}

export function isEmpty(val: string | Array<any>) {
  if (isNullOrUndefined(val) || toString(val).trim() === '')
    return true;

  return val.length === 0;
}

export function enumToList<T>(e: any): T[] {
  return Object.keys(e).map(k => e[k]);
}

export function uniqueId(): string {
  return uuiv4().toString();
}

/**
 * Compares 2 string values.
 * @param {string} s1
 * @param {string} s2
 * @returns {number}
 */
export function stringComparer(s1: string, s2: string): number {
  return s1 && s2 && s1.localeCompare(s2);
}

export class AppHelpers {

  static hasDuplicates(array: any[], field: string): boolean {
    for (let i = 0; i < array.length; ++i) {
      let value = array[i];
      let duplicateValue = array.filter(c => c[field] === value[field]);
      if (duplicateValue && duplicateValue.length > 1)
        return true;
    }
    return false;
  }
}
