import { Injectable } from '@angular/core';
import { BaseAbstractService } from './base-abstract.service';
import { environment } from '../../../environments/environment';
import { Task } from '../../models/task.model';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Rc } from '../../models/rc.model';
import { Sign } from '../../models/sign.model';
import * as _ from 'lodash';
import {
  CommunalPvFile,
  FirstStepTaskUpdateRequestModel,
} from '../../models/service/first-step-task-update-request-model';

@Injectable()
export class TaskService extends BaseAbstractService {
  private _rcTasks: Rc[] = [];
  private _task: Task = null;
  public rcTask: Rc;

  private _taskSource$ = new Subject<Task>();
  public taskHandler$: Observable<Task> = <Observable<Task>>(this._taskSource$.asObservable());

  private _taskKey = {
    COM_T01: 1, COM_T02: 2, COM_T03: 3,
    COM_T04: 4, COM_T05: 5, REG_T01: 2,
    REG_T02: 3, REG_T03: 4, REG_T04: 5
  };

  public fromSignView: Sign = null;

  set task(task: Task) {
    this._task = task;
    this._taskSource$.next(task);
  }

  get task(): Task {
    return this._task;
  }

  get rcTasks(): Rc[] {
    return this._rcTasks;
  }

  set rcTasks(rcs: Rc[]) {
    this._rcTasks = _.orderBy(rcs, 'id', 'desc' );
  }

  get taskKey() {
    return this._taskKey[this.task.taskKey];
  }

  showTaskEvent(part: string): boolean {
    switch (this._taskKey[part]) {
      case 1:
        return true;
      case 2:
        if (this.taskKey >= 2) {
          return true;
        }
        break;
      case 3:
        if (this.taskKey >= 3) {
          return true;
        }
        break;
      case 4:
        if (this.taskKey === 4) {
          return true;
        }
        break;
      case 5:
        if (this.taskKey === 5) {
          return true;
        }
        break;
      default:
        return false;
    }
    return false;
  }

  waitingTaskEvent(part: string): boolean {
    switch (this._taskKey[part]) {
      case 1:
        if (this.taskKey === 1) {
          return true;
        }
        break;
      case 2:
        if (this.taskKey === 2) {
          return true;
        }
        break;
      case 3:
        if (this.taskKey === 3) {
          return true;
        }
        break;
      case 4:
        if (this.taskKey === 4 && !this.task.finalDate) {
          return true;
        }
        break;
      case 5:
        return false;
      default:
        return false;
    }
    return false;
  }

  get url(): string {
    return (
      environment.backendURL +
      environment.taskPath +
      this.configService.appConfig.apiUrl +
      'Task/'
    );
  }

  async updateTaskRc(data): Promise<boolean> {
    this.normalizeFileName(data);
    return this.post<boolean>(`${this.url + 'update/'}`, data);
  }

  async updateFirstStepOfTask(data: FirstStepTaskUpdateRequestModel): Promise<boolean> {
    data.communalPvFiles.forEach(file => this.normalizeFileName(file));
    return this.post<boolean>(`${this.url + 'update/'}`, data);
  }

  private normalizeFileName(data: CommunalPvFile) {
    if (data.fileName) {
      data.fileName = data.fileName
        .replace(/_/g, '')
        .replace(/|/g, '')
        .replace(/ /g, '');
    }
  }
}
