import { Component, Injector, OnInit } from '@angular/core';
import { LoggedComponent } from '../../../shared/components/logged/logged.component';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import { SignTypeService } from '../../../core/services/sign-type.service';
import { SignType } from '../../../models/code.model';
import { TranslationService } from '../../../core/services/translation.service';
import { LoaderService } from 'app/core/services/loader.service';
import { SelectItem } from 'primeng/api';
import { SignCategoryService } from '../../../core/services/sign-category.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../models/user.model';
import {NotificationService} from '../../../core/services/notification.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sign-type-list',
  templateUrl: './sign-type-list.component.html',
  styleUrls: ['./sign-type-list.component.scss']
})
export class SignTypeListComponent extends LoggedComponent implements OnInit {
  categoriesOption: SelectItem[] = [];
  translate: TranslateService = this.injector.get(TranslateService);
  signTypes: SignType[] = [];

  constructor(
    userService: UserService,
    private injector: Injector,
    public layoutService: LayoutService,
    private notificationService: NotificationService,
    public signTypeService: SignTypeService,
    public signCategoryService: SignCategoryService,
    private loaderService: LoaderService
  ) {
    super(userService);
  }

  async ngOnInit() {
    this.loaderService.showLoader();

    const categories = await this.signCategoryService.findAll();
    categories.forEach(async cat => {
      this.categoriesOption.push({ label: await this.translate.get(cat.code).toPromise(), value: cat.id });
    });

    try {
      this.signTypeService.codes = null;
      await this.signTypeService.loadTranslations();
      await this.signTypeService.findAll().then(types => this.signTypes = this.initSignTypes(types));
    } catch (errror) {

    } finally {
      this.loaderService.hideLoader();
    }
  }

  async generateSignTypeFile() {
    this.loaderService.showLoader();

    try {
      await this.signTypeService.generateSignTypeFile();
      this.notificationService.addSingleSuccess(this.translate.instant('COMMON_SUCESS'), this.translate.instant('SIGN_TYPE_VIEW:BUTTON:GENERATE_RUNNING'));
    } catch (error) {
      this.notificationService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  async downloadSignTypeFile() {
    this.loaderService.showLoader();

    try {
      await this.signTypeService.downloadSignTypeFile().toPromise();
    } catch (error) {
      this.notificationService.addSingleInfo(this.translate.instant('INFORMATION'), this.translate.instant('SIGN_TYPE_VIEW:BUTTON:GENERATE_WAIT'));
    } finally {
      this.loaderService.hideLoader();
    }
  }

  updateSignType(signType: SignType) {
    this.signTypeService.showList = false;
    this.signTypeService.signType = signType;
  }

  addSignType() {
    this.signTypeService.signType = <SignType>{id: null};
    this.signTypeService.showList = false;
  }


  public initSignTypes(signTypes: SignType[]) {
    if (signTypes) {
      return signTypes.map(s => {
        s['categoryId'] = s.typeCategorie.id;
        return s;
      });
    } else {
      return undefined;
    }
  }
}
