import { Layout } from 'app/models/layout.model';
import { Injectable } from "@angular/core";
@Injectable()
export class AppConfig {
  // Global
  apiUrl = 'api/';
  lang = 'fr';
  languages: string[] = ['fr'];

  // Layout
  layout: Layout = new Layout();
}
