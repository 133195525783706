import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MeasureService} from '../../../core/services/api/measure.service';
import {Sign} from '../../../models/sign.model';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import {RcService} from '../../../core/services/rc.service';
import {SignService} from '../../../core/services/sign.service';
import { from, Observable } from 'rxjs';
import {LoaderService} from '../../../core/services/loader.service';
import { RcMeasure } from '../../../models/measure.model';

@Component({
  selector: 'app-sub-measure-form',
  templateUrl: './sub-measure-form.component.html',
  styleUrls: ['./sub-measure-form.component.scss']
})
export class SubMeasureFormComponent implements OnInit {
  form: FormGroup;
  parent: RcMeasure;
  signs$: Observable<Sign[]>;
  nextArticleIdInMunicipality$: Observable<string>;

  constructor(private fb: FormBuilder,
              public measureService: MeasureService,
              private rcService: RcService,
              private cdr: ChangeDetectorRef,
              private signService: SignService,
              private loaderService: LoaderService) {
    this.form = this.fb.group({
      signId: ['', Validators.required],
      articleId: ['', Validators.required],
      textFr: ['', Validators.required],
      textNl: ['', Validators.required],
      id: [{value: null, disabled: true}],
      parentId: [{value: null, disabled: true}],
      level: [{value: null, disabled: true}],
      checked: [{value: true, disabled: true}],
      isAbrogation: [{value: null, disabled: true}],
    });
  }

  ngOnInit(): void {
    this.parent = this.measureService.selected.signId ? this.measureService.selected.parent : this.measureService.selected;
    const selected = this.measureService.selected;
    if (selected.signId) {
      this.form.patchValue({...selected, signId: {id: selected.signId}});
    } else {
      this.form.patchValue({
        id: this.signService.negativeIndex,
        parentId: selected.id,
        level: selected.level + 1,
        isAbrogation: selected.isAbrogation
      });
    }

    this.signs$ = this.measureService.getSigns(selected.signId ? selected.parent.measureId : selected.measureId).pipe(
      tap(() => this.loaderService.showLoader()),
      map(signTypes =>
        signTypes && signTypes.length ? this.rcService.rc.signs.filter(sign => signTypes.find(signType => signType.id === sign.type.id)) : []
      ),
      switchMap(signs => from(this.signService.getSignsFullForSupportsIds(signs.map(sign => sign.supportId))).pipe(
        map(fullSigns => {
          fullSigns.forEach(fullSign => {
            const index = signs.findIndex(sign => sign.id === fullSign.id);
            if (index !== -1) {
              signs[index] = fullSign;
            }
          });

          if (signs && signs.length) {
            this.form.get('signId').setValue(signs[0]);
          }

          return signs;
        })
      )),
      finalize(() => this.loaderService.hideLoader())
    );

    if(!(this.form.controls['articleId'].value)) {
      this.getNextArticleIdInMunicipality(this.parent.articleId).subscribe((articleId) => {
        this.form.get('articleId').setValue(articleId);
      });
    }

    if(!this.measureService.existingSubMeasureArticleIdsInMunicipality
     || this.measureService.existingSubMeasureArticleIdsInMunicipality.length === 0) {
      this.rcService.getExistingArticleIds().subscribe((ids) => {
        this.measureService.existingSubMeasureArticleIdsInMunicipality = ids;
      });
    }

  }

  submit(): void {
    this.measureService.saved$.next({...this.form.getRawValue(), signId: this.form.get('signId').value.id});
  }

  cancel(): void {
    this.form.reset();
    this.measureService.cancelled$.next();
  }

  getNextArticleIdInMunicipality(parentArticleId: string): Observable<string> {
    const childrenArticleIds = this.measureService.existingSubMeasureArticleIds;
    return this.rcService.getNextArticleId(parentArticleId, childrenArticleIds);
  }
}
