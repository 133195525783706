import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseAbstractService } from './base-abstract.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Session } from '../../app.session';
import { ConfigService } from './config.service';
import { NotificationService } from './notification.service';
import { environment } from '../../../environments/environment';
import { Traduction } from '../../models/code.model';
import Utils from '../../shared/services/utils';

@Injectable()
export class TranslationService extends BaseAbstractService {
  _translations: any = {};
  _pending = {};
  constructor(
    @Inject(TranslateService) private translate: TranslateService,
    _router: Router,
    _http: HttpClient,
    _session: Session,
    configService: ConfigService,
    errorService: NotificationService
  ) {
    super(_router, _http, _session, configService, errorService);
  }

  get url(): string {
    return (
      environment.backendURL +
      environment.resourcePath +
      this.configService.appConfig.apiUrl +
      'Resource/translation'
    );
  }

  async getTranslationFor(code: string, language: string): Promise<string> {
    if (!this._pending[language]) {
      this._pending[language] = this.loadTranslation(language);
    }
    await this._pending[language];
    delete this._pending[language];
    return this._translations[language][code];
  }

  async localeCalendar() {
    const sunday: string = await this.translate
      .get('CALANDAR:DAYNAMES:SUNDAY')
      .toPromise();
    const monday: string = await this.translate
      .get('CALANDAR:DAYNAMES:MONDAY')
      .toPromise();
    const tuesday: string = await this.translate
      .get('CALANDAR:DAYNAMES:TUESDAY')
      .toPromise();
    const wednesday: string = await this.translate
      .get('CALANDAR:DAYNAMES:WEDNESDAY')
      .toPromise();
    const thursday: string = await this.translate
      .get('CALANDAR:DAYNAMES:THURSDAY')
      .toPromise();
    const friday: string = await this.translate
      .get('CALANDAR:DAYNAMES:FRIDAY')
      .toPromise();
    const saturday: string = await this.translate
      .get('CALANDAR:DAYNAMES:SATURDAY')
      .toPromise();

    const sun: string = await this.translate
      .get('CALANDAR:DAYNAMESSHORT:SUN')
      .toPromise();
    const mon: string = await this.translate
      .get('CALANDAR:DAYNAMESSHORT:MON')
      .toPromise();
    const tue: string = await this.translate
      .get('CALANDAR:DAYNAMESSHORT:TUE')
      .toPromise();
    const wed: string = await this.translate
      .get('CALANDAR:DAYNAMESSHORT:WED')
      .toPromise();
    const thu: string = await this.translate
      .get('CALANDAR:DAYNAMESSHORT:THU')
      .toPromise();
    const fri: string = await this.translate
      .get('CALANDAR:DAYNAMESSHORT:FRI')
      .toPromise();
    const sat: string = await this.translate
      .get('CALANDAR:DAYNAMESSHORT:SAT')
      .toPromise();

    const su: string = await this.translate
      .get('CALANDAR:DAYNAMESMIN:SU')
      .toPromise();
    const mo: string = await this.translate
      .get('CALANDAR:DAYNAMESMIN:MO')
      .toPromise();
    const tu: string = await this.translate
      .get('CALANDAR:DAYNAMESMIN:TU')
      .toPromise();
    const we: string = await this.translate
      .get('CALANDAR:DAYNAMESMIN:WE')
      .toPromise();
    const th: string = await this.translate
      .get('CALANDAR:DAYNAMESMIN:TH')
      .toPromise();
    const fr: string = await this.translate
      .get('CALANDAR:DAYNAMESMIN:FR')
      .toPromise();
    const sa: string = await this.translate
      .get('CALANDAR:DAYNAMESMIN:SA')
      .toPromise();

    const january: string = await this.translate
      .get('CALANDAR:MONTHNAMES:JANUARY')
      .toPromise();
    const february: string = await this.translate
      .get('CALANDAR:MONTHNAMES:FEBRUARY')
      .toPromise();
    const march: string = await this.translate
      .get('CALANDAR:MONTHNAMES:MARCH')
      .toPromise();
    const april: string = await this.translate
      .get('CALANDAR:MONTHNAMES:APRIL')
      .toPromise();
    const may: string = await this.translate
      .get('CALANDAR:MONTHNAMES:MAY')
      .toPromise();
    const june: string = await this.translate
      .get('CALANDAR:MONTHNAMES:JUNE')
      .toPromise();
    const july: string = await this.translate
      .get('CALANDAR:MONTHNAMES:JULY')
      .toPromise();
    const august: string = await this.translate
      .get('CALANDAR:MONTHNAMES:AUGUST')
      .toPromise();
    const september: string = await this.translate
      .get('CALANDAR:MONTHNAMES:SEPTEMBER')
      .toPromise();
    const october: string = await this.translate
      .get('CALANDAR:MONTHNAMES:OCTOBER')
      .toPromise();
    const november: string = await this.translate
      .get('CALANDAR:MONTHNAMES:NOVEMBER')
      .toPromise();
    const december: string = await this.translate
      .get('CALANDAR:MONTHNAMES:DECEMBER')
      .toPromise();

    const sjan: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:JAN')
      .toPromise();
    const sfeb: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:FEB')
      .toPromise();
    const smar: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:MAR')
      .toPromise();
    const sapr: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:APR')
      .toPromise();
    const smay: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:MAY')
      .toPromise();
    const sjun: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:JUN')
      .toPromise();
    const sjul: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:JUL')
      .toPromise();
    const saug: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:AUG')
      .toPromise();
    const ssep: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:SEP')
      .toPromise();
    const soct: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:OCT')
      .toPromise();
    const snov: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:NOV')
      .toPromise();
    const sdec: string = await this.translate
      .get('CALANDAR:MONTHNAMESSHORT:DEC')
      .toPromise();

    const today: string = await this.translate
      .get('CALANDAR:TODAY')
      .toPromise();
    const clear: string = await this.translate
      .get('CALANDAR:CLEAR')
      .toPromise();

    const localeCalendar = {
      firstDayOfWeek: 0,
      dayNames: [
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday
      ],
      dayNamesShort: [sun, mon, tue, wed, thu, fri, sat],
      dayNamesMin: [su, mo, tu, we, th, fr, sa],
      monthNames: [
        january,
        february,
        march,
        april,
        may,
        june,
        july,
        august,
        september,
        october,
        november,
        december
      ],
      monthNamesShort: [
        sjan,
        sfeb,
        smar,
        sapr,
        smay,
        sjun,
        sjul,
        saug,
        ssep,
        soct,
        snov,
        sdec
      ],
      today: today,
      clear: clear
    };

    return localeCalendar;
  }

  async loadTranslation(language: string) {
    let translations: any;
    if (
      this.translate.currentLang.toLocaleLowerCase() ===
      language.toLocaleLowerCase()
    ) {
      translations = await this.translate.getTranslation(language).toPromise();
    } else {
      translations = await this.getTranslation(language);
    }
    return (this._translations[language] = translations);
  }

  async getTranslation(code: string) {
    return this.get(`${this.url}s/${code}`);
  }

  async uploadTranslation(file: string|ArrayBuffer) {
    return this.postBlob(`${this.url}s`, { file: file, name: 'translation' });
  }

  async exportTranslation() {
    const response: Response = await this.getBlob(`${this.url}s`);
    const blob = await response.body;
    Utils.downloadBlob(blob, 'translations');
  }

  instant(key: string | string[], params?: Object): string {
    return this.translate.instant(key, params);
  }

  async saveTranslation(trad: Traduction) {
    return this.put(`${this.url}`, trad);
  }
}
