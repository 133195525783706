import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {PanelTaskListComponent} from './panel-task-list/panel-task-list.component';
import {TaskViewComponent} from './task-view/task-view.component';
import {PanelSearchRcComponent} from '../rc/panel-search-rc/panel-search-rc.component';
import {PanelSearchRcResultComponent} from '../rc/panel-search-rc-result/panel-search-rc-result.component';
import {PanelShowMultiRcComponent} from '../rc/panel-show-multi-rc/panel-show-multi-rc.component';
import { CorruptedTaskComponent } from './task-view/corrupted-task/corrupted-task.component';

const MODULES = [SharedModule];

const DECLARATIONS = [
  TaskViewComponent,
  PanelTaskListComponent,
  PanelSearchRcComponent,
  PanelSearchRcResultComponent,
  PanelShowMultiRcComponent,
  CorruptedTaskComponent
];

@NgModule({
  imports: MODULES,
  declarations: [...DECLARATIONS],
  entryComponents: [...DECLARATIONS]
})
export class TaskModule {}
