<div
    [class]="overviewIntegrated ? containerStyle : containerStyle + ' ' + map.basemap?.id"
    (click)="expandBasemaps()"
    *ngIf="viewer.mapMgr.map; let map"
>
    <div class="selected-base-map basemap-image ng-viewer-holder">
        <div #holderOverview style="width:100%;height:100%"></div>
        <div class="basemap-shadow-effect">
            <div class="basemap-name">
                {{ (map.basemap?.label != null ? map.basemap?.label : map.basemap?.id) | translate }}
            </div>
        </div>
    </div>
</div>
<nsi-viewer-basemap-list *ngIf="basemapListVisible"></nsi-viewer-basemap-list>
