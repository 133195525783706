import {Sign} from './sign.model';
import {Task} from './task.model';
import {RcMeasure} from './measure.model';

export enum RC_STATUS {
  pending = 'RC_PENDING',
  active = 'RC_ACTIVE',
  old = 'RC_OLD',
  reject = 'RC_REJECT',
  draft = 'RC_DRAFT'
}

export interface Rc {
  id?: number;
  title?: string;
  signs?: Sign[];
  tasks?: Task[];
  rcGedId?: string;
  userId?: number;
  userName?: string;
  userSurname?: string;
  municipalityId?: number;
  isRegional?: boolean;
  header?: string;
  body?: string;
  footer?: string;
  status?: string;
  creationDate?: Date;
  effectiveDate?: Date;
  repealedDate?: Date;
  communalPvGedId?: string;
  secretariaNote?: string;
  secretariaRcGedId?: string;
  secretariaNoteDate?: Date;
  miniterNote?: string;
  ministerRcGedId?: string;
  municipalityName?: string;
  introductionFr?: string;
  introductionNl?: string;
  conclusionFr?: string;
  conclusionNl?: string;
  processDefinitionId?: string;
  processInstanceId?: string;
  abrogations?: RcMeasure[];
  newClauses?: RcMeasure[];
}

export class RcHelper {
  static getDueDate(rc: Rc) {
    if (rc.tasks[0] && rc.tasks[0].dueDate) {
      return rc.tasks[0].dueDate;
    }
    return null;
  }

  static getTaskStatus(rc: Rc, code: string): boolean {
    const tmp_tasks = rc.tasks.filter(task => task.taskKey === code);
    if (tmp_tasks.length === 1) {
      return tmp_tasks[0].isValid;
    }
    return false;
  }

  static getTaskDate(rc: Rc, code: string): Date {
    const tmp_tasks = rc.tasks.filter(task => task.taskKey === code);
    if (tmp_tasks.length === 1) {
      return tmp_tasks[0].validationDate
        ? new Date(tmp_tasks[0].validationDate)
        : null;
    }
    return null;
  }

  static getLastTask(rc: Rc): Task {
    let lastTask: Task = null;
    const tasks = rc.tasks;
    if (tasks) {
      lastTask = tasks[0];
      tasks.forEach(task => {
        if (lastTask) {
          if (
            task.startDate &&
            lastTask.startDate &&
            task.startDate > lastTask.startDate
          ) {
            lastTask = task;
          }
        } else {
          lastTask = task;
        }
      });
    }
    return lastTask;
  }

  static getLastCompletedTask(rc: Rc): Task {
    let lastTask: Task = null;
    const tasks = rc.tasks;
    if (tasks) {
      tasks
        .filter(task => task.completed)
        .forEach(task => {
          if (lastTask) {
            if (
              task.startDate &&
              lastTask.startDate &&
              task.startDate > lastTask.startDate
            ) {
              lastTask = task;
            }
          } else {
            lastTask = task;
          }
        });
    }
    return lastTask;
  }

  static getCurrentTask(rc: Rc): Task {
    const tasks = rc.tasks;
    if (tasks) {
      return tasks.find(task => task.currentTask);
    }
    return null;
  }
}
