import {NgModule} from '@angular/core';
import {Routes} from '@angular/router';


export const SignsRoutes: Routes = [

];


@NgModule({
    providers: [],
    imports: [  ]
})
export class SignsRoutingModule {}
