import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { Picture } from '../../../models/picture.model';
import { SignService } from '../../../core/services/sign.service';
import { FileUpload } from 'primeng/primeng';
import { NotificationService } from '../../../core/services/notification.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'picture-view',
  templateUrl: './picture-view.component.html',
  styleUrls: ['./picture-view.component.scss']
})
export class PictureViewComponent implements OnInit, AfterViewInit {
  @Input() imgs: Picture[];
  @Input() pictureService;
  @Input() sourceId: number;
  @Input() canEdit = false;
  @ViewChild('fileUpload', {static: true}) fileUpload: FileUpload;

  displayPicture = false;
  img: Picture = {};

  constructor(
    private signService: SignService,
    private errorService: NotificationService
  ) {}
  ngOnInit() {}
  ngAfterViewInit() {
    if (this.pictureService) {
      this.pictureService.imgs = [];
      this.pictureService.selectedPictureHandler.subscribe(values => {
        if (this.fileUpload && values && values.length === 0) {
          this.fileUpload.clear();
        }
      });
    }
  }
  async readFile(file: File): Promise<Picture> {
    const reader = new FileReader();
    const def = new Promise<Picture>(resolve => {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve({ file: base64data, name: file.name });
      };
    });
    return def;
  }
  async uploadhandler($event) {
    try {
      this.pictureService.imgs = await Promise.all(
        $event.files.map(this.readFile)
      );
    } catch (error) {
      this.errorService.addSingleError(error);
    }
  }

  async getPictureAsync(id: number) {
    let url = '';
    try {
      const img = await this.pictureService.getPicture(id);
      url = img.pictureLink;
    } catch (error) {
      this.errorService.addSingleError(error);
    }
    return url;
  }

  async showPicture(img: Picture) {
    this.img = img;
    this.displayPicture = true;
  }

  async deletePicture(pic: Picture) {
    if (pic.gedId) {
      try {
        const index: number = this.imgs
          .map(img => img.gedId)
          .indexOf(pic.gedId);
        if (index >= 0) {
          this.signService.deletedImgs.push({
            gedId: pic.gedId,
            objectId: pic.objectId
          });
          this.imgs.splice(index, 1);
        }
      } catch (error) {
        this.errorService.addSingleError(error);
      }
    }
  }
}
