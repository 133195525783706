<div class="basemaps-list">
  <mat-grid-list cols="2" rowHeight="1:1">
    <mat-grid-tile *ngFor="let basemap of viewer.mapMgr.basemaps">
      <div (click)="changeBasemap(basemap)"
        [class]="'basemap-tile' + (basemap.selected ? ' selected primary-background' : '')">
        <div [class]="'basemap-image ' + basemap.id">
          <div class="basemap-shadow-effect">
            <div class="basemap-name" *ngIf="viewer.mapMgr.map">
              {{ (basemap.label != null ? basemap.label : basemap.id) | translate }}
            </div>
          </div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
