import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared/shared.module';
import { LayoutComponent } from './layout.component';
import { LayoutService } from '../../core/services/layout.service';
import { ContainerContentDirective } from '../../shared/components/container/container.content.directive';
import { LayoutHeaderComponent } from './header/layout.header.component';
import { LayoutFooterComponent } from './footer/layout.footer.component';
import { LayoutLeftPanelComponent } from './left-panel/layout.left-panel.component';
import { LayoutRightPanelComponent } from './right-panel/layout.right-panel.component';
import { LoaderComponent } from './loader/loader.component';
import { LayoutActionIconsModule } from '../action-icons/layout.action-icons.module';
import { UserService } from '../../core/services/user.service';
import { NotificationComponent } from './error-dialolg/notification.component';
import { CenterPanelComponent } from './center-panel/center-panel.component';
import { LayoutCenterPanelComponent } from './center-panel/layout.center-panel.component';
/* yang-add-component-import - Yang will add components imports here */

@NgModule({
  declarations: [
    /* yang-add-component-declaration - Yang will add components declarations here */
    LayoutComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    LayoutLeftPanelComponent,
    LayoutRightPanelComponent,
    LayoutCenterPanelComponent,
    LoaderComponent,
    ContainerContentDirective,
    NotificationComponent,
    CenterPanelComponent,
  ],
  imports: [SharedModule, LayoutActionIconsModule],
  exports: [LayoutComponent],
  providers: [LayoutService, UserService],
})
export class LayoutModule {}
