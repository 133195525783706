import { Component, OnInit } from '@angular/core';
import { LoggedComponent } from '../../../shared/components/logged/logged.component';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { NotificationService } from '../../../core/services/notification.service';
import { ShowUserComponent } from '../show-user/show-user.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends LoggedComponent implements OnInit {
  passwordForm: FormGroup;
  oldPassCtrl: FormControl;
  newPass1Ctrl: FormControl;
  newPass2Ctrl: FormControl;
  old = '';
  pass1 = '';
  pass2 = '';

  isValid() {
    return this.pass1.length >= 5 && this.pass1 === this.pass2;
  }

  passwordsMatch() {
    return this.pass1 === this.pass2;
  }

  constructor(
    private fb: FormBuilder,
    userService: UserService,
    private notificationService: NotificationService,
    private layoutService: LayoutService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.old = '';
    this.pass1 = '';
    this.pass2 = '';
    this.oldPassCtrl = this.fb.control('', Validators.required);
    this.newPass1Ctrl = this.fb.control('', Validators.required);
    this.newPass2Ctrl = this.fb.control('', Validators.required);

    this.passwordForm = this.fb.group({
      oldPass: this.oldPassCtrl,
      newPass1: this.newPass1Ctrl,
      newPass2: this.newPass2Ctrl
    });
  }

  async save() {
    const passwordCorrect = await this.userService.checkPassword(this.old);
    if (passwordCorrect) {
      await this.userService.updatePassword(this.pass1);
      this.notificationService.addSingleInfo(
        'Changement de mot de passe',
        'Votre mot de passe a été modifié'
      );
      this.cancel();
    } else {
      this.pass1 = '';
      this.pass2 = '';
      this.old = '';
      this.notificationService.addSingleError(
        'Le mot de passe actuel renseigné n\'est pas valide'
      );
    }
  }

  cancel() {
    this.layoutService.centerPanelContent = ShowUserComponent;
  }
}
