<div class="ui-g actions-panel">
  <div class="ui-g-12" *ngIf="userService.hasRole(userService.agentCode) || userService.hasRole(userService.sprbCode) || userService.hasRole(userService.secretaryCode)">
    <tasks-button></tasks-button>
  </div>
  <div class="ui-g-12" *ngIf="!userService.hasRole(userService.contractorCode)">
    <search-rc-button></search-rc-button>
  </div>
  <div class="ui-g-12">
    <search-sign-button></search-sign-button>
  </div>
  <div class="ui-g-12" *ngIf="userService.hasRole(userService.secretaryCode)">
    <day-order-rc-button></day-order-rc-button>
  </div>
  <div class="ui-g-12" *ngIf="userService.hasRole(userService.agentCode) || userService.hasRole(userService.sprbCode)">
    <unique-regulation-button></unique-regulation-button>
  </div>
</div>
