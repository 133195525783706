import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from '../../../core/services/map.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'documentation-icon',
  templateUrl: './layout.documentation-icon.component.html',
  styleUrls: ['./layout.documentation-icon.component.scss']
})
export class DocumentationIconComponent {
  activeActionItem: any;
  constructor(
    public userService: UserService,
    public i18n: TranslateService,
    protected mapService: MapService
  ) {}
  get url() {
    return `assets/documents/documentation_${
      this.i18n.currentLang ? this.i18n.currentLang.toLocaleLowerCase() : 'fr'
    }.pdf`;
  }
}
