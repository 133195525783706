<p-treeTable #tt [value]="measures" [columns]="cols" dataKey="id" selectionMode="checkbox"
             [(selection)]="selection" [totalRecords]="measures?.length" [scrollable]="true" scrollHeight="60vh">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="w-44"></th>
      <th *ngFor="let col of columns" [className]="col.class">
        {{col.header | translate}}
      </th>
      <th class="w-100" *ngIf="editMode"></th>
    </tr>
    <tr>
      <th></th>
      <th *ngFor="let col of columns">
        <input pInputText type="text" (input)="filter($event.target.value, col)">
      </th>
      <th *ngIf="editMode"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
    <tr [class.isRowDisabled]="rowData.disabled">
      <td class="w-44">
        <p-treeTableCheckbox [disabled]="rowData['disabled'] || disabled" [value]="rowNode"></p-treeTableCheckbox>
      </td>
      <td *ngFor="let col of columns; let i = index" [className]="col.class">
        <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
        {{rowData[col.field]}}
      </td>
      <td class="w-100" *ngIf="editMode">
        <div *ngIf="!rowData.disabled">
          <button *ngIf="update?.observers?.length" (click)="setParentAndUpdate(rowNode)" pButton
                  icon="fa fa-pencil" class="ui-button button-table"></button>
          <div *ngIf="!rowData.signId; then addButton else remove"></div>
          <ng-template #addButton>
            <button *ngIf="add?.observers?.length && rowData.level === 3" (click)="add.emit(rowData)" pButton
                    icon="fa fa-plus" class="ui-button button-table ui-button-success"></button>
          </ng-template>
          <ng-template #remove>
            <button *ngIf="delete?.observers?.length" (click)="delete.emit(rowData)" pButton
                    icon="fa fa-minus" class="ui-button button-table ui-button-danger"></button>
          </ng-template>
        </div>
      </td>
    </tr>
  </ng-template>
</p-treeTable>
