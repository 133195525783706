import { Injectable, Type } from '@angular/core';
import { MessageService, ConfirmationService, Confirmation, DialogService, DynamicDialogConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationService {
  private ref: any;
  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private confirmService: ConfirmationService,
    private dialogService: DialogService
  ) {}

  async addSingleError(error: any) {
    console.error(error);
    const summary = await this.translateService.get('ERROR_OCCURED').toPromise();

    let errorDetail = '';
    if (typeof error === typeof 'string') {
      errorDetail = error;
    } else if (typeof error.error === typeof 'string') {
      errorDetail = error.error;
    } else {
      if (error.message === 'session.expired') {
        errorDetail = 'ERROR:SESSION_EXPIRED';
      } else {
        errorDetail = error.message;
      }
    }
    errorDetail = await this.translateService.get(errorDetail).toPromise();
    this.messageService.add({ key: 'custom', severity: 'error', summary: summary, detail: errorDetail });
  }

  async addSingleInfo(summary: string, info: string) {
    this.messageService.add({ key: 'custom', severity: 'info', summary: summary, detail: info });
  }

  async addSingleSuccess(summary: string, msgDetail: string) {
    this.messageService.add({ key: 'custom', severity: 'success', summary: summary, detail: msgDetail });
  }

  addMultipleError(msgs: any[]) {
    if (msgs && msgs.length) {
      msgs.forEach(msg => {
        this.addSingleError(msg.detail);
      });
    }
  }

  confirm(confirmation: Confirmation) {
    return this.confirmService.confirm(confirmation);
  }

  openDialog(componentType: Type<any>, config: DynamicDialogConfig) {
    this.ref = this.dialogService.open(componentType, config);
    return this.ref;
  }

  closeDialog(data: any) {
    if (this.ref) {
      this.ref.close(data);
    }
  }
}
