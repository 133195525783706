import {ChangeDetectorRef, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Task} from '../../../models/task.model';
import {TaskService} from '../../../core/services/task.service';
import {LayoutService} from '../../../core/services/layout.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RcService} from '../../../core/services/rc.service';
import {RcComponent} from '../../rc/rc.component';
import {Rc, RC_STATUS, RcHelper} from '../../../models/rc.model';
import {UserService} from '../../../core/services/user.service';
import {SelectCode} from '../../../models/code.model';
import {TranslateService} from '@ngx-translate/core';
import {Sign} from '../../../models/sign.model';
import {SignService} from '../../../core/services/sign.service';
import {SignHistory} from '../../../models/sign-history.model';
import {SignComponent} from '../../signs/sign/sign.component';
import {NotificationService} from '../../../core/services/notification.service';
import {PanelSignListComponent} from '../../signs/panel-sign-list/panel-sign-list.component';
import Utils, {CALENDAR_LOCALE_EN} from '../../../shared/services/utils';
import {LoaderService} from 'app/core/services/loader.service';
import { CommunalPvFile, FirstStepTaskUpdateRequestModel } from '../../../models/service/first-step-task-update-request-model';

@Component({
  selector: 'task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss']
})
export class TaskViewComponent implements OnInit, OnDestroy {
  public translate: TranslateService = this.injector.get(TranslateService);
  private tableSigns$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public tableSigns: Observable<any[]> = this.tableSigns$.asObservable();

  private _intervention = {
    add: { id: 1, code: 'SIGN:INTERVENTION:ADD' },
    none: { id: 2, code: 'SIGN:INTERVENTION:NONE' },
    remove: { id: 3, code: 'SIGN:INTERVENTION:REMOVE' }
  };

  public statusOptions: SelectCode[];

  private _allSign: Sign[];
  private _allSignHistory: SignHistory[];

  public files: { [key: number]: File};
  public communalPvSize = 50000000;

  public decisionForm: FormGroup;
  public opinionForm: FormGroup;
  public approvalForm: FormGroup;

  public selectedValueCOM_T01: string;
  public selectedValueCOM_T02: string;
  public selectedValueCOM_T03: string;

  public displayInfoMissingRC: Boolean = false;
  public taskChanges: Subscription;
  public cols: any[];

  public CALENDAR_LOCALE_EN = CALENDAR_LOCALE_EN;

  get RC_STATUS() {
    return RC_STATUS;
  }

  get intervention() {
    return this._intervention;
  }

  get task(): Task {
    return this.taskService.task;
  }

  get rcTask(): Rc {
    return this.taskService.rcTask;
  }

  set allSign(signs: Sign[]) {
    this._allSign = signs;
  }

  get allSign(): Sign[] {
    return this._allSign;
  }

  set allSignHistory(signs: SignHistory[]) {
    this._allSignHistory = signs;
  }

  get allSignHistory(): SignHistory[] {
    return this._allSignHistory;
  }

  getUsername(rc: Rc): string {
    return rc.userName && rc.userSurname ?
      `${rc.userName} ${rc.userSurname}` :
      null;
  }

  constructor(
    public taskService: TaskService,
    public userService: UserService,
    private layoutService: LayoutService,
    public rcService: RcService,
    private signService: SignService,
    private errorService: NotificationService,
    private injector: Injector,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.taskChanges = this.taskService.taskHandler$.subscribe(() => this.init());

    await this.init();

    this.cols = [
      { field: '', header: 'SUPPORT', width: '30%' },
      { field: '', header: 'INTERVENTION', width: '70%' }
    ];
  }

  ngOnDestroy() {
    this.taskChanges.unsubscribe();
  }

  async init() {
    this.signService.signsSelected = [];

    if (this.allSign) {
      this.allSign.splice(0, this.allSign.length);
    } else {
      this.allSign = [];
    }

    if (this.allSignHistory) {
      this.allSignHistory.splice(0, this.allSignHistory.length);
    } else {
      this.allSignHistory = [];
    }

    this.files = null;

    this.decisionForm = this.fb.group({ check: this.fb.control('') });

    this.opinionForm = this.fb.group({
      check: this.fb.control(''),
      motivationFr: this.fb.control(''),
      motivationNl: this.fb.control(''),
      secretariaNoteDate: this.fb.control(new Date()),
    });

    document['opinionForm'] = this.opinionForm;

    this.approvalForm = this.fb.group({
      check: this.fb.control(''),
      motivationFr: this.fb.control(''),
      motivationNl: this.fb.control(''),
      effectiveDate: this.fb.control(new Date()),
    });

    // recuperation des signalisations impacter.
    if (
      this.showFromStatus('COM_T04') ||
      this.showFromStatus('COM_T05') ||
      this.showFromStatus('REG_T03') ||
      this.showFromStatus('REG_T04')
    ) {
      // si accuser de reception.
      if (RcHelper.getLastTask(this.rcTask).isValid) {
        await this.loadHistorySign();
      } else {
        await this.loadStatusOption();
      }
    }
    await this.loadAllSign();
    this.refreshSignTable();
  }

  get currentStatus() {
    let status = 'RC_NEW';
    if (
      this.rcTask.status === 'RC_OLD' ||
      this.rcTask.status === 'RC_REJECT_COMM' ||
      this.rcTask.status === 'RC_REJECT'
    ) {
      status = this.rcTask.status;
    } else {
      const last = RcHelper.getLastCompletedTask(this.rcTask);
      if (last) {
        status = `${last.taskKey}:${last.isValid ? 'APPROVED' : 'REJECTED'}`;
      }
    }
    return status;
  }

  async loadHistorySign() {
    try {
      this.allSignHistory = await this.signService.historyGetSignsFullByRcIds([
        this.rcTask.id
      ]);
    } catch (error) {
      await this.errorService.addSingleError(error);
    }
  }

  refreshSignTable() {
    if (this.allSignHistory.length > 0) {
      this.tableSigns$.next(this.allSignHistory);
    } else {
      this.setDefaultSignStatus(this.allSign);
      this.tableSigns$.next(this.allSign);
    }
    this.cdr.markForCheck();
  }

  setDefaultSignStatus(signs: Sign[]) {
    for (const sign of signs) {
      if ((sign.rcs.length === 1 && sign.rcs[0].status === RC_STATUS.active)) {
        sign.signStatus = { id: this.intervention.add.id, code: this.intervention.add.code };
      } else {
        sign.signStatus = { id: this.intervention.none.id, code: this.intervention.none.code };
      }
    }
  }

  async loadAllSign() {
    try {
      const allSign = await this.signService.getSignsFullForRcIds([this.rcTask.id]);

      this.allSign = this.setStatusSign(allSign);
    } catch (error) {
      await this.errorService.addSingleError(error);
    }
  }

  setStatusSign(signs: Sign[]): Sign[] {
    return signs.map(sign => {
      const intervention = this.intervention;
      if (sign.rcs.length === 1) {
        if (sign.rcs[0].status === RC_STATUS.old) {
          sign.signStatus = intervention.none;
        } else {
          sign.signStatus = intervention.add;
        }
      } else {
        sign.signStatus = intervention.none;
      }
      return sign;
    });
  }

  getAddressLabel(address) {
    return Utils.getAddressLabel(address);
  }

  async loadStatusOption() {
    const addCode = await this.translate
      .get(this.intervention.add.code)
      .toPromise();
    const noneCode = await this.translate
      .get(this.intervention.none.code)
      .toPromise();
    const removeCode = await this.translate
      .get(this.intervention.remove.code)
      .toPromise();
    const intervention = this.intervention;
    this.statusOptions = [{
      label: addCode,
      value: { id: intervention.add.id, code: intervention.add.code }
    }, {
      label: noneCode,
      value: { id: intervention.none.id, code: intervention.none.code }
    }, {
      label: removeCode,
      value: { id: intervention.remove.id, code: intervention.remove.code }
    }];
  }

  /**
   * Try to validate the first step of a task flow which is the communal decision
   */
  async validateCommunalDecision() {
    const canProcessCommunalDecision = this.selectedValueCOM_T01 && this.files;

    if(!canProcessCommunalDecision) {
      this.displayInfoMissingRC = true;
      return;
    }

    try {
      this.loaderService.showLoader();

      const files: File[] = this.toFileList(this.files);
      const mapper = (content: string, file: File): CommunalPvFile => { return { file: content, fileName: file.name }; };
      const promises = files.map(file => this.mapFile(file, (content) => mapper(content, file)));
      const mapped = await Promise.all(promises);

      const taskData = this.rcTaskData;
      const task: FirstStepTaskUpdateRequestModel = {
        value: this.selectedValueCOM_T01 === 'true',
        communalPvFiles: mapped,
        rcId: taskData.rcId,
        taskKey: taskData.taskKey,
        taskId: taskData.taskId
      }

      await this.taskService.updateFirstStepOfTask(task);
      await this.update();

    } catch (error) {
      await this.errorService.addSingleError(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  private mapFile<T>(file: File, mapper: (content: string) => T): Promise<T> {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onloadend = async () => {
        const base64data = reader.result;
        const pv: T = mapper(base64data as string);
        resolve(pv);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  get rcTaskData() {
    const rc = this.rcTask;
    const task = RcHelper.getLastTask(rc);
    const data = {
      rcId: rc.id,
      taskKey: task.taskKey,
      taskId: task.taskId
    };
    return data;
  }

  async communale_favorableOrUnfavorableOpinion() {
    if (
      this.selectedValueCOM_T02 === 'true' ||
      this.selectedValueCOM_T02 === 'false'
    ) {
      try {
        this.loaderService.showLoader();

        const data = {
          value: this.selectedValueCOM_T02 === 'true',
          ...this.opinionForm.getRawValue()
        };
        Object.assign(data, this.rcTaskData);
        const ret = await this.taskService.updateTaskRc(data);
        await this.update();
      } catch (error) {
        await this.errorService.addSingleError(error);
      }
      finally {
        this.loaderService.hideLoader();
      }
    }
  }

  async communale_ministerApproveOrNotCR() {
    if (
      this.selectedValueCOM_T03 === 'true' ||
      this.selectedValueCOM_T03 === 'false'
    ) {
      try {
        this.loaderService.showLoader();

        const rc = this.rcTask;
        const task = RcHelper.getLastTask(rc);
        const data = {
          rcId: rc.id,
          taskKey: task.taskKey,
          taskId: task.taskId,
          value: this.selectedValueCOM_T03 === 'true',
          ...this.approvalForm.getRawValue()
        };
        const ret = await this.taskService.updateTaskRc(data);
        await this.update();
      } catch (error) {
        await this.errorService.addSingleError(error);
      }
      finally{
        this.loaderService.hideLoader();
      }
    }
  }

  async communale_finalValidation() {
    this.loaderService.showLoader();

    try {
      const action = [];

      this.allSign.forEach(sign => {
        action.push({ signId: sign.id, intervention: sign.signStatus });
      });
      const rc = this.rcTask;
      const task = RcHelper.getLastTask(rc);
      const data = {
        rcId: rc.id,
        taskKey: task.taskKey,
        taskId: task.taskId,
        action: action,
        value: true
      };
      const ret = await this.taskService.updateTaskRc(data);
      await this.update();
    } catch (error) {
      await this.errorService.addSingleError(error);
    }
    finally{
      this.loaderService.hideLoader();
    }
  }

  async update() {
    try {
      this.loaderService.showLoader();

      let rcTasks: Rc[] = await this.rcService.getTasks().toPromise();

      rcTasks = rcTasks.sort((a: Rc, b: Rc) => {
        const dda = RcHelper.getDueDate(a);
        const ddb = RcHelper.getDueDate(b);
        if (dda > ddb) {
          return 1;
        }
        if (dda < ddb) {
          return -1;
        }
        return 0;
      });

      this.taskService.rcTasks = rcTasks;
    } catch (error) {
      await this.errorService.addSingleError(error);
    }
    finally {
      this.loaderService.hideLoader();
    }

    this.layoutService.closeRightPanel();
  }

  close() {
    this.layoutService.closeRightPanel();
  }

  expand() {
    this.layoutService.leftPanelFull = false;
    this.layoutService.rightPanelFull = !this.layoutService.rightPanelFull;
  }

  async generateRcDocument($event) {
    $event.stopPropagation();
    this.loaderService.showLoader();

    try {
      await this.rcService.getSingleGedDocument(this.taskService.rcTask.rcGedId).toPromise();
    } catch (error) {
      await this.errorService.addSingleError(error);
    }
    finally {
      this.loaderService.hideLoader();
    }
  }

  updateRC() {
    this.rcService.rc = this.taskService.rcTask;
    this.rcService.editMode = true;
    this.rcService.fromTaskView = this.taskService.rcTask;
    this.layoutService.rightPanelContent = RcComponent;
    this.layoutService.rightPanelStyle = {width: '800px'};
    this.layoutService.rightPanelFull = true;
  }

  async addPvFile($event: { originalEvent: Event, files: FileList }) {
    try {
      if (
        $event.files &&
        $event.files.length > 0 &&
        this.toFileList($event.files).every((file: File) => file.size <= this.communalPvSize)
      ) {
        this.files = $event.files;
      }
    } catch (error) {
      await this.errorService.addSingleError(error);
    }
  }

  async removePvFile($event: { originalEvent: PointerEvent, file: File }) {
    try {
      const files: File[] = this.toFileList(this.files);
      const removed = files.filter(file => file.name !== $event.file.name);
      this.files = removed.reduce((accumulator, value, currentIndex) => { return { ...accumulator, [currentIndex]: value } }, {});
    } catch (error) {
      await this.errorService.addSingleError(error);
    }
  }

  private toFileList(files: FileList | { [key: number]: File }): File[] {
    return Object.keys(files).map(key => files[key]);
  }

  hideInfoMissingRC() {
    this.displayInfoMissingRC = false;
  }

  isInProgress(code: string): boolean {
    const found = this.rcTask.tasks.find(task => !task.completed);
    return found ? found.taskKey === code : false;
  }

  isDone(code: string): boolean {
    const found = this.rcTask.tasks.find(task => task.taskKey === code);
    return found ? found.completed : false;
  }

  showFromStatus(code: string): boolean {
    const tasks = this.rcTask.tasks.filter(task => task.taskKey === code);
    return tasks.length === 1;
  }

  backToSignView(): boolean {
    if (this.taskService.fromSignView) {
      this.signService.editMode = false;
      this.layoutService.rightPanelContent = SignComponent;
      this.taskService.fromSignView = null;
      return true;
    }
    return false;
  }

  zoomToSigns() {
    this.signService.signsSelected = this.allSign;
    this.layoutService.leftPanelContent = PanelSignListComponent;
    this.layoutService.leftPanelVisible = true;
  }
}
