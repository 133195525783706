import { Component, Input } from '@angular/core';
import { NgViewerModuleComponent } from '@nsi/gis-viewer-ui';

/**
 * BasemapListComponent shows, in a region or a floating widget, a list of all available basemaps. The user
 * can choose one by clicking it.
 */
@Component({
  selector: 'nsi-viewer-basemap-list',
  templateUrl: './basemap-list.component.html',
  styleUrls: ['./basemap-list.component.scss'],
})
export class BasemapListComponent extends NgViewerModuleComponent {
  /**
   * Update the basemap of the map with the new one selected by the user
   */
  changeBasemap(basemap) {
    this.viewer.mapMgr.map.basemap = basemap;
  }
}
