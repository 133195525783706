import {Injectable} from '@angular/core';
import {BaseAbstractService} from './base-abstract.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Session} from '../../app.session';
import {ConfigService} from './config.service';
import {NotificationService} from './notification.service';
import {TranslationService} from './translation.service';
import {ParameterModel} from '../../models/parameter.model';

@Injectable()
export class ParameterService extends BaseAbstractService {

  constructor(_router: Router,
              _http: HttpClient,
              _session: Session,
              configService: ConfigService,
              errorService: NotificationService,
              private translationService: TranslationService) {
    super(_router, _http, _session, configService, errorService);
  }

  RC_TEMPLATE_COMM_INTRO_FR = 'ADMINISTRATION:TEMPLATE:COMMUNAL:INTRO:FR';
  RC_TEMPLATE_COMM_INTRO_NL = 'ADMINISTRATION:TEMPLATE:COMMUNAL:INTRO:NL';
  RC_TEMPLATE_COMM_CONCL_FR = 'ADMINISTRATION:TEMPLATE:COMMUNAL:CONCL:FR';
  RC_TEMPLATE_COMM_CONCL_NL = 'ADMINISTRATION:TEMPLATE:COMMUNAL:CONCL:NL';
  RC_TEMPLATE_REG_INTRO_FR = 'ADMINISTRATION:TEMPLATE:REGIONAL:INTRO:FR';
  RC_TEMPLATE_REG_INTRO_NL = 'ADMINISTRATION:TEMPLATE:REGIONAL:INTRO:NL';
  RC_TEMPLATE_REG_CONCL_FR = 'ADMINISTRATION:TEMPLATE:REGIONAL:CONCL:FR';
  RC_TEMPLATE_REG_CONCL_NL = 'ADMINISTRATION:TEMPLATE:REGIONAL:CONCL:NL';

  RC_TEMPLATE_INTRODUCTION = 'ADMINISTRATION:PARAMETER:RC_TEMPLATE_INTRODUCTION';
  RC_TEMPLATE_CONCLUSION = 'ADMINISTRATION:PARAMETER:RC_TEMPLATE_CONCLUSION';
  IGNORE_PARAMETERS_IN_FIND_ALL: string[] = [
    this.RC_TEMPLATE_CONCLUSION,
    this.RC_TEMPLATE_INTRODUCTION,
    this.RC_TEMPLATE_COMM_INTRO_FR,
    this.RC_TEMPLATE_COMM_INTRO_NL,
    this.RC_TEMPLATE_COMM_CONCL_FR,
    this.RC_TEMPLATE_COMM_CONCL_NL,
    this.RC_TEMPLATE_REG_INTRO_FR,
    this.RC_TEMPLATE_REG_INTRO_NL,
    this.RC_TEMPLATE_REG_CONCL_FR,
    this.RC_TEMPLATE_REG_CONCL_NL
  ];

  get url(): string {
    return environment.backendURL + environment.resourcePath + this.configService.appConfig.apiUrl + 'Parameter';
  }

  private _parameters: ParameterModel[];

  get parameters(): ParameterModel[] {
    return this._parameters;
  }

  set parameters(value: ParameterModel[]) {
    this._parameters = value;
  }

  private _allParameters: ParameterModel[];

  get allParameters(): ParameterModel[] {
    return this._allParameters;
  }

  set allParameters(value: ParameterModel[]) {
    this._allParameters = value;
  }

  private _introRcTemplate: ParameterModel;

  get introRcTemplate():ParameterModel {
    return this._introRcTemplate;
  }

  set introRcTemplate(value: ParameterModel) {
    this._introRcTemplate = value;
  }

  private _conclusionRcTemplate: ParameterModel;

  get conclusionRcTemplate():ParameterModel{
    return this._conclusionRcTemplate;
  }

  set conclusionRcTemplate(value: ParameterModel) {
    this._conclusionRcTemplate = value;
  }

  async findAll(): Promise<ParameterModel[]> {
    this.allParameters = this.allParameters ? this.allParameters: await this.get(`${this.url}/all`);
    this.parameters = this.allParameters.filter(param => {
      if(param.code == this.RC_TEMPLATE_INTRODUCTION){
        this.introRcTemplate = param;
      }else if(param.code == this.RC_TEMPLATE_CONCLUSION){
        this.conclusionRcTemplate = param;
      }
      return this.IGNORE_PARAMETERS_IN_FIND_ALL.indexOf(param.code) == -1;
    });
    return this.parameters;
  }

  async saveIntroParameter(param: ParameterModel){
    this.introRcTemplate = await this.saveSingleParameter(param);
    this.mergeNewParam(param);
    return this.introRcTemplate;
  }

  async saveConclusionParameter(param: ParameterModel){
    this.conclusionRcTemplate = await this.saveSingleParameter(param);
    this.mergeNewParam(param);
    return this.conclusionRcTemplate;
  }

  async save(parameters: ParameterModel[]): Promise<ParameterModel[]> {
    this.parameters = await this.put(`${this.url}/updateParameters`, parameters);
    return this.parameters;
  }

  async saveSingleParameter(parameter: ParameterModel){
    let parameters =  await this.put(`${this.url}/updateParameters`, [parameter]);
    return parameter;
  }

  mergeNewParam(param: ParameterModel){
    let paramToRemove = this.allParameters.find(x => x.code == param.code);
    let index = this.allParameters.indexOf(paramToRemove);
    if(index > -1){
      this.allParameters[index]=param;
    }
  }

  find(code: string){
    return this.allParameters.find(x => x.code == code);
  }

  saveLocaly(param: ParameterModel){
    this.find(param.code).value = param.value;
  }
}
