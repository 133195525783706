import {Component, Injector, OnInit} from '@angular/core';
import {TaskService} from '../../../core/services/task.service';
import {TaskViewComponent} from '../task-view/task-view.component';
import {LayoutService} from '../../../core/services/layout.service';
import {Rc, RcHelper} from '../../../models/rc.model';
import {RcService} from '../../../core/services/rc.service';
import {SignType} from '../../../models/code.model';
import {NotificationService} from '../../../core/services/notification.service';
import {MapService} from '../../../core/services/map.service';
import {SignService} from '../../../core/services/sign.service';
import {SignComponent} from '../../signs/sign/sign.component';
import {Sign, SIGN_MOBILE_STATUS} from '../../../models/sign.model';
import {UserService} from '../../../core/services/user.service';
import {SelectItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import Utils from '../../../shared/services/utils';
import { LoaderService } from 'app/core/services/loader.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'panel-task-list',
  templateUrl: './panel-task-list.component.html',
  styleUrls: ['./panel-task-list.component.scss']
})
export class PanelTaskListComponent implements OnInit {
  translate: TranslateService = this.injector.get(TranslateService);
  // tri Tasks
  sortFieldTask: string;

  sortOrderTask: number;

  sortOptionsTask: SelectItem[];

  sortKeyTask: SelectItem;

  // tri Recensement
  sortFieldRecense: string;

  sortOrderRecense: number;

  sortOptionsRecense: SelectItem[];

  sortKeyRecense: SelectItem;

  signsTypeUnique(rc: Rc): SignType[] {
    const signsTypeUnique: SignType[] = [];
    rc.signs.forEach(sign => {
      if (
        signsTypeUnique.findIndex(type => {
          if (type.code === sign.type.code) {
            return true;
          }
        })
      ) {
        signsTypeUnique.push(sign.type);
      }
    });
    return signsTypeUnique;
  }

  streetsUniqueRc(rc: Rc) {
    const streetUnique = new Set();
    rc.signs.map(sign => {
      const label = Utils.getAddressLabel(sign.address);
      streetUnique.add(label);
    });
    return Array.from(streetUnique);
  }
  get SIGN_MOBILE_STATUS() {
    return SIGN_MOBILE_STATUS;
  }

  constructor(
    public taskService: TaskService,
    private rcService: RcService,
    public userService: UserService,
    private mapService: MapService,
    public signService: SignService,
    private errorService: NotificationService,
    private layoutService: LayoutService,
    private injector: Injector,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.layoutService.visibilityLeftPanelHandler.subscribe(res => {
      if (!res) {
        this.mapService.highlightedSupports = [];
      }
    });
    this.init();
  }

  async init() {
    const filteringNewest = await this.translate
      .get('FILTERING:NEWEST')
      .toPromise();
    const filteringOldest = await this.translate
      .get('FILTERING:OLDEST')
      .toPromise();
    this.sortOptionsTask = [
      { label: filteringNewest, value: '!id' },
      { label: filteringOldest, value: 'id' }
    ];
    this.sortOptionsRecense = [
      { label: filteringNewest, value: '!mobileStatusDate' },
      { label: filteringOldest, value: 'mobileStatusDate' }
    ];
    this.sortKeyTask = this.sortOptionsTask[0].value;
    this.sortKeyRecense = this.sortOptionsRecense[0].value;
  }

  tabChanged() {
    this.mapService.highlightedSupports = [];
  }

  closePanel() {
    this.layoutService.closeRightPanel();
  }

  getAddressLabel(address) {
    return Utils.getAddressLabel(address);
  }

  async selectTask(task: Rc) {
    const rcId = task.id;

    this.taskService.fromSignView = null;

    try {
      this.loaderService.showLoader();

      const rc = await this.rcService.getRc(rcId).toPromise();
      this.taskService.rcTask = rc;
      this.layoutService.rightPanelContent = TaskViewComponent;
      this.layoutService.rightPanelVisible = true;
    } catch (error) {
      this.errorService.addSingleError(error);
    }
    finally{
      this.loaderService.hideLoader();
    }
  }

  isOver(rc: Rc): boolean {
    const task = RcHelper.getLastTask(rc);
    return task && task.taskKey === 'COM_T01' && (task.remainingDays == null || task.remainingDays < 0);
  }

  close() {
    this.layoutService.leftPanelVisible = false;
  }

  statusIn(rc: Rc, keys: string[]): boolean {
    const task = RcHelper.getLastTask(rc);
    return task && keys.indexOf(task.taskKey) >= 0;
  }

  showSign(sign: Sign) {
    this.signService.editMode = false;
    this.signService.sign = sign;
    if (sign.support) {
      this.mapService.zoomToSupport(sign.support);
      this.mapService.refresh();
    }
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = SignComponent;
  }

  async terminateRecense(recensement: Sign, event) {
    event.stopPropagation();
    try {
      const ret = await this.signService.updateSignRecense(recensement.id);
      const pos = this.signService.signsRecense.findIndex(
        sign => sign.id === ret.id
      );
      if (pos >= 0) {
        this.signService.signsRecense.splice(pos, 1);
      }
    } catch (error) {
      this.errorService.addSingleError(error);
    }
  }

  onSortChangeTask(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrderTask = -1;
      this.sortFieldTask = value.substring(1, value.length);
    } else {
      this.sortOrderTask = 1;
      this.sortFieldTask = value;
    }
  }

  onSortChangeRecense(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrderRecense = -1;
      this.sortFieldRecense = value.substring(1, value.length);
    } else {
      this.sortOrderRecense = 1;
      this.sortFieldRecense = value;
    }
  }
}
