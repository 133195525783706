import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Code} from '../../models/code.model';
import {BaseAbstractService} from './base-abstract.service';

@Injectable()
export class SignFormatService extends BaseAbstractService
{
  _codes:Code[];

  _default:string = null;

  get url():string {
    return environment.backendURL + environment.signPath+ this.configService.appConfig.apiUrl + 'Sign/SignFormats';
  }

  async findAll(): Promise<Code[]> {
    this._codes= this._codes ? this._codes: await this.get<Code[]>(`${this.url}`);
    return this._codes;
  }

  get default():Code{
    return this._codes.find((code)=>{
      return this._default==code.code?true:false;
    })||this._codes[0];
  }
}
